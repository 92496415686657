<template>
  <v-container fluid class="settings my-0 py-0">
    <v-row>
      <v-container fluid class="settings-content ma-0 pa-0">
        <v-row class="height100 pl-2">
          <v-col class="pa-0" sm="1" md="3" lg="3">
            <een-side-menu
              :sideMenuHeader="`${user.firstName} ${user.lastName}`"
              :menus="menus"
              :currentEndpoint="menus[menuIndex].endpoint"
              @itemSelected="navigate"
              :sideMenuDrop="users"
              :path="'/settings/users/'"
              :subPath="'/details'"
            ></een-side-menu>
          </v-col>
          <v-col id="settings-content" class="pa-0" cols="12" sm="11" md="9" lg="9">
            <router-view
              :key="$route.path"
              :rights="rights"
              :cameras="cameras"
              :user="user"
              @nameUpdated="userUpdated"
              toolbar-color="transparent"
            ></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import { store } from "@/store";
import { getMessageFromError } from "@eencloud/core-components/src/service/errors";

export default {
  name: "SingleUser",
  props: ["rights", "cameras"],
  data() {
    return {
      menu: "details",
      users: [],
      user: {},
      menus: [
        {
          name: "Details",
          endpoint: "details",
          icon: "fas fa-user",
        },
        {
          name: "Permissions",
          endpoint: "rights",
          icon: "fas fa-user-check",
        },
        {
          name: "Cameras",
          endpoint: "cameras",
          icon: "fas fa-camera",
        },
      ],
      menuIndex: 0,
    };
  },

  methods: {
    async userUpdated() {
      try {
        this.user = await this.restapi.getSpecificUser(this.$route.params.userId);
      } catch (error) {
        store.dispatch("toastMessage", {
          text: getMessageFromError(error, "GET_USER"),
          color: "error",
          showing: true,
          timeout: -1,
          support: true,
        });
      }
    },
    getComponentFromRoute() {
      this.menus.map((item, i) => {
        if (this.$route.path.search(item.endpoint) > -1) {
          this.menuIndex = i;
        }
      });
    },
    navigate(menu) {
      if (this.menus[this.menuIndex].endpoint !== menu.endpoint)
        this.$router.replace({
          path: `/settings/users/${this.$route.params.userId}/${menu.endpoint}`,
        });
    },
  },
  async mounted() {
    try {
      const allUsers = await this.restapi.getUsers();
      const account = this.$store.getters.account ?? await this.restapi.getAccountDetails();
      this.users = allUsers
        .filter((user) => account.userId != user.userId)
        .map((user) => {
          return Object.assign(user, {
            name: user.firstName + " " + user.lastName,
            cameraId: user.userId,
          });
        });
      this.user = this.users.find((user) => user.userId == this.$route.params.userId);
    } catch (error) {
      store.dispatch("toastMessage", {
        text: getMessageFromError(error, "GET_USER"),
        color: "error",
        showing: true,
        timeout: -1,
        support: true,
      });
    }
  },
  updated() {
    this.getComponentFromRoute();
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/main";

.settings {
  .settings-content {
    background-color: $color-panel-grey;
    min-height: $main-content-height;
  }
}

#settings-content {
  margin-top: 50px;
}
</style>
