<template>
    <div>
        <v-tooltip :value="tooltip" nudge-right="10" nudge-bottom="40" right absolute :position-x="tooltipX" :position-y="tooltipY">
            <span>{{ mouseDown ? time : $t("Click and drag to edit") }}</span>
        </v-tooltip>
        <div @mousemove="updateTooltipPos" @mouseleave="hideTooltip" v-resize="updateMarkerWidth" ref="containerRef" class="container d-flex flex-wrap">
            <div class="marker flex-grow-1 flex-shrink-1" v-for="(value,index) in markers" :key="`${index}-${value}`" @mousedown.stop="setMouseDown(index, $event)">
                <div class="body" :class="{'active': !!value}"></div>
                <div v-if="!(index % 12) || index + 1 === markerCount" class="test" :style="{'left': index + 1 === markerCount ? `${ -16 + markerWidth}px` : '-16px' }">
                    <span>{{ pad(index + 1 === markerCount ? (index + 1) / 4 : index / 4) }}:00</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { computed, onBeforeUnmount, onMounted, reactive, toRefs, watch } from 'vue'
import { pad } from '@/service/main'
export default {
    props: {
        intervalList: {
            type: Array,
            default: () => []
        }
    },
    setup (props, context) {
        const state = reactive({
            markerCount: 96,
            markers: [],
            markerRanges: [],
            mouseDown: false,
            adding: true,
            startingPoint: null,
            startingX: null,
            markerWidth: 0,
            offset: 0,
            containerRef: null,
            tooltip: false,
            tooltipX: 0,
            tooltipY: 0,
            time: "00:00"
        })

        const { setMouseDown, setMouseUp, mouseMove, clearSelection } = MouseFunctions(state, context)
        const { setMarkerArray, loadIntervals, updateMarkerWidth } = IntervalArrayFunctions(state)
        const { updateTooltipPos, hideTooltip } = TooltipFunctions(state)

        setMarkerArray(state.markerCount)

        watch( () => props.intervalList, () => {
            setMarkerArray(state.markerCount)
            loadIntervals(props.intervalList)
        })

        onMounted(() => {
            updateMarkerWidth()
            loadIntervals(props.intervalList)

            document.addEventListener("mouseup", setMouseUp)
            document.addEventListener("mousemove", mouseMove)
        })

        onBeforeUnmount(() => {
            document.removeEventListener("mouseup", setMouseUp)
            document.removeEventListener("mousemove", mouseMove)
        })

        return { ...toRefs(state), setMouseDown, setMouseUp, updateMarkerWidth, updateTooltipPos, hideTooltip }
    }
}

function IntervalArrayFunctions(state) {
    function setMarkerArray(count) {
        for (let index = 0; index < count; index++) {
            Vue.set(state.markers, index, false)
        }
    }

    function loadIntervals(intervals) {
        intervals.forEach(interval => {
            const start = interval.start.substring(1).split(':')
            const end = interval.end.substring(1).split(':')

            const startingPoint = (start[0] * 4) + Math.round(start[1] / 15)
            const endingPoint = (end[0] * 4) + Math.round(end[1] / 15) - 1

            setRange(startingPoint, endingPoint, true)
        });
    }

    function setRange(startingPoint, endPoint, value) {
        state.markers = state.markers.map((marker, index) => {
            return index >= Math.min(startingPoint, endPoint) && index <= Math.max(startingPoint, endPoint) ? value : marker
        })
    }

    function setTooltipTime(point) {
        let currentMarker = Math.min(Math.max(point + 1, 0), state.markerCount)
        let hours = Math.floor(currentMarker / 4)
        let minutes = currentMarker % 4

        state.time = `${pad(hours)}:${pad(minutes * 15)}`
    }

    function updateMarkerWidth() {
        if (state.containerRef) {
            state.markerWidth = state.containerRef.offsetWidth / state.markerCount
        }
    }

    return { setMarkerArray, loadIntervals, setRange, updateMarkerWidth, setTooltipTime }
}

function MouseFunctions(state, context) {
    const { setRange, setTooltipTime } = IntervalArrayFunctions(state)
    function setMouseDown(index, event) {
        clearSelection()
        state.startingPoint = index
        state.startingX = event.pageX
        state.adding = !state.markers[index]
        state.mouseDown = true
        Vue.set(state.markers, index, state.adding)
        setTooltipTime(index)
    }
    
    function setMouseUp() {
        if (state.mouseDown) {
            state.mouseDown = false
            const intervals = []
            const interval = {
                start: null,
                end: null
            }
            state.markers.forEach((marker, index) => {
                if (!interval.start && marker) {
                    interval.start = `T${pad(Math.floor(index / 4))}:${pad((index % 4) * 15)}:00`
                } else if (interval.start && !marker) {
                    interval.end = `T${pad(Math.floor(index / 4))}:${pad((index % 4) * 15)}:00`
                    intervals.push({ ...interval })
                    
                    interval.start = null
                    interval.end = null
                }
                if (interval.start && index + 1 === state.markerCount) {
                    interval.end = `T23:59:59`
                    intervals.push({ ...interval })
                }
            })
            context.emit('rangeSelected', intervals)
        }
    }

    function mouseMove(event) {
        if (state.mouseDown) {
            let diff = event.pageX - state.startingX
            let steps = Math.floor(diff / state.markerWidth)
            let endPoint = state.startingPoint + steps
            setRange(state.startingPoint, endPoint, state.adding)
            setTooltipTime(endPoint)
        }
    }

    function clearSelection() {
        if (window.getSelection) {window.getSelection().removeAllRanges();}
        else if (document.selection) {document.selection.empty();}
    }

    return { setMouseDown, setMouseUp, mouseMove, clearSelection }
}

function TooltipFunctions(state) {
    function updateTooltipPos(e) {
        state.tooltipX = e.x;
        state.tooltipY = e.y;
        state.tooltip = true;
    }
    function hideTooltip () {
        state.tooltip = false;
    }

    return { updateTooltipPos, hideTooltip }
}
</script>

<style lang="scss" scoped>

.container {
    user-select: none;
    height: 25px;
    margin: 0px;
    padding: 0;
    box-sizing: content-box;
    .marker {
        &:nth-child(8n +1), &:nth-child(8n +2), &:nth-child(8n +3), &:nth-child(8n +4) {
            .body {
                background-color: rgb(169, 169, 169);
                &.active {
                    background-color: var(--v-primary-lighten2);
                }
            }
        }
        &:nth-child(8n), &:nth-child(8n -1), &:nth-child(8n -2), &:nth-child(8n -3) {
            .body {
                background-color: rgb(119, 119, 119);
                &.active {
                    background-color: var(--v-primary-base);
                }
            }
        }
        height: 100%;
        user-select: none;
        cursor: pointer;
        padding: 0px;
        .body {
            width: 100%;
            height: 100%;
        }
        .test {
            cursor: default;
            pointer-events: none;
            position: relative;
            width: 0px;
            span {
                position: absolute;
                font-size: 12px;
                padding-top: 4px;
            }
        }
    }
}

</style>