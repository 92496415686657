<template>
  <v-container fluid class="dashboard my-0 py-0">
    <v-row justify="space-around" class="fill-height">
      <v-col class="dashboard-content-left">
        <v-row class="px-3 pt-6">
          <h2 class="headline">{{ $t('Zone settings') }}</h2>
        </v-row>
          <v-card>
            <een-spinner 
              :isloading="loadingZone"
            ></een-spinner>
            <v-col class="py-0">
              <v-list dense two-line>
                <v-list-item class="py-0">
                  <v-list-item-content class="py-0">
                    <v-container fluid>
                      <v-row>
                        <v-col> 
                          <v-text-field
                            v-model="zoneName"
                            :label="$t('Zone name')"
                            :error-messages="zoneNameErrors"
                            @input="delayTouch($v.zoneName)"
                            @blur="$v.zoneName.$touch()"
                            :disabled="loadingZone"
                            flat dense class="mb-2"
                          ></v-text-field>
                          <v-text-field
                            v-model="zonePrimaryPhone"
                            :label="$t('Primary phone number')"
                            :error-messages="zonePrimaryPhoneErrors"
                            @input="delayTouch($v.zonePrimaryPhone)"
                            @blur="$v.zonePrimaryPhone.$touch()"
                            :disabled="loadingZone"
                            flat dense class="mb-2"
                          ></v-text-field>
                          <v-text-field
                            v-model="zoneSecondaryPhone"
                            :label="$t('Secondary phone number')"
                            :error-messages="zoneSecondaryPhoneErrors"
                            @input="delayTouch($v.zoneSecondaryPhone)"
                            @blur="$v.zoneSecondaryPhone.$touch()"
                            :disabled="loadingZone"
                            flat dense class="mb-2"
                          ></v-text-field>
                          <v-text-field
                            v-model="zoneAddress"
                            :label="$t('Address')"
                            :error-messages="zoneAddressErrors"
                            @input="delayTouch($v.zoneAddress)"
                            @blur="$v.zoneAddress.$touch()"
                            :disabled="loadingZone"
                            flat dense class="mb-2"
                          ></v-text-field>
                          <v-text-field
                            v-model="zoneRegion"
                            :label="$t('Region')"
                            :error-messages="zoneRegionErrors"
                            @input="delayTouch($v.zoneRegion)"
                            @blur="$v.zoneRegion.$touch()"
                            :disabled="loadingZone"
                            flat dense class="mb-2"
                          ></v-text-field>
                          <v-text-field
                            v-model="zonePostalCode"
                            :label="$t('Postal Code')"
                            :error-messages="zonePostalCodeErrors"
                            @input="delayTouch($v.zonePostalCode)"
                            @blur="$v.zonePostalCode.$touch()"
                            :disabled="loadingZone"
                            flat dense class="mb-2"
                          ></v-text-field>
                          <v-text-field
                            v-model="zoneCity"
                            :label="$t('City')"
                            :error-messages="zoneCityErrors"
                            @input="delayTouch($v.zoneCity)"
                            @blur="$v.zoneCity.$touch()"
                            :disabled="loadingZone"
                            flat dense class="mb-2"
                          ></v-text-field>
                          <v-autocomplete
                            v-model="zoneCountry"
                            :items="countries"
                            @change="$v.zoneCity.$touch()"
                            :label="$t('Country')"
                            :disabled="loadingZone"
                            flat dense class="mb-2"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-list-item-content>
                </v-list-item>
                <v-flex>
                  <v-btn
                    color="primary"
                    @click="saveChanges" 
                    :disabled="$v.$anyError || !$v.$anyDirty"
                  >{{ $t("Save changes") }}
                  </v-btn>
                </v-flex>
              </v-list>
            </v-col>
          </v-card>
      </v-col>
      <v-col class="dashboard-content-right">
        <v-row class="px-3 pb-4 pt-6">
          <v-spacer></v-spacer>
              <v-btn
                color="primary"
                :ripple="false"
                @click="$router.push({ name: 'AddCamera', params: { zoneId: zone.zoneId } })"
                class="mr-6"
                v-if="rights && (rights.addCameraManually || rights.demoUser)"
              >
              {{ $t('Add new camera') }}
              </v-btn>
              <v-btn
                color="primary"
                :ripple="false"
                @click.exact="showCameraDialog = true"
                v-if="rights && (rights.editCamera || rights.demoUser)"
              >
              {{ $t('Transfer Camera') }}
              </v-btn>
        </v-row>
        <v-card>
          <v-data-table
          :headers="headers"
          :items="computedZoneCameras"
          item-key="cameraId"
          hide-default-header
          hide-default-footer
          :footer-props="{ 'items-per-page-text': '', 'items-per-page-options': [10000]}"
          dense
          :no-data-text= "$t('There are no cameras in this zone')"
          >
            <template v-slot:header="{ props, on }">
              <thead>
                <tr>
                  <th class="table-head" v-for="(header, index) in props.headers" :key="index">
                    <a @click="on.sort(header.value)" >
                      {{ $t(header.text) }}
                      <v-icon 
                        class="pb-1" 
                        v-if="header.value === props.options.sortBy[0]" 
                        color="grey darken-2" size="12px">{{ props.options.sortDesc[0] ? 'fas fa-long-arrow-alt-down' : 'fas fa-long-arrow-alt-up' }}
                      </v-icon>
                    </a>
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr :key="item.cameraId" :class="{ 'table-row': true }" @click="$router.push({ path: `/settings/cameras/${item.cameraId}` })">
                <td class="table-cell">{{ item.name }}</td>
                <td class="table-cell action-buttons">
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>  
    <v-dialog v-model="showCameraDialog" width="600">
      <div class="cameraDialog">
        <div class="pt-3 pl-10">{{$t(`Select the cameras you'd like to transfer to X`, {zone: zone.name}) }}</div>
        <div class="px-4">
          <v-chip
            class="mt-2 camera-chips ml-1 mr-1"
            v-for="(camera, index) in addableCameras"
            :id="`cameraDialog${index}`"
            :key="camera.cameraId"
            ref="cameraChip"
            @click="selectCamera(camera, index)"
          >
            {{ camera.name }}
          </v-chip>
        </div>
          <v-row v-if="moveWarning" color="primary" class="mx-auto">
            <v-col class="ml-4 mr-0">
              {{$t(warningText)}}
            </v-col>
            <v-col class="px-0 d-flex justify-end mr-4">
              <v-btn depressed class="ma-2" @click.stop="confirmMovingCameras" color="primary">{{$t("transfer")}}</v-btn>
              <v-btn depressed class="ma-2 mr-0" @click.stop="showCameraDialog=false" >{{ $t("Cancel") }}</v-btn>
            </v-col>
          </v-row>
        <div v-if="!moveWarning" class="d-flex justify-end mr-2">
          <v-btn depressed class="ma-2" @click.stop="showCameraDialog=false">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn depressed :disabled="!camerasToAdd.length" class="ma-2" @click.stop="moveWarning = true">
            {{ $t("Transfer") }}
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import { required, numeric, minLength, maxLength, minValue, maxValue } from "vuelidate/lib/validators";
import { store } from "@/store";
import { getMessageFromError } from "@eencloud/core-components/src/service/errors";

export default {
  name: 'EditZone',
  components: {},
  props: ['cameras', 'account', 'zones', 'rights'],
  data() {
    return {
      zoneCameras: null,
      showToolbar: false,
      showCameraDialog: false,
      moveWarning: false,
      allowCameraTransfer: false,
      isDirty: false,
      loadingZone: false,
      camerasToAdd: [],
      zonePrimaryPhone: "",
      zoneSecondaryPhone: "",
      zone: {},
      zoneName:"",
      zoneAddress: "",
      zoneCity:"",
      zoneRegion:"",
      zonePostalCode:"",
      zoneCountry:"",
      headers: [
        { text: 'Name', value: 'name' },
        { text: '', value: 'expand' },
      ],
    }
  },
  mounted() {
    this.getZone() 
  },
  watch: {
    showCameraDialog() {
      if (this.showCameraDialog == false) {
        this.closeCameraDialog()
      }
    }
  },
  computed: {
    computedZoneCameras: {
        get: function () {
          if (this.zoneCameras) return this.zoneCameras
          return this.cameras && this.cameras.filter(cam => cam.zoneId == this.zone.zoneId)
        },
        set: function (newVal) {
          this.zoneCameras = newVal
        },
      },
      zoneNameErrors() {
        const errors = [];
        if (!this.$v.zoneName.$dirty) return errors;
        if (!this.$v.zoneName.required) errors.push(this.$t("Zone name is required"));
        if (!this.$v.zoneName.minLength || !this.$v.zoneName.maxLength) {
          errors.push(
            this.$t("Zone name should be X-Y characters", {
              min: this.$v.zoneName.$params.minLength.min,
              max: this.$v.zoneName.$params.maxLength.max,
            })
          );
        }
        return errors;
      },
      zonePrimaryPhoneErrors() {
        const errors = [];
        if (!this.$v.zonePrimaryPhone.$dirty) return errors;
        if (!this.$v.zonePrimaryPhone.numeric)
          errors.push(this.$t("Please enter a numeric value"))
        if (!this.$v.zonePrimaryPhone.minLength || !this.$v.zonePrimaryPhone.maxLength) {
          errors.push(
            this.$t("Phone number should be X-Y characters", {
              min: this.$v.zonePrimaryPhone.$params.minLength.min,
              max: this.$v.zonePrimaryPhone.$params.maxLength.max,
            })
          );
        }
        return errors;
      },
      zoneSecondaryPhoneErrors() {
        const errors = [];
        if (!this.$v.zoneSecondaryPhone.$dirty) return errors;
        if (!this.$v.zoneSecondaryPhone.numeric)
          errors.push(this.$t("Please enter a numeric value"))
        if (!this.$v.zoneSecondaryPhone.minLength || !this.$v.zoneSecondaryPhone.maxLength) {
          errors.push(
            this.$t("Phone number should be X-Y characters", {
              min: this.$v.zoneSecondaryPhone.$params.minLength.min,
              max: this.$v.zoneSecondaryPhone.$params.maxLength.max,
            })
          );
        }
        return errors;
      },
      zoneAddressErrors() {
        const errors = [];
        if (!this.$v.zoneAddress.$dirty) return errors;
        if (!this.$v.zoneAddress.minLength || !this.$v.zoneAddress.maxLength) {
          errors.push(
            this.$t("Address should be X-Y characters", {
              min: this.$v.zoneAddress.$params.minLength.min,
              max: this.$v.zoneAddress.$params.maxLength.max,
            })
          );
        }
        return errors;
      },
      zoneCityErrors() {
        const errors = [];
        if (!this.$v.zoneCity.$dirty) return errors;
        if (!this.$v.zoneCity.minLength || !this.$v.zoneCity.maxLength) {
          errors.push(
            this.$t("City should be X-Y characters", {
              min: this.$v.zoneCity.$params.minLength.min,
              max: this.$v.zoneCity.$params.maxLength.max,
            })
          );
        }
        return errors;
      },
      zoneRegionErrors() {
        const errors = [];
        if (!this.$v.zoneRegion.$dirty) return errors;
        if (!this.$v.zoneRegion.minLength || !this.$v.zoneRegion.maxLength) {
          errors.push(
            this.$t("Region should be X-Y characters", {
              min: this.$v.zoneRegion.$params.minLength.min,
              max: this.$v.zoneRegion.$params.maxLength.max,
            })
          );
        }
        return errors;
      },
      zonePostalCodeErrors() {
        const errors = [];
        if (!this.$v.zonePostalCode.$dirty) return errors;
        if (!this.$v.zonePostalCode.minLength || !this.$v.zonePostalCode.maxLength) {
          errors.push(
            this.$t("Postal code should be X-Y characters", {
              min: this.$v.zonePostalCode.$params.minLength.min,
              max: this.$v.zonePostalCode.$params.maxLength.max,
            })
          );
        }
        return errors;
      },
      zoneCountryErrors() {
        const errors = [];
        if (!this.$v.zoneCountry.$dirty) return errors;
        if (!this.$v.zoneCountry.minLength || !this.$v.zoneCountry.maxLength) {
          errors.push(
            this.$t("Country should be X-Y characters", {
              min: this.$v.zoneCountry.$params.minLength.min,
              max: this.$v.zoneCountry.$params.maxLength.max,
            })
          );
        }
        return errors;
      },
      countries() {
        return store.getters.countries
      },
      addableCameras() {
        return this.cameras.filter(cam => cam.zoneId !== this.zone.zoneId)
      },
      warningText() {
        if (this.camerasToAdd.length === 1) {
            this.allowCameraTransfer = true
            return `Are you sure you want to transfer this camera to ${this.zone.name}?`
        } else if (this.camerasToAdd.length > 1) {
            this.allowCameraTransfer = true
            return `Are you sure you want to transfer these cameras to ${this.zone.name}?`
        } else if (!this.camerasToAdd.length) {
            this.allowCameraTransfer = false
            this.moveWarning = false
            return "You didn't select any cameras"
        }
      },
  },
  methods: {
    getZone() {
      const that = this
      that.loadingZone = true
      that.restapi.getZones(that.$route.params.zoneId)
      .then(function (res) {
        that.loadingZone = false
        that.zone = res
        that.zoneName = that.zone && that.zone.name ? that.zone.name : "";
        that.zonePrimaryPhone = that.zone.phone && that.zone.phone[0] ? that.zone.phone[0] : "";
        that.zoneSecondaryPhone = that.zone.phone && that.zone.phone[1] ? that.zone.phone[1] : "";
        that.zoneAddress = that.zone.address && that.zone.address.streetAddress ? that.zone.address.streetAddress : ""
        that.zoneCity = that.zone.address && that.zone.address.city ? that.zone.address.city : "";
        that.zoneRegion = that.zone.address && that.zone.address.region ? that.zone.address.region : "";
        that.zonePostalCode = that.zone.address && that.zone.address.postalCode ? that.zone.address.postalCode : "";
        that.zoneCountry = that.zone.address && that.zone.address.country ? that.zone.address.country : "";
      })
      .catch(function (error) {
        console.error(error)
      })
    },
    saveChanges() {
      const that = this;
      if (this.rights && this.rights.demoUser) {
        return this.$store.dispatch("demoMessage", this.$t("As a demo user you cannot make any changes."))
      }
      async function patch() {
        try {
          //PATCHING ZONE NAME
          if (that.zoneName !== that.zone.name) {
            await that.restapi.setZoneName(that.zone.zoneId, that.zoneName)
          }
          //PATCHING PHONE ARRAY
          if (that.zonePrimaryPhone !== that.zone.phone[0] || 
              that.zoneSecondaryPhone !== that.zone.phone[1]) {
                await that.restapi.setZonePhone(that.zone.zoneId, that.zonePrimaryPhone, that.zoneSecondaryPhone)
          }
          // PATCHING THE ADDRESS OBJECT
          if ( that.zoneAddress !== that.zone.address.streetAddress ||
              that.zoneRegion !== that.zone.address.region ||
              that.zoneCity !== that.zone.address.city ||
              that.zoneCountry !== that.zone.address.country ||
              that.zonePostalCode !== that.zone.address.postalCode
              ) {
              const newAddressObj = {city:that.zoneCity,country:that.zoneCountry,postalCode:that.zonePostalCode,region:that.zoneRegion,streetAddress:that.zoneAddress}
              console.log(newAddressObj)
              await that.restapi.setZoneAddress(that.zone.zoneId, newAddressObj)
            }
          store.dispatch('toastMessage', {
            text: that.$t("Your changes are saved successfully"),
            color: "primary",
            showing: true,
            timeout: 4000,
          })
          that.$emit("patchedZone", that.zone)
          that.$v.$reset()
        } catch (error) {
              store.dispatch('toastMessage', {
                text: getMessageFromError(error, "UPDATE_ZONE"),
                color: "error",
                showing: true,
                timeout: -1,
                support: true
              })
            }
      } return patch()
    },
    selectCamera(cam, i) {
      if (this.addableCameras[i].selected) {
        this.addableCameras[i].selected = false
        this.camerasToAdd.pop(this.addableCameras[i].cameraId);
        document.getElementById("cameraDialog" + i).classList.remove("chipBackGround");
      } else {
        this.camerasToAdd.push(this.addableCameras[i].cameraId);
        this.addableCameras[i].selected = true
        document.getElementById("cameraDialog" + i).classList.add("chipBackGround");
      }
    },
    closeCameraDialog() {
      this.showCameraDialog = false;
      this.moveWarning = false;
      this.camerasToAdd = [];
      this.addableCameras.forEach((cam, i) => {
        cam.selected = false;
        document.getElementById("cameraDialog" + i).classList.remove("chipBackGround");
      })
    },
    confirmMovingCameras() {
      if (this.rights && this.rights.demoUser) {
        return this.$store.dispatch("demoMessage")
      }
      const that = this;
      async function patchZone() {
        try {
          await that.restapi.setZoneCameras(that.camerasToAdd, that.zone.zoneId)
          that.camerasToAdd.map(camId => that.$emit('transferredCamera', camId, that.zone.zoneId))
          that.closeCameraDialog()
          store.dispatch('toastMessage', {
            text: that.$t("Transfer successful"),
            color: "primary",
            showing: true,
            timeout: 4000
          })
        } catch (error) {
          store.dispatch('toastMessage', {
            text: getMessageFromError(error, "UPDATE_CAMERA_ZONE"),
            color: "error",
            showing: true,
            timeout: -1,
            support: true
          })
        }
      } return patchZone()
    },
    delayTouch($v) {
      const touchMap = new WeakMap()
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 1000))
    }
  },
  validations: {
      zoneName: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(32),
      },
      zonePrimaryPhone: {
        minLength: minLength(10),
        maxLength: maxLength(20),
        numeric
      },
      zoneSecondaryPhone: {
        minLength: minLength(10),
        maxLength: maxLength(20),
        numeric
      },
      zoneAddress: {
        minLength: minLength(2),
        maxLength: maxLength(64),
      },
      zoneCity: {
        minLength: minLength(2),
        maxLength: maxLength(32),
      },
      zoneCountry: {
        minLength: minLength(1),
        maxLength: maxLength(5),
      },
      zonePostalCode: {
        minLength: minLength(2),
        maxLength: maxLength(16),
      },
      zoneRegion: {
        minLength: minLength(2),
        maxLength: maxLength(32),
      },
  },
}
</script>

<style lang="scss" scoped>
  @import "@/assets/styles/main";

  .dashboard {
    background-color: #eee;
    .dashboard-content-left {
      min-height: $main-content-height;
      margin: auto 0.1vw auto auto;
      padding-left: 15px;
      max-width: 1000px;
    }
    .dashboard-content-right {
      min-height: $main-content-height;
      margin: auto auto auto 0.1vw;
      padding-right: 15px;
      max-width: 1000px;
    }
  }
  .cameraDialog {
    background: white;
  }

  .headline {
    color: #555555;
    height: 52px;
    padding-left: 5px;
  }

  .table-head {
    border-bottom-color: var(--v-primary-lighten1) !important;
    border-bottom-width: 2px !important;
  }
  
  .table-row {
    &:nth-child(even) {
      background-color: #f8f8f8;
    }
    &.expanded {
      background-color: #f5f5f5;
    }
    .table-cell {
      padding: 10px;
      vertical-align: middle;
      font-size: 0.9em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border: none !important;
      &:hover {
        cursor: pointer;
      }
      &.camera-status {
        text-align: center;
        color: white;
        .online {
          background-color: #207cad;
          padding: 5px;
          border-radius: $border-radius;
        }
        .offline {
          background-color: #d43536;
          padding: 5px;
          border-radius: $border-radius;
        }
      }
      &.action-buttons {
        padding: 5px;
        &::v-deep .v-btn {
          height: 30px;
          width: 30px;

        }
      }
    }
  }

  .expanded-area {
    cursor: default;
    .row {
      .col {
        padding: 0;
        span {
          font-size: 12px;
          color: rgb(204, 204, 204);
          img {
            height: 14px;
          }
          &.connectionIcon {
            color: rgb(74, 74, 74);
          }
        }
        p {
          margin-bottom: 5px;
        }
      }
    }
  }


  .iccid-icon, .sim-status-icon {
    width: 20px;
    display: inline-block;
    margin-right: 5px;
  }

  .sim-status-icon {
    &.offline {
      path {
        fill: $color-gray;
      }
    }
    &.sim-installed {
      path {
        fill: $primary;
      }
    }
    &.no-sim-installed {
      path {
        fill: $color-gray;
      }
    }
  }

  .iccid-label, .sim-status-label {
    display: inline-block;
  }

  .iccid-block, .sim-status-block {
    padding-top: 5px;
  }

  .is-divider {
    height: 2px;
    background-color: #dfdfdf73;
    display: block;
    margin-top: 0.5rem;
    margin-bottom: 1.75rem;
  }

  .chipBackGround {
  background: var(--v-primary-base) !important;
  color: white !important;
}
  .move-warning {
    width: 600px;
  }
  .een-spinner {
    position: absolute;
    top: 45%;
    left: 45%;
  }

</style>