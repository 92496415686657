<template>
  <v-list rounded id="zone-list" pa-0 class="zonelist">
    <v-app-bar flat height="36" class="transparent appSettings px-0" clipped-left tile>
      <v-row>
        <v-col xs="6" md="5" lg="4">
          <v-text-field
            solo
            flat
            class="searchCams"
            background-color="transparent"
            v-model="filter"
            :placeholder="$t('Search zones or camera name')"
            hide-details
          > 
            <v-icon slot="prepend-inner" size="16">fa-search</v-icon>
          </v-text-field>
        </v-col>
        <v-col xs="6" sm="2">
          <div class="d-flex justify-center">
            <v-btn depressed @click="onlineCameras()" :color="online ? 'primary' : '#eeeeee'" class="px-2"
              >{{ $t("Online") }}:{{ onlineCamera.length }}</v-btn
            >
            <v-btn depressed @click="offlineCameras()" :color="offline ? 'primary' : '#eeeeee'" class="px-2 mr-0"
              >{{ $t("Offline") }}:{{ cameras.length - onlineCamera.length }}</v-btn
            >
          </div>
        </v-col>
        <v-col md="5" lg="6" v-if="!$vuetify.breakpoint.smAndDown">
          <v-btn
            v-if="rights && (rights.addCameraManually || rights.demoUser)"
            color="primary"
            :ripple="false"
            class="float-right"
            max-width="200"
            @click="$router.push({ name: 'AddCamera' })"
          >
            <span v-if="$vuetify.breakpoint.mdAndDown && $locale.getCurrentLanguage() != 'en'"><v-icon size="20">fa-plus-circle</v-icon> {{ $t("device") }}</span>
            <span v-else> {{ $t("Add device") }} </span>
          </v-btn
          >
          <v-btn
            v-if="rights && (rights.addZone || rights.demoUser)"
            color="primary"
            class="mr-2 float-right"
            :ripple="false"
            max-width="200"
            @click="onShowAddZoneModal"
          >
            <span v-if="$vuetify.breakpoint.mdAndDown && $locale.getCurrentLanguage() != 'en'"><v-icon size="20">fa-plus-circle</v-icon> {{ $t("zone") }}</span>
            <span v-else> {{ $t("Add zone") }} </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-row v-if="$vuetify.breakpoint.smAndDown">
      <v-col class="d-flex justify-center align-center">
        <v-btn
          v-if="rights && (rights.addCameraManually || rights.demoUser)"
          color="primary"
          :ripple="false"
          @click="$router.push({ name: 'AddCamera' })"
          >{{ $t("Add device") }}</v-btn
        >
      </v-col>
      <v-col class="d-flex justify-center align-center">
        <v-btn
          v-if="rights && (rights.addZone || rights.demoUser)"
          color="primary"
          :ripple="false"
          @click="addingNewZone = true"
          >{{ $t("Add zone") }}</v-btn
        >
      </v-col>
    </v-row>
    <template v-for="(zone, index) in filteredZones">
      <zone-cameras
        :key="index"
        :id="`zone-${index}`"
        :zone="zone"
        :selected="isSelectedZone(zone)"
        :cameras="filteredCameras"
        :camera="camera"
        :rights="rights"
        @selectZone="selectZone"
        @editedZone="editZone"
        @selectCamera="selectCamera"
        @deleted="deletedZone(zone.zoneId)"
        @addCamera="$emit('add-camera')"
      ></zone-cameras>
      <div v-if="index < cameraZones.length - 1" class="gap" :key="zone.zoneId"></div>
    </template>

    <v-dialog v-model="addingNewZone" max-width="400">
      <v-card>
        <v-card-title primary-title>
          <p class="title unset-title-color">{{ $t("Add new zone") }}</p>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="newZoneName"
            :label="$t('Zone name')"
            :error-messages="newZoneNameErrors"
            @input="$v.newZoneName.$touch()"
            @blur="$v.newZoneName.$touch()"
            @keyup.enter="addZone()"
            ref="addZoneInput"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <een-spinner :isloading="addingZone"></een-spinner>
          <v-btn depressed color="primary" :disabled="$v.newZoneName.$invalid || addingZone" @click="addZone()">{{
            $t("Add new zone")
          }}</v-btn>
          <v-btn depressed @click="cancelAddZone">{{ $t("Cancel") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import ZoneCameras from "@/components/Cameras/ZoneCameras";

export default {
  name: "ZoneList",
  components: {
    ZoneCameras,
  },
  props: ["zones", "cameras", "camera", "selectedZones", "rights"],
  data() {
    return {
      filter: "",
      addingNewZone: false,
      newZoneName: null,
      online: false,
      offline: false,
      cams: [],
      currentZones: null,
      addingZone: false,
    };
  },
  computed: {
    onlineCamera() {
      return this.cameras.filter((ele) => {
        if (ele.status) return ele.status.online == true;
      });
    },
    cameraZones: {
      get: function () {
        if (this.currentZones) return this.currentZones;
        return this.zones;
      },
      set: function (newVal) {
        this.currentZones = newVal;
      },
    },
    filteredZones() {
      const matcher = new RegExp(this.filter, "i");
      const cameraZones =
        this.filter !== null && this.filter.length > 0
          ? this.cameraZones
              .filter((z) => {
                return matcher.test(z.name) || this.camerasForFilteredZone(z.zoneId).length > 0;
              })
              .sort((a, b) => {
                return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
              })
          : this.cameraZones.sort((a, b) => {
              return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            });
      const nonEmptyZones = cameraZones.filter((z) => {
        return z.cameraIds.length > 0;
      });
      const emptyZones = cameraZones.filter((z) => {
        return z.cameraIds.length === 0;
      });
      return [...nonEmptyZones, ...emptyZones];
    },
    filteredCameras() {
      const matcher = new RegExp(this.filter, "i");
      let cam = this.online ? this.cams : this.offline ? this.cams : this.cameras;
      return this.filter !== null && this.filter.length > 0
        ? cam
            .filter((c) => {
              return matcher.test(c.name);
            })
            .sort((a, b) => {
              return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            })
        : cam.sort((a, b) => {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          });
    },
    newZoneNameErrors() {
      const errors = [];
      if (!this.$v.newZoneName.$dirty) return errors;
      if (!this.$v.newZoneName.required) errors.push(this.$t("Zone name is required"));
      if (!this.$v.newZoneName.minLength || !this.$v.newZoneName.maxLength) {
        errors.push(
          this.$t("Zone name should be X-Y characters", {
            min: this.$v.newZoneName.$params.minLength.min,
            max: this.$v.newZoneName.$params.maxLength.max,
          })
        );
      }
      return errors;
    },
  },
  methods: {
    selectZone(id) {
      this.$emit("selectZone", id);
    },
    onlineCameras() {
      this.online = !this.online;
      if (this.online) {
        this.offline = false;
        this.cams = this.onlineCamera;
      } else {
        this.cams = this.cameras;
      }
    },
    offlineCameras() {
      this.offline = !this.offline;
      if (this.offline) {
        this.online = false;
        this.cams = this.cameras.filter((ele) => {
          if (ele.status) return ele.status.online == false;
        });
      } else {
        this.cams = this.cameras;
      }
    },
    deletedZone(id) {
      let that = this;
      this.restapi.getZones().then(function (zones) {
        that.cameraZones = zones;
        that.$emit("deleted", id);
      });
    },
    editZone() {
      let that = this;
      this.restapi.getZones().then(function (zones) {
        that.cameraZones = zones;
      });
    },
    isSelectedZone(zone) {
      return this.selectedZones && Array.isArray(this.selectedZones) ? this.selectedZones.includes(zone.zoneId) : null;
    },
    selectCamera(id) {
      this.$emit("select", id);
    },
    camerasForFilteredZone(zoneId) {
      return this.filteredCameras.filter((c) => {
        return c.zoneId === zoneId;
      });
    },
    isSelectedCamera(camera) {
      return this.camera && this.camera.cameraId === camera.cameraId;
    },
    cancelAddZone() {
      this.addingNewZone = false;
      this.newZoneName = null;
      this.$v.$reset();
    },
    addZone() {
      if (this.rights.demoUser) {
        this.$store.dispatch("demoMessage")
      } else if (!this.$v.newZoneName.$invalid) {
        const that = this;
        let zoned;
        this.addingZone = true;
        this.restapi
          .addZone(this.newZoneName)
          .then(function (zone) {
            zoned = zone;
            that.addingNewZone = false;
            that.newZoneName = null;
            that.addingZone = false;
            that.$v.$reset();
          })
          .then(function () {
            that.restapi.getZones().then(function (zones) {
              that.cameraZones = zones;
              that.$emit("added", zoned);
            });
          })
          .catch(function (error) {
            console.error(error);
          });
      }
    },
    onShowAddZoneModal() {
      this.addingNewZone=true
      // auto focus
      setTimeout(() => {this.$refs.addZoneInput.focus()}, 200)
    }
  },
  validations: {
    newZoneName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(32),
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main";

.zonelist {
  background-color: $color-panel-grey !important;
  .gap {
    height: 2px;
  }
}
</style>
<style>
.appSettings .v-toolbar__content {
  padding: 0;
}
.searchCams .v-input__control .v-input__slot {
  padding: 0 0 10px 0 !important;
}
.loader-indicator {
  position: absolute;
  top: 32%;
  right: 43%;
}
</style>
