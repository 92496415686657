<template>
  <v-container fluid class="dashboard my-0 py-0">
    <v-row justify="space-around" class="fill-height">
      <v-col class="dashboard-content">
        <v-row>
          <v-col cols="3" class="mt-2">
            <v-text-field
              v-model="search"
              append-icon="fa-search"
              :label="$t('Search')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="8">
            <v-row>
              <v-col>
                <v-row justify="center">
                  <v-col cols="auto" class="d-flex justify-end align-center">
                    {{ $t("From") }}
                  </v-col>
                  <v-col cols="auto">
                    <v-menu
                      v-model="fromDateDialog"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      rounded
                      min-width="290px"
                      class="mr-0"
                    >
                      <template v-slot:activator="{ on: { click } }">
                        <v-row @click="click" class="date-time-select-audit float-right px-3">
                          <div class="mt-1">
                            {{ fromDisplayDate }}
                          </div>
                          <v-icon size="17" color="grey darken-2" class="ml-2 pt-0">fa-calendar-day</v-icon>
                        </v-row>
                      </template>
                      <v-date-picker
                        v-if="fromDateDialog"
                        v-model="fromDate"
                        @input="fromDateSelected"
                        no-title
                        :locale="locale"
                        :max="maxDate"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row justify="center">
                  <v-col cols="auto" class="d-flex justify-end align-center">
                    {{ $t("To") }}
                  </v-col>
                  <v-col cols="auto">
                    <v-menu
                      v-model="toDateDialog"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      class="mr-0"
                    >
                      <template v-slot:activator="{ on: { click } }">
                        <v-row @click="click" class="date-time-select-audit float-right px-3">
                          <div class="mt-1">
                            {{ toDisplayDate }}
                          </div>
                          <v-icon size="17" color="grey darken-2" class="ml-2 pt-0">fa-calendar-day</v-icon>
                        </v-row>
                      </template>
                      <v-date-picker
                        v-if="toDateDialog"
                        v-model="toDate"
                        @input="toDateSelected"
                        no-title
                        :locale="locale"
                        :min="fromDate"
                        :max="maxDate"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="1" class="d-flex justify-end align-center">
            <v-btn @click="showDialog = true" icon color="primary" class="float-right">
              <v-icon size="20">fas fa-info-circle</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          show-expand
          :search="search"
          :headers="headerLogs"
          :items="logs"
          item-key="index"
          class="mt-4"
          :page.sync="page"
          :footer-props="{ 
            'items-per-page-options': [10, 25, 50, -1],
            'items-per-page-all-text': $t('All'),
            'items-per-page-text': $t('Rows per page')
          }"
          :loading-text="$t('Loading results')"
          :no-results-text="$t('No matching records found')"
          :no-data-text="$t('No data')"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">{{ item.details }}</td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="showDialog" width="800">
      <v-card class="pa-4">
        <h2 class="headline pl-2">{{ $t("Available Logs") }}</h2>
        <v-divider></v-divider>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th v-for="header in headerTypes" class="text-left" v-bind:key="header">
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in typesOfLogs" :key="item">
                <td>
                  {{ $t(item.zone) }}
                </td>
                <td>{{ $t(item.camera) }}</td>
                <td>{{ $t(item.user) }}</td>
                <td>{{ $t(item.authentication) }}</td>
                <td>{{ $t(item.video) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-dialog>
    <een-spinner :isloading="spinner"></een-spinner>
  </v-container>
</template>

<script>
import _ from "lodash";
import { store } from "@/store";
import { getTimeStamp } from '@eencloud/core-components/src/service/time'

export default {
  name: "Logs",
  computed: {
    headerLogs() {
      return [
        {
          text: this.$t("Time"),
          class: "primary--text",
          sortable: false,
          value: "timestamp",
        },
        {
          text: this.$t("User"),
          sortable: false,
          value: "email",
          class: "primary--text",
        },
        {
          text: this.$t("Activity"),
          class: "primary--text",
          sortable: false,
          value: "action",
        },
        {
          text: this.$t("Camera"),
          class: "primary--text",
          sortable: false,
          value: "cameraName",
        },
        { text: "Details", class: "primary--text", value: "data-table-expand" },
      ];
    },
    locale() {
      return this.$locale.getCurrentLanguage();
    },
    fromDate: {
      get() {
        return this.fromDateData;
      },
      set(newVal) {
        this.fromDateData = newVal;
      },
    },
    toDate: {
      get() {
        return this.toDateData;
      },
      set(newVal) {
        this.toDateData = newVal;
      },
    },

    fromDisplayDate() {
      const dt = new Date(new Date(this.fromDate).getTime() - this.offsetToSystemTime * 60000);
      return `${dt.toLocaleDateString(this.$locale.getCurrentLanguage(), { weekday: "long" })}, ${this.fromDate}`;
    },
    toDisplayDate() {
      const dt = new Date(new Date(this.toDate).getTime() - this.offsetToSystemTime * 60000);
      return `${dt.toLocaleDateString(this.$locale.getCurrentLanguage(), { weekday: "long" })}, ${this.toDate}`;
    },
    offsetToSystemTime() {
      return new Date().getTimezoneOffset();
    },
    accountTimeZoneOffset() {
      return store.getters.accountTimeZoneOffset || store.getters.accountTimeZoneOffset === 0 ? store.getters.accountTimeZoneOffset : 0
    }
  },
  data() {
    return {
      page: 1,
      spinner: false,
      logs: [],
      users: [],
      search: "",
      allCameras: null,
      fromDateData: new Date().toISOString().substr(0, 10),
      toDateData: new Date().toISOString().substr(0, 10),
      showDialog: false,
      toDateDialog: false,
      fromDateDialog: false,
      maxDate: new Date().toISOString().substr(0, 10),
      typesOfLogs: [
        {
          zone: "addZone",
          camera: "addCamera",
          user: "addSubUser",
          authentication: "Login",
          video: "playFootage",
        },
        {
          zone: "zonesUpdate",
          camera: "updateCamera",
          user: "updateSubUser",
          authentication: "Logout",
          video: "exportFootage",
        },
        {
          zone: "deleteZone",
          camera: "deleteCamera",
          user: "deleteSubUser",
          video: "deleteFootage",
        },
        {
          user: "Set recording schedule",
        },
        {
          user: "Set email notification schedule",
        },
        {
          user: "Set push notification schedule",
        },
      ],
    };
  },
  async mounted() {
    this.allUsers();
    this.cams();
    if (store.getters.accountTimeZoneOffset || store.getters.accountTimeZoneOffset === 0) {
      this.maxDate = getTimeStamp(store.getters.accountTimeZoneOffset).slice(0,10)
      this.fromDateData = getTimeStamp(store.getters.accountTimeZoneOffset).slice(0,10)
      this.toDateData = getTimeStamp(store.getters.accountTimeZoneOffset).slice(0,10)
      this.getLogs(this.fromDate, this.toDate);
    }
  },
  methods: {
    toDateSelected() {
      this.toDateDialog = false;
    },
    fromDateSelected() {
      this.fromDateDialog = false;
    },
    async allUsers() {
      try {
        this.users = await this.restapi.getUsers();
      } catch (error) {
        console.error(error);
      }
    },
    async cams() {
      try {
        this.allCameras = await this.restapi.getCameras();
      } catch (error) {
        console.error(error);
      }
    },
    async getLogs(min, max) {
      let that = this;
      let index = 0;
      const from = new Date(new Date(min).getTime() - this.accountTimeZoneOffset * 60000).toISOString()
      const till = new Date(new Date(max).getTime() + (1440 - this.accountTimeZoneOffset) * 60000).toISOString()
      this.spinner = true;
      this.logs = await this.restapi.getLogs(
        encodeURIComponent(from.replace("Z", "+0000")),
        encodeURIComponent(till.replace("Z", "+0000"))
      );
      this.spinner = false;
      this.logs.map((element) => {
        element.action = that.$t(element.action);
        element.timestamp = getTimeStamp(-this.accountTimeZoneOffset, new Date(element.timestamp)).slice(0,20)
        element["details"] = _.omitBy(element, _.isNull);
        element.details = JSON.stringify(
          _.omit(element.details, ["userId", "timestamp", "action", "endTime", "cameraName"])
        );
        element.details = element.details
          .replace(/"/g, "")
          .replace(/{/g, "")
          .replace(/:/g, " : ")
          .replace(/,/g, " , ")
          .replace(/}/g, "")
          .replace(/([A-Z])/g, " $1")
          .trim();
        element.details = element.details.charAt(0).toUpperCase() + element.details.slice(1);
        element["index"] = index++;
        let user = that.users.find((e) => e.userId === element.userId).email;
        element["email"] = user ? user : "";
      });
    },
  },
  watch: {
    fromDate(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getLogs(this.fromDate, this.toDate);
      }
    },
    toDate(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getLogs(this.fromDate, this.toDate);
      }
    },
    accountTimeZoneOffset(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.maxDate = getTimeStamp(newValue).slice(0,10)
        this.fromDateData = getTimeStamp(newValue).slice(0,10)
        this.toDateData = getTimeStamp(newValue).slice(0,10)
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main";

.dashboard {
  background-color: #eee;
  .dashboard-content {
    min-height: $main-content-height;
    max-width: 1000px;
    margin: auto;
  }
}
.date-time-select-audit {
  height: 32px;
  cursor: pointer;
  border-radius: $border-radius;
  background-color: $color-panel-grey;
  background-color: #d8d8d8 !important ;
}
.headline {
  color: #555555;
}

</style>
