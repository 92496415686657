<template>
  <v-row align="center" justify="center" class="logged-into-other-app px-5" v-if="!isAuthorized">
    <v-col class="content pa-0">
      <p class="headline text-xs-center">{{ $t('You are already logged in to the Dealer Dashboard. To sign out from Dealer Dashboard and sign in as an end user, click') }} <a @click="logout">{{ $t('here') }}</a>.</p>
    </v-col>
  </v-row>
</template>

<script>
  const auth = require('@/service/auth')

  export default {
    beforeMount () {
      if (!auth.isLoggedIntoOtherApp()) {
        this.$router.push('/')
      }
    },
    computed: {
      isAuthorized () {
        return this.$store.getters.isAuthorized
      },
    },
    methods: {
        logout() {
            auth.logout()
        }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../assets/styles/main";

  .logged-into-other-app {
    background-color: $color-panel-grey;
    min-height: calc(100vh - 56px);
    max-height: 100vh;
    .content {
      max-width: 1000px;
      margin: 0 100px;
    }
  }
</style>
