<template>
  <v-container fluid class="camera-info pa-0">
    <v-app-bar v-if="showToolbar" dense flat :app="isAppToolbar" :color="toolbarColor || 'white'" class="title--text">
      <v-container v-if="isDirty" class="my-2 align">
        <v-row class="xs8 offset-xs2">
          <v-spacer></v-spacer>
            <v-btn  @click="cancelChanges">
              {{ $t("Cancel") }}
            </v-btn>
            <v-btn class="ml-2" color="primary" @click="saveChanges">
              {{ $t("Save") }}
            </v-btn>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-container v-if="camera" class="pa-0 align">
      <v-col sm="10" offset-sm="1" class="py-0">
        <v-row class="px-3">
          <v-col cols="10" class="hidden-sm-and-up">
            <v-btn text class="pa-0" @click="$emit('allCameras')">
              <v-icon left size="18">
                fa-arrow-left
              </v-icon>
              <p class="mb-0">
                {{ $t("Camera info") }}
              </p>
            </v-btn>
          </v-col>
          <v-col cols="2" class="hidden-sm-and-up">
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" dark icon>
                  <v-icon color="black" size="18">
                    fa-ellipsis-v
                  </v-icon>
                </v-btn>
              </template>
              <v-list dense rounded class="px-4">
                <v-list-item
                  v-for="(menu, i) in menus"
                  :key="i"
                  @click="$router.push({ path: `/settings/cameras/${camera.cameraId}/${menu.endpoint}` })"
                >
                  <v-list-item-title>{{ menu.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="10" offset-sm="1" class="pt-0">
        <p class="text-uppercase pl-0 mb-2">
          {{ $t("General") }}
        </p>
        <v-card>
          <v-col class="py-0">
            <v-list dense rounded two-line>
              <v-list-item class="py-0">
                <v-list-item-action>
                  <v-img
                    v-if="!status || online"
                    :src="snapshot || blankImg"
                    xs4
                    width="100"
                    aspect-ratio="1.3888"
                    class="rounded"
                  ></v-img>
                  <v-icon v-else>
                    fa-video-slash
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content class="py-0">
                  <v-container fluid>
                    <v-row>
                      <v-col class="py-0" cols="12" sm="6">
                        <v-text-field
                          v-model="cameraName"
                          :label="$t('Camera name')"
                          :placeholder="$t('Name of your camera')"
                          :error-messages="cameraNameErrors"
                          @input="$v.cameraName.$touch()"
                          @blur="$v.cameraName.$touch()"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-list dense rounded v-if="status">
              <v-row class="mx-1">
                <v-col v-if="cameraEnabled" class="my-2" cols="11">
                  <p class="subheading mb-0">
                    {{ $t("Camera enabled") }}
                  </p>
                  <p xs12 class="ma-0">
                    {{ $t("Video streaming on") }}
                  </p>
                </v-col>
                <v-col v-else class="my-2" cols="11">
                  <p class="subheading mb-0">
                    {{ $t("Camera disabled") }}
                  </p>
                  <p xs12 class="ma-0">
                    {{ $t("Video streaming off") }}
                  </p>
                </v-col>
                <v-col class="align-end mt-2" cols="1" :pl-4="$vuetify.breakpoint.mdAndUp">
                  <v-switch hide-details dense v-model="cameraEnabled" color="accent" class="my-0"></v-switch>
                </v-col>
              </v-row>
            </v-list>
          </v-col>
        </v-card>
      </v-col>

      <v-col cols="12" sm="10" offset-sm="1" class="pt-0">
        <p class="text-uppercase pl-0 mb-2">
          {{ $t("Camera mode") }}
        </p>
        <v-card>
          <v-col cols="12" class="pa-0">
            <v-radio-group
              dense
              v-model="cameraModus"
              hide-details
              class="camera-mode my-0"
              :disabled="cameraMode && cameraMode.mode === 'private'"
            >
              <v-list dense rounded v-if="cameraMode">
                <div v-if="desktop">
                  <v-list-item>
                    <v-list-item-action class="pa-0">
                      <v-radio key="armed" value="armed" class="camera-mode-option"></v-radio>
                    </v-list-item-action>
                    <v-list-item-content class="pa-0">
                      <v-list-item-title>{{ $t("Armed") }}</v-list-item-title>
                      <v-list-item-subtitle>{{ $t("Recording on, notifications on") }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="my-1"></v-divider>
                  <v-list-item>
                    <v-list-item-action class="pa-0">
                      <v-radio key="silent" value="silent" class="camera-mode-option"></v-radio>
                    </v-list-item-action>
                    <v-list-item-content class="pa-0">
                      <v-list-item-title>{{ $t("Silent") }}</v-list-item-title>
                      <v-list-item-subtitle>{{ $t("Recording on, notifications off") }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="my-1"></v-divider>
                  <v-list-item>
                    <v-list-item-action>
                      <v-radio key="disarmed" value="disarmed" class="camera-mode-option"></v-radio>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("Disarmed") }}</v-list-item-title>
                      <v-list-item-subtitle>{{ $t("Recording off, notifications off") }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="my-1"></v-divider>
                  <v-list-item>
                    <v-list-item-action>
                      <v-radio key="custom" value="custom" class="camera-mode-option"></v-radio>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("Scheduled") }}</v-list-item-title>
                      <v-list-item-subtitle>{{ $t("Custom recording & notifications schedule") }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="my-1"></v-divider>
                  <v-list-item class="body-2">
                    {{
                      $t("Your camera is set to RECORDINGMODE recording. Go to the MENU menu to change this.", {
                        recordingMode: recordingMode,
                        menu: $t("Recording"),
                      })
                    }}
                  </v-list-item>
                </div>
                <v-list-item
                  v-else
                  class="body-2"
                  @mousedown="$router.push({ name: 'ScheduleNotifications', params: { cameraId } })"
                >
                  {{
                    $t("Your camera mode is set to CAMERAMODE. Go to the MENU menu to change this.", {
                      cameraMode: getCameraModeName(cameraModus),
                      menu: $t("Schedule & Notifications"),
                    })
                  }}
                </v-list-item>
              </v-list>
            </v-radio-group>
          </v-col>
        </v-card>
      </v-col>

      <v-col cols="12" sm="10" offset-sm="1" class="pt-0">
        <p class="text-uppercase pl-0 mb-2">
          {{ $t("Camera settings") }}
        </p>
        <v-card>
          <v-col cols="12" class="pa-0">
            <v-list dense rounded>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="my-2 py-0">
                      {{ $t("Current zone") }}
                    </v-col>
                    <v-col class="py-0">
                      <v-select
                        v-model="zone"
                        :items="zones"
                        item-value="zoneId"
                        item-text="name"
                        solo
                        dense
                        flat
                        hide-details
                        background-color="shadow"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider class="my-1"></v-divider>
            <v-list v-if="brand" dense rounded>
              <v-list-item>
                <v-list-item-content>
                  <v-container class="pa-0" fluid>
                    <v-row>
                      <v-col class="py-0">
                        {{ $t("Brand") }}
                      </v-col>
                      <v-col class="py-0">
                        <p class="text-right my-0">
                          {{ brand }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider class="my-1" v-if="brand"></v-divider>
            <v-list dense rounded v-if="model">
              <v-list-item>
                <v-list-item-content>
                  <v-container class="pa-0" fluid>
                    <v-row>
                      <v-col class="py-0">
                        {{ $t("Type") }}
                      </v-col>
                      <v-col class="py-0">
                        <p class="text-right my-0" @click="goToDevice">
                          {{ model }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider class="my-1" v-if="model"></v-divider>
            <v-list dense rounded>
              <v-list-item>
                <v-list-item-content>
                  <v-container class="pa-0" fluid>
                    <v-row>
                      <v-col class="py-0">
                        {{ $t("MAC address") }}
                      </v-col>
                      <v-col class="py-0">
                        <p class="text-right my-0">
                          {{ macAddress }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider class="my-1"></v-divider>
            <v-list dense rounded>
              <v-list-item>
                <v-list-item-content>
                  <v-container class="pa-0" fluid>
                    <v-row>
                      <v-col class="py-0">
                        {{ $t("IP address") }}
                      </v-col>
                      <v-col class="py-0">
                        <p class="text-right my-0">
                          {{ globalHost }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider class="my-1"></v-divider>
            <v-list dense rounded>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="pb-0">
                      {{ $t("Time zone") }}
                    </v-col>
                    <v-col class="py-0">
                      <v-autocomplete
                        v-model="timeZone"
                        :items="timeZones"
                        flat
                        solo
                        dense
                        hide-details
                        background-color="shadow"
                      >
                        <template v-slot:item="data">
                          {{ data.item + addOffset(data.item) }}
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <template v-if="provideFirmwareVersion && uptodate">
              <v-divider class="my-1"></v-divider>
              <v-list dense rounded>
                <v-list-item>
                  <v-list-item-content>
                    <v-container class="pa-0" fluid>
                      <v-row>
                        <v-col class="py-0">
                          {{ $t("Firmware version") }}
                        </v-col>
                        <v-col class="py-0">
                          <p class="text-right my-0">{{ currentFirmwareVersion }} ({{ $t("up to date") }})</p>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </template>
          </v-col>
        </v-card>
      </v-col>

      <v-col v-if="provideFirmwareVersion && (upgradable || upgrading)" cols="12" sm="10" offset-sm="1" class="pt-0">
        <p class="text-uppercase pl-0 mb-2">
          {{ $t("Firmware update") }}
          <v-icon rounded color="primary" class="ml-2" small>
            fa-exclamation-circle
          </v-icon>
        </p>
        <firmware-update
          :camera="camera"
          :status="status"
          :firmware="firmware"
          @updated="firmwareUpdated"
        ></firmware-update>
      </v-col>

      <v-col cols="12" sm="10" offset-sm="1" class="pt-0">
        <p class="text-uppercase pl-0 mb-2">
          {{ $t("Camera actions") }}
        </p>
        <v-card>
          <v-col cols="12" class="pa-0">
            <template v-if="rebootSupported && this.rights && this.rights.editCamera && online">
              <v-list dense rounded>
                <v-list-item>
                  <v-list-item-content>
                    <v-row align="center">
                      <v-col cols="8" class="my-2 py-0">
                        <p class="body-2 ma-0">{{ $t("Reboot camera") }}</p>
                        <p class="mb-0">
                          {{
                            $t("After reboot, the camera retains all settings/recordings and is still connected to your account.")
                          }}
                        </p>
                      </v-col>
                      <v-col cols="4" class="py-0 text-right">
                        <v-btn color="error" :disabled="rebootingCamera" @click="toggleRebootingCamera">
                          {{ $t("Reboot camera") }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider class="my-1"></v-divider>
            </template>
            <v-list dense rounded>
              <v-list-item>
                <v-list-item-content>
                  <v-row align="center">
                    <v-col cols="8" class="my-2 py-0">
                      <p class="body-2 ma-0">{{ $t("Remove camera") }}</p>
                      <p class="mb-0">
                        {{
                          $t("This will also remove all of its settings/recordings, this cannot be undone.")
                        }}
                      </p>
                    </v-col>
                    <v-col cols="4" class="py-0 text-right">
                      <v-btn color="error" :disabled="deletingCamera" @click="toggleDeletingCamera" v-if="this.rights && this.rights.deleteCamera">
                        {{ $t("Remove camera") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-card>
      </v-col>
    </v-container>


    <camera-delete-dialog
      :camera="camera"
      :showDialog="deletingCamera"
      @closeDialog="deletingCamera = false"
      @deleted="cameraDeleted"
    ></camera-delete-dialog>

    <camera-reboot-dialog
      :camera="camera"
      :showDialog="rebootingCamera"
      @closeDialog="rebootingCamera = false"
    ></camera-reboot-dialog>

    <v-dialog v-model="cameraCredentialsInvalid" hide-overlay width="500">
      <v-card>
        <v-card-title primary-title class="headline error white--text">
          {{ $t("Wrong credentials") }}
        </v-card-title>
        <v-card-text>{{ $t("The given credentials are not valid, so they were not updated.") }}</v-card-text>
        <v-card-actions>
          <v-btn depressed color="error" @click="cameraCredentialsInvalid = false">
            {{ $t("Got it") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { required, numeric, minLength, maxLength, minValue, maxValue } from "vuelidate/lib/validators";
import FirmwareUpdate from "@/components/Cameras/FirmwareUpdate";
import CameraDeleteDialog from "@/components/Base/CameraDeleteDialog";
import CameraRebootDialog from "@/components/Base/CameraRebootDialog";
import { findTimeZone, getUTCOffset } from "timezone-support";
import { store } from "@/store";
import warningForCameraSettingsChangesMixin from '@eencloud/core-components/src/service/warningForCameraSettingsChangesMixin';

const hash = require("object-hash");

export default {
  name: "CameraInfo",
  mixins: [warningForCameraSettingsChangesMixin],
  components: { FirmwareUpdate, CameraDeleteDialog, CameraRebootDialog },
  props: ["camera", "rights", "showToolbar", "appToolbar", "toolbarColor", "menus", "desktop"],
  data() {
    return {
      isDirty: false,
      initValues: {},
      connectionDetails: null,
      cameraMode: null,
      cameraCredentials: null,
      zones: [],
      firmware: null,
      showCameraPassword: false,
      addingToNewZone: false,
      newZoneName: null,
      deletingCamera: false,
      rebootingCamera: false,
      showCredentials: false,
      restoring: false,
      cameraCredentialsHash: null,
      cameraCredentialsInvalid: false,
      snapshot: null,
      blankImg: "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==",
      systemCapabilities: null,
      timeZones: [],
      search: null,
      mcdCamera: false,
    };
  },
  computed: {
    brand() {
      return this.camera && this.camera.hasOwnProperty("deviceInfo") ? this.camera.deviceInfo.brand : null;
    },
    model() {
      return this.camera && this.camera.hasOwnProperty("deviceInfo") ? this.camera.deviceInfo.model : null;
    },
    isAppToolbar() {
      if (this.appToolbar === undefined || this.appToolbar === null) return true;
      return !!this.appToolbar;
    },
    cameraId() {
      return this.camera ? this.camera.cameraId : null;
    },
    cameraSnapshot() {
      if (this.snapshot) {
        const snapshot = btoa(this.snapshot);
        return `data:image/jpeg;charset=utf-8;base64,${snapshot}`;
      }
      return null;
    },
    zoneId() {
      return this.camera ? this.camera.zoneId : null;
    },
    manualConnected() {
      return this.connectionDetails ? this.connectionDetails.connectionType === "manual" : null;
    },
    recordingMode() {
      return this.cameraMode
        ? this.cameraMode.recordingMode === "continuous"
          ? this.$t("Continuous")
          : this.$t("Event based")
        : null;
    },
    cameraModus: {
      get: function () {
        if (this.cameraMode) {
          return this.cameraMode.mode === "private" ? this.cameraMode.previousMode : this.cameraMode.mode;
        }
        return null;
      },
      set: function (newValue) {
        this.cameraMode.mode = newValue;
        this.checkIfDirty();
      },
    },
    cameraName: {
      get: function () {
        return this.camera ? this.camera.name : null;
      },
      set: function (newValue) {
        this.camera.name = newValue;
        this.checkIfDirty();
      },
    },
    cameraEnabled: {
      get: function () {
        return this.cameraMode ? this.cameraMode.mode !== "private" : null;
      },
      set: function (newValue) {
        if (newValue === false) {
          this.cameraMode.previousMode = this.cameraMode.mode;
          this.cameraMode.mode = "private";
        } else {
          const previousMode = this.cameraMode.previousMode;
          this.cameraMode.previousMode = this.cameraMode.mode;
          this.cameraMode.mode = previousMode;
        }
        this.checkIfDirty();
      },
    },
    zone: {
      get: function () {
        if (this.zones && this.zones.length > 0 && this.zoneId) {
          const zoneId = this.zoneId;
          return this.zones.find((z) => {
            return z.zoneId === zoneId;
          });
        }
        return null;
      },
      set: function (zoneId) {
        this.camera.zoneId = zoneId;
        this.checkIfDirty();
      },
    },
    timeZone: {
      get: function () {
        return this.camera ? this.camera.timeZone : null;
      },
      set: function (newValue) {
        this.camera.timeZone = newValue;
        this.checkIfDirty();
      },
    },
    macAddress() {
      if (!this.camera) return null;
      return this.camera.hasOwnProperty("ethMacAddress") &&
        this.camera.ethMacAddress !== null &&
        this.camera.ethMacAddress !== "00-00-00-00-00-00"
        ? this.camera.ethMacAddress
        : "n.a.";
    },
    provideFirmwareVersion() {
      return this.systemCapabilities ? this.systemCapabilities.getFirmwareVersion : null;
    },
    rebootSupported() {
      return this.systemCapabilities ? this.systemCapabilities.reboot : false;
    },
    currentFirmwareVersion() {
      return this.firmware ? this.firmware.currentVersion : null;
    },
    online() {
      return this.status ? this.status.online : null;
    },
    status() {
      return this.desktop
        ? this.desktop.status
        : this.camera && this.camera.hasOwnProperty("status")
        ? this.camera.status
        : null;
    },
    capabilities() {
      return this.camera && this.camera.hasOwnProperty("detectionCapabilities")
        ? this.camera.detectionCapabilities
        : null;
    },
    uptodate() {
      return this.firmware ? this.firmware.status === "up-to-date" : null;
    },
    upgradable() {
      return this.firmware ? this.firmware.status === "upgradable" : null;
    },
    upgrading() {
      return this.firmware ? this.firmware.status === "upgrading" : null;
    },
    upgradeStatusText() {
      switch (this.upgradeStatus) {
        case "Requesting":
          return this.$t("Requesting...");
        case "Initializing":
          return this.$t("Initializing...");
        case "Uploading":
          return this.$t("Downloading firmware...");
        case "Upgrading":
          return this.$t("Installing firmware...");
        case "Restarting":
          return this.$t("Restarting camera...");
        default:
          return this.upgradeStatus;
      }
    },
    progress() {
      if (this.jobProgress) {
        return parseInt((this.jobProgress.current / this.jobProgress.total) * 100);
      }
      return null;
    },
    cameraNameErrors() {
      const errors = [];
      if (!this.$v.cameraName.$dirty) return errors;
      if (!this.$v.cameraName.required) errors.push("Camera name is required");
      if (!this.$v.cameraName.minLength || !this.$v.cameraName.maxLength) {
        errors.push(
          this.$t("Camera name should be X-Y characters", {
            min: this.$v.cameraName.$params.minLength.min,
            max: this.$v.cameraName.$params.maxLength.max,
          })
        );
      }
      return errors;
    },
    newZoneNameErrors() {
      const errors = [];
      if (!this.$v.newZoneName.$dirty) return errors;
      if (!this.$v.newZoneName.required) errors.push(this.$t("Zone name is required"));
      if (!this.$v.newZoneName.minLength || !this.$v.newZoneName.maxLength) {
        errors.push(
          this.$t("Zone name should be X-Y characters", {
            min: this.$v.newZoneName.$params.minLength.min,
            max: this.$v.newZoneName.$params.maxLength.max,
          })
        );
      }
      return errors;
    },
    username: {
      get: function () {
        return this.cameraCredentials ? this.cameraCredentials.username : null;
      },
      set: function (value) {
        if (!this.cloudManagedCredentials) {
          this.cameraCredentials.username = value;
        }
      },
    },
    password: {
      get: function () {
        return this.cameraCredentials ? this.cameraCredentials.password : null;
      },
      set: function (value) {
        if (!this.cloudManagedCredentials) {
          this.cameraCredentials.password = value;
        }
      },
    },
    cloudManagedCredentials() {
      return this.cameraCredentials ? this.cameraCredentials.cloudManagedCredentials : null;
    },
    isNuboCam() {
      return this.camera && this.camera.hasOwnProperty("deviceInfo")
        ? this.camera.deviceInfo.model === "NuboCam"
        : null;
    },
    globalHost: {
      get: function () {
        return this.connectionDetails
          ? this.connectionDetails.globalHost === "0.0.0.0"
            ? "n.a."
            : this.connectionDetails.globalHost
          : null;
      },
      set: function (value) {
        this.connectionDetails.globalHost = value;
      },
    },
    globalPort: {
      get: function () {
        return this.connectionDetails ? this.connectionDetails.globalPort : null;
      },
      set: function (value) {
        this.connectionDetails.globalPort = value;
      },
    },
    globalHostErrors() {
      const errors = [];
      if (!this.$v.globalHost.$dirty) return errors;
      if (!this.$v.globalHost.required) errors.push(this.$t("Please enter a valid ip address or hostname"));
      return errors;
    },
    globalPortErrors() {
      const errors = [];
      if (!this.$v.globalPort.$dirty) return errors;
      if (!this.$v.globalPort.required || !this.$v.globalPort.numeric)
        errors.push(this.$t("Please enter a numeric value"));
      if (!this.$v.globalPort.minValue || !this.$v.globalPort.maxValue) {
        errors.push(
          this.$t("Enter a value between X and Y", {
            min: this.$v.globalPort.$params.minValue.min,
            max: this.$v.globalPort.$params.maxValue.max,
          })
        );
      }
      return errors;
    },
    cameraCredentialsChanged() {
      return this.cameraCredentialsHash !== hash(this.cameraCredentials);
    },
    tbColor() {
      return this.toolbarColor || "white";
    },
  },
  watch: {
    camera: function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.isDirty = false;
        this.initValues = {
          name: this.camera.name,
          zoneId: this.camera.zoneId,
          cameraMode: {},
          timeZone: this.camera.timeZone,
        };
      } else if (oldVal !== null && newVal === null) {
        this.isDirty = false;
        this.initValues = {
          name: null,
          zoneId: null,
          cameraMode: {},
          timeZone: null,
        };
      }
      if (newVal) {
        this.getCameraInfo();
        this.getFormInfo();
        this.initValues = {
          name: this.camera.name,
          zoneId: this.camera.zoneId,
          cameraMode: {},
          timeZone: this.camera.timeZone,
        };
      }
    },
    status: async function (newVal, oldVal) {
      if (newVal) {
        if (this.status && this.status.online) {
          try {
            const snapshot = await this.restapi.getSnapshot(this.cameraId, "320x240");
            this.snapshot = snapshot;
          } catch (error) {
            console.error(error);
          }
        }
      }
    },
    "cameraMode.mode": function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.initValues.cameraMode = Object.assign({}, this.cameraMode);
      }
    },
    "camera.name": function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.initValues.name = this.camera.name;
      }
    },
    "camera.zoneId": function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.initValues.zoneId = this.camera.zoneId;
      }
    },
    "camera.timeZone": function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.initValues.timeZone = this.camera.timeZone;
      }
    },
  },
  mounted() {
    this.getCameraInfo();
    this.getFormInfo();
    this.initValues = {
      name: this.camera.name,
      zoneId: this.camera.zoneId,
      cameraMode: {},
      timeZone: this.camera.timeZone,
    };
  },
  methods: {
    getCameraModeName(mode) {
      switch (mode) {
        case "armed":
          return this.$t("Armed");
        case "silent":
          return this.$t("Silent");
        case "disarmed":
          return this.$t("Disarmed");
        case "custom":
          return this.$t("Scheduled");
      }
    },
    cameraDeleted(cameraId) {
      this.$emit("deleted", cameraId);
      this.deletingCamera = false;
      this.$router.push({ name: "Settings" });
    },
    async getCameraInfo() {
      const [capabilities, details, mode, credentials, deviceInfo, mcdCamera] = await Promise.allSettled([
        this.restapi.getCameraSystemCapabilities(this.cameraId),
        this.restapi.getCameraConnectionDetails(this.cameraId),
        this.restapi.getCameraMode(this.cameraId),
        this.restapi.getCameraConnectionCredentials(this.cameraId),
        this.restapi.getCameraDeviceInfo(this.camera.cameraId),
        this.restapi.getCamera(this.cameraId),
      ]);
      capabilities.value ? (this.systemCapabilities = capabilities.value) : console.log(capabilities.reason);
      details.value ? (this.connectionDetails = details.value) : console.log(details.reason);
      mcdCamera.value.hasOwnProperty("parentDeviceId") ? (this.mcdCamera = mcdCamera.value.parentDeviceId) : (this.mcdCamera = false);
      mode.value ? (this.cameraMode = mode.value) : console.log(mode.reason);
      credentials.value ? (this.cameraCredentials = credentials.value) : console.log(credentials.reason);
      credentials.value ? (this.cameraCredentialsHash = hash(credentials.value)) : null;

      if (!this.camera.hasOwnProperty("deviceInfo") && deviceInfo.value) {
        this.$set(this.camera, "deviceInfo", deviceInfo.value);
      }
      if (this.systemCapabilities && this.systemCapabilities.getFirmwareVersion) {
        try {
          const firmware = await this.restapi.getCameraFirmware(this.cameraId);
          this.firmware = firmware;
        } catch (error) {
          console.log(error);
        }
      }
      if (this.status && this.status.online && this.camera.hasOwnProperty("snapshot")) {
        this.snapshot = this.camera.snapshot;
      } else {
        try {
          const snapshot = await this.restapi.getSnapshot(this.cameraId, "320x240");
          this.snapshot = snapshot;
        } catch (error) {
          console.log(error);
        }
      }
    },
    checkIfDirty() {
      if (!this.initValues.hasOwnProperty("name")) return null;
      this.isDirty =
        this.initValues.name !== this.camera.name ||
        this.initValues.zoneId !== this.camera.zoneId ||
        this.initValues.cameraMode.mode !== this.cameraMode.mode ||
        this.initValues.timeZone !== this.camera.timeZone;
    },
    cancelChanges() {
      this.camera.name = this.initValues.name;
      this.camera.zoneId = this.initValues.zoneId;
      this.cameraMode = Object.assign({}, this.initValues.cameraMode);
      this.camera.timeZone = this.initValues.timeZone;
      this.checkIfDirty();
    },
    agreeRemoval() {
      this.deletingCamera = false;
      this.model = 0;
    },

    goToDevice() {
      if (this.mcdCamera) {
        this.$router.push({ path: `/settings/devices/${this.mcdCamera}` });
      }
    },
    saveChanges() {
      if (this.rights && this.rights.demoUser) {
        return this.$store.dispatch("demoMessage", this.$t("As a demo user you cannot make any changes."));
      }
      let hasErrors = false;
      const that = this;
      const promise = new Promise(function (resolve, reject) {
        resolve("Success!");
      });
      promise
        .then(function () {
          if (that.camera.name !== that.initValues.name) {
            return that.restapi
              .setCameraName(that.cameraId, that.camera.name)
              .then(function () {
                console.log("Done!");
                that.initValues.name = that.camera.name;
                that.checkIfDirty();
              })
              .catch(function (error) {
                console.error(error);
                console.log("hasErrors?", hasErrors);
                hasErrors = true;
              })
              .then(function () {
                return hasErrors;
              });
          } else {
            return hasErrors;
          }
        })
        .then(function () {
          if (that.camera.zoneId !== that.initValues.zoneId) {
            return that.restapi
              .setZoneCameras([that.cameraId], that.camera.zoneId)
              .then(function () {
                that.initValues.zoneId = that.camera.zoneId;
                that.checkIfDirty();
              })
              .catch(function (error) {
                console.error(error);
                hasErrors = true;
              })
              .then(function () {
                return hasErrors;
              });
          } else {
            return hasErrors;
          }
        })
        .then(function () {
          if (that.cameraMode.mode !== that.initValues.cameraMode.mode) {
            return that.restapi
              .setCameraMode(that.cameraId, { mode: that.cameraMode.mode })
              .then(function () {
                that.restapi
                  .getCameraMode(that.cameraId)
                  .then(function (mode) {
                    that.cameraMode = mode;
                    that.initValues.cameraMode = Object.assign({}, mode);
                    that.checkIfDirty();
                  })
                  .catch(function (error) {
                    console.error(error);
                  });
              })
              .catch(function (error) {
                console.error(error);
                hasErrors = true;
              })
              .then(function () {
                return hasErrors;
              });
          } else {
            return hasErrors;
          }
        })
        .then(function () {
          if (that.camera.timeZone !== that.initValues.timeZone) {
            return that.restapi
              .setCameraLocation(that.cameraId, that.camera.timeZone)
              .then(function () {
                that.initValues.timeZone = that.camera.timeZone;
                that.camera.timeOffset = that.getOffset(that.camera.timeZone);
                that.checkIfDirty();
              })
              .catch(function (error) {
                console.error(error);
                console.log("hasErrors?", hasErrors);
                hasErrors = true;
              })
              .then(function () {
                return hasErrors;
              });
          } else {
            return hasErrors;
          }
        })
        .then(function () {
          if (hasErrors === false) {
            store.dispatch("toastMessage", {
              showing: true,
              text: that.$t("Your changes were successfully saved."),
              timeout: 4000,
              color: "primary",
            });
          } else {
            store.dispatch("toastMessage", {
              showing: true,
              text: that.$t("An error occurred while saving your changes."),
              timeout: -1,
              color: "error",
              support: true,
            });
          }
        });
    },
    async firmwareUpdated() {
      try {
        const firmware = await this.restapi.getCameraFirmware(this.cameraId);
        this.firmware = firmware;
      } catch (error) {
        console.error(error);
      }
    },
    async getFormInfo() {
      try {
        const [zones, timeZones] = await Promise.allSettled([this.restapi.getZones(), this.restapi.getTimeZones()]);
        zones.value ? (this.zones = zones.value.sort()) : console.log(zones.reason);
        timeZones.value ? (this.timeZones = timeZones.value) : console.log(timeZones.reason);
      } catch (error) {
        console.log(error);
      }
    },
    filter() {
      return (item, search, textKey) => item[textKey].indexOf(search) > -1;
    },
    getOffset(timeZone) {
      const foundTimeZone = findTimeZone(timeZone);
      const date = new Date();
      const timeZoneOffset = getUTCOffset(date, foundTimeZone);
      return timeZoneOffset.offset;
    },
    addOffset(timezone) {
      const offset = this.getOffset(timezone);
      const hours = offset / 60;
      const minutes = offset % 60;
      if (hours > 0) return " ( -" + hours + (minutes ? `:${Math.abs(minutes)}` : "") + " )";
      else return " ( +" + Math.floor(Math.abs(hours)) + (minutes ? `:${Math.abs(minutes)}` : "") + " )";
    },
    toggleDeletingCamera() {
      if (this.rights && this.rights.demoUser) {
        return this.$store.dispatch("demoMessage");
      } else this.deletingCamera = true;
    },
    toggleRebootingCamera() {
      if (this.rights && this.rights.demoUser) {
        return this.$store.dispatch("demoMessage");
      } else this.rebootingCamera = true;
    },
  },

  destroyed() {
    this.cancelChanges();
  },
  validations: {
    cameraName: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(32),
    },
    newZoneName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(32),
    },
    globalHost: {
      required,
    },
    globalPort: {
      required,
      numeric,
      minValue: minValue(1),
      maxValue: maxValue(65535),
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/settings";
.agree-button {
  position: absolute;
  width: 100%;
  bottom: 10px;
}
</style>
