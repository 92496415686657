<template>
  <v-dialog v-model="addingNewZone" max-width="400">
    <v-card>
      <v-card-title primary-title> </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="newZoneName"
          :label="$t('Zone name')"
          :error-messages="newZoneNameErrors"
          @input="$v.newZoneName.$touch()"
          @blur="$v.newZoneName.$touch()"
          @keyup.enter="addZone"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <een-spinner :size="50" :isloading="zoneSpinner"></een-spinner>
        <v-spacer></v-spacer>
        <v-btn depressed color="primary" :disabled="$v.newZoneName.$invalid || zoneSpinner" @click="addZone">
          {{ $t("Add new zone") }}
        </v-btn>
        <v-btn depressed @click="cancelAddZone">{{ $t("Close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  name: "AddZone",
  data() {
    return { zoneSpinner: false, newZoneName: "" };
  },
  props: {
    addingNewZone: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    newZoneNameErrors() {
      const errors = [];
      if (!this.$v.newZoneName.$dirty) return errors;
      if (!this.$v.newZoneName.required) errors.push(this.$t("Zone name is a required field"));
      if (!this.$v.newZoneName.minLength || !this.$v.newZoneName.maxLength) {
        errors.push(
          this.$t("Zone name should be X-Y characters", {
            min: this.$v.newZoneName.$params.minLength.min,
            max: this.$v.newZoneName.$params.maxLength.max,
          })
        );
      }
      return errors;
    },
  },
  validations() {
    return {
      newZoneName: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(32),
      },
    };
  },

  methods: {
    addZone() {
      this.zoneSpinner = true;
      if (!this.$v.newZoneName.$invalid) {
        const that = this;
        this.restapi
          .addZone(this.newZoneName)
          .then(function (zone) {
            that.$v.$reset();
            that.$emit("added", zone.zoneId);
          })
          .then(function () {
            that.restapi.getZones().then(function () {
              that.newZoneName = null;
              that.zoneSpinner = false;
            });
          })
          .catch(function (error) {
            console.error(error);
          });
      }
    },
    cancelAddZone() {
      this.$v.$reset();
      this.$emit("added");
      this.newZoneName = null;
    },
  },
};
</script>

<style></style>
