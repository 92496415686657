const state = {
  loggedIn: false,
  blocked: false,
  scheduled: true,
}

const getters = {
  isBlocked: state => {
    return state.blocked
  },
  isScheduled: state => {
    return state.scheduled
  },
}

const mutations = {
  blocked: (state, blocked) => {
    state.blocked = blocked
  },
  scheduled: (state, scheduled) => {
    state.scheduled = scheduled
  },
}

export default {
  state,
  getters,
  mutations,
}
