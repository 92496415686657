<template>
  <div class="partner-profile edit-mode">
    <div class="head">
      <p>{{ $t("To add or remove a camera/zone for this user use the corresponding switch.") }}</p>
    </div>
    <v-btn @click="saveChanges" :disabled="isDirty" color="primary">{{ $t("Save Changes") }}</v-btn>
    <div>
      <template v-for="(zone, index) in cameraZones" v-if="cameraZones && cameras">
        <zone-list
          :key="index"
          :id="`zone-${index}`"
          :zone="zone"
          :cameras="cameras"
          @zoneUpdated="zoneUpdated"
          @cameraUpdated="cameraUpdated"
          :newPermissions="newPermissions"
        ></zone-list>
        <div v-if="index < cameraZones.length - 1" class="pt-0" :key="zone.zoneId"></div>
      </template>
    </div>
    <een-spinner :isloading="isloading" :size="56" :width="7"></een-spinner>
  </div>
</template>

<script>
import _ from "lodash";
import ZoneList from "@/components/Users/ZoneList";
import { store } from "@/store";
import { getMessageFromError } from "@eencloud/core-components/src/service/errors";

const hash = require("object-hash");

export default {
  name: "UserCameras",
  components: { ZoneList },
  props: ["rights" , "cameras"],
  data() {
    return {
      permissions: null,
      isloading: false,
      cameras: "",
      filter: "",
      cameraZones: "",
      newPermissions: null,
    };
  },
  async mounted() {
    try {
      this.permissions = await this.restapi.getSpecificUserRights(this.$route.params.userId);
      this.newPermissions = _.cloneDeep(this.permissions);
      await this.getZones();
      await this.getCameras();
    } catch (error) {
      store.dispatch("toastMessage", {
        text: getMessageFromError(error, "GET_USER"),
        color: "error",
        showing: true,
        timeout: -1,
        support: true,
      });
    }
  },
  methods: {
    zoneUpdated(id) {
      const index = this.newPermissions.zoneIds.indexOf(id);
      if (index !== -1) {
        this.newPermissions.zoneIds.splice(index, 1);
      } else {
        this.newPermissions.zoneIds.push(id);
      }
    },
    cameraUpdated(camera) {
      const index = this.newPermissions.cameraIds.indexOf(camera.cameraId);
      if (index !== -1) {
        this.newPermissions.cameraIds.splice(index, 1);
        this.cameras.find((element) => {
          if (element.cameraId == camera.cameraId) {
            this.$set(element, "present", false);
          }
        });
      } else {
        this.newPermissions.cameraIds.push(camera.cameraId);
        this.cameras.find((element) => {
          if (element.cameraId == camera.cameraId) {
            this.$set(element, "present", true);
          }
        });
      }
    },
    async getCameras() {
      try {

        this.cameras.map((element) => {
          if (this.permissions.cameraIds.includes(element.cameraId)) {
            this.$set(element, "present", true);
          } else {
            this.$set(element, "present", false);
          }
        });
        const statuses = await this.restapi.getCamerasStatus();
        this.cameras.forEach((cam) => {
          this.$set(
            cam,
            "status",
            statuses.find((s) => s.cameraId === cam.cameraId)
          );
        });
      } catch (error) {
        store.dispatch("toastMessage", {
          text: this.$t("Error getting the cameras"),
          color: "error",
          showing: true,
          timeout: -1,
          support: true,
        });
      }
    },

    async getZones() {
      try {
        this.cameraZones = await this.restapi.getZones();
        this.cameraZones.map((element) => {
          if (this.permissions.zoneIds.includes(element.zoneId)) {
            this.$set(element, "present", true);
          } else {
            this.$set(element, "present", false);
          }
        });
      } catch (error) {
        store.dispatch("toastMessage", {
          text: that.$t("Error getting the zones"),
          color: "error",
          showing: true,
          timeout: -1,
          support: true,
        });
      }
    },
    saveChanges() {
      if (this.rights && this.rights.demoUser) {
        return this.$store.dispatch("demoMessage");
      }
      const that = this;
      this.restapi
        .setSpecificUserRights(this.$route.params.userId, this.newPermissions)
        .then(() => {
          that.permissions = _.cloneDeep(that.newPermissions);
          store.dispatch("toastMessage", {
            text: "Permissions have been saved successfully",
            color: "primary",
            showing: true,
            timeout: 4000,
          });
        })
        .catch((error) => {
          store.dispatch("toastMessage", {
            text: getMessageFromError(error, "SET_USER_RIGHTS"),
            color: "error",
            showing: true,
            timeout: -1,
            support: true,
          });
        });
    },

    camerasForFilteredZone(zoneId) {
      return this.filteredCameras.filter((c) => {
        return c.zoneId === zoneId;
      });
    },
  },
  computed: {
    isDirty() {
      return this.permissions ? _.isEqual(this.permissions, this.newPermissions) : false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/main";

.partner-profile {
  max-width: 320px;
  width: 320px;
  margin: 10px;
  h2 {
    color: $primary;
    text-transform: uppercase;
    font-size: 14px;
  }

  &.edit-mode {
    position: relative;

    padding-bottom: 40px;
    max-width: 900px;
    width: 900px;
    margin: 0 auto;
  }
}
</style>
