<template>
  <v-dialog v-model="showDialog" persistent width="500">
    <v-card :height="125">
      <v-card-title primary-title>
      </v-card-title>
      <v-card-text>
        {{
          $t(
            "Are you sure you want to reboot this camera?"
          )
        }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          color="primary"
          @click="rebootCamera()"
          >{{ $t("Confirm") }}</v-btn
        >
        <v-btn depressed @click.stop="closeDialog">{{ $t("Close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { store } from "@/store";
import { getMessageFromError } from "@eencloud/core-components/src/service/errors";
import { t } from "@eencloud/core-components/src/service/locale";

export default {
  props: ["camera", "showDialog"],
  data() {
    return {
      rebootCameraText: t("Your device is rebooting. Please wait for 2 minutes before accessing it."),
    };
  },
  computed: {
    cameraId() {
      return this.camera ? this.camera.cameraId : null;
    },
  },
  methods: {
    async rebootCamera() {
      try {
        const result = await this.restapi.cameraMaintenance(this.cameraId, {operation: "reboot"});
        if(result && result.success) {
          store.dispatch("toastMessage", {
            showing: true,
            text: this.rebootCameraText,
            timeout: -1,
            color: "primary",
          });
        } else {
          store.dispatch("toastMessage", {
            showing: true,
            text: this.$t("Camera reboot failed"),
            timeout: -1,
            color: "error",
            support: true
          });
        }
      } catch (error) {
        store.dispatch("toastMessage", {
          showing: true,
          text: getMessageFromError(error, "CAMERA_MAINTENANCE"),
          timeout: -1,
          color: "error",
          support: true
        });
      }
      this.$emit("closeDialog");
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style lang="scss" scoped></style>
