//this module holds webapp-specific values about the state of the app.
const state = {
    cameraSelected: null,
    isMultiView: false,
    allCameras: null,
    supportAccess: false,
    isAiAnalyticsMenuVisible: false,
    aiAnalyticsToken: null
  };
  
  const getters = {
    cameraSelected: state => {
      return state.cameraSelected;
    },
    isMultiView: state => {
      return state.isMultiView;
    },
    supportAccess: state => {
      return state.supportAccess;
    },
    allCameras: state => {
      return state.allCameras
    },
    isAiAnalyticsMenuVisible: state => {
      return state.isAiAnalyticsMenuVisible
    },
    aiAnalyticsToken: state => {
      return state.aiAnalyticsToken
    },
  };
  
  const mutations = {
    CALL_CAMERA: (state, camera) => {
      state.cameraSelected = camera;
    },
    CALL_MULTIVIEW: (state, isMultiView) => {
      state.isMultiView = isMultiView;
    },
    SET_SUPPORT_ACCESS: (state, supportAccess) => {
      state.supportAccess = supportAccess;
    },
    ALL_CAMERAS: (state, allCameras) => {
      state.allCameras = allCameras
    },
    SET_AI_ANALYTICS_MENU_VISIBLE: (state, visible) => {
      state.isAiAnalyticsMenuVisible = visible
    },
    SET_AI_ANALYTICS_TOKEN: (state, token) => {
      state.aiAnalyticsToken = token
    }
  };
  
  const actions = {
    selectedCamera({ commit }, message) {
      commit("CALL_CAMERA", message);
    },
    isMultiView({ commit }, message) {
      commit("CALL_MULTIVIEW", message);
    },
    supportAccess({ commit }, data) {
      commit("SET_SUPPORT_ACCESS", data);
    },
    allCameras({ commit }, message) {
      commit("ALL_CAMERAS", message)
    },
    aiAnalyticsMenuVisible({ commit }, message) {
      commit("SET_AI_ANALYTICS_MENU_VISIBLE", message)
    },
    setAiAnalyticsToken({ commit }, message) {
      commit("SET_AI_ANALYTICS_TOKEN", message)
    }
  };
  export default {
    state,
    getters,
    mutations,
    actions
  };
  