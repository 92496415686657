import { t } from "@eencloud/core-components/src/service/locale";

export function getCameraErrorMsg(error) { 
  let text = undefined;
  const customErrorCodePrefix = "3.w.4";
  switch (error) {
    case "notConnected":
      text = `${t("Device wasn't able to connect to the cloud.")} ${t(
        "Please make sure the device is connected to a power source and has a stable internet connection."
      )} errorCode: ${customErrorCodePrefix}.14.239a`;
      break;
    case "notOnline":
      text = `${t("Device wasn't able to connect to the cloud.")} ${t(
        "Please make sure the device is connected to a power source and has a stable internet connection."
      )} errorCode: ${customErrorCodePrefix}.14.239b`;
      break;
    case "deviceIdentificationFailed":
      text = `${t("Something went wrong.")} ${t(
      "The device could not be identified. Please restart or factory reset the device and try again."
      )} errorCode: ${customErrorCodePrefix}.3.239a`;
      break;
    case "credentialSetupFailed ":
      text = `${t("Unknown error.")} ${t(
      "The application has encountered an unknown error, try again later. If the problem persists, contact support."
      )} errorCode: ${customErrorCodePrefix}.1.239`;
      break;
    case "credentialValidationFailed":
      text = `${t("Device doesn't have the default credentials.")} ${t(
        "Please factory reset your device or make sure your device has the default credentials. Try again after this."
      )} errorCode: ${customErrorCodePrefix}.8.239`;
      break;
    case "localUrlValidationFailed":
      text = `${t("Problem detected with your device.")} ${t(
        "Please restart or factory reset the device."
      )} errorCode: ${customErrorCodePrefix}.13.239`;
      break;
    case "unsupportedModel":
      text = `${t("Something went wrong.")} ${t("The device is not supported.")} errorCode: ${customErrorCodePrefix}.3.239b`;
      break;
    case "hwIdRegistrationFailed":
      text = `${t("Something went wrong.")} ${t("Remove the device from the current account before adding it to a new account.")} errorCode: ${customErrorCodePrefix}.5.239a`;
      break;
    case "failedAddingDeviceAfterValidation":
      text = `${t("Something went wrong.")} ${t("The device was validated successfully, but an unexpected error occurred afterwards. Please try again later.")} errorCode: ${customErrorCodePrefix}.1.239a`;
      break;
    default:
      text = t("Something went wrong. Please try again later.");
  }
  return text;
}
