import Vue from 'vue'
import Vuex from 'vuex'

import coreData from '@eencloud/core-components/src/storeModules/coreData'
import appState from './modules/appState'
import authStatus from './modules/authStatus'
import accountDetails from '@eencloud/core-components/src/storeModules/accountDetails'
import cameraDetails from './modules/cameraDetails'
import timelineData from '@eencloud/core-components/src/storeModules/timelineData'
import eventFilters from './modules/eventFilters'
import recordingExports from '@eencloud/core-components/src/storeModules/recordingExports'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    coreData,
    appState,
    authStatus,
    accountDetails,
    cameraDetails,
    timelineData,
    eventFilters,
    recordingExports
  },
})
