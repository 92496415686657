<template>
  <v-container fluid class="settings my-0 py-0">
    <v-row>
      <v-container fluid class="settings-content ma-0 pa-0">
        <v-row class="height100 pl-2">
          <add-camera
            v-if="addCamera"
            :rights="rights"
            :show-back-button="true"
            :add-gap="false"
            @cancel="addCamera = false"
          ></add-camera>
          <v-col v-else-if="!camera" md="12">
            <v-row justify="space-around">
              <v-col cols="12" sm="10" md="10" lg="8" xl="6">
                <dashboard
                  :zones="zones"
                  :cameras="cameras"
                  :camera="camera"
                  :rights="rights"
                  @added="addedZone"
                  @deleted="deletedZone"
                  @select="selectCamera"
                  @addCamera="addCamera = true"
                ></dashboard>
              </v-col>
            </v-row>
          </v-col>
          <template v-else>
            <v-col class="hidden-xs-only pa-0" sm="1" md="3" lg="3">
              <v-card class="side-menu-card" elevation="0" height="100%">
                <een-side-menu 
                  :sideMenuHeader="camera.name"
                  :menus="computedMenus" 
                  :currentEndpoint="menus[menuIndex].endpoint" 
                  @itemSelected="navigate"
                  :sideMenuDrop="cameras"
                  :path='"/settings/cameras/"'
                >
                </een-side-menu>
              </v-card>
            </v-col>
            <v-col id="settings-content" class="pa-0" cols="12" sm="11" md="9" lg="9">
              <router-view 
                v-if="isCameraDataLoaded"
                :camera="camera"
                :menus="menus"
                :app-toolbar="false"
                :show-toolbar="showToolbar(menus[menuIndex].endpoint)"
                :rights="rights"
                toolbar-color="transparent"
                @allCameras="allCameras"
                @deleted="cameraDeleted"
              ></router-view>
              <v-col v-else cols="12" offset="3" class="pt-16">{{$t("Couldn't retrieve camera details")}}</v-col>
            </v-col>
          </template>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import Dashboard from "@/components/Dashboard";
import AddCamera from "@/components/AddCamera";

export default {
  name: "Settings",
  components: {
    Dashboard,
    AddCamera
  },
  props: ["zones", "cameras", "camera", "rights"],
  data() {
    return {
      addCamera: false,
      menuIndex: 0,
      isCameraDataLoaded: false,
      errorLoadingCameraData: false,
      menus: [
        {
          name: "Camera info",
          endpoint: "info",
          icon: "fas fa-camera",
        },
        {
          name: "Recording",
          endpoint: "recording",
          icon: "fas fa-record-vinyl",
        },
        {
          name: "Detections",
          endpoint: "detections",
          icon: "fas fa-diagnoses",
        },
        {
          name: "Detection areas",
          endpoint: "detectionareas",
          icon: "fas fa-chart-area",
        },
        {
          name: "Schedule & Notifications",
          endpoint: "schedule",
          icon: "fas fa-clipboard-list",
        },
        {
          name: "Networks",
          endpoint: "networks",
          icon: "fas fa-wifi",
        },
        {
          name: "Video & Sound",
          endpoint: "video",
          icon: "fas fa-video",
        },
      ]
    };
  },
  watch: {
    camera(){
      this.getCameraInfo();
    },
    $route() {
      this.getComponentFromRoute();
    },
    
  },
  mounted() {
    this.$emit("select", null);
    this.getCameraInfo()
  },
  updated() {
    this.getComponentFromRoute();
  },
  computed: {
    computedMenus() {
      return this.menus.filter(menu => {
        if (!this.showDetections && menu.name === "Detections") return false
        if (!this.showDetectionAreas && menu.name === "Detection areas") return false
        return true
      })
    },
    showDetections() {
      return this.camera?.detectionCapabilities ? Object.values(this.camera.detectionCapabilities).some(value => value) : false
    },
    showDetectionAreas() {
      return this.camera?.detectionCapabilities?.analyticsObjectArea ||
             this.camera?.detectionCapabilities?.analyticsObjectTripWire ||
             this.camera?.detectionCapabilities?.analyticsPersonArea ||
             this.camera?.detectionCapabilities?.analyticsPersonTripWire
    }
  },
  methods: {
    navigate(menu) {
     if (this.camera && this.menus[this.menuIndex]?.endpoint !== menu.endpoint) 
      this.$router.replace({ path: `/settings/cameras/${this.camera.cameraId}/${menu.endpoint}` })
    },
    selectCamera(id) {
      this.$emit("select", id);
    },
    allCameras() {
      this.$router.push({ name: "Settings" });
    },
    showToolbar(menu) {
      return true;
    },
    cameraDeleted(id) {
      this.$emit("select", null);
      this.$emit("deleted", id);
      this.$emit("deletedCamera", id);
    },
    addedZone(zone) {
      this.$emit("addedzone", zone);
    },
    deletedZone(id) {
      this.$emit("deletedzone", id);
    },
    getCameraInfo() {
      const that = this;
      const promise = new Promise(function (resolve, reject) {
        resolve("Success!");
      });
      promise
        .then(() => {
          if (that.camera && !that.camera.hasOwnProperty("cameraCapabilities")) {
            const that = this;
            that.restapi
              .getCameraCapabilities(that.camera.cameraId)
              .then(function (capabilities) {
                that.$set(that.camera, "cameraCapabilities", capabilities);
              })
              .catch((error) => {
                console.error(error);
                that.errorLoadingCameraData = true;
              });
          }
        })
        .then(() => {
          if (that.camera && !that.camera.hasOwnProperty("detectionCapabilities")) {
            const that = this;
            that.restapi
              .getCameraDetectionCapabilities(that.camera.cameraId)
              .then(function (detectionCapabilities) {
                that.$set(that.camera, "detectionCapabilities", detectionCapabilities);
              })
              .catch((error) => {
                console.error(error);
                that.errorLoadingCameraData = true;
              });
          }
        })
        .then(() => {
          if (that.camera && !that.camera.hasOwnProperty("deviceInfo")) {
            const that = this;
            return that.restapi
              .getCameraDeviceInfo(that.camera.cameraId)
              .then(function (deviceInfo) {
                that.$set(that.camera, "deviceInfo", deviceInfo);
              })
              .catch((error) => {
                console.error(error);
                that.errorLoadingCameraData = true;
              });
          }
        })
        .then(() => {
          if (that.camera && !that.camera.hasOwnProperty("status")) {
            const that = this;
            return that.restapi
              .getCameraStatus(that.camera.cameraId)
              .then(function (status) {
                that.$set(that.camera, "status", status);
              })
              .catch((error) => {
                console.error(error);
                that.errorLoadingCameraData = true;
              });
          }
        })
        .then(() => {
          if (this.camera && this.camera.hasOwnProperty("status") && 
              this.camera && this.camera.hasOwnProperty("deviceInfo") && 
              this.camera && this.camera.hasOwnProperty("detectionCapabilities") && 
              this.camera && this.camera.hasOwnProperty("cameraCapabilities")
          ) {
            this.isCameraDataLoaded = true
          }
        })
        
    },
    getComponentFromRoute() {
      let result = 0
      this.menus.map((item, i)=> {
        if (this.$route.path.search(item.endpoint) !=-1) {
          result = i
        }
      })
      this.menuIndex = result
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/main";

.settings {
  .settings-content {
    background-color: $color-panel-grey;
    min-height: $main-content-height;
  }
}
.menu-text {
  letter-spacing: 1px !important;
  font-size: 14px !important;
}
.item-icon {
  width: 50px;
  margin: auto !important;
  i {
    margin: auto;
  }
}
.side-menu-selected-item {
  background-color: #eeeeee;
  font-weight: 600;
  border-radius: 0;
}
.side-navigation-drawer {
  top: 70px !important;
}
.item-icon {
  width: 50px;
  margin: auto !important;
  i {
    margin: auto;
  }
}
.menu-text {
  letter-spacing: 1px !important;
  font-size: 14px !important;
}
.side-menu:hover {
  width: 102%;
  border-radius: 0 !important;
}
.side-menu:focus {
  background-color: transparent;
  width: 102%;
  border-radius: 0 !important;
}
.side-menu-card {
  background: #eeeeee;
}
</style>