<template>
  <v-app id="app" class="global-container bg-primary" :class="{ isIE: isIE }">
    <v-main>
      <v-container class="ma-0 pa-0" fluid>
        <een-header
          v-if="showNavigation"
          :isAuthorized="isAuthorized"
          :logo="logo"
          :appSwitcher="headerData.applications"
          :logoHeight="logoHeight" 
          :logoWidth="logoWidth"
          :email="$store.getters.account ? $store.getters.email : 'an end user'"
          :supportAccess="$store.getters.supportAccess"
          @logoClick="$router.push({ name: 'Dashboard' })"
        >
          <template v-slot:header>
            <navigation
              v-if="isAuthorized"
              :rights="rights"
              :selectedCamera="$store.getters.cameraSelected && $store.getters.cameraSelected.id"
              @openChat="openChat"
              />
          </template>
          <template v-slot:account-menu>
            <div class="pt-3 pr-2">
              <een-account-menu
                :langSwitcher="true"
                :showMyAccount="!$store.getters.supportAccess"
                :email="$store.getters.account ? $store.getters.email : null"
                :name="$store.getters.account ? $store.getters.username : null"
                @logout="logout"
              />
            </div>
          </template>
        </een-header>
        <router-view 
          :received-messages="receivedMessages" 
          :logo="logo" 
          :logo-navigation="logoNavigation" 
          class="main-content" 
          :class="{'show-nav': showNavigation, 'padding-content':!showSidebar && isAuthorized && !this.$route.path.includes('logout') }"
          :zones="zones" 
          :cameras="cameras" 
          :camera="camera" 
          :rights="rights"
          :audioAvailable="audioAvailable"
          :show-navigation="showNavigation"
          @loadCameras="loadCameras"
          @transferredCamera="transferredCamera" 
          @deletedzone="deletedZone" 
          @addedzone="addedZone" 
          @patchedzone="patchedZone"
          @deletedCamera="deletedCamera"
        >
        </router-view>
        <een-snack-bar
          :showing="$store.state.coreData.snackBar.showing"
          :timeout="$store.state.coreData.snackBar.timeout"
          :color="$store.state.coreData.snackBar.color"
          :text="$store.state.coreData.snackBar.text"
          :support="$store.state.coreData.snackBar.support && $store.state.coreData.theme.application === 'Cameramanager'"
          :confirmation="$store.state.coreData.snackBar.confirmation"
          @closeSnackBar="$store.state.coreData.snackBar.showing = false"
          @openChat="openChat"
        ></een-snack-bar>
        <!-- <CookieBanner v-if="showNavigation"/> -->
        <video src="../src/assets/video/hevc-cm-example.mp4" width="1px" height="1px" id="hevctest" autoplay controls />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Theme from "@eencloud/core-components/src/service/themes";
import Navigation from '@/components/Navigation'
import StompClient from '@/service/StompClient'
import { changeFavicon } from './service/main'
import * as Bowser from 'bowser'
import { findTimeZone, getUTCOffset } from 'timezone-support'
import CookieBanner from '@eencloud/core-components/src/components/CookieBanner'
import locale from '@eencloud/core-components/src/service/locale'
const auth = require('@/service/auth')

const TIMEOUT = 20000
const AI_ANALYTICS_CLIENT_ID = "devtest1"

const themeName = config.hasOwnProperty('theme') ? config.theme : 'Cameramanager'
const theme = new Theme(themeName)
const links = theme.links()

export default {
  name: 'WebApp',
  components: {
    Navigation,
    CookieBanner
  },
  data() {
    return {
      lastTime: (new Date()).getTime(),
      pingInterval: null,
      saveMessages: false,
      receivedMessages: [],
      token: null,
      logo: '',
      logoNavigation: '',
      showSidebar: false,
      zones: [],
      cameras: [],
      camera: null,
      logoWidth: '',
      logoHeight: '',
      user: null,
      rights: null,
      stompClient: null,
      browser: null,
      headerData: {
        applications: [
          {
            name: "Web App",
            link: links.webapp ? links.webapp : config.webappURL,
            icon: "globe",
            show: true
          },
          {
            name: "Download Portal",
            link: links.downloadportal ? links.downloadportal : config.downloadportalURL,
            icon: "download",
            show: links.showDownloadPortal
          },
          {
            name: "My Account",
            link: links.myaccount ? links.myaccount : config.myaccountURL,
            icon: "user",
            show: true
          },
          {
            name: "Dealer Dashboard",
            link: links.dealerdashboard ? links.dealerdashboard : config.dealerURL,
            icon: "list-alt",
            show: links.showdealerdashboard
          }
        ]
      }
    }
  },
  computed: {
    userId() {
      return this.$store.getters.userId
    },
    cameraId() {
      return this.$route.params.cameraId
    },
    isAuthorized() {
      return this.$store.getters.isAuthorized
    },

    showNavigation() {
      return true
    },
    audioAvailable() {
      return this.camera?.detectionCapabilities?.audio
    },
    defaultLanguage() {
      const browserLanguage = (navigator.language || navigator.userLanguage).substr(0, 2)
      return Array.isArray(this.supportedLanguages) ? (this.supportedLanguages.includes(browserLanguage) ? browserLanguage : this.supportedLanguages[0]) : 'en'
    },
    theme() {
      return this.$store.getters.theme
    },
    langCode() {
      return locale.i18n.locale
    }
  },
  watch: {
    userId: function (newVal, oldVal) {
      if (newVal) {
        console.log('Now setting up the stomp client and ping interval')
        this.setupStompClient()
        this.pingInterval = setInterval(() => {
          if (this.isPageVisible()) {
            this.ping()
            const currentTime = (new Date()).getTime()
            if (currentTime > (this.lastTime + TIMEOUT + 2000)) {
              this.$emit('wakeup')
            } else {
              this.$emit('awake')
            }
            this.lastTime = currentTime
          }
        }, TIMEOUT)
      } else if (this.pingInterval) {
        clearInterval(this.pingInterval)
        this.pingInterval = null
      }
    },
    cameraId() {
      this.selectCamera(this.cameraId)
    },
    cameras() {
      if (this.cameras && !this.stompClient) this.setupStompClient()
    },
    'stompClient.connected': function (newVal, oldVal) {
      if (oldVal === true && newVal === false) {
        this.setupStompClient()
      }
    },
    '$route.query'() {
      this.parseQueryParameters()
      this.token = auth.getToken()
    },
    token: function (newVal, oldVal) {
      console.log('token', oldVal, '=>', newVal)
      if (newVal !== oldVal) {
        if (this.token) {
          this.restapi.token = this.token
          this.getAccountInfo()
            .then(() => { console.log('Retrieved account info') })
        } else {
          if (this.pingInterval) {
            clearInterval(this.pingInterval)
            this.pingInterval = null
          }
          if (this.stompClient && this.stompClient.connected) {
            this.stompClient.disconnect()
          }
        }
      }
    },
    theme: function (newVal, oldVal) {
      console.log('theme', oldVal, '=>', newVal)
      if (newVal && newVal !== oldVal) {
        this.$vuetify.theme = this.theme.colors()
        this.logo = null
        this.logoNavigation = null
        /* eslint-disable no-undef */
        const logo = this.theme.logo()
        console.log('logo', logo)
        if (logo) {
          if (logo.endsWith('.svg')) {
            this.logo = require(`@eencloud/core-components/src/assets/images/${logo}`)
          } else {
            this.logo = this.getImgUrl(logo)
          }
        }
        const logoNavigation = this.theme.logo('Navigation')
        console.log('Navigation logo', logoNavigation)
        if (logoNavigation) {
          if (logoNavigation.endsWith('.svg')) {
            this.logoNavigation = require(`@eencloud/core-components/src/assets/images/${logoNavigation}`)
          } else {
            this.logoNavigation = this.getImgUrl(logoNavigation)
          }
        }
        this.logoHeight = this.theme.logoHeight();
        this.logoWidth = this.theme.logoWidth()
      }
    },
    langCode: async function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        const eventTypes = await this.restapi.getEventTypes(newVal)
        this.$store.dispatch("setEventTypes", eventTypes)
      }
    },
    rights() {
      this.reRouteWhenMissingRights()
    },
    '$route.name'() {
      this.reRouteWhenMissingRights()
    }
  },
  beforeMount() {
    this.restapi.token = auth.getToken()
  },
  mounted() {
    this.hevc();
    this.parseQueryParameters()
    /* eslint-disable no-undef */
    const themeName = config.hasOwnProperty('theme') ? config.theme : 'Cameramanager'
    const theme = new Theme(themeName)
    this.$store.commit('theme', theme)
    if (this.$ga !== undefined) {
      this.$ga.set('theme', themeName)
      this.$ga.event('Theme', 'Set new theme', themeName)
      this.$ga.set('language', this.$locale.getCurrentLanguage())
      this.$ga.event('Language', 'Set new language', this.$locale.getCurrentLanguage())
    }
    const logo = theme.logo()
    if (logo) {
      if (logo.endsWith('.svg')) {
        this.logo = require(`@eencloud/core-components/src/assets/images/${logo}`)
      } else {
        this.logo = this.getImgUrl(theme.logo())
      }
    }
    const logoNavigation = theme.logo('Navigation')
    console.log('Navigation logo', logoNavigation)
    if (logoNavigation) {
      if (logoNavigation.endsWith('.svg')) {
        this.logoNavigation = require(`@eencloud/core-components/src/assets/images/${logoNavigation}`)
      } else {
        this.logoNavigation = this.getImgUrl(logoNavigation)
      }
    }
    const favicon = theme.favicon()
    if (favicon) {
      changeFavicon(favicon)
    }
    this.token = auth.getToken()
    if (this.token) {
      this.apiCallsOnMounted()
    }
  },
  beforeDestroy() {
    if (this.$db !== undefined) this.$db.events.clear()
    if (this.stompClient && this.stompClient.connected) {
      this.stompClient.disconnect()
    }
    if (this.pingInterval) {
      clearInterval(this.pingInterval)
      this.pingInterval = null
    }
  },
  methods: {
    hevc() {
      let browser = this.getBrowser()
      let OS  = navigator.platform
      const videoElement = document.getElementById("hevctest");
      if (browser.name == "Chrome" && browser.version > 107) {
        videoElement.addEventListener('loadeddata', (e) => {
          if (videoElement.readyState >= 3) {
            console.log("HEVC: YES!");
            gtag("event", "HEVC_Chrome108+_yes "+OS);
          }
        });
        videoElement.addEventListener('error', (e) => {
          console.log("HEVC: NO!");
          gtag("event", "HEVC_Chrome108+_no "+OS);

        });
      }
    },
    getBrowser() {
      var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return { name: 'IE', version: (tem[1] || '') };
      }
      if (M[1] === 'Chrome') {
        tem = ua.match(/\bOPR|Edge\/(\d+)/)
        if (tem != null) { return { name: 'Opera', version: tem[1] }; }
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
      if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
      return {
        name: M[0],
        version: M[1]
      };
    },
    async apiCallsOnMounted() {

      this.getAccountInfo()
      this.getEventTypes()

      try {
        const [accountDetails, countries, languages, zones, cameras, user, rights] = await Promise.allSettled(
          [
            this.restapi.getAccountDetails(),
            this.restapi.getCountries(),
            this.restapi.getLanguages(),
            this.restapi.getZones(),
            this.restapi.getCameras(),
            this.restapi.getCurrentUser(),
            this.restapi.getUserRights()
          ])
        accountDetails.value ? this.$store.commit('accountDetails', accountDetails.value) : console.log(accountDetails.reason)
        countries.value ? this.$store.commit('countries', countries.value) : console.log(countries.reason)
        languages.value ? this.$store.commit('languages', languages.value) : console.log(languages.reason)
        zones.value ? this.zones = zones.value : console.log(zones.reason)
        cameras.value ? this.cameras = cameras.value : console.log(cameras.reason)
        user.value ? this.user = user.value : console.log(user.reason)
        rights.value ? this.rights = rights.value : console.log(rights.reason)

        //this call needs cameras:
        this.getAllCamerasInfo()

        if (this.$route.params.cameraId) {
          await this.selectCamera(this.$route.params.cameraId)
        }
        if (this.$db !== undefined) {
          this.$db.events.clear()
          const event = await this.restapi.getFirstEvent()
          console.log('First event', event)
          // @todo: delete all events older then this first event
          // @todo: get latest event from indexdb and then search only newer events
          // this.firstEvent = event
          let minDate
          if (event) {
            minDate = new Date(event.timestamp)
            minDate.setHours(0)
            minDate.setMinutes(0)
            minDate.setSeconds(0)
            const maxDate = new Date()
            const events = await this.restapi.getAllEvents(minDate.getTime(), maxDate.getTime(), null, this.$db)
            console.log(`Retrieved ${events.length} events`)
            // this.mergeEvents(events)
            // for (let i = 0; i < events.length; i++) {
            //   // if (events[i].eventId === this.firstEvent.eventId) this.allEventsLoaded = true
            //   if (this.eventTypes.includes(events[i].type)) {
            //     this.$db.events.put({
            //       eventId: events[i].eventId,
            //       cameraId: events[i].source.sourceId,
            //       type: events[i].type,
            //       timestamp: (new Date(events[i].timestamp)).getTime()
            //     })
            //   }
            // }
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getEventTypes() {
      try {
        const eventTypes = await this.restapi.getEventTypes(this.langCode)
        this.$store.dispatch("setEventTypes", eventTypes)
      } catch (error) {
        console.log(error)
      }
    },
    async loadCameras() {
      try {
        let cameras = await this.restapi.getCameras()
        this.cameras = cameras
        this.getAllCamerasInfo()
      }
      catch (error) {
        console.error(error)
      }
    },
    ping() {
      const that = this
      return this.restapi.ping()
        .then(() => {
          return Promise.resolve(true)
        })
        .catch(error => {
          console.error(error)
          if (error === 'Not authorized') {
            if (that.pingInterval) {
              clearInterval(that.pingInterval)
              that.pingInterval = null
            }
            console.log('Dispatching invalidtoken event')
            document.getElementById('app').dispatchEvent(new Event('invalidtoken'))
          }
          return Promise.reject(error)
        })
    },
    async getAccountInfo() {
      const that = this
      this.$store.commit('account', null)
      this.$store.commit('accountStatus', {
        allowsHelpdesk: false,
        blocked: true,
        scheduled: false,
      })
      try {
        const [account, accountStatus,] = await Promise.allSettled(
          [
            this.restapi.getAccountBase(),
            this.restapi.getAccountStatus(),
            calculateAccountTime()
          ]);
        account.value ? this.$store.commit('account', account.value) : console.log(account.reason);
        accountStatus.value ? this.$store.commit('accountStatus', accountStatus) : console.log(accountStatus.value);

        async function calculateAccountTime() {
          try {
            const response = await that.restapi.getAccountTimeZone()
            const timeZone = findTimeZone(response.timeZone)
            const timeZoneOffset = getUTCOffset(new Date(), timeZone)
            that.$store.dispatch('accountTimeZoneOffset', timeZoneOffset.offset)
          } catch (error) {
            that.$store.dispatch('accountTimeZoneOffset', 0)
            console.log(error)
          }
        }

      } catch (error) {
        console.log(error)
      }
    },
    parseQueryParameters() {
      const query = Object.assign({}, this.$route.query)
      const accessToken = query.hasOwnProperty('access_token') ? query.access_token : null
      const expiresIn = query.hasOwnProperty('expires_in') ? query.expires_in : null
      const lang = query.hasOwnProperty('lang') ? query.lang : (this.$cookies.get('lang') || this.defaultLanguage)
      const theme = query.hasOwnProperty('theme') ? query.theme : null
      if (accessToken && expiresIn) {
        auth.login(accessToken, expiresIn)
        this.token = auth.getToken()
        delete query.a
        delete query.access_token
        delete query.expires_in
        delete query.token_type
        delete query.state
      }
      if (lang) {
        if (this.supportedLanguages.includes(lang)) this.$locale.setLanguage(lang)
        delete query.lang
      }
      if (theme) {
        this.$store.commit('theme', new Theme(theme))
        delete query.theme
      }
      const token = auth.getToken()
      if (token) { }
      if (Object.values(this.$route.query).length !== Object.values(query).length) {
        this.$router.replace({ path: this.$route.path, query: query },
          () => { console.log('Finished re-routing') },
          () => { console.error('Re-routing aborted! Same route?') })
      }
    },
    async getAllCamerasInfo() {

      try {
        const statuses = await this.restapi.getCamerasStatus()
        this.cameras.forEach(cam => {
          this.$set(cam, 'status', statuses.find(s => s.cameraId === cam.cameraId))
        })

        const promises = [
          this.restapi.getCamerasMode(),
          this.restapi.getCamerasMicrophoneEnabled(),
          this.restapi.getCamerasSubscriptionRights(),
          this.restapi.getCamerasLocations(),
          this.restapi.getAllCamerasFootageSettings(this.cameras) //limited to 10 cameras, since we use separate calls
          // we should add a collective call for camera capabilities
        ]
        const [modes, enabled, rights, timeZones, footageSettings] =
          (await Promise.allSettled(promises)).map(element => element.status === "fulfilled" ? element.value : null)

        const camerasHaveAddons = this.doCamerasHaveAddons(rights);

        if(this.isUserAdmin() && camerasHaveAddons) {
          await this.fetchAccountToken();
          this.setAiAnalyticsMenuVisible(true);
        }
        
        for (let i = 0; i < this.cameras.length; i++) {
          const camId = this.cameras[i].cameraId
          if (modes) this.$set(this.cameras[i], 'cameraMode', modes.find(m => { return m.cameraId === camId }))
          if (enabled) this.$set(this.cameras[i], 'microphoneEnabled', enabled.find(e => { return e.cameraId === camId })?.enabled)
          if (rights) this.$set(this.cameras[i], 'subscriptionRights', rights.find(r => { return r.cameraId === camId }))
          if (footageSettings[camId]) this.$set(this.cameras[i], 'footageSettings', footageSettings[camId])
          if (timeZones) {
            const timezone = timeZones.find(t => t.cameraId === camId).timeZone
            this.$set(this.cameras[i], 'timeZone', timezone ? timezone : "Etc/UTC")
            const foundTimeZone = findTimeZone(this.cameras[i].timeZone)
            const timeZoneOffset = getUTCOffset(new Date(), foundTimeZone)
            this.$set(this.cameras[i], 'timeOffset', timeZoneOffset.offset)
          } else {
            this.$set(this.cameras[i], 'timeZone', "Etc/UTC")
            this.$set(this.cameras[i], 'timeOffset', 0)
          }
        }
        this.getAllCamerasCapabilities() //limited to 10
        this.preFetchStreams()
      } catch (error) {
        console.error(error)
      }
    },
    async getCameraInfo(camera) {
      try {
        const promises = [
          this.restapi.getCameraMode(camera.cameraId),
          this.restapi.getCameraMicrophoneEnabled(camera.cameraId),
          this.restapi.getCameraSubscriptionRights(camera.cameraId),
          this.restapi.getCameraStatus(camera.cameraId),
          this.restapi.getCameraLocation(camera.cameraId),
          this.restapi.getCameraFootageSettings(camera.cameraId),
          this.restapi.getCameraDetectionCapabilities(camera.cameraId),
        ]

        const [mode, microphone, rights, status, timeZone, footageSettings, detectionCapabilities] = (await Promise.allSettled(promises)).map(element => element.status === "fulfilled" ? element.value : null)

        if (mode) this.$set(camera, 'cameraMode', mode)
        if (microphone) this.$set(camera, 'microphoneEnabled', microphone)
        if (rights) this.$set(camera, 'subscriptionRights', rights)
        if (status) this.$set(camera, 'status', status)
        if (footageSettings) this.$set(camera, 'footageSettings', footageSettings)
        if (detectionCapabilities) this.$set(camera, 'detectionCapabilities', detectionCapabilities)
        if (timeZone) {
          this.$set(camera, 'timeZone', timeZone ? timeZone.timeZone : "Etc/UTC")
          const foundTimeZone = findTimeZone(camera.timeZone)
          const timeZoneOffset = getUTCOffset(new Date(), foundTimeZone)
          this.$set(camera, 'timeOffset', timeZoneOffset.offset)
        } else {
          this.$set(camera, 'timeZone', "Etc/UTC")
          this.$set(camera, 'timeOffset', 0)
        }
      } catch (error) {
        console.error(error)
      }
    },
    getAllCamerasCapabilities() {
      const that = this
      async function getCameraCapabilities(camera) {
        const result = await that.restapi.getCameraCapabilities(camera.cameraId)
        if (result) that.$set(camera, 'cameraCapabilities', result)
      }
      //prefetching capabilities limited to 10 cameras
      [...this.cameras].filter(cam => cam.status.online).slice(0, 10).forEach(element => {
        getCameraCapabilities(element)
      });
    },
    camerasForZone(zoneId) {
      return this.cameras.filter(c => { return c.zoneId === zoneId })
    },
    async selectCamera(id) {
      this.camera = id === null ? null : this.cameras.find(c => { return c.cameraId === parseInt(id) })
      if (this.camera) {
        try {
          const deviceInfo = await this.restapi.getCameraDeviceInfo(this.camera.cameraId)
          this.$set(this.camera, 'deviceInfo', deviceInfo)
        } catch (error) {
          console.error(error)
        }
        try {
          const detectionCapabilities = await this.restapi.getCameraDetectionCapabilities(this.camera.cameraId)
          this.$set(this.camera, 'detectionCapabilities', detectionCapabilities)
        } catch (error) {
          console.error(error)
        }
        try {
          const cameraCapabilities = await this.restapi.getCameraCapabilities(this.camera.cameraId)
          this.$set(this.camera, 'cameraCapabilities', cameraCapabilities)
        } catch (error) {
          console.error(error)
        }
      }
    },
    cameraDeleted(id) {
      const i = this.cameras.findIndex(c => { return c.cameraId === id })
      if (i !== -1) {
        this.cameras.splice(i, 1)
        this.$emit('deleted', id)
      }
    },
    toggleSidebar() {
      this.showSidebar = !this.showSidebar
    },
    addedZone(zone) {
      this.zones.push(zone)
    },
    transferredCamera(cameraId, zoneId) {
      const cameraIndex = this.cameras.findIndex(cam => cam.cameraId === cameraId)
      const newZoneIndex = this.zones.findIndex(z => z.zoneId === zoneId)
      const oldZoneIndex = this.zones.findIndex(z => z.zoneId === this.cameras[cameraIndex].zoneId)
      //removing the camera from the old zone's cameraIds
      if (oldZoneIndex !== -1) this.zones[oldZoneIndex].cameraIds.pop(cameraId)
      //changing the camera's zoneId
      if (cameraIndex !== -1) this.cameras[cameraIndex].zoneId = zoneId
      //adding the camera to the new zone's cameraIds
      if (newZoneIndex !== -1) this.zones[newZoneIndex].cameraIds.push(cameraId)
    },
    patchedZone(zone) {
      const zoneIndex = this.zones.findIndex(z => z.zoneId === zone.zoneId)
      if (zoneIndex !== -1) Object.assign(this.zones[zoneIndex], zone)
    },
    deletedZone(zoneId) {
      const zoneIndex = this.zones.findIndex(z => z.zoneId === zoneId)
      if (zoneIndex !== -1) this.zones.splice(zoneIndex, 1)
    },
    deletedCamera(id) {
      const index = this.cameras.findIndex(c => c.cameraId === id)
      if (index !== -1) this.cameras.splice(index, 1);
    },
    setupStompClient() {
      const token = auth.getToken()
      if (token && this.userId) {
        console.log('Setting up stomp client')
        /* eslint-disable no-undef */
        const url = config.apiServer.replace('http', 'ws') + '/stomp/v2.0?access_token=' + token
        this.stompClient = new StompClient(url, this.userId, null, null, this.notify)
        console.log('And now connecting')
        this.stompClient.connect()
      }
    },
    notify(tick) {
      const routing = tick.headers.destination.split('/')[3].split('.')
      const message = JSON.parse(tick.body)

      if (routing[1] === 'cameras') {
        const cameraId = parseInt(routing[2])
        const camera = this.cameras.find(c => { return c.cameraId === cameraId })
        switch (routing[3]) {
          case 'status':
            // console.log(`Camera with id ${cameraId} has status changed to ${message.online}`)
            if (camera && camera.hasOwnProperty('status')) {
              const status = Object.assign({}, camera.status)
              status.online = message.online
              status.recordingOnCloud = message.recordingOnCloud
              this.$set(camera, 'status', status)
              if (status.online) {
                const that = this
                that.restapi.getSnapshot(camera.cameraId, '100x100')
                  .then(function (snapshot) {
                    that.$set(camera, 'smallSnapshot', snapshot)
                  })
                  .catch(function (error) {
                    console.error(error)
                  })
              } else {
                this.$set(camera, 'smallSnapshot', null)
              }
            }
            break

          case 'mode':
            if (camera) {
              const cameraMode = camera.cameraMode
              cameraMode.mode = message.mode.toLowerCase()
              cameraMode.previousMode = message.previousMode.toLowerCase()
              this.$set(camera, 'cameraMode', cameraMode)
            }
            break

          // case 'partial':
          //   // console.log(`Camera with id ${cameraId} is partial updated to`, message)
          //   if (camera) {
          //     for (const property in message) {
          //       if (message.hasOwnProperty(property)) {
          //         this.$set(camera, property, message[property])
          //       }
          //     }
          //   } else {
          //     const that = this
          //     this.restapi.getCamera(cameraId)
          //       .then(function (camera) {
          //         that.getCameraInfo(camera)
          //           .then(() => {
          //             that.cameras.push(camera)
          //           })
          //           .catch(function (error) {
          //             console.error(error)
          //           })
          //       })
          //       .catch(function (error) {
          //         console.error(error)
          //       })
          //   }
          //   break

          case 'updates':
            // console.log(`Camera with id ${cameraId} is added or updated`)
            const that = this
            this.restapi.getCamera(cameraId)
              .then(function (camera) {
                that.getCameraInfo(camera)
                  .then(() => {
                    const i = that.cameras.findIndex(c => { return c.cameraId === cameraId })
                    if (i !== -1) {
                      that.cameras.splice(i, 1, camera)
                    } else {
                      that.cameras.push(camera)
                    }
                  })
                  .catch(function (error) {
                    console.error(error)
                  })
              })
              .catch(function (error) {
                console.error(error)
              })
            break

          case 'deletes':
            // console.log(`Camera with id ${cameraId} is removed`)
            const i = this.cameras.findIndex(c => { return c.cameraId === cameraId })
            if (i !== -1) {
              this.cameras.splice(i, 1)
            }
            break

          case 'events':
            // console.log(`Received event for camera with id ${cameraId}`)
            if (this.$db !== undefined && this.eventTypes.includes(message.type)) {
              this.$db.events.put({
                eventId: message.eventId,
                cameraId: cameraId,
                type: message.type,
                timestamp: (new Date(message.timestamp)).getTime(),
              })
            }
            break
        }
      } else if (routing[1] === 'zones') {
        const zoneId = parseInt(routing[2])
        switch (routing[3]) {
          case 'updates':
            console.log(`Zone with id ${zoneId} is added or updated`)
            const that = this
            this.restapi.getZones(zoneId)
              .then(function (zone) {
                const i = that.zones.findIndex(z => { return z.zoneId === zoneId })
                if (i !== -1) {
                  that.zones.splice(i, 1, zone)
                } else {
                  that.zones.push(zone)
                }
              })
              .catch(function (error) {
                console.error(error)
              })
            break

          case 'deletes':
            console.log(`Zone with id ${zoneId} is deleted`)
            const i = this.zones.findIndex(z => { return z.zoneId === zoneId })
            if (i !== -1) {
              this.zones.splice(i, 1)
            }
            break
        }
      }
    },
    logout() {
      auth.logout(false)
    },
    reRouteWhenMissingRights() {
      if (this.rights.demoUser) return // demo users should see everything even though they don't have any rights
      if (this.rights && !this.rights.editCamera &&
        (this.$route.name === 'CameraInfo' ||
          this.$route.name === 'Recording' ||
          this.$route.name === 'Detections' ||
          this.$route.name === 'Networks' ||
          this.$route.name === 'VideoSound' ||
          this.$route.name === 'DetectionAreas' ||
          this.$route.name === 'ScheduleNotifications')
      ) this.$router.push({ name: 'Dashboard' })

      if (this.rights && !this.rights.playback &&
        (this.$route.name === 'Footage' ||
          this.$route.name === 'EventSearch')
      ) this.$router.push({ name: 'Dashboard' })

      if (this.rights && !this.rights.editZone &&
        this.$route.name === 'EditZone'
      ) this.$router.push({ name: 'Dashboard' })

      if (this.rights && !this.rights.editUser &&
        (this.$route.name === 'UserRights' ||
          this.$route.name === 'UserCameras' ||
          this.$route.name === 'UserDetails' ||
          this.$route.name === 'SingleUser')
      ) this.$router.push({ name: 'Dashboard' })

      if (this.rights && !this.rights.viewUserSettings &&
        (this.$route.name === 'Users' ||
          this.$route.name === 'Logs')
      ) this.$router.push({ name: 'Dashboard' })

      if (this.rights && !this.rights.addCameraManually &&
        this.$route.name === 'AddCamera'
      ) this.$router.push({ name: 'Dashboard' })
    },
    openChat() {
      $zoho.salesiq.floatwindow.onlinetitle(this.$t('Chat with us'))
      $zoho.salesiq.visitor.name(
        this.$store.getters.account ? this.$store.getters.username : null
      );
      $zoho.salesiq.visitor.email(this.$store.getters.account ? this.$store.getters.account.email : null);
      const errorCode = this.$store.state.coreData.snackBar.text && this.$store.state.coreData.snackBar.text.split('errorCode: ')[1]
      if (errorCode) $zoho.salesiq.visitor.info({ "Last seen error code": errorCode });
      $zoho.salesiq.floatwindow.open();
    },
    preFetchStreams() {
      const getStreams = async i => {
        try {
          const [oldStreamsApi, newStreamsApi] = await Promise.all([
            this.restapi.getCameraStreams(this.cameras[i].cameraId),
            this.restapi.getCameraStreamsMulti(this.cameras[i].cameraId)
          ])
          if (oldStreamsApi && newStreamsApi)
            this.$set(this.cameras[i], "streams", [Object.assign(oldStreamsApi[0], newStreamsApi[0])]);
        } catch (error) {
          console.error(error);
        }
      }
      let limit = config['streamURLPreFetchLimit']
      let counter = 0
      this.cameras.forEach((camera, i) => {
        if (camera.status.online && counter < limit)
          getStreams(i);
        counter++
      })
    },
    doCamerasHaveAddons(subscriptionRights) {
      return subscriptionRights.some(obj => obj.addOns && obj.addOns.length > 0);
    },
    setAiAnalyticsMenuVisible(visible) {
      this.$store.commit('SET_AI_ANALYTICS_MENU_VISIBLE', visible);
    },
    isUserAdmin() {
      return this.$store.getters.account.accountId === this.$store.getters.account.userId;
    },
    async fetchAccountToken() {
      try {
        const accountToken = await this.restapi.getAccountToken(AI_ANALYTICS_CLIENT_ID);
        if(accountToken) {
          this.$store.commit("SET_AI_ANALYTICS_TOKEN", accountToken);
        }
      } catch (err) {
        console.error(err);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "assets/styles/main";

.active {
  color: $primary;
}

.snack-bar {
  height: auto;
  bottom: 50% !important;
}

#hevctest {
  display: none;
}

.main-content {
  // padding-left: 56px;
  overflow: hidden;
}

.padding-content {
  //padding-left: 300px !important;
  transition-duration: 0.3s;
}

.show-nav {
  box-sizing: border-box;
}

>>>.v-step {
  z-index: 9;
}
</style>
