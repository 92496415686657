<template>
  <v-card v-if="zone" class="mt-2">
    <v-card-title class="body-2 text-uppercase py-3">
      {{ zone.name }}
      <v-spacer></v-spacer>
      <v-switch v-model="zoneRight" @change="rights" class="mt-0 pt-0" hide-details dense></v-switch>
    </v-card-title>
    <v-divider class="mb-2"></v-divider>
    <v-card-text class="pa-0">
      <v-list rounded v-for="camera in zoneCameras" :key="camera.cameraId" dense class="px-0 pt-0 mb-2">
        <camera-list :camera="camera" @cameraUpdated="cameraUpdated"></camera-list>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import CameraList from "@/components/Users/CameraList";

export default {
  name: "ZoneList",
  components: {
    CameraList,
  },
  props: ["newPermissions", "zone", "cameras"],
  data() {
    return {
      zoneRight: null,
    };
  },
  computed: {
    zoneCameras() {
      return this.cameras
        ? this.cameras.filter((c) => {
            return c.zoneId === this.zone.zoneId;
          })
        : null;
    },
  },
  methods: {
    cameraUpdated(camera, value) {
      let unassigned = 0;
      this.$emit("cameraUpdated", camera);
      if (value && !this.zoneRight) {
        this.zoneRight = true;
        this.$emit("zoneUpdated", this.zone.zoneId);
      }
      this.zoneCameras.forEach((element) => {
        if (element.present == false) {
          unassigned++;
        }
      });
      if (this.zoneCameras.length === unassigned) {
        if (this.zoneRight) {
          this.$emit("zoneUpdated", this.zone.zoneId);
          this.zoneRight = false;
        }
      }
    },
    rights() {
      this.$emit("zoneUpdated", this.zone.zoneId);
      let that = this;
      if (this.zoneCameras.length) {
        if (this.zoneRight) {
          this.zoneCameras.map((element) => {
            if (!element.present) {
              that.$emit("cameraUpdated", element);
              that.$set(element, "present", true);
            }
          });
        } else {
          this.zoneCameras.map((element) => {
            if (element.present) {
              that.$emit("cameraUpdated", element);
              that.$set(element, "present", false);
            }
          });
        }
      }
    },
  },
  mounted() {
    this.zoneRight = this.zone.present;
  },
};
</script>

<style lang="scss" scoped>
.background-title {
  background: #eaeaea;
}
</style>
