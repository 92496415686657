<template>
  <v-container fluid class="add-camera ma-0 pa-0">
    <v-row>
      <v-container fluid class="add-camera-content ml-0 my-0 pa-0" :class="{ 'pt-5': addGap }">
        <v-row>
          <v-col v-if="showBackButton" class="mb-6" cols="12" offset="2">
            <v-btn text class="ml-12 title unset-title-color" @click="$emit('cancel')">
              <v-icon left>arrow_back</v-icon>
              {{ $t("Back to zones") }}
            </v-btn>
          </v-col>

          <v-col cols="10" offset="1" lg="4" offset-lg="4" class="pb-0">
            <div class="mb-4">
              <span class="headline">{{ $t("Add a device") }}</span>
            </div>
            <p
              v-if="model != 2"
              class="body-2"
              v-html="
                $t(
                  'Adding devices is possible through Web App by entering the device MAC address. If your device does not support “Add by MAC address“ please add it via the desktop or mobile apps'
                )
              "
            ></p>
            <p v-if="model == 1" class="body-2">
              {{ $t("This Device") }} {{ cameraName }} {{ $t("was successfully added") }}.
            </p>
          </v-col>

          <v-col cols="10" offset="1" lg="4" offset-lg="4">
            <v-card class="px-6 py-3">
              <v-row justify="space-around"></v-row>
              <v-row>
                <v-carousel
                  touchless
                  :height="$vuetify.breakpoint.xsOnly ? 430 : 400"
                  hide-delimiters
                  :show-arrows="false"
                  v-model="model"
                  :continuous="false"
                  light
                >
                  <v-carousel-item>
                    <h2 class="text-center pb-2">{{ $t("Device details") }}</h2>
                    <v-row>
                      <v-col cols="4">
                        <p class="subheading my-2">{{ $t("Device Name") }}</p>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model="cameraName"
                          flat
                          dense
                          solo
                          placeholder="New Device"
                          :error-messages="cameraNameErrors"
                          @input="$v.cameraName.$touch()"
                          @blur="$v.cameraName.$touch()"
                          background-color="grey lighten-3"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        <p class="subheading my-2">{{ $t("Device Zone") }}</p>
                      </v-col>
                      <v-col cols="8">
                        <v-autocomplete
                          :search-input.sync="searchInput"
                          flat
                          filled
                          dense
                          :items="zones"
                          :error-messages="selectedZoneErrors"
                          item-text="name"
                          item-value="zoneId"
                          v-model="selectedZone"
                          @input="$v.selectedZone.$touch()"
                          @blur="$v.selectedZone.$touch()"
                        ></v-autocomplete>
                        <v-btn
                          v-if="rights && rights.addZone"
                          outlined
                          color="primary"
                          :ripple="false"
                          @click="addingNewZone = true"
                        >
                          <v-icon left>fas fa-plus</v-icon>
                          {{ $t("Add new zone") }}
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <p class="subheading my-2">{{ $t("MAC Address") }}</p>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-mask="hexMaks"
                          v-model="macAddress"
                          @input="$v.macAddress.$touch()"
                          @blur="$v.macAddress.$touch()"
                          flat
                          dense
                          solo
                          hide-details
                          background-color="grey lighten-3"
                        ></v-text-field>
                        <p
                          class="error-message pt-1 pl-3 caption"
                          v-if="$v.macAddress.$error && !$v.macAddress.required"
                        >
                          {{ $t("MAC Address is a required field") }}
                        </p>
                        <p
                          class="error-message pt-1 pl-3 caption"
                          v-if="$v.macAddress.$error && !$v.macAddress.minLength"
                        >
                          {{ $t("Please enter a valid MAC address") }}
                        </p>
                      </v-col>
                    </v-row>
                    <v-btn
                      color="primary"
                      class="next-button"
                      ref="next-button"
                      :disabled="
                        $v.cameraName.$invalid || $v.selectedZone.$invalid || $v.macAddress.$invalid || isloading
                      "
                      @click="addMac"
                      >{{ $t("Add Device") }}</v-btn
                    >
                    <v-overlay class="add-camera-overlay" opacity=".85" :value="isloading">
                      <een-spinner :size="50" :isloading="isloading" :absolute="false"></een-spinner>
                      <p class="text-center color-white overlay-text">{{ $t("Adding Device") }}</p>
                      <p class="color-white">{{ $t("Please do not reload") }}</p>
                    </v-overlay>
                  </v-carousel-item>
                  <v-carousel-item>
                    <v-img contain height="120" class="camera-image" :src="cloudCamImg"></v-img>
                    <div class="center-align flex-column">
                      <p class="headline text-center">{{ $t("Device added successfully") }}</p>
                      <div class="d-flex d-sm-none navigation-buttons go-to-dashboard">
                        <v-btn color="primary" block @click="resetForm">{{ $t("Add a new device") }}</v-btn>
                      </div>
                      <div class="d-flex d-sm-none pt-2 navigation-buttons">
                        <v-btn color="primary" block @click="goToDashboard()">{{ $t("Go to Dashboard") }}</v-btn>
                      </div>
                    </div>
                    <div class="d-none d-sm-flex">
                      <v-btn color="primary" class="previous-button" @click="resetForm">{{
                        $t("Add a new Device")
                      }}</v-btn>
                      <v-btn color="primary" class="next-button" @click="goToDashboard()">{{
                        $t("Go to Dashboard")
                      }}</v-btn>
                    </div>
                  </v-carousel-item>
                  <v-carousel-item>
                    <p class="headline text-center">{{ $t("Adding device failed") }}</p>
                    <v-img contain height="120" class="camera-image-unsuccessful" :src="cloudCamImg"></v-img>
                    <div class="center-align flex-column">
                      <h3 class="text-center">{{ statusHeader }}</h3>
                      <p class="camera-unsuccessful-sub-status text-center">{{ subStatus }}</p>
                      <div class="d-flex d-sm-none navigation-buttons go-to-dashboard">
                        <v-btn color="primary" block @click="resetForm">{{ $t("Add a new device") }}</v-btn>
                      </div>
                      <div class="d-flex d-sm-none pt-2 navigation-buttons">
                        <v-btn color="primary" block @click="goToDashboard()">{{ $t("Go to Dashboard") }}</v-btn>
                      </div>
                    </div>
                    <div class="d-none d-sm-flex">
                      <v-btn color="primary" class="previous-button" @click="resetForm">{{
                        $t("Add a new Device")
                      }}</v-btn>
                      <v-btn color="primary" class="next-button" @click="goToDashboard()">{{
                        $t("Go to Dashboard")
                      }}</v-btn>
                    </div>
                  </v-carousel-item>
                </v-carousel>
              </v-row>
            </v-card>
            <p class="mt-2">
              <a @click="qrWifiSetup = true">{{ $t("Connect Body Worn Camera to WiFi") }}</a>
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <v-dialog v-model="addingNewZone" max-width="400">
      <v-card>
        <v-card-title primary-title> </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="newZoneName"
            :label="$t('Zone name')"
            :error-messages="newZoneNameErrors"
            @input="$v.newZoneName.$touch()"
            @blur="$v.newZoneName.$touch()"
            @keyup.enter="addZone"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <een-spinner :size="50" :isloading="zoneSpinner"></een-spinner>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" :disabled="$v.newZoneName.$invalid || zoneSpinner" @click="addZone">
            {{ $t("Add new zone") }}
          </v-btn>
          <v-btn depressed @click="cancelAddZone">{{ $t("Close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="qrWifiSetup" max-width="500">
      <v-card>
        <v-card-title primary-title> {{ $t("Add WiFi details") }} </v-card-title>
        <v-card-text>
          <p>{{ $t("This QR-code only works for the BC01 Body Cam") }}</p>
          <v-text-field v-model="wifiSsid" autocomplete="off" :label="$t('Network name')"></v-text-field>
          <v-text-field
            v-model="wifiPassword"
            autocomplete="off"
            :append-icon="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
            @click:append="() => (showPassword = !showPassword)"
            :type="showPassword ? 'text' : 'password'"
            :label="$t('Password')"
          ></v-text-field>
          <p class="text-center">QR</p>
          <div class="d-flex justify-center">
            <qrcode-vue v-if="wifiSsid && wifiPassword" size="200" :value="qrcode" level="H" />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click="closeDewarp">{{ $t("Close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mask } from "vue-the-mask";
import { store } from "@/store";
import Theme from "@eencloud/core-components/src/service/themes";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { getMessageFromError } from "@eencloud/core-components/src/service/errors";
import Sentry from "@eencloud/core-components/src/plugins/sentry";
import QrcodeVue from "qrcode.vue";

export default {
  name: "AddCamera",
  components: {
    QrcodeVue,
  },
  props: {
    showBackButton: {
      type: Boolean,
      default: false,
    },
    addGap: {
      type: Boolean,
      default: true,
    },
    rights: {
      type: Object,
    },
  },
  directives: { mask },
  data() {
    return {
      checkModels: false,
      showPassword: "",
      cloudCamImg: require("@eencloud/core-components/src/assets/images/cloudcams.svg"),
      cameraName: "",
      macAddress: store.getters.device_MAC_address ? store.getters.device_MAC_address : "",
      hexMaks: {
        mask: "FF-FF-FF-FF-FF-FF",
        tokens: {
          F: {
            pattern: /[0-9a-fA-F]/,
            transform: (v) => v.toLocaleUpperCase(),
          },
        },
      },
      model: 0,
      zones: [],
      wifiPassword: "",
      wifiSsid: "",
      selectedZone: null,
      addingNewZone: false,
      newZoneName: null,
      isloading: false,
      links: {},
      zoneSpinner: false,
      cameraId: null,
      overlay: false,
      searchInput: null,
      subStatus: "",
      statusHeader: "",
      qrWifiSetup: false,
    };
  },
  computed: {
    isMobile() {
      return ["iOS", "Android"].includes(this.getOS());
    },
    mask() {
      const F = {
        pattern: /[0-9a-fA-F]/,
        transform: (v) => v.tozoneSpinnereUpperCase(),
      };

      return "XX-XX-XX-XX-XX-XX";
    },

    qrcode() {
      return `1=21
2=${window.btoa("Cloud1234")}
4=${window.btoa(this.wifiSsid)}/${window.btoa(this.wifiPassword)}`;
    },
    newZoneNameErrors() {
      const errors = [];
      if (!this.$v.newZoneName.$dirty) return errors;
      if (!this.$v.newZoneName.required) errors.push(this.$t("Zone name is a required field"));
      if (!this.$v.newZoneName.minLength || !this.$v.newZoneName.maxLength) {
        errors.push(
          this.$t("Zone name should be X-Y characters", {
            min: this.$v.newZoneName.$params.minLength.min,
            max: this.$v.newZoneName.$params.maxLength.max,
          })
        );
      }
      return errors;
    },
    cameraNameErrors() {
      const errors = [];
      if (!this.$v.cameraName.$dirty) return errors;
      if (!this.$v.cameraName.required) errors.push(this.$t("Device name is a required field"));
      if (!this.$v.cameraName.maxLength) {
        errors.push(this.$t("Device name should be max 32 characters"));
      }
      return errors;
    },
    selectedZoneErrors() {
      const errors = [];
      if (!this.$v.selectedZone.$dirty) return errors;
      if (!this.$v.selectedZone.required) errors.push(this.$t("Device Zone is a required field"));
      return errors;
    },
    customErrorCodePrefix() {
      // we use old format here instead of: `${platform}.${browser}.w.13
      // so that the code is the same as mobile. Support can quickly look up the error in the documentation
      // we lose the platform and browser, which is not important for adding cameras
      return "3.w.4"
    }
  },

  mounted() {
    Sentry.configureScope((scope) => scope.setTransactionName(this.$options.name));
    const themeName = config.hasOwnProperty("theme") ? config.theme : "Cameramanager";
    const theme = new Theme(themeName);
    this.links = theme.links();
    const that = this;
    this.restapi.getZones().then(function (zones) {
      that.zones = zones;
    });
    window.addEventListener("keydown", this.nextStep);
    if (this.$route.params) {
      this.selectedZone = this.$route.params.zoneId;
      this.searchInput = this.zones.find((zone) => this.selectedZone === zone.zoneId);
    }
  },
  destroyed() {
    window.removeEventListener("keydown", this.nextStep);
  },
  methods: {
    resetForm() {
      this.macAddress = "";
      this.cameraName = "";
      this.selectedZone = null;
      store.dispatch("setDeviceMAC", null);
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.model = 0;
      this.searchInput = "";
    },

    closeDewarp() {
      this.qrWifiSetup = false;
      this.wifiPassword = null;
      this.wifiSsid = null;
      this.$v.$reset();
    },

    async addMac() {
      if (this.rights && this.rights.demoUser) {
        return this.$store.dispatch("demoMessage")
      }
      const that = this;
      this.isloading = true;
      try {
        const addCameraByMAC = await this.restapi.addDeviceByMAC(this.cameraName, this.selectedZone, this.macAddress);
        this.cameraId = addCameraByMAC.deviceId;
        try {
          const addCameraStatusPoll = await this.restapi.addDeviceStatusPoll(this.cameraId);
          this.isloading = false;
          this.$emit("loadCameras");
          if (addCameraStatusPoll.status == "added") {
            this.model++;
          } else {
            switch (addCameraStatusPoll.subStatus) {
              case "notConnected":
                store.dispatch("toastMessage", {
                  text: `${this.$t("Device wasn't able to connect to the cloud.")} ${this.$t(
                    "Please make sure the device is connected to a power source and has a stable internet connection."
                  )} errorCode: ${this.customErrorCodePrefix}.14.239a`,
                  color: "error",
                  showing: true,
                  timeout: -1,
                  support: true
                });
              case "notOnline":
                store.dispatch("toastMessage", {
                  text: `${this.$t("Device wasn't able to connect to the cloud.")} ${this.$t(
                    "Please make sure the device is connected to a power source and has a stable internet connection."
                  )} errorCode: ${this.customErrorCodePrefix}.14.239b`,
                  color: "error",
                  showing: true,
                  timeout: -1,
                  support: true
                });
                break;
              case "deviceIdentificationFailed":
                store.dispatch("toastMessage", {
                  text: `${this.$t("Something went wrong.")} ${this.$t(
                    "The device could not be identified. Please restart or factory reset the device and try again."
                  )} errorCode: ${this.customErrorCodePrefix}.3.239a`,
                  color: "error",
                  showing: true,
                  timeout: -1,
                  support: true
                });
                break;
              case "credentialSetupFailed ":
                store.dispatch("toastMessage", {
                  text: `${this.$t("Unknown error.")} ${this.$t(
                    "The application has encountered an unknown error, try again later. If the problem persists, contact support."
                  )} errorCode: ${this.customErrorCodePrefix}.1.239`,
                  color: "error",
                  showing: true,
                  timeout: -1,
                  support: true
                });
                break;
              case "credentialValidationFailed":
                store.dispatch("toastMessage", {
                  text: `${this.$t("Device doesn't have the default credentials.")} ${this.$t(
                    "Please factory reset your device or make sure your device has the default credentials. Try again after this."
                  )} errorCode: ${this.customErrorCodePrefix}.8.239`,
                  color: "error",
                  showing: true,
                  timeout: -1,
                  support: true
                });
                break;
              case "localUrlValidationFailed":
                store.dispatch("toastMessage", {
                  text: `${this.$t("Problem detected with your device.")} ${this.$t(
                    "Please restart or factory reset the device."
                  )} errorCode: ${this.customErrorCodePrefix}.13.239`,
                  color: "error",
                  showing: true,
                  timeout: -1,
                  support: true
                });
                break;
              case "unsupportedModel":
                store.dispatch("toastMessage", {
                  text: `${this.$t("Something went wrong.")} ${this.$t("The device is not supported.")} errorCode: ${this.customErrorCodePrefix}.3.239b`,
                  color: "error",
                  showing: true,
                  timeout: -1,
                  support: true
                });
                break;
              case "hwIdRegistrationFailed":
                store.dispatch("toastMessage", {
                  text: `${this.$t("Something went wrong.")} ${this.$t("Remove the device from the current account before adding it to a new account.")} errorCode: ${this.customErrorCodePrefix}.5.239a`,
                  color: "error",
                  showing: true,
                  timeout: -1,
                  support: true
                });
                break;
              case "failedAddingDeviceAfterValidation":
                store.dispatch("toastMessage", {
                  text: `${this.$t("Something went wrong.")} ${this.$t("The device was validated successfully, but an unexpected error occurred afterwards. Please try again later.")} errorCode: ${this.customErrorCodePrefix}.1.239a`,
                  color: "error",
                  showing: true,
                  timeout: -1,
                  support: true
                });
                break;
              default:
                this.subStatus = store.dispatch("toastMessage", {
                  text: this.$t("Something went wrong. Please try again later."),
                  color: "error",
                  showing: true,
                  timeout: -1,
                  support: true
                });
            }
          }
        } catch (error) {
          Sentry.captureException(error);
          console.log("sunnel error", error);
          that.isloading = false;
          store.dispatch("toastMessage", {
            showing: true,
            text: getMessageFromError(error, "ADD_DEVICE_STATUS_POLL"),
            timeout: -1,
            support: true,
            color: "error",
          });
        }
      } catch (error) {
        Sentry.captureException(error);
        console.log("add device mac error", error);
        this.isloading = false;
        store.dispatch("toastMessage", {
          showing: true,
          text: getMessageFromError(error, "ADD_DEVICE"),
          timeout: -1,
          support: true,
          color: "error",
        });
      }
    },
    goToDashboard() {
      this.$router.push({ name: "Dashboard" });
    },
    downloadClient() {
      if (this.clients.hasOwnProperty(this.getOS())) {
        if (this.$ga !== undefined) {
          this.$ga.event("Add Camera", "Download client app", this.getOS());
        }
        const link = this.$refs.downloadLink;
        link.href = this.clients[this.getOS()];
        link.click();
      }
    },
    gotoStore(os) {
      if (this.$ga !== undefined) {
        this.$ga.event("Add Camera", "Go to store", os);
      }
      const link = this.$refs.downloadLink;
      link.href = this.clients[os];
      link.click();
    },
    cancelAddZone() {
      this.addingNewZone = false;
      this.newZoneName = null;
    },
    addZone() {
      this.zoneSpinner = true;
      if (!this.$v.newZoneName.$invalid) {
        const that = this;
        this.restapi
          .addZone(this.newZoneName)
          .then(function (zone) {
            that.$emit("added", zone);
            that.selectedZone = zone.zoneId;
          })
          .then(function () {
            that.restapi.getZones().then(function (zones) {
              that.zones = zones;
              that.newZoneName = null;
              that.addingNewZone = false;
              that.zoneSpinner = false;
            });
          })
          .catch(function (error) {
            console.error(error);
          });
      }
    },
    nextStep(event) {
      if (
        event.key == "Enter" &&
        this.model == 0 &&
        !this.$v.cameraName.$invalid &&
        !this.$v.selectedZone.$invalid &&
        !this.$v.macAddress.$invalid &&
        !this.isloading
      )
        this.addMac();
    },
  },
  validations() {
    return {
      newZoneName: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(32),
      },
      cameraName: {
        required,
        maxLength: maxLength(32),
      },
      selectedZone: {
        required,
      },
      macAddress: {
        required,
        minLength: minLength(17),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/main";

.add-camera {
  .add-camera-content {
    background-color: $color-panel-grey;
    min-height: $main-content-height;
  }
}

.steps {
  color: black;
  padding: 0 5px;
}

.previous-button {
  position: absolute;
  left: 0;
  bottom: 0;
}
.next-button {
  position: absolute;
  right: 0;
  bottom: 0;
}
.steps-add-camera {
  display: flex;
  flex-direction: column;
  height: 80%;
}

.center-align {
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-camera-card {
  height: 100%;
}
.add-mac-text {
  border-right: 1px solid #d6d6d6;
}
.mac-button {
  background: #eeeeee;
  height: 38px;
  width: 100%;
  padding: 13px;
  border-radius: 4px;
}
.mac-error-message {
  font-size: 12px;
}
.een-spinner {
  position: absolute;
  top: 42%;
  right: 33%;
}
.information-icon {
  position: absolute;
  top: 0;
  right: 0;
  height: 28px;
  width: 28px;
}
.navigation-buttons {
  width: 100%;
  position: absolute;
  bottom: 0;
}
.go-to-dashboard {
  bottom: 45px;
}
.camera-image {
  margin-top: 85px;
}

.camera-image-unsuccessful {
  margin-top: 35px;
  margin-bottom: 35px;
}

.camera-unsuccessful-sub-status {
  padding: 0 25px;
}

@media (max-width: 450px) {
  .camera-image-unsuccessful {
    margin-top: 0px;
    margin-bottom: 0;
  }
  .camera-unsuccessful-sub-status {
    padding: 0;
  }
}

.overlay-text {
  margin-top: 175px;
}
.color-white {
  color: #ffffff;
}
</style>