<template>
  <v-container fluid class="dashboard my-0 py-0">
    <v-row justify="space-around" class="fill-height">
      <v-col class="dashboard-content">
        <v-row class="px-3 pb-4 pt-6" justify="center">
          <v-col cols="3" class="mt-4">
            <h2 class="headline">{{ $t("Users") }}</h2>
          </v-col>
          <v-col cols="5">
            <v-text-field
              v-model="search"
              append-icon="fa-search"
              :label="$t('Search')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="mt-3">
            <v-btn
              color="primary"
              class="float-right"
              :ripple="false"
              @click="showAddUserModal"
              v-if="rights && (rights.addUser || rights.demoUser)"
              >{{ $t("Add User") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="users"
            item-key="userId"
            hide-default-header
            dense
            :items-per-page="25"
            :search="search"
            :footer-props="{ 
              'items-per-page-options': [10, 25, 50, -1],
              'items-per-page-all-text': $t('All'),
              'items-per-page-text': $t('Rows per page')
             }"
            :loading-text="$t('Loading results')"
            :no-results-text="$t('No matching records found')"
            :no-data-text="$t('No data')"
          >
            <template v-slot:header="{ props, on }">
              <thead>
                <tr>
                  <th class="table-head" v-for="(header, index) in props.headers" :key="index">
                    <a @click="on.sort(header.value)">
                      {{ $t(header.text) }}
                      <v-icon
                        class="pb-1"
                        v-if="header.value === props.options.sortBy[0]"
                        color="grey darken-2"
                        size="12px"
                        >{{
                          props.options.sortDesc[0] ? "fas fa-long-arrow-alt-down" : "fas fa-long-arrow-alt-up"
                        }}</v-icon
                      >
                    </a>
                  </th>
                  <th class="table-head"></th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr :key="item.userId" :class="{ 'table-row': true }" @click="goToUser(item)">
                <td class="table-cell">{{ item.lastName }}, {{ item.firstName }}</td>
                <td class="table-cell">{{ item.email }}</td>
                <td class="table-cell action-buttons">
                  <div class="d-flex flex-row justify-end">
                    <v-btn v-if="rights && (rights.editUser || rights.demoUser)" icon class="mr-3">
                      <v-icon color="grey darken-2" size="17px">fas fa-pen</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="rights && (rights.deleteUser || rights.demoUser)"
                      @click.stop="selectToDelete(item)"
                      icon
                      class="mr-3"
                      ><v-icon color="grey darken-2" size="18">fas fa-trash-alt</v-icon>
                    </v-btn>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="addingNewUser" max-width="500">
      <v-card>
        <v-card-title primary-title>
          <p class="title unset-title-color">{{ $t("Add new user") }}</p>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="newUserFirstName"
            :label="$t('First name')"
            :error-messages="firstNameErrors"
            @input="$v.newUserFirstName.$touch()"
            @blur="$v.newUserFirstName.$touch()"
            @keyup.enter="addUser()"
            ref="firstNameInput"
          ></v-text-field>
          <v-text-field
            v-model="newUserLastName"
            :label="$t('Last name')"
            :error-messages="lastNameErrors"
            @input="$v.newUserLastName.$touch()"
            @blur="$v.newUserLastName.$touch()"
            @keyup.enter="addUser()"
            ref="lastNameInput"
          ></v-text-field>
          <v-text-field
            v-model="newUserEmail"
            :label="$t('Email')"
            :error-messages="emailErrors"
            @input="$v.newUserEmail.$touch()"
            @blur="$v.newUserEmail.$touch()"
            @keyup.enter="addUser()"
            ref="emailInput"
          ></v-text-field>
          <v-text-field
            v-model="newUserPassword"
            :label="$t('Password (optional)')"
            :error-messages="passwordErrors"
            @input="$v.newUserPassword.$touch()"
            @blur="$v.newUserPassword.$touch()"
            @keyup.enter="addUser()"
            ref="emailInput"
          ></v-text-field>
          <v-checkbox v-model="startWithAllRights" :label="$t('Grant all rights to this user.')"></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <een-spinner :isloading="addingUser"></een-spinner>
          <v-btn depressed color="primary" :disabled="$v.newUserFirstName.$invalid || $v.newUserLastName.$invalid || $v.newUserEmail.$invalid  || addingUser" @click="addUser()">{{
            $t("Add new user")
          }}</v-btn>
          <v-btn depressed @click="cancelAddUser">{{ $t("Cancel") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmRemoveUser" max-width="400">
      <v-card>
        <v-card-title primary-title>
          <p class="title unset-title-color">{{ $t("Are you sure you want to remove this user?") }}</p>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" @click="removeUser">{{ $t("Confirm") }}</v-btn>
          <v-btn depressed @click.stop="confirmRemoveUser = false">{{ $t("Cancel") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <een-spinner :isloading="isloading"></een-spinner>
  </v-container>
</template>

<script>
import { required, minLength, maxLength, email } from "vuelidate/lib/validators";
import { store } from "@/store";
import { getMessageFromError } from "@eencloud/core-components/src/service/errors";
import Sentry from "@eencloud/core-components/src/plugins/sentry";

export default {
  name: "Users",
  props: ["rights"],
  data() {
    return {
      headers: [
        { text: "Name", value: "lastName" },
        { text: "Email", value: "email" },
      ],
      addingNewUser: false,
      addingUser: false,
      newUserFirstName: "",
      newUserLastName: "",
      newUserEmail: "",
      newUserPassword: "",
      confirmRemoveUser: false,
      selectedUser: null,
      users: [],
      search: "",
      isloading: false,
      startWithAllRights: false,
    };
  },
  computed: {
    firstNameErrors() {
      const errors = [];
      if (!this.$v.newUserFirstName.$dirty) return errors;
      if (!this.$v.newUserFirstName.required) errors.push(this.$t("First name is required"));
      if (!this.$v.newUserFirstName.minLength || !this.$v.newUserFirstName.maxLength) {
        errors.push(
          this.$t("First name should be X-Y characters", {
            min: this.$v.newUserFirstName.$params.minLength.min,
            max: this.$v.newUserFirstName.$params.maxLength.max,
          })
        );
      }
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.newUserLastName.$dirty) return errors;
      if (!this.$v.newUserLastName.required) errors.push(this.$t("Last name is required"));
      if (!this.$v.newUserLastName.minLength || !this.$v.newUserLastName.maxLength) {
        errors.push(
          this.$t("Last name should be X-Y characters", {
            min: this.$v.newUserLastName.$params.minLength.min,
            max: this.$v.newUserLastName.$params.maxLength.max,
          })
        );
      }
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.newUserEmail.$dirty) return errors;
      if (!this.$v.newUserEmail.required) errors.push(this.$t("Email is required"));
      if (this.$v.newUserEmail.$invalid) errors.push(this.$t("Please enter a valid email address"));
      if (!this.$v.newUserEmail.minLength || !this.$v.newUserEmail.maxLength) {
        errors.push(
          this.$t("Email should be X-Y characters", {
            min: this.$v.newUserEmail.$params.minLength.min,
            max: this.$v.newUserEmail.$params.maxLength.max,
          })
        );
      }
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.newUserPassword.$dirty) return errors;
      if (!this.$v.newUserPassword.minLength || !this.$v.newUserPassword.maxLength) {
        errors.push(
          this.$t("Password should be X characters", {
            min: this.$v.newUserPassword.$params.minLength.min,
            max: this.$v.newUserPassword.$params.maxLength.max,
          })
        );
      }
      return errors;
    },
  },
  mounted() {
    Sentry.configureScope((scope) => scope.setTransactionName(this.$options.name));
    this.getUsers();
  },
  methods: {
    async getUsers() {
      this.isloading = true;
      try {
        const users = await this.restapi.getUsers();
        this.isloading = false;
        this.users = users.filter((element) => {
          if (this.$store.getters.userId !== element.userId) {
            return element;
          }
        });
      } catch (error) {
        this.isloading = false;
        console.error(error);
      }
    },
    goToUser(item) {
      if (this.rights && (this.rights.editUser || this.rights.demoUser))
        this.$router.push({ path: `/settings/users/${item.userId}/details` });
    },
    deletedUser(userId) {
      const userIndex = this.users.findIndex((z) => {
        return z.userId === userId;
      });
      if (userIndex !== -1) this.users.splice(userIndex, 1);
    },
    selectToDelete(item) {
      this.selectedUser = item;
      this.confirmRemoveUser = true;
    },
    showAddUserModal() {
      this.addingNewUser = true;
      // auto focus
      setTimeout(() => {
        this.$refs.firstNameInput.focus();
      }, 200);
      this.$v.$reset();
    },
    cancelAddUser() {
      this.addingNewUser = false;
      this.newUserName = null;
      this.$v.$reset();
    },
    addUser() {
      if (this.rights && this.rights.demoUser) {
        return this.$store.dispatch("demoMessage");
      }
      if (!this.$v.newUserFirstName.$invalid || !this.$v.newUserLastName.$invalid || !this.$v.newUserEmail.$invalid) {
        const that = this;
        const newUser = {
          firstName: this.newUserFirstName,
          lastName: this.newUserLastName,
          email: this.newUserEmail,
          startWithZeroRights: !this.startWithAllRights,
        };
        if (this.newUserPassword) newUser.password = this.newUserPassword;
        this.addingUser = true;
        this.restapi
          .addUser(newUser)
          .then(function (user) {
            that.addingNewUser = false;
            that.newUserFirstName = "";
            that.newUserLastName = "";
            that.newUserEmail = "";
            that.newUserPassword = "";
            that.addingUser = false;
            that.$v.$reset();
            that.users.push(user);
            store.dispatch("toastMessage", {
              text: "User has been added successfully",
              color: "primary",
              showing: true,
              timeout: 4000,
            });
          })
          .catch(function (error) {
            Sentry.captureException(error);
            store.dispatch("toastMessage", {
              text: getMessageFromError(error, "ADD_USER"),
              color: "error",
              showing: true,
              timeout: -1,
              support: true
            });
            setTimeout(() => (that.addingUser = false), 1000);
          });
      }
    },
    removeUser() {
      if (this.rights && this.rights.demoUser) {
        this.confirmRemoveUser = false;
        return this.$store.dispatch("demoMessage");
      }
      const that = this;
      this.restapi
        .deleteUser(that.selectedUser.userId)
        .then(that.deletedUser(that.selectedUser.userId))
        .catch(function (error) {
          store.dispatch("toastMessage", {
            text: getMessageFromError(error, "DELETE_USER"),
            color: "error",
            showing: true,
            timeout: -1,
            support: true
          });
        })
        .then(function () {
          that.confirmRemoveUser = false;
        });
    },
  },
  validations: {
    newUserFirstName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(32),
    },
    newUserLastName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(32),
    },
    newUserEmail: {
      required,
      email,
      minLength: minLength(5),
      maxLength: maxLength(255),
    },
    newUserPassword: {
      minLength: minLength(8),
      maxLength: maxLength(32),
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/main";

.dashboard {
  background-color: #eee;
  .dashboard-content {
    min-height: $main-content-height;
    max-width: 1000px;
    margin: auto;
  }
}

.headline {
  color: #555555;
}

.table-head {
  border-bottom-color: var(--v-primary-lighten1) !important;
  border-bottom-width: 2px !important;
}

.table-row {
  &:nth-child(even) {
    background-color: #f8f8f8;
  }
  &.expanded {
    background-color: #f5f5f5;
  }
  .table-cell {
    padding: 10px;
    vertical-align: middle;
    font-size: 0.9em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none !important;
    &:hover {
      cursor: pointer;
    }
    &.action-buttons {
      padding: 5px;
      &::v-deep .v-btn {
        height: 30px;
        width: 30px;
      }
    }
  }
}
</style>
