<template>
  <v-card v-if="zone" flat :color="zone.zoneId == $attrs.selected - zone ? 'primary' : 'transparent'">
    <v-card-title class="py-1">
      <v-container class="pa-0">
        <v-row>
          <v-col class="py-0 pr-1" cols="9">
            <v-checkbox v-if="false" v-model="isSelected" :label="zone.name" hide-details></v-checkbox>
            <p v-else class="mb-0 body-2 text-uppercase d-inline-block mr-0 my-0">{{ zone.name }}</p>
          </v-col>
          <v-col class="py-0 pl-0" cols="1">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="$route.name === 'Live'"
                  x-small
                  text
                  depressed
                  :ripple="false"
                  class="my-0 pa-0"
                  :disabled="!zone.cameraIds.length"
                  @click="isSelected = zone.zoneId"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon color="#808080" small>fa-th-large</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("View all cameras") }}</span>
            </v-tooltip>
          </v-col>
          <v-col class="py-0" cols="2">
            <v-btn
              icon
              small
              :ripple="false"
              class="ma-0"
              @click="showCameras = !showCameras"
              :disabled="!zone.cameraIds.length"
            >
              <v-icon small v-if="!showCameras">fa-caret-down</v-icon>
              <v-icon small v-else>fa-caret-up</v-icon>
            </v-btn>
          </v-col>
          <v-col class="py-0" cols="12">
            <v-divider class="mt-1"></v-divider>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-text v-if="showCameras" class="pa-0">
      <v-list
        v-for="camera in camerasForZone(zone.zoneId)"
        :key="camera.cameraId"
        :disabled="isButtonDisabled"
        class="list pa-0"
        :color="selectedColor(camera)"
        flat
      >
        <camera-list-tile
          :camera="camera"
          :class="{ 'primary--text': isSelectedCamera(camera) }"
          @select="selectCamera(camera.cameraId)"
        ></camera-list-tile>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import CameraListTile from "@/components/CameraListTile";
import { store } from "@/store";

export default {
  name: "ZoneCameras",
  components: {
    CameraListTile,
  },
  props: ["selected", "zone", "cameras", "camera", "rights", "selectedCameras"],
  data() {
    return {
      showCameras: true,
      isButtonDisabled: false,
    };
  },
  computed: {
    allCameras() {
      return this.$store.getters.allCameras;
    },
    isSelected: {
      get: function () {
        return this.selected;
      },
      set: function (value) {
        this.$emit("selectZone", this.zone.zoneId);
      },
    },
    isContinousRecording() {
      return this.camera && this.camera.cameraMode ? this.camera.cameraMode.recordingMode === "continuous" : null;
    },
    isMultiView() {
      return this.$store.getters.isMultiView;
    },
  },
  methods: {
    camerasForZone(zoneId) {
      return this.cameras
        .filter((c) => {
          return c.zoneId === zoneId;
        })
        .sort((a, b) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
    },
    selectCamera(id) {
      this.isButtonDisabled = true;
      setTimeout(() => {
        this.isButtonDisabled = false;
      }, 1000);
      store.dispatch("selectedCamera", { id });
      this.$emit("selectCamera", id);
    },
    selectedColor(cam) {
      if (this.selectedCameras && this.selectedCameras.find((c) => cam.cameraId == c)) {
        return "#e0e0e0";
      } else {
        return "transparent";
      }
    },
    isSelectedCamera(camera) {
      return this.camera && this.camera.cameraId === camera.cameraId;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/main";

.list {
  border-radius: 0;
}

label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.camera-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 48px);
}
.truncate {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
>>> .v-input--selection-controls {
  margin-top: 0;
}
.rounded-image {
  border-radius: $border-radius;
}
.recording-icon {
  position: absolute;
  top: 8px;
  left: 18px;
  z-index: 1;
}
.offline-camera-wrapper {
  width: 40px;
  height: 40px;
  background-color: #959595;
  .offline-camera {
    width: 100%;
    height: 100%;
    background-color: white;
    mask: url("~@eencloud/core-components/src/assets/images/videocam_off.svg") no-repeat;
    position: absolute;
    left: 24px;
    top: 12px;
  }
}
>>> .v-toolbar {
  background-color: unset;
  .offline-camera-wrapper {
    width: 60px;
    .offline-camera {
      left: 40px;
    }
  }
}
>>> .v-divider--vertical.v-divider--inset {
  min-height: calc(100% - 16px);
}
>>> .v-divider--inset:not(.v-divider--vertical) {
  margin-left: 102px;
}
>>> .v-toolbar__title {
  font-size: 16px;
}
</style>
