<template>
  <v-container fluid class="event-search ma-0 pa-0">
    <v-row>
      <v-container fluid class="event-search-content">
        <een-spinner :isloading="loading" :size="60"></een-spinner>
        <v-row justify="center">
          <v-col cols="auto">
            <v-badge
              color="rgba(0,0,0,0.5)"
              offset-y="10"
              :content="cameraFilter.length"
              :value="cameraFilter.length"
              overlap
            >
              <v-btn
                small
                color="#d8d8d8"
                elevation="0"
                height="32"
                @click.exact="showCameraDialog = true"
              >
                <p class="filter-text mb-0">
                  {{ $t("Cameras") }}
                </p>
                <v-icon small color="grey darken-2" class="ml-2">
                  fa-filter
                </v-icon>
              </v-btn>
            </v-badge>
          </v-col>
          <v-col cols="auto">
            <v-menu
              v-model="dateDialog"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
              class="mr-0"
            >
              <template v-slot:activator="{ on: { click } }">
                <v-row
                  @click="click"
                  class="date-time-select float-right mr-1 px-3"
                >
                  <div class="mt-1">
                    {{ displayDate }}
                  </div>
                  <v-icon size="17" color="grey darken-2" class="ml-2 pt-0"
                    >fa-calendar-day</v-icon
                  >
                </v-row>
              </template>
              <v-date-picker
                v-if="dateDialog"
                v-model="date"
                @input="dateSelected"
                no-title
                :locale="locale"
                :max="maxDate"
                :show-current="false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="auto">
            <v-row>
              <span @dblclick.shift="toggler = true" class="mt-1 mr-1">{{
                $t("From")
              }}</span>
              <div class="date-time-select time mr-2">
                <vue-timepicker
                  auto-scroll
                  hide-clear-button
                  input-width="100%"
                  fixed-dropdown-button
                  :hour-range="[[0, endTime.split(':')[0]]]"
                  :minute-range="[
                    startTime.split(':')[0] == endTime.split(':')[0]
                      ? [0, endTime.split(':')[1] - 1]
                      : [0, 60],
                  ]"
                  format="HH:mm"
                  v-model="startTime"
                  ref="startTimePicker"
                  v-click-outside="closeStartTimePicker"
                  @open="startTimePickerStatus = true"
                  @close="startTimePickerStatus = false"
                >
                  <template v-slot:dropdownButton>
                    <v-icon size="17" color="grey darken-2" class="ml-2 pt-0"
                      >fa-clock</v-icon
                    >
                  </template>
                </vue-timepicker>
              </div>
            </v-row>
          </v-col>
          <v-col cols="auto">
            <v-row>
              <span class="mt-1 mx-1">{{ $t("To") }}</span>
              <div class="date-time-select time mr-2">
                <vue-timepicker
                  auto-scroll
                  hide-clear-button
                  input-width="100%"
                  fixed-dropdown-button
                  :hour-range="[[startTime.split(':')[0], 24]]"
                  :minute-range="[
                    startTime.split(':')[0] == endTime.split(':')[0]
                      ? [parseInt(startTime.split(':')[1]) + 1, 60]
                      : [0, 60],
                  ]"
                  format="HH:mm"
                  v-model="endTime"
                  ref="endTimePicker"
                  v-click-outside="closeEndTimePicker"
                  @open="endTimePickerStatus = true"
                  @close="endTimePickerStatus = false"
                >
                  <template v-slot:dropdownButton>
                    <v-icon size="17" color="grey darken-2" class="ml-2 pt-0"
                      >fa-clock</v-icon
                    >
                  </template>
                </vue-timepicker>
              </div>
            </v-row>
          </v-col>
          <v-col class="px-0" cols="auto">
            <v-badge
              color="rgba(0,0,0,0.5)"
              offset-y="10"
              :content="eventFilter.length"
              :value="eventFilter.length"
              overlap
            >
              <v-btn
                small
                color="#d8d8d8"
                elevation="0"
                height="32"
                @click.exact="showEventDialog = true"
              >
                <p class="filter-text mb-0">
                  {{ $t("Events") }}
                </p>
                <v-icon small color="grey darken-2" class="ml-2">
                  fa-filter
                </v-icon>
              </v-btn>
            </v-badge>
          </v-col>
          <v-col cols="auto">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click.exact="reset"
                  small
                  color="#d8d8d8"
                  elevation="0"
                  height="32"
                  width="6"
                  class="ml-6"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="17" color="grey darken-2" class="ml-2"
                    >fa-undo</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $t("Reset filters to default") }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <div
          class="d-flex justify-center align-center noEvents text-center"
          v-if="events.length === 0 && loading == false"
        >
          {{
            $t("No events available. Change your filter to see other events.")
          }}
        </div>
        <v-row dense class="mx-5" :class="{ 'px-12': !$vuetify.breakpoint.xs }">
          <v-col
            xl="2"
            cols="6"
            lg="2"
            md="3"
            sm="4"
            class="outerSquare"
            v-for="(item, index) in events"
            @click="goToVideo(item)"
            :key="item.eventId"
            ><div
              @mouseenter="hoverMouseOver(index)"
              @mouseleave="hoverMouseOut(index, item.stream)"
              :class="[
                item.type == 'objectInAreaDetected' ||
                item.type == 'personInAreaDetected' ||
                item.type == 'objectTripwireDetected' ||
                item.type == 'personTripwireDetected' ||
                item.type == 'objectDetected' ||
                item.type == 'personDetected' ||
                item.type == 'animalDetected' ||
                item.type == 'faceDetected'
                  ? 'styledIcon'
                  : '',
                { hoverSquare: isHovering },
              ]"
              class="square"
              :id="`squareEnclosure${index}`"
            >
              <div class="imageContainer">
                <video
                  autoplay
                  loop
                  :data="posterData(item, index)"
                  v-if="index == hoveredIndex && delay && item.stream == 'mp4'"
                  class="sqaureVideo fade-in fade-out"
                  :id="`squareVideo${index}`"
                >
                  <source
                    :src="`${item.streamUrls.mp4Https}?access_token=${restapi.token}`"
                    type="video/mp4"
                  />
                </video>
                <v-img
                  @load="imageLoaded(item, index)"
                  v-if="
                    index == hoveredIndex && delay && item.stream == 'mjpeg'
                  "
                  :data="posterImage(item, index)"
                  class="sqaureVideo fade-in fade-out"
                  :src="imageUrl(item)"
                ></v-img>
                <v-img
                  class="imageEvents"
                  :contain="contain(item)"
                  :id="`squareImage${index}`"
                  :src="imageType(item, index)"
                ></v-img>
                <transition name="fade">
                  <v-progress-circular
                    v-if="index == hoveredIndex"
                    indeterminate
                    :id="`squareProgress${index}`"
                    color="white"
                    :size="18"
                    :width="2"
                    class="eventLoader mt-1"
                  ></v-progress-circular>
                </transition>
                <span class="eventHeading cameraText pt-1">
                  {{ cameraName(item.source.sourceId) }}</span
                >
                <span class="eventTime cameraText pb-1">
                  {{ getTranslatedEventType(item) }} {{ timeStamp(item) }}
                </span>
              </div>
            </div></v-col
          >
        </v-row>
      </v-container>
    </v-row>
    <v-dialog v-model="showCameraDialog" width="500">
      <div class="cameraDialog">
        <div class="pa-2">
          <v-chip
            class="mt-2 camera-chips ml-1 mr-1"
            v-for="(camera, index) in cameras"
            :color="coloredCameras(camera)"
            :text-color="coloredCamerasText(camera)"
            :id="`cameraDialog${index}`"
            :key="camera.cameraId"
            @click="filterClickedCamera(camera)"
          >
            {{ camera.name }}
          </v-chip>
        </div>
        <div class="d-flex justify-end">
          <v-btn depressed class="ma-2" @click.stop="showCameraDialog = false">
            {{ $t("Close") }}
          </v-btn>
        </div>
        <div></div>
      </div>
    </v-dialog>
    <v-dialog v-model="showEventDialog" width="500">
      <div class="cameraDialog">
        <div class="pa-2">
          <v-chip
            :key="item"
            v-for="(item, index) in eventFilters"
            :id="`eventDialog${index}`"
            :color="coloredEvents(item)"
            :text-color="coloredEventsText(item)"
            class="mt-2 camera-chips ml-1 mr-1"
            @click="filterClickedEvents(item)"
          >
            {{ getTranslatedEventType({ type: item }) }}
          </v-chip>
        </div>
        <div class="d-flex justify-end">
          <v-btn depressed class="ma-2" @click.stop="showEventDialog = false">
            {{ $t("Close") }}
          </v-btn>
        </div>
        <div></div>
      </div>
    </v-dialog>
  </v-container>
</template>
<script>
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import { store } from "@/store";
import Sentry from "@eencloud/core-components/src/plugins/sentry";
import { findTimeZone, getZonedTime } from "timezone-support";
import { getTimeStamp } from "@eencloud/core-components/src/service/time";

export default {
  name: "EventSearch",
  props: ["cameras"],
  components: {
    VueTimepicker,
  },
  data() {
    return {
      events: [],
      showCameraDialog: false,
      showEventDialog: false,
      isHovering: false,
      loading: true,
      hoveredIndex: null,
      delay: false,
      toggler: false,
      delayTimeOut: null,
      dateDialog: false,
      startTimePickerStatus: false,
      endTimePickerStatus: false,
      availableEventTypes: [],
      currentDate: null,
      showTour: false,
    };
  },
  computed: {
    eventTypes() {
      const eventTypes = {};
      store.getters.eventTypes.forEach((type) => {
        eventTypes[type.eventType] = type;
      });
      return eventTypes;
    },
    eventTypesNames() {
      return Object.keys(this.eventTypes);
    },
    eventFilters() {
      const filters = [
        "vmdDetected",
        "audioDetected",
        "pirDetected",
        "AnalyticsObject",
        "AnalyticsPerson",
      ];

      this.eventTypesNames.forEach((type) => {
        const actualType = this.convertType(type);
        if (
          !filters.includes(actualType) &&
          this.availableEventTypes.includes(actualType)
        ) {
          filters.push(actualType);
        }
      });

      return filters;
    },

    displayDate() {
      let parts = this.date.split("-");
      this.currentDate = new Date(parts[0], parts[1] - 1, parts[2]);
      const timestamp = this.currentDate.getTime();
      const dt = new Date(timestamp);
      return `${dt.toLocaleDateString(this.$locale.getCurrentLanguage(), {
        weekday: "long",
      })}, ${this.pad(dt.getDate())}/${this.pad(
        dt.getMonth() + 1
      )}/${dt.getFullYear()}`;
    },

    locale() {
      return this.$locale.getCurrentLanguage();
    },
    cameraFilter: {
      get() {
        return store.getters.cameraFilter;
      },
      set(newVal) {
        store.dispatch("setCameraFilter", newVal);
      },
    },
    date: {
      get() {
        return store.getters.dateFilter;
      },
      set(newVal) {
        store.dispatch("setDateFilter", newVal);
      }
    },
    startTime: {
      get() {
        return store.getters.startTimeFilter;
      },
      set(newVal) {
        if (
          parseInt(newVal.replace(":", "")) >
          parseInt(this.endTime.replace(":", ""))
        )
          store.dispatch("setStartTimeFilter", "00:00");
        else store.dispatch("setStartTimeFilter", newVal);
      }
    },
    endTime: {
      get() {
        return store.getters.endTimeFilter;
      },
      set(newVal) {
        store.dispatch("setEndTimeFilter", newVal);
      },
    },
    eventFilter: {
      get() {
        return store.getters.eventFilter;
      },
      set(newVal) {
        store.dispatch("setEventFilter", newVal);
      },
    },
    accountTimeZoneOffset() {
      return store.getters.accountTimeZoneOffset;
    },
    allCamerasHaveTimezone() {
      return this.cameras.every((camera) => camera.timeZone !== undefined);
    },
    maxDate() {
      const offsetToSystemTime = new Date().getTimezoneOffset();
      let epoch = new Date().getTime();
      epoch -=
        (store.getters.accountTimeZoneOffset - offsetToSystemTime) * 60000;
      let accountTime = new Date(epoch);
      const month = ("0" + (accountTime.getMonth() + 1)).slice(-2);
      const day = ("0" + accountTime.getDate()).slice(-2);
      return `${accountTime.getFullYear()}-${month}-${day}`;
    },
  },
  watch: {
    date(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getEvents();
      }
    },
    startTime(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (this.isTimeGreater(this.endTime, this.startTime)) this.getEvents();
      }
    },
    endTime(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (this.isTimeGreater(this.endTime, this.startTime)) this.getEvents();
      }
    },
    accountTimeZoneOffset(newValue, oldValue) {
      if (newValue !== oldValue) this.setTime();
    },
    cameras(newValue, oldValue) {
      if (newValue.length !== oldValue.length) {
        this.getAvailableTypes();
        this.getEvents();
      }
    },
    allCamerasHaveTimezone(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.getEvents();
      }
    },
  },
  mounted() {
    Sentry.configureScope((scope) =>
      scope.setTransactionName(this.$options.name)
    );

    this.getAvailableTypes();

    if (!store.getters.timeSet) {
      this.setTime();
      store.dispatch("setTimeSet", true);
    }

    // Temporary solution to not having the timezone available at mount when the page is refreshed
    setTimeout(() => {
      this.getEvents();
    }, 1000);

  },
  methods: {
    async getAvailableTypes() {
      const camIds = this.cameras.map((camera) => camera.cameraId);
      const types =
        camIds.length === 0
          ? []
          : await this.restapi.getAvailableEventTypes(camIds);
      this.availableEventTypes = types;
    },
    formatFilters(filters) {
      return filters.flatMap((filter) => {
        if (filter.indexOf("Analytics") !== -1) {
          switch (filter) {
            case "AnalyticsObject":
              return [
                "objectDetected",
                "objectInAreaDetected",
                "objectTripwireDetected",
              ];
            case "AnalyticsPerson":
              return [
                "personDetected",
                "personInAreaDetected",
                "personTripwireDetected",
              ];
          }
        } else {
          return filter;
        }
      });
    },
    getTranslatedEventType(event) {
      const eventType = this.eventTypes[event.type];
      if (
        event.category &&
        eventType.categoriesTranslated &&
        eventType.categoriesTranslated[event.category]
      ) {
        return eventType.categoriesTranslated[event.category];
      } else {
        return eventType ? eventType.eventTypeTranslated : this.$t(event.type);
      }
    },
    closeStartTimePicker() {
      if (this.$refs.startTimePicker && this.startTimePickerStatus) {
        this.$refs.startTimePicker.toggleActive();
      }
    },
    closeEndTimePicker() {
      if (this.$refs.endTimePicker && this.endTimePickerStatus) {
        this.$refs.endTimePicker.toggleActive();
      }
    },
    setTime() {
      const offset =
        this.accountTimeZoneOffset || this.accountTimeZoneOffset === 0
          ? this.accountTimeZoneOffset
          : new Date().getTimezoneOffset();
      this.endTime = getTimeStamp(offset).slice(12, 17);
      this.startTime = getTimeStamp(offset + 60).slice(12, 17);
      this.date = getTimeStamp(offset).slice(0, 10);
    },
    isTimeGreater(timeOne, timeTwo) {
      return (
        Number(timeOne.replace(":", "")) > Number(timeTwo.replace(":", ""))
      );
    },
    dateSelected() {
      this.dateDialog = false;
    },
    imageLoaded(item, index) {
      document
        .getElementById("squareProgress" + index)
        .style.display = "none";
    },
    cameraName(camID) {
      let name;
      this.cameras.find((element) => {
        if (element.cameraId == camID) name = element.name;
      });
      return name;
    },
    imageUrl(item) {
      const ts = new Date(item.timestamp).toISOString().replace("Z", "+0000");
      return `${
        item.streamUrls.multipartaudioHttps
      }?start_time=${encodeURIComponent(ts)}&access_token=${
        this.restapi.token
      }`;
    },
    async getEvents() {
      if (!this.allCamerasHaveTimezone) return;
      let that = this;
      this.loading = true;

      try {
        let promises = [];

        this.cameras.forEach((cam) => {
          if (
            !this.cameraFilter.length ||
            this.cameraFilter.find((c) => c == cam.cameraId)
          ) {
            let start = new Date(`${this.date}T${this.startTime}Z`);
            let end = new Date(`${this.date}T${this.endTime}Z`);
            const timeZone = findTimeZone(cam.timeZone);
            const dateObject = getZonedTime(start, timeZone);
            const offset = dateObject.zone.offset;
            start = start.getTime() + offset * 60000;
            end = end.getTime() + offset * 60000;
            const startTime = new Date(start)
              .toISOString()
              .replace("Z", "+0000");
            const endTime = new Date(end).toISOString().replace("Z", "+0000");

            promises.push(
              this.restapi.getNewCameraEvents(
                //array of camIds
                [cam.cameraId],
                //from
                startTime,
                //till
                endTime,
                //limit
                100,
                //requestId
                Math.round(Math.random() * 100000000),
                //types
                this.formatFilters(
                  that.eventFilter.length == 0
                    ? that.eventFilters
                    : that.eventFilter
                ),
                //streams
                "multipartaudioHttps, mp4Https"
              )
            );
          }
        });

        const rawEvents = await Promise.allSettled(promises);

        let newEvents = [];

        rawEvents.forEach((element) => {
          if (element.status === "fulfilled" && element.value.length) {
            newEvents = newEvents.concat(element.value);
          }
        });

        newEvents.sort((a, b) => compare(a, b));

        newEvents.map((element) => {
          if (element.streamUrls.mp4Https) {
            element.stream = "mp4";
          } else if (element.streamUrls.multipartaudioHttps) {
            element.stream = "mjpeg";
          } else {
            element.stream = null;
          }
        });

        this.events = newEvents;

        this.loading = false;

        function compare(a, b) {
          const epochA = new Date(a.timestamp).getTime();
          const epochB = new Date(b.timestamp).getTime();
          const timezoneA = that.cameras.find(
            (cam) => cam.cameraId === a.source.sourceId
          ).timeOffset;
          const timezoneB = that.cameras.find(
            (cam) => cam.cameraId === b.source.sourceId
          ).timeOffset;
          const timeA = epochA - timezoneA * 60000;
          const timeB = epochB - timezoneB * 60000;
          if (timeA < timeB) return 1;
          if (timeA > timeB) return -1;
          return 0;
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    },

    coloredEvents(item) {
      const index = this.eventFilter.indexOf(item);
      if (index > -1) {
        return "primary";
      } else {
        return "";
      }
    },

    coloredEventsText(item) {
      const index = this.eventFilter.indexOf(item);
      if (index > -1) {
        return "";
      } else {
        return "black";
      }
    },

    coloredCameras(cam) {
      if (this.cameraFilter.find((camId) => camId === cam.cameraId)) {
        return "primary";
      } else {
        return "";
      }
    },

    coloredCamerasText(cam) {
      if (this.cameraFilter.find((camId) => camId === cam.cameraId)) {
        return "white";
      } else {
        return "black";
      }
    },

    contain(item) {
      return (
        item.type === "audioDetected" ||
        item.type === "vmdDetected" ||
        item.type === "pirDetected" ||
        item.type === "audioCategorized"
      );
    },

    hoverMouseOver(index) {
      let that = this;
      this.isHovering = true;
      this.hoveredIndex = index;
      this.delayTimeOut = setTimeout(() => {
        that.delay = true;
      }, 1000);
    },
    posterData(item, index) {
      this.$nextTick(() => {
        document
          .getElementById("squareVideo" + index)
          .style.display = "none";
        if (
          item.type !== "audioDetected" &&
          item.type !== "vmdDetected" &&
          item.type !== "pirDetected" &&
          item.type !== "audioCategorized"
        ) {
          document
            .getElementById("squareEnclosure" + index)
            .classList.add("backGroundBlack");
        }
        document
          .getElementById("squareVideo" + index)
          .addEventListener("loadeddata", () => {
            console.log("data loaded for index", index)
            if (document.getElementById("squareVideo" + index))
              document
                .getElementById("squareVideo" + index)
                .style.display = "inline";
            if (document.getElementById("squareImage" + index))
              document
                .getElementById("squareImage" + index)
                .style.display = "none";
            if (document.getElementById("squareEnclosure" + index))
              document
                .getElementById("squareEnclosure" + index)
                .classList.add("backGroundBlack");
            if (document.getElementById("squareProgress" + index))
              document
                .getElementById("squareProgress" + index)
                .style.display = "none";
          });
      });
    },
    posterImage(item, index) {
      this.$nextTick(() => {
        document
          .getElementById("squareImage" + index)
          .style.display = "none";
        if (
          item.type !== "audioDetected" &&
          item.type !== "vmdDetected" &&
          item.type !== "pirDetected" &&
          item.type !== "audioCategorized"
        ) {
          document
            .getElementById("squareEnclosure" + index)
            .classList.add("backGroundBlack");
        }
      });
    },
    hoverMouseOut(index, stream) {
      if (stream == "mp4") {
        document
          .getElementById("squareImage" + index)
          .style.display = "inline";
        document
          .getElementById("squareProgress" + index)
          .style.display = "inline";
      }

      if (stream == "mjpeg") {
        document
          .getElementById("squareImage" + index)
          .style.display = "inline";
      }

      this.hoveredIndex = null;
      clearTimeout(this.delayTimeOut);
      this.isHovering = false;
      this.delay = false;
    },
    imageType(item, index) {
      let imageSelected = null;
      if (item.type === "audioDetected" || item.type === "audioCategorized") {
        imageSelected = require(`@eencloud/core-components/src/assets/images/sound_event.svg`);
      } else if (item.type === "vmdDetected") {
        imageSelected = require(`@eencloud/core-components/src/assets/images/motion_event.svg`);
      } else if (item.type === "pirDetected") {
        imageSelected = require(`@eencloud/core-components/src/assets/images/infrared_event.svg`);
      } else {
        imageSelected = `${this.restapi.baseURL}/rest/v2.3/events/${item.eventId}.jpg?access_token=${this.restapi.token}`;
      }
      return imageSelected;
    },

    goToVideo(item) {
      const time = new Date(item.timestamp).getTime();
      const actualType = this.convertType(item.type);
      this.$router
        .push({
          name: "Footage",
          params: { cameraId: item.source.sourceId },
          query: { time: time - 3000, events: actualType },
        })
        .catch((failure) => console.log(failure));
    },

    filterClickedCamera(camera) {
      const index = this.cameraFilter.indexOf(camera.cameraId);
      if (index > -1) {
        const cameras = this.cameraFilter;
        cameras.splice(index, 1);
        this.cameraFilter = cameras;
        this.getEvents();
      } else {
        this.cameraFilter = [...this.cameraFilter, camera.cameraId];
        this.getEvents();
      }
    },

    filterClickedEvents(type) {
      const index = this.eventFilter.indexOf(type);
      if (index > -1) {
        const filters = this.eventFilter;
        filters.splice(index, 1);
        this.eventFilter = filters;
        this.getEvents();
      } else {
        this.eventFilter = [...this.eventFilter, type];
        this.getEvents();
      }
    },
    reset() {
      store.dispatch("resetToInitialState");
      this.setTime();
      this.getEvents();
    },
    timeStamp(event) {
      const camera = this.cameras.find(
        (c) => c.cameraId === event.source.sourceId
      );
      const date = new Date(event.timestamp);
      const timeZone = findTimeZone(camera.timeZone);
      const dateObject = getZonedTime(date, timeZone);
      const offset = dateObject.zone.offset;
      const result = getTimeStamp(
        offset,
        new Date(event.timestamp),
        store.getters.accountTimeZoneOffset
      ).slice(12);
      return result.slice(0, 5) + result.slice(8);
    },
    convertType(type) {
      if (type.indexOf("object") !== -1) {
        return "AnalyticsObject";
      } else if (type.indexOf("person") !== -1) {
        return "AnalyticsPerson";
      } else return type;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/main";

.event-search {
  .event-search-content {
    background-color: $color-panel-grey;
    min-height: $main-content-height;
  }
}

.camera-chips {
  color: #fff;
  width: auto;
}

.imageEvents {
  max-width: 100%;
  margin: 0 auto;
  object-fit: fill;
  border-radius: 5px;
}

.imageContainer {
  display: grid;
  position: relative;
  width: 100%;
  background-color: #9b9a9a;
}

.cameraDialog {
  background: white;
}

.chipBackGround {
  background: var(--v-primary-base) !important;
  color: white !important;
}

.backGroundBlack {
  background: black;
}

.noEvents {
  height: 90%;
}

.eventLoader {
  top: 2px;
  right: 10px;
  position: absolute;
}

.cameraText {
  text-shadow: 0 0 2px #000000, 0 0 4px #000000;
  font-size: 14px !important;
}

.eventTime {
  position: absolute;
  left: 20px;
  color: white;
  bottom: 0;
  font-size: 10px;
}

.eventHeading {
  position: absolute;
  left: 20px;
  color: white;
  font-size: 10px;
}

.sqaureVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.fade-enter-active {
  transition-delay: 3s;
}

.fade-leave-active {
  display: none;
}

.time-picker {
  outline: none;
  height: 100%;
  padding-left: 6px;

  &::v-deep input {
    height: 100%;
    outline: none;
    cursor: pointer;
    user-select: none;
    border: 0 !important;
  }

  &::v-deep .dropdown {
    border-radius: 4px;
    .select-list {
      border-radius: 4px;
    }
  }
  &::v-deep .active {
    background-color: $primary !important;
  }

  &::v-deep .controls {
    padding-right: 6px;
  }
}

.time-picker {
  &::v-deep .time-picker-overlay {
    display: none;
  }
}

</style>

<style lang="scss">
.square {
  background: #e0e0e0;
  height: 150px;
  display: flex;
  transition: 0.4s;
  border-radius: 5px;

  cursor: pointer;
  &:hover {
    position: relative;
    z-index: 10;
    transform: scale(1.4);
  }
}

.outerSquare:hover .square:hover {
  transition-delay: 0.5s;
}
</style>