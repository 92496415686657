<template>
  <v-container fluid class="camera-info pa-0">
    <v-app-bar v-if="showToolbar" dense flat :color="toolbarColor || 'white'" class="title--text">
      <v-container v-if="isDirty" class="my-2 align">
        <v-row class="xs8 offset-xs2">
          <v-spacer></v-spacer>
            <v-btn @click="cancelChanges">
              {{ $t("Cancel") }}
            </v-btn>
            <v-btn class="ml-2" color="primary" @click="saveChanges">
              {{ $t("Save") }}
            </v-btn>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-container v-if="device" class="pa-0 align">
      <v-col cols="12" sm="10" offset-sm="1" class="pt-0">
        <p class="text-uppercase pl-0 mb-2">
          {{ $t("Channels") }}
        </p>
        <v-card class="mb-4" v-if="!online">
          <v-col cols="12" class="pa-0">
            <v-list dense rounded>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="my-2 py-0">
                      {{ $t("The MCD is offline") }}
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-card>
        <v-card v-if="channels && device">
          <v-data-table
            :headers="headers"
            :items="channels"
            headers-length="2"
            item-key="channelId"
            :key="key"
            hide-default-header
            :items-per-page="25"
            :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
            :loading-text="$t('Loading results')"
            :no-results-text="$t('No matching records found')"
            :no-data-text="$t('No data')"
          >
            <template v-slot:header="{ props, on }">
              <thead>
                <tr>
                  <th class="table-head" v-for="(header, index) in props.headers" :key="index">
                    <a @click="on.sort(header.value)">
                      {{ $t(header.text) }}
                      <v-icon
                        class="pb-1"
                        v-if="header.value === props.options.sortBy[0]"
                        color="grey darken-2"
                        size="12px"
                        >{{
                          props.options.sortDesc[0] ? "fas fa-long-arrow-alt-down" : "fas fa-long-arrow-alt-up"
                        }}</v-icon
                      >
                    </a>
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr :key="item.channelId" :class="{ 'table-row': true }">
                <div></div>
                <v-img
                  v-if="item.snapShot"
                  class="rounded-image mr-2 ml-4 mt-1"
                  :src="item.snapShot"
                  width="60"
                  max-width="60"
                  height="40"
                  max-height="40"
                  aspect-ratio="16/9"
                ></v-img>
                <td class="table-cell">{{ item.cameraName }}</td>
                <td class="table-cell">{{ item.channelId }}</td>
                <td class="table-cell">{{ item.zoneName }}</td>
                <td class="table-cell action-buttons">
                  <div>
                    <v-btn icon class="mr-3" @click="addNewChannel(item)" v-if="!item.cameraId">
                      <v-icon color="grey darken-2" size="18px">fas fa-plus</v-icon>
                    </v-btn>
                    <v-btn v-if="item.cameraId" icon class="mr-3" @click="deleteCamera(item)">
                      <v-icon color="grey darken-2" size="18px">fas fa-trash-alt</v-icon>
                    </v-btn>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-dialog v-model="addCameraDialog" max-width="530">
        <v-card>
          <h2 class="text-center pt-4">{{ $t("Channel Camera details") }}</h2>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <p class="subheading heading-size my-2">{{ $t("Channel Number") }}</p>
              </v-col>
              <v-col cols="8">
                <p class="subheading my-2 heading-size">{{ selectedChannel }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <p class="subheading heading-size my-2">{{ $t("Device Name") }}</p>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="cameraName"
                  flat
                  dense
                  solo
                  placeholder="New Camera"
                  :error-messages="cameraNameErrors"
                  @input="$v.cameraName.$touch()"
                  @blur="$v.cameraName.$touch()"
                  background-color="grey lighten-3"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <p class="subheading heading-size my-2">{{ $t("Device Zone") }}</p>
              </v-col>
              <v-col cols="8">
                <v-autocomplete
                  flat
                  filled
                  dense
                  :items="zones"
                  :error-messages="selectedZoneErrors"
                  item-text="name"
                  item-value="zoneId"
                  v-model="selectedZone"
                  @input="$v.selectedZone.$touch()"
                  @blur="$v.selectedZone.$touch()"
                ></v-autocomplete>
                <v-btn outlined color="primary" :ripple="false" @click="addingNewZone = true">
                  <v-icon left>fas fa-plus</v-icon>
                  {{ $t("Add new zone") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              depressed
              class="next-button float-right"
              ref="next-button"
              :disabled="$v.cameraName.$invalid || $v.selectedZone.$invalid"
              @click="addChannel"
              >{{ $t("Add Device") }}</v-btn
            >
            <v-btn depressed @click="closeAddChannel">{{ $t("Close") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <add-zone :addingNewZone="addingNewZone" @added="newZoneAdded"></add-zone>
      <een-spinner :size="50" :isloading="spinner" class="show-spinner"></een-spinner>
      <camera-delete-dialog
        :camera="selectedCamera"
        :showDialog="deletingCamera"
        @closeDialog="deletingCamera = false"
        @deleted="cameraDeleted"
      ></camera-delete-dialog>
    </v-container>
  </v-container>
</template>

<script>
import CameraDeleteDialog from "@/components/Base/CameraDeleteDialog";
import AddZone from "@/components/Zones/AddZone";
import { required, maxLength } from "vuelidate/lib/validators";
import { store } from "@/store";
import { getMessageFromError } from "@eencloud/core-components/src/service/errors";

export default {
  name: "DeviceChannels",
  components: { CameraDeleteDialog, AddZone },
  props: ["device", "zones", "showToolbar", "toolbarColor", "menus", "desktop", "cameras", "status"],
  data() {
    return {
      isDirty: false,
      initValues: {},
      channels: null,
      spinner: false,
      deletingCamera: false,
      selectedCamera: null,
      key: 0,
      selectedZone: null,
      selectedChannel: null,
      addingNewZone: false,
      addCameraDialog: false,
      cameraName: "",
      deletingCamera: false,
      headers: [
        { text: "Snapshot" },
        { text: "Camera Name", value: "cameraName" },
        { text: "Channel", value: "channelId" },
        { text: "Zone", value: "zoneName" },
        { text: "", value: "expand" },
      ],
    };
  },
  mounted() {
    this.spinner = true;
    this.getChannelInfo();
  },

  computed: {
    cameraNameErrors() {
      const errors = [];
      if (!this.$v.cameraName.$dirty) return errors;
      if (!this.$v.cameraName.required) errors.push(this.$t("Camera name is a required field"));
      if (!this.$v.cameraName.maxLength) {
        errors.push(this.$t("Camera name should be max 32 characters"));
      }
      return errors;
    },

    online() {
      return this.status ? this.status.online : null;
    },

    selectedZoneErrors() {
      const errors = [];
      if (!this.$v.selectedZone.$dirty) return errors;
      if (!this.$v.selectedZone.required) errors.push(this.$t("Camera Zone is a required field"));
      return errors;
    },
  },

  validations() {
    return {
      cameraName: {
        required,
        maxLength: maxLength(32),
      },
      selectedZone: {
        required,
      },
    };
  },

  watch: {
    "cameras.length": function () {
      this.getChannelInfo();
    },
  },

  methods: {
    async getChannelInfo() {
      this.spinner = true;
      try {
        this.channels = await this.restapi.getChannels(this.$route.params.deviceId);
        this.channels.forEach((device, i) => {
          if (device.cameraId) this.getDeviceInfo(device, i);
          else {
            Object.assign(this.channels[i], { zoneName: "-" });
          }
          if (!device.cameraId) this.getSnapshot(device, i);
          this.spinner = false;
        });
      } catch (error) {
        console.log(error);
      }
    },
    getDeviceInfo(device, i) {
      this.zones.map(async (zone) => {
        if (zone.cameraIds.length && zone.cameraIds.includes(device.cameraId)) {
          Object.assign(this.channels[i], { zoneName: zone.name });
          let snapShot = await this.restapi.getSnapshot(device.cameraId);
          Object.assign(this.channels[i], { snapShot });
          this.key++;
        }
      });
      let camera = this.cameras.find((camera) => camera.cameraId == device.cameraId);
      Object.assign(this.channels[i], { camera });
      Object.assign(this.channels[i], { cameraName: camera.name });
      this.key++;
    },

    async getSnapshot(device, i) {
      try {
        Object.assign(this.channels[i], { cameraName: this.$t("No Camera Added") });
        let snapShot = await this.restapi.getDeviceSnapShot(this.$route.params.deviceId, device.channelId);
        snapShot = URL.createObjectURL(snapShot);
        // if (snapShot) {
        //   Object.assign(this.channels[i], { cameraName: "-" });
        // }
        Object.assign(this.channels[i], { snapShot });
        this.key++;
      } catch (error) {
        console.log(error);
      }
    },
    addNewChannel(item) {
      this.addCameraDialog = true;
      this.selectedChannel = item.channelId;
    },

    newZoneAdded(item) {
      this.selectedZone = item;
      this.addingNewZone = false;
    },

    closeAddChannel() {
      this.$v.$reset();
      this.cameraName = "";
      this.selectedZone = "";
      this.addCameraDialog = false;
    },

    deleteCamera(item) {
      this.selectedCamera = item.camera;
      this.deletingCamera = true;
    },

    cameraDeleted(cameraId) {
      this.getChannelInfo();
      this.$emit("deleted", cameraId);
      this.deletingCamera = false;
    },

    async addChannel() {
      let that = this;
      try {
        that.spinner = true;
        await this.restapi.addCameraToChannel(
          this.device.deviceId,
          this.selectedChannel,
          this.cameraName,
          this.selectedZone
        );
        that.addCameraDialog = false;
        that.spinner = false;
        that.$emit("loadCameras");
        that.getChannelInfo();
        this.closeAddChannel();
        store.dispatch("toastMessage", {
          showing: true,
          text: that.$t("Your channel was added successfully"),
          timeout: 4000,
          color: "primary",
        });
      } catch (error) {
        that.spinner = false;
        this.closeAddChannel();
        store.dispatch("toastMessage", {
          showing: true,
          text: getMessageFromError(error, "ADD_CHANNEL_AS_CAMERA"),
          timeout: -1,
          color: "error",
          support: true,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/settings";

.table-head {
  border-bottom-color: var(--v-primary-lighten1) !important;
  border-bottom-width: 2px !important;
  width: 23%;
}

.rounded-image {
  border-radius: $double-border-radius;
}

.show-spinner {
  z-index: 1000;
}
.heading-size {
  font-size: 16px;
}

.table-row {
  &:nth-child(even) {
    background-color: #f8f8f8;
  }
  &.expanded {
    background-color: #f5f5f5;
  }
  .table-cell {
    padding: 10px;
    vertical-align: middle;
    font-size: 0.9em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none !important;
    &:hover {
      cursor: pointer;
    }

    &.action-buttons {
      padding: 5px;
      &::v-deep .v-btn {
        height: 30px;
        width: 30px;
      }
    }
  }
}
</style>
