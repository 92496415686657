<template>
  <v-container fluid class="networks pa-0">
    <v-app-bar v-if="showToolbar" dense flat :color="toolbarColor || 'white'" class="title--text">
      <v-container v-if="false" class="my-2 align">
        <v-row class="xs8 offset-xs2">
          <v-spacer></v-spacer>
            <v-btn @click="cancelChanges">{{ $t("Cancel") }}</v-btn>
            <v-btn color="primary" @click="saveChanges">{{ $t("Save") }}</v-btn>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-container class="pa-0 align">
      <v-col cols="12" sm="10" offset-sm="1" class="pt-0">
        <p class="text-uppercase pl-0 mb-2 no-select" @dblclick.shift.exact="showCredentials = !showCredentials">
          {{ $t("Device IP address and port") }}
        </p>
        <v-card>
          <template v-if="showCredentials">
            <v-list rounded dense>
              <v-list-item flat>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0" cols="6">{{ $t("Username") }}</v-col>
                    <v-col class="py-0" cols="6">
                      <p class="text-right my-0">{{ connectionCredentials.username }}</p>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list rounded dense v-if="true">
              <v-list-item flat>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0" cols="6">{{ $t("Password") }}</v-col>
                    <v-col class="py-0" cols="6">
                      <p class="text-right my-0">{{ connectionCredentials.password }}</p>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list rounded dense>
              <v-list-item flat>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">{{ $t("Local IP address") }}</v-col>
                    <v-col class="py-0">
                      <p class="text-right my-0">{{ connectionDetails.localHost }}</p>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list rounded dense>
              <v-list-item flat>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">{{ $t("Local port") }}</v-col>
                    <v-col class="py-0">
                      <p class="text-right my-0">{{ connectionDetails.localPort }}</p>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
          </template>
          <v-list rounded dense>
            <v-list-item flat>
              <v-list-item-content>
                <v-row>
                  <v-col class="py-0">{{ $t("IP address") }}</v-col>
                  <v-col class="py-0">
                    <p class="text-right my-0">{{ connectionDetails.globalHost }}</p>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list rounded dense>
            <v-list-item flat>
              <v-list-item-content>
                <v-row>
                  <v-col class="py-0">{{ $t("Port") }}</v-col>
                  <v-col class="py-0">
                    <p class="text-right my-0">{{ connectionDetails.globalPort }}</p>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-container>
  </v-container>
</template>

<script>
import { getMessageFromError } from "@eencloud/core-components/src/service/errors";
export default {
  name: "Device Networks",
  props: ["device", "showToolbar", "toolbarColor"],
  data() {
    return {
      showCredentials: false,
      connectionDetails: null,
      connectionCredentials: null,
    };
  },
  async mounted() {
    try {
      this.connectionCredentials = await this.restapi.getDeviceConnectionCredentials(this.$route.params.deviceId);
    } catch (error) {
      store.dispatch("toastMessage", {
        showing: true,
        text: getMessageFromError(error, "GET_DEVICE_CONNECTION_CREDENTIALS"),
        timeout: -1,
        color: "error",
        support: true
      });
    }
    try {
      this.connectionDetails = await this.restapi.getDeviceConnectionDetails(this.$route.params.deviceId);
    } catch (error) {
      store.dispatch("toastMessage", {
        showing: true,
        text: getMessageFromError(error, "GET_DEVICE_CONNECTION_DETAILS"),
        timeout: -1,
        color: "error",
        support: true
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/settings";
</style>
