/* eslint-disable no-console */
const state = {
    cameraId: null,
    device_brand: null,
    device_model: null,
    device_MAC_address: null,
    device_serial: null,
    direct_access: false,
  };
  
  const getters = {
    cameraId: state => {
      return state.cameraId;
    },
    device_brand: state => {
      return state.device_brand;
    },
    device_model: state => {
      return state.device_model;
    },
    device_MAC_address: state => {
      return state.device_MAC_address;
    },
    device_serial: state => {
      return state.device_serial;
    },
    direct_access: state => {
      return state.direct_access;
    }
  };
  
  const mutations = {
    SET_CAMERA_ID: (state, id) => {
      state.cameraId = id;
    },
    SET_DEVICE_BRAND: (state, brand) => {
      state.device_brand = brand;
    },
    SET_DEVICE_MODEL: (state, model) => {
      state.device_model = model;
    },
    SET_DEVICE_MAC_ADDRESS: (state, mac) => {
      state.device_MAC_address = mac;
    },
    SET_DEVICE_SERIAL: (state, serial) => {
      state.device_serial = serial;
    },
    SET_PASSWORD_ACCESS: (state, passwordAccess) => {
      state.direct_access = passwordAccess;
    }
  };
  
  const actions = {
    setCameraId({ commit }, data) {
      commit("SET_CAMERA_ID", data);
    },
    setDeviceBrand({ commit }, data) {
      commit("SET_DEVICE_BRAND", data);
    },
    setDeviceModel({ commit }, data) {
      commit("SET_DEVICE_MODEL", data);
    },
    setDeviceMAC({ commit }, data) {
      commit("SET_DEVICE_MAC_ADDRESS", data);
    },
    setPasswordAccess({ commit }, data) {
      commit("SET_PASSWORD_ACCESS", data);
    },
    setDeviceSerial({ commit }, data) {
      commit("SET_DEVICE_SERIAL", data);
    }
  };
  
  export default {
    state,
    getters,
    mutations,
    actions
  };
  