<template>
  <v-dialog v-model="showDialog" persistent width="500">
    <v-card
      :height="$vuetify.breakpoint.xsOnly ? 280 : directAccess ? 140 : 275"
      min-height="180"
    >
      <v-card-title primary-title> </v-card-title>
      <v-card-text>
        {{
          $t(
            "Are you sure you want to remove this device from your account? This will permanently remove all your recordings related to this device."
          )
        }}
        <p v-if="!directAccess" class="font-weight-bold pt-2">
          {{ $t("Please enter your password to remove the device.") }}
        </p>
        <v-text-field
          v-if="!directAccess"
          type="password"
          v-model="userPassword"
          class="pt-2"
          :placeholder="$t('Password')"
          :error-messages="userPasswordErrors"
          @input="$v.userPassword.$touch()"
          @blur="$v.userPassword.$touch()"
          @keyup.enter="removeCamera()"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          color="primary"
          :disabled="directAccess ? false : $v.userPassword.$invalid"
          @click="removeCamera()"
          >{{ $t("Confirm") }}</v-btn
        >
        <v-btn depressed @click.stop="closeDialog">{{ $t("Close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { store } from "@/store";
import { getMessageFromError } from "@eencloud/core-components/src/service/errors";

export default {
  props: ["camera", "showDialog", "device"],
  data() {
    return {
      userPassword: "",
      deleteCamera: "",
    };
  },
  computed: {
    directAccess() {
      if (this.camera)
        return store.getters.direct_access ? store.getters.direct_access : false;
      if (this.device) return false;
    },
    cameraId() {
      return this.camera ? this.camera.cameraId : null;
    },
    deviceId() {
      return this.device ? this.device.deviceId : null;
    },
    userPasswordErrors() {
      const errors = [];
      if (!this.$v.userPassword.$dirty) return errors;
      if (!this.$v.userPassword.required) errors.push(this.$t("Password is required"));
      return errors;
    },
  },
  methods: {
    async removeCamera() {
      (this.deleteCamera = this.$t(
        "Your device has been removed and will be reset now. Please wait for 2 minutes before re-adding."
      ));
      if (this.camera) {
        if (!this.directAccess) {
          try {
            await this.restapi.deleteCamera(this.cameraId, this.userPassword);
            store.dispatch("toastMessage", {
              showing: true,
              text: this.deleteCamera,
              timeout: -1,
              color: "primary",
            });
            this.$emit("deleted", this.cameraId);
          } catch (error) {
            console.error(error);
            store.dispatch("toastMessage", {
              showing: true,
              text: getMessageFromError(error, "DELETE_CAMERA"),
              timeout: -1,
              color: "error",
              support: true,
            });
          }
        } else {
          try {
            const result = await this.restapi.deleteCameraWithoutPassword(this.cameraId);
            store.dispatch("toastMessage", {
              showing: true,
              text: this.deleteCamera,
              timeout: -1,
              color: "primary",
            });
            this.$emit("deleted", this.cameraId);
          } catch (error) {
            console.error(error);
            store.dispatch("toastMessage", {
              showing: true,
              text: getMessageFromError(error, "DELETE_CAMERA_WITHOUT_PASSWORD"),
              timeout: -1,
              color: "error",
              support: true,
            });
          }
        }
      }
      if (this.device) {
        try {
          await this.restapi.deleteDevice(this.device.deviceId, this.userPassword);
          store.dispatch("toastMessage", {
            showing: true,
            text: this.deleteCamera,
            timeout: -1,
            color: "primary",
          });
          this.$emit("deleted", this.deviceId);
        } catch (error) {
          store.dispatch("toastMessage", {
            showing: true,
            text: getMessageFromError(error, "DELETE_DEVICE"),
            timeout: -1,
            color: "error",
            support: true,
          });
        }
      }
    },
    closeDialog() {
      this.$emit("closeDialog");
      this.userPassword = "";
      this.$v.$reset();
    },
  },
  validations: {
    userPassword: {
      required,
    },
  },
};
</script>

<style lang="scss" scoped></style>
