<template>
  <v-card v-if="zone" class="mt-2">
    <v-card-title class="body-2 text-uppercase py-1 ">
      {{ zone.name }}
      <v-spacer></v-spacer>
      <v-menu left offset-y>
        <template v-slot:activator="{ on }">
          <v-btn 
            v-on="on" icon 
            :disabled="rights && !rights.demoUser && !(rights.editZone || rights.addCameraManually || rights.deleteZone)"
            :ripple="false"
          >
            <v-icon size="16" color="#808080">fas fa-ellipsis-v</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="rights && (rights.addCameraManually || rights.demoUser)" @click="addCamera">
            <v-list-item-content>
              <v-list-item-title>{{ $t('Add Device') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
              <v-list-item v-if="rights && (rights.editZone || rights.demoUser)" @click="setZoneName=true">
            <v-list-item-content>
              <v-list-item-title @click="focusOnInput">{{ $t('Edit zone name') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="rights && (rights.deleteZone || rights.demoUser)"
            @click="hasCameras ? 
              $store.dispatch('toastMessage', {
                showing: true,
                text: $t( `Only empty zones can be removed. Move your cameras to another zone or remove your cameras.`),
                timeout: -1,
                color: 'error',
              })
           : confirmRemoveZone = true"
          >
            <v-list-item-content>
              <v-list-item-title>{{ $t('Remove zone') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-divider class="mb-2"></v-divider>
    <v-card-text class="pa-0">
      <v-list v-for="camera in zoneCameras" :key="camera.cameraId" dense rounded class="px-0 pt-0 py-2">
        <v-list-item :class="{'primary--text': isSelectedCamera(camera)}">
          <v-icon
            v-if="camera.status && camera.status.recordingOnCloud"
            class="recording-icon"
            size="10"
          >fa-circle</v-icon>
          <camera-image
            :camera="camera"
            width="60"
            max-width="60"
            height="40"
            max-height="40"
            aspect-ratio="16/9"
          ></camera-image>
          <p class="subheading mb-0">{{ camera.name }}</p>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="goToLive(camera.cameraId)"
              >
                <v-icon size="18" color="primary">fas fa-play-circle</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("Live") }}</span>
          </v-tooltip>
          <div v-if="rights && (rights.playback || rights.demoUser)" class="vl"></div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="goToFootage(camera.cameraId)"
                v-if="rights && (rights.playback || rights.demoUser)"
              >
                <v-icon size="18" color="primary">fas fa-history</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("Footage") }}</span>
          </v-tooltip> 
          <div v-if="rights && (rights.editCamera || rights.demoUser)" class="vl"></div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="rights && (rights.editCamera || rights.demoUser)"
                v-bind="attrs"
                v-on="on"
                icon
                @click="$router.push({ path: `/settings/cameras/${camera.cameraId}` })"
              >
                <v-icon size="18" color="primary">fas fa-cog</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("Settings") }}</span>
          </v-tooltip>
        </v-list-item>
      </v-list>
    </v-card-text>

    <v-dialog v-model="setZoneName"  max-width="400">
      <v-card>
        <v-card-title primary-title>
          <p class="title unset-title-color">{{ $t('Edit zone') }}</p>
        </v-card-title>
        <v-card-text>
          <v-text-field
             v-model="zoneName"
             ref="editedtext"
            :label="$t('Zone name')"
            :error-messages="zoneNameErrors"
            @input="$v.zoneName.$touch()"
            @blur="$v.zoneName.$touch()"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="primary"
            :disabled="$v.selctedZone.$invalid"
            @click="changeZoneName"
          >{{ $t('Save') }}</v-btn>
          <v-btn depressed @click.stop="zoneEdited">{{ $t('Cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmRemoveZone" max-width="400">
      <v-card>
        <v-card-title primary-title>
          <p class="title unset-title-color">{{ $t('Are you sure you want to remove this zone?') }}</p>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            depressed color="primary" 
            @click="removeZone"
            >{{ $t('Confirm') }}
          </v-btn>
          <v-btn depressed @click.stop="confirmRemoveZone = false">{{ $t('Cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import CameraImage from "@/components/Base/CameraImage";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  name: "ZoneCameras",
  components: {
    CameraImage,
  },
  props: ["selected", "zone", "cameras", "camera", "rights"],
  data() {
    return {
      showCameras: true,
      setZoneName: false,
      confirmRemoveZone: false,
      selctedZone: null
    };
  },
  computed: {
    zoneName: {
      get() {
        return this.zone ? this.zone.name : null;
      },
      set: function (value) {
        this.selctedZone = value;
      },
    },
    zoneCameras() {
      return this.zone
        ? this.cameras.filter((c) => {
          return c.zoneId === this.zone.zoneId;
          })
        : null;
    },
    hasCameras() {
      return Array.isArray(this.zoneCameras)
        ? this.zoneCameras.length > 0
        : null;
    },
    isSelected: {
      get: function () {
        return this.selected;
      },
      set: function (value) {
        this.$emit("selectZone", this.zone.zoneId);
      },
    },
    isContinousRecording() {
      return this.camera && this.camera.cameraMode
        ? this.camera.cameraMode.recordingMode === "continuous"
        : null;
    },
    zoneNameErrors() {
      const errors = [];
      if (!this.$v.zoneName.$dirty) return errors;
      if (!this.$v.zoneName.required)
        errors.push(this.$t("Zone name is required"));
      if (!this.$v.zoneName.minLength || !this.$v.zoneName.maxLength) {
        errors.push(
          this.$t("Zone name should be X-Y characters", {
            min: this.$v.zoneName.$params.minLength.min,
            max: this.$v.zoneName.$params.maxLength.max,
          })
        );
      }
      return errors;
    },
  },
  watch: {
    confirmRemoveZone:
    function () {
      if (this.confirmRemoveZone === true) {
        window.addEventListener('keydown', this.confirmWithEnterKey)
      }
      if (this.confirmRemoveZone === false) {
      window.removeEventListener('keydown', this.confirmWithEnterKey)
      }
    }
  },
  methods: {
    goToLive(id) {
      this.$store.dispatch("selectedCamera");
      this.$cookies.set("grid",1)
      this.$router.push({ name: 'Live', params: { cameraId: id } });
    },
    goToFootage(id) {
      this.$store.dispatch("selectedCamera");
      this.$router.push({ name: 'Footage', params: { cameraId: id } });
    },
    selectCamera(id) {
      this.$emit("selectCamera", id);
    },
    addCamera() {
      this.$router.push({ name: "AddCamera", params:{ zoneId: this.zone.zoneId} });
    },
    isSelectedCamera(camera) {
      return this.camera && this.camera.cameraId === camera.cameraId;
    },
    changeZoneName() {
      if (this.rights?.demoUser) {
        this.$store.dispatch("demoMessage")
      } else {
        const that = this;
        this.restapi
          .setZoneName(this.zone.zoneId, this.selctedZone)
          .then(function () {
            that.$emit("editedZone")
          })
          .catch(function (error) {
            console.error(error);
          })
          .then(function () {
            that.setZoneName = false;
          });
      }
    },
    zoneEdited(){
     this.setZoneName=false
     this.$refs.editedtext.lazyValue = this.$refs.texter.initialValue
    },
    removeZone() {
      if (this.rights?.demoUser) {
        this.$store.dispatch("demoMessage")
      } else {
        const that = this;
        this.restapi
          .deleteZone(this.zone.zoneId)
          .then(function () {
            console.log("Deleted");
            that.$emit("deleted");
          })
          .catch(function (error) {
            console.error(error);
          })
          .then(function () {
            that.confirmRemoveZone = false;
          });
      }
    },
    confirmWithEnterKey(event) {
      if (event.key == "Enter") {
        this.removeZone()
      }
    },
    focusOnInput() {
      // auto focus
      setTimeout(() => {
        this.$refs.editedtext.focus()
      }, 200)
    }
  },
  validations: {
    zoneName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(32),
    },
    selctedZone: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(32),
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main";

label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.truncate {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
>>> .v-input--selection-controls {
  margin-top: 0;
}
.rounded-image {
  border-radius: $double-border-radius;
}
.recording-icon {
  position: absolute;
  top: 8px;
  left: 18px;
  z-index: 1;
  color: red;
}
.offline-camera-wrapper {
  width: 60px;
  height: 40px;
  background-color: #959595;
  .offline-camera {
    width: 100%;
    height: 100%;
    background-color: white;
    mask: url("~@eencloud/core-components/src/assets/images/videocam_off.svg") no-repeat;
    position: absolute;
    left: 34px;
    top: 12px;
  }
}
.vl {
  border-left: 2px solid #dcdcdc;
  height: 18px;
  border-radius: 37px;
  position: relative;
  margin-right: 10px;
  margin-left: 10px;
}
</style>
