<template>
  <v-container fluid class="networks pa-0">
    <v-app-bar v-if="showToolbar" dense flat :app="isAppToolbar" :color="toolbarColor || 'white'" class="title--text">
      <v-container v-if="isDirty" class="my-2 align">
        <v-row class="xs8 offset-xs2">
          <v-spacer></v-spacer>
            <v-btn  @click="cancelChanges">{{ $t("Cancel") }}</v-btn>
            <v-btn class="ml-2" color="primary" @click="saveChanges">{{ $t("Save") }}</v-btn>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-progress-linear v-if="loading" :indeterminate="true" height="2" class="my-0 py-0"></v-progress-linear>
    <v-container v-if="camera" class="pa-0 align">
      <v-col sm="10" offset-sm="1" class="py-0">
        <v-row>
          <v-col cols="10" class="hidden-sm-and-up">
            <v-btn text class="pa-0" @click="$emit('allCameras')">
              <v-icon size="18" left>
                fa-arrow-left
              </v-icon>
              <p class="mb-0">{{ $t("Networks") }}</p>
            </v-btn>
          </v-col>
          <v-col cols="2" class="hidden-sm-and-up">
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" dark icon>
                   <v-icon size="18" color="black">
                    fa-ellipsis-v
                  </v-icon>
                </v-btn>
              </template>
              <v-list rounded dense class="px-4">
                <v-list-item v-for="(menu, i) in menus" :key="i" @click="$router.push({ path: `/settings/cameras/${camera.cameraId}/${menu.endpoint}` })">
                  <v-list-item-title>{{ menu.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="!connectionDetails && !wifiStatus && !mobileNetwork" cols="12" sm="10" offset-sm="1" class="pt-0">
        <p>{{ $t("There is no information to be shown here at this moment.") }}</p>
      </v-col>
      <v-col v-if="manualConnected" cols="12" sm="10" offset-sm="1" class="pt-0">
        <p class="text-uppercase pl-0 mb-2 no-select" @dblclick.shift.exact="showCredentials = !showCredentials">
          {{ $t("Manual camera settings") }}
        </p>
        <v-card>
          <template
            v-if="
              !isNuboCam &&
              ((cameraCredentials && cameraCredentials.cloudManagedCredentials === false) || showCredentials)
            "
          >
            <v-list  rounded dense>
              <v-list-item flat>
                <v-list-item-content>
                  <v-row>
                    <v-col
                      class="my-2 py-0"
                      cols="6"
                      :my-2="!!cloudManagedCredentials"
                      :my-3="!cloudManagedCredentials"
                      >{{ $t("Username") }}</v-col
                    >
                    <v-col v-if="!!cloudManagedCredentials" class="my-2 py-0" cols="6">
                      <p class="text-right my-0">{{ username }}</p>
                    </v-col>
                    <v-col v-else class="py-0" cols="6">
                      <v-text-field v-model="username" hide-details solo flat dense></v-text-field>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list dense rounded v-if="true">
              <v-list-item flat>
                <v-list-item-content>
                  <v-row>
                    <v-col
                      class="my-2 py-0"
                      cols="6"
                      :my-2="!!cloudManagedCredentials"
                      :my-3="!cloudManagedCredentials"
                      >{{ $t("Password") }}</v-col
                    >
                    <v-col v-if="!!cloudManagedCredentials" class="my-2 py-0" cols="6">
                      <p class="text-right my-0">{{ password }}</p>
                    </v-col>
                    <v-col v-else class="py-0" cols="6">
                      <v-text-field
                        v-model="password"
                        :label="$t('Password')"
                        v-if="password"
                        :readonly="!!cloudManagedCredentials"
                        :append-icon="showCameraPassword ? 'far fa-eye' : 'far fa-eye-slash'"
                        :type="showCameraPassword ? 'text' : 'password'"
                        solo
                        flat
                        dense
                        hide-details
                        @click:append="showCameraPassword = !showCameraPassword"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <template v-if="cloudManagedCredentials === false">
              <v-divider></v-divider>
              <v-list rounded dense>
                <v-list-item flat>
                  <v-container class="py-0" fluid>
                    <v-row>
                      <v-col cols="12" class="text-right">
                        <v-btn
                          text
                          small
                          color="primary"
                          :disabled="!cameraCredentialsChanged"
                          @click="saveCredentials"
                          >{{ $t("Save credentials") }}</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-list-item>
              </v-list>
            </template>
            <v-divider></v-divider>
          </template>
          <v-list rounded dense>
            <v-list-item flat>
              <v-list-item-content>
                <v-row>
                  <v-col class="my-2 py-0" cols="6">{{ $t("Local IP address") }}</v-col>
                  <v-col class="py-0" cols="6">
                    <v-text-field
                      v-model="connectionDetails.localHost"
                      solo
                      dense
                      flat
                      hide-details
                      background-color="shadow"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list rounded dense>
            <v-list-item flat>
              <v-list-item-content>
                <v-row>
                  <v-col class="my-2 py-0" cols="6">{{ $t("Local port") }}</v-col>
                  <v-col class="py-0" cols="6">
                    <v-text-field
                      v-model="connectionDetails.localPort"
                      solo
                      dense
                      flat
                      hide-details
                      background-color="shadow"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list rounded dense>
            <v-list-item flat>
              <v-list-item-content>
                <v-row>
                  <v-col class="my-2 py-0" cols="6">{{ $t("Global IP address") }}</v-col>
                  <v-col class="py-0" cols="6">
                    <v-text-field
                      v-model="connectionDetails.globalHost"
                      solo
                      dense
                      flat
                      hide-details
                      background-color="shadow"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list rounded dense>
            <v-list-item flat>
              <v-list-item-content>
                <v-row>
                  <v-col class="my-2 py-0" cols="6">{{ $t("Global port") }}</v-col>
                  <v-col class="py-0" cols="6">
                    <v-text-field
                      v-model="connectionDetails.globalPort"
                      solo
                      dense
                      flat
                      hide-details
                      background-color="shadow"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list  rounded dense>
            <v-list-item flat>
              <v-list-item-content>
                <v-row>
                  <v-col class="my-2 py-0" cols="11">{{ $t("Enable HTTPS") }}</v-col>
                  <v-col class="align-end py-0" cols="1" :pl-4="$vuetify.breakpoint.mdAndUp">
                    <v-switch
                      v-model="connectionDetails.globalSSL"
                      color="accent"
                      hide-details
                      dense
                      class="my-0"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <v-col v-else-if="!isNuboCam && connectionDetails" cols="12" sm="10" offset-sm="1" class="pt-0">
        <p class="text-uppercase pl-0 mb-2 no-select" @dblclick.shift.exact="showCredentials = !showCredentials">
          {{ $t("Camera IP address and port") }}
        </p>
        <v-card>
          <template
            v-if="
              !isNuboCam &&
              ((cameraCredentials && cameraCredentials.cloudManagedCredentials === false) || showCredentials)
            "
          >
            <v-list rounded dense>
              <v-list-item flat>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0" cols="6" :my-2="!!cloudManagedCredentials" :my-3="!cloudManagedCredentials">{{
                      $t("Username")
                    }}</v-col>
                    <v-col v-if="!!cloudManagedCredentials" class="py-0" cols="6">
                      <p class="text-right my-0">{{ username }}</p>
                    </v-col>
                    <v-col v-else class="py-0" cols="6">
                      <v-text-field v-model="username" dense flat hide-details class="text-right my-0"></v-text-field>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list rounded dense v-if="true">
              <v-list-item flat>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0" cols="6" :my-2="!!cloudManagedCredentials" :my-3="!cloudManagedCredentials">{{
                      $t("Password")
                    }}</v-col>
                    <v-col v-if="!!cloudManagedCredentials" class="py-0" cols="6">
                      <p class="text-right my-0">{{ password }}</p>
                    </v-col>
                    <v-col v-else class="py-0" cols="6">
                      <v-text-field
                        v-model="password"
                        :label="$t('Password')"
                        v-if="password"
                        :readonly="!!cloudManagedCredentials"
                        :append-icon="showCameraPassword ? 'far fa-eye' : 'far fa-eye-slash'"
                        :type="showCameraPassword ? 'text' : 'password'"
                        flat
                        dense
                        hide-details
                        class="text-right my-0"
                        @click:append="showCameraPassword = !showCameraPassword"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <template v-if="cloudManagedCredentials === false">
              <v-divider></v-divider>
              <v-list rounded dense>
                <v-list-item flat>
                  <v-container class="py-0" fluid>
                    <v-row>
                      <v-col cols="12" class="text-right">
                        <v-btn text color="primary" :disabled="!cameraCredentialsChanged" @click="saveCredentials">{{
                          $t("Save credentials")
                        }}</v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-list-item>
              </v-list>
            </template>
            <v-divider></v-divider>
            <v-list rounded dense>
              <v-list-item flat>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">{{ $t("Local IP address") }}</v-col>
                    <v-col class="py-0">
                      <p class="text-right my-0">{{ connectionDetails.localHost }}</p>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list rounded dense>
              <v-list-item flat>
                <v-list-item-content>
                  <v-row>
                    <v-col class="py-0">{{ $t("Local port") }}</v-col>
                    <v-col class="py-0">
                      <p class="text-right my-0">{{ connectionDetails.localPort }}</p>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
          </template>
          <v-list rounded dense>
            <v-list-item flat>
              <v-list-item-content>
                <v-row>
                  <v-col class="py-0">{{ $t("IP address") }}</v-col>
                  <v-col class="py-0">
                    <p class="text-right my-0">{{ connectionDetails.globalHost }}</p>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list rounded dense>
            <v-list-item flat>
              <v-list-item-content>
                <v-row>
                  <v-col class="py-0">{{ $t("Port") }}</v-col>
                  <v-col class="py-0">
                    <p class="text-right my-0">{{ connectionDetails.globalPort }}</p>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <v-col v-if="modemEnabled === true" class="pt-0" cols="12" sm="10" offset-sm="1">
        <p class="text-uppercase pl-0 mb-2">{{ $t("Mobile network") }}</p>
        <v-card>
          <v-list rounded dense>
            <v-list-item>
              <v-list-item-content v-if="mobileModem.state === 'connected'">
                <v-row>
                  <v-col class="my-2 py-0" cols="10">{{ mobileNetwork.network }}</v-col>
                  <v-col class="my-2 py-0" cols="2">
                    <p class="text-right my-0">
                      <img class="icon mr-3" :src="mobileNetworkImage(mobileNetwork)" />
                    </p>
                  </v-col>
                </v-row>
              </v-list-item-content>
              <v-list-item-title
                v-else-if="mobileModem.state === 'no sim card' || mobileNetwork.state === 'no_sim_card'"
                class="pl-6"
              >
                <p>{{ $t("No SIM inserted") }}</p>
              </v-list-item-title>
              <v-list-item-title v-else class="pl-6">
                <p>{{ $t("Not connected") }}</p>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <v-col v-if="wifiStatus" class="pt-0" cols="12" sm="10" offset-sm="1">
        <p class="text-uppercase pl-0 mb-2">{{ $t("Current Wi-Fi") }}</p>
        <v-card>
          <template v-if="canDisableWifi && allowDisableWifi">
            <v-list rounded dense>
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <v-col class="my-2 py-0" cols="11">{{ $t("Status") }}</v-col>
                    <v-col class="align-end align-content-end py-0" cols="1" :pl-4="$vuetify.breakpoint.mdAndUp">
                      <v-switch
                        hide-details
                        dense
                        v-model="wifiEnabled"
                        color="primary"
                        class="my-0"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
          </template>
          <v-list rounded dense>
            <v-list-item flat>
              <v-list-item-content v-if="isWifiConnected" class="px-0">
                <v-row>
                  <v-col class="my-2 py-0" cols="8">{{ wifiStatus.ssid }}</v-col>
                  <v-col class="py-0" cols="4">
                    <p class="wifi-row-buttons d-flex align-center justify-end my-0">
                      <v-icon class="mr-2 pt-1" small v-if="wifiStatus.strength && wifiStatus.encrypted">fa-lock</v-icon>
                      <img class="icon mr-3" :src="wifiImageForStrength(wifiStatus.strength)" v-if="wifiStatus.strength" />
                      <v-icon style="width: 40px;" v-if="false">{{ wifiIconForStrength(wifiStatus.strength) }}</v-icon>
                      <v-icon v-if="false && !wifiStatus.strength" small>fa-times-circle</v-icon>
                      <v-menu :nudge-width="100" content-class="px-2 white" v-if="rights && !rights.demoUser && canForgetWifi">
                        <template v-slot:activator="{ on }">
                          <v-btn icon v-on="on">
                            <v-icon size="18">fa-ellipsis-v</v-icon>
                          </v-btn>
                        </template>
                        <v-list rounded dense>
                          <v-list-item @click="cannotForgetWiFiWarning = true">
                            <v-list-item-title>{{ $t("Forget") }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </p>
                  </v-col>
                </v-row>
              </v-list-item-content>
              <v-list-item-title v-else class="caption">{{ $t("Not connected") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col  
        class="pt-0"
        cols="12"
        sm="10"
        offset-sm="1" >
       <v-progress-linear
          v-if="job"
          color="primary"
          indeterminate
          reverse
       ></v-progress-linear>
      </v-col>
      <v-col
        v-if="hasWifiCapability && isWifiEnabled && canScanForAvailableWifi"
        class="pt-0"
        cols="12"
        sm="10"
        offset-sm="1"
      >
        <p class="text-uppercase pl-0 mb-2">
          {{ $t("Choose Wi-Fi") }}
          <een-spinner 
            :isloading="!!loadingWifi"
            :size="20"
            :width="2"
          ></een-spinner>
        </p>
        <v-card>
          <v-list rounded dense v-if="!hasWifiNetworks">
            <v-list-item flat>
              <v-list-item-title>
                <p class="pt-4">{{ $t(noWiFiNetworksMessage) }}</p>
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <template v-for="(wifi, index) in selectableWifiNetworks">
            <v-list rounded dense :key="wifi.ssid" class="pa-0">
              <v-list-item flat>
                <v-list-item-content class="pa-0">
                  <v-row>
                    <v-col cols="8" class="pt-3 py-0">{{ wifi.ssid }}</v-col>
                    <v-col cols="4" class="py-0">
                      <p class="wifi-row-buttons d-flex align-center justify-end my-0">
                        <v-icon class="mr-2 pt-1" small v-if="wifi.strength && wifi.encrypted">fa-lock</v-icon>
                        <img class="icon mr-3" :src="wifiImageForStrength(wifi.strength)" v-if="wifi.strength" />
                        <v-icon style="width: 40px;" v-if="false">{{ wifiIconForStrength(wifi.strength) }}</v-icon>
                        <v-menu :nudge-width="100" content-class="px-2 white" v-if="rights && !rights.demoUser">
                          <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on">
                              <v-icon size="18">fa-ellipsis-v</v-icon>
                            </v-btn>
                          </template>
                          <v-list rounded dense>
                            <v-list-item @click="connectWifi(wifi.ssid)">
                              <v-list-item-title>{{ $t("Connect") }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                          <template v-if="isSavedWifi(wifi.ssid) && canForgetWifi">
                            <v-divider></v-divider>
                            <v-list rounded dense>
                              <v-list-item @click="forgetWifi(wifi.ssid)">
                                <v-list-item-title>{{ $t("Forget") }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </template>
                        </v-menu>
                      </p>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider v-if="index < selectableWifiNetworks.length" :key="index"></v-divider>
          </template>
        </v-card>
      </v-col>

      <v-col v-if="camera && !online" class="pt-0" cols="12" sm="10" offset-sm="1">{{
        $t("This camera is offline and therefore the network settings cannot be shown or changed.")
      }}</v-col>
    </v-container>

    <v-dialog v-model="closeWifiConnect" hide-overlay width="500" height="300">
      <v-card>
        <v-card-text>
          <v-container class="fill-height">
            <v-row align="center">
             
              <v-col cols="12" class="text-center ">
                 <span class="job-text">{{ jobText }}</span>
                 <div>{{$t("Switching Wi-Fi, it might take a few minutes")}}</div> 
              </v-col>
              <!-- <div cols="12" class="text-center ">
                <een-spinner :size="50" :isloading="true"></een-spinner>
              </div> -->
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click="closeWifiDialog = false">{{ $t("Close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="jobFailed" hide-overlay width="500">
      <v-card>
        <v-card-title class="headline">{{ jobText }} {{ $t("failed") }}</v-card-title>
        <v-card-text>{{
          $t("There was a problem performing this request. Please try again or contact support.")
        }}</v-card-text>
        <v-card-actions>
          <v-btn depressed color="error" @click="jobFailed = false">{{ $t("Got it") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="cannotForgetWiFiWarning" hide-overlay persistent max-width="500">
      <v-card>
        <v-card-title class="headline">{{ $t("Warning") }}</v-card-title>
        <v-card-text>{{ $t("You cannot forget your current Wi-Fi network.") }}</v-card-text>
        <v-card-actions>
          <v-btn depressed color="primary" @click="cannotForgetWiFiWarning = false">{{ $t("Got it") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="disableWiFiWarning" hide-overlay persistent max-width="500">
      <v-card>
        <v-card-title class="headline">{{ $t("Warning") }}</v-card-title>
        <v-card-text>
          {{
            $t(
              "Your camera is connected to a 4G network, keep in mind that continuous recording and live " +
                "viewing on 4G consumes a lot of bandwidth. Before continuing, ensure your sim card is setup correctly."
            )
          }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" @click="acceptWiFiWarning">{{ $t("Got it") }}</v-btn>
          <v-btn depressed @click="cancelWifiWarning">{{ $t("Cancel") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="outOfRangeWarning" hide-overlay persistent max-width="500">
      <v-card>
        <v-card-title class="headline">{{ $t("Warning") }}</v-card-title>
        <v-card-text>{{ $t("Cannot connect to this Wi-Fi network because it is not visible.") }}</v-card-text>
        <v-card-actions>
          <v-btn depressed color="primary" @click="outOfRangeWarning = false">{{ $t("Got it") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="setWifiPassword" max-width="500px">
      <v-card>
        <v-card-title>{{ $t("Enter password for") }} '{{ this.selectedSsid }}'</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="wifiPassword"
            :append-icon="showWifiPassword ? 'far fa-eye' : 'far fa-eye-slash'"
            :type="showWifiPassword ? 'text' : 'password'"
            name="wifi-password"
            :label="$t('Enter password')"
            @click:append="showWifiPassword = !showWifiPassword"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" flat @click="acceptWifiPassword">{{ $t("Connect") }}</v-btn>
          <v-btn depressed @click="setWifiPassword = false">{{ $t("Cancel") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmForgetWifi" max-width="500px">
      <v-card>
        <v-card-text>{{ $t("Are you sure you want to forget this Wi-Fi network?") }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" flat @click="forgetWifiConfirmed">{{ $t("Confirm") }}</v-btn>
          <v-btn depressed @click="confirmForgetWifi = false">{{ $t("Cancel") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="cameraCredentialsInvalid" hide-overlay width="500">
      <v-card>
        <v-card-title primary-title class="headline error white--text">{{ $t("Wrong credentials") }}</v-card-title>
        <v-card-text>{{ $t("The given credentials are not valid, so they were not updated.") }}</v-card-text>
        <v-card-actions>
          <v-btn depressed color="error" @click="cameraCredentialsInvalid = false">{{ $t("Got it") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import auth from "@/service/auth";
import StompClient from "@/service/StompClient";
import { store } from "@/store";
import warningForCameraSettingsChangesMixin from '@eencloud/core-components/src/service/warningForCameraSettingsChangesMixin';

const hash = require("object-hash");
const strengths = { high: 3, medium: 2, low: 1 };

export default {
  name: "Networks",
  props: ["camera", "rights", "showToolbar", "appToolbar", "toolbarColor", "menus", "desktop"],
  mixins: [warningForCameraSettingsChangesMixin],
  data() {
    return {
      isDirty: false,
      initValues: {},
      noWiFiNetworksMessage: "No Wi-Fi networks loaded yet",
      connectionDetails: null,
      loading: false,
      job: null,
      jobStatus: null, // Created -> Ended
      jobProgress: null,
      jobWifiStatus: null, // Initializing -> Uploading (with progress) -> Upgrading -> Restarting -> Success -> Ended
      stompClient: null,
      messages: [],
      wifiStatus: null,
      switchingWifiStatus: false,
      wifiNetworks: [],
      loadingWifi: 0,
      wifiEnabled: false,
      mobileNetwork: null,
      mobileModem: null,
      selectedSsid: null,
      wifiPassword: null,
      showWifiPassword: false,
      showLoader: false,
      jobText: null,
      connectionImages: {
        "3g-1": this.getImgUrl("3g-1.png"),
        "3g-2": this.getImgUrl("3g-2.png"),
        "3g-3": this.getImgUrl("3g-3.png"),
        "4g-1": this.getImgUrl("4g-1.png"),
        "4g-2": this.getImgUrl("4g-2.png"),
        "4g-3": this.getImgUrl("4g-3.png"),
        "wifi-1": this.getImgUrl("wifi-1.png"),
        "wifi-2": this.getImgUrl("wifi-2.png"),
        "wifi-3": this.getImgUrl("wifi-3.png"),
      },
      jobFailed: false,
      selectingWifiNetwork: false,
      wifiInterval: null,
      disableWiFiWarning: false,
      acceptedWiFiWarning: false,
      cannotForgetWiFiWarning: false,
      outOfRangeWarning: false,
      setWifiPassword: false,
      confirmForgetWifi: false,
      wifiCapabilities: null,
      cameraCredentials: null,
      cameraCredentialsHash: null,
      cameraCredentialsInvalid: false,
      showCredentials: false,
      showCameraPassword: false,
      iswifiOn: true,
      closeWifiDialog: false
    };
  },
  computed: {
    closeWifiConnect(){
      return this.job && this.closeWifiDialog
    },
    isAppToolbar() {
      if (this.appToolbar === undefined || this.appToolbar === null) return true;
      return !!this.appToolbar;
    },
    cameraId() {
      return this.camera ? this.camera.cameraId : null;
    },
    online() {
      return this.status ? this.status.online : null;
    },
    status() {
      return this.desktop ? this.desktop.status : this.camera ? this.camera.status : null;
    },
    cameraCapabilities() {
      return this.desktop ? this.desktop.cameraCapabilities : this.camera ? this.camera.cameraCapabilities : null;
    },
    manualConnected() {
      return this.connectionDetails ? this.connectionDetails.connectionType === "manual" : null;
    },
    username: {
      get: function () {
        return this.cameraCredentials ? this.cameraCredentials.username : null;
      },
      set: function (value) {
        if (!this.cloudManagedCredentials) {
          this.cameraCredentials.username = value;
        }
      },
    },
    password: {
      get: function () {
        return this.cameraCredentials ? this.cameraCredentials.password : null;
      },
      set: function (value) {
        if (!this.cloudManagedCredentials) {
          this.cameraCredentials.password = value;
        }
      },
    },
    cloudManagedCredentials() {
      return this.cameraCredentials ? this.cameraCredentials.cloudManagedCredentials : null;
    },
    isNuboCam() {
      return this.camera && this.camera.hasOwnProperty("deviceInfo")
        ? this.camera.deviceInfo.model === "NuboCam"
        : null;
    },
    isWifiEnabled() {
      return this.wifiStatus
        ? this.wifiStatus.hasOwnProperty("enabled")
          ? this.wifiStatus.enabled
          : this.wifiStatus.status
          ? ["offline", "disconnected", "connected", "connecting", "accessPointMode"].indexOf(
              this.wifiStatus.status
            ) !== -1
          : null
        : null;
    },
    isWifiConnected() {
      return this.wifiStatus ? this.wifiStatus.status === "connected" : null;
    },
    hasWifiCapability() {
      return this.cameraCapabilities ? this.cameraCapabilities.wifi : null;
    },
    canDisableWifi() {
      return this.wifiCapabilities ? this.wifiCapabilities.disableWifi : null;
    },
    allowDisableWifi() {
      return this.wifiStatus && this.modemEnabled && this.mobileModem && this.mobileModem.state === "connected";
    },
    canForgetWifi() {
      return this.wifiCapabilities ? this.wifiCapabilities.forgetWifi : null;
    },
    canSaveMultipleWifi() {
      return this.wifiCapabilities ? this.wifiCapabilities.saveMultipleWifi : null;
    },
    canScanForAvailableWifi() {
      return this.wifiCapabilities ? this.wifiCapabilities.scanForAvailableWifi : null;
    },
    connectionType() {
      return this.status ? this.status.connectionType : null;
    },
    wifiCanBeEnabled() {
      return this.wifiStatus ? this.wifiStatus.hasOwnProperty("enabled") : null;
    },
    modemEnabled() {
      return this.mobileModem ? this.mobileModem.enabled : null;
    },
    sortedWifiNetworks() {
      if (!this.wifiNetworks) return [];
      return this.wifiNetworks.sort(function (a, b) {
        return a.ssid.toLowerCase().localeCompare(b.ssid.toLowerCase());
      });
    },
    selectableWifiNetworks() {
      let networks = this.sortedWifiNetworks;
      if (this.wifiStatus && this.wifiStatus.ssid) {
        const ssid = this.wifiStatus.ssid;
        networks = networks.filter((n) => {
          return n.ssid !== ssid;
        });
      }
      return networks;
    },
    selectedWifiNetwork() {
      const ssid = this.selectedSsid;
      return this.selectedSsid
        ? this.wifiNetworks.find((w) => {
            return w.ssid === ssid;
          })
        : null;
    },
    hasWifiNetworks() {
      return this.wifiNetworks !== null && Array.isArray(this.wifiNetworks) && this.wifiNetworks.length > 0;
    },
    cameraCredentialsChanged() {
      return this.cameraCredentialsHash !== hash(this.cameraCredentials);
    },
  },
  watch: {
    camera: function (newVal, oldVal) {
      if (
        (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) ||
        (oldVal !== null && newVal === null)
      ) {
        this.isDirty = false;
      }
      if (newVal) {
        this.wifiStatus = null;
        this.mobileNetwork = null;
        this.mobileModem = null;
        this.wifiNetworks = [];
        this.wifiInterval = setInterval(this.loadVisibleWifi, 10000);
        this.getCameraInfo();
        this.getActiveJob();
      } else if (newVal === undefined || newVal === null) {
        if (this.stompClient && this.stompClient.connected) {
          this.stompClient.disconnect();
        }
        if (this.wifiInterval) {
          clearInterval(this.wifiInterval);
          this.wifiInterval = null;
        }
      }
      if (oldVal === null || (newVal && newVal.cameraId !== oldVal.cameraId)) {
        this.setupStompClient();
      }
    },
    canSaveMultipleWifi: function (newVal, oldVal) {
      if (newVal === true) {
        const that = this;
        that.loadingWifi += 1;
        that.restapi
          .getCameraWifiSaved(that.cameraId)
          .then(function (savedWifi) {
            for (let i = 0; i < savedWifi.length; i++) {
              const ssid = savedWifi[i].ssid;
              const found = that.wifiNetworks.find((w) => {
                return w.ssid === ssid;
              });
              if (found) {
                Object.assign(found, savedWifi[i]);
              } else {
                savedWifi[i].saved = true;
                savedWifi[i].visible = that.isSavedWifi(savedWifi[i]);
                that.wifiNetworks.push(savedWifi[i]);
              }
            }
          })
          .catch(function (error) {
            console.error(error);
          })
          .then(function () {
            that.loadingWifi -= 1;
          });
      }
    },
    wifiStatus: function (newVal, oldVal) {
      if (newVal) {
        this.wifiEnabled = this.wifiStatus.enabled;
        if (this.wifiStatus.enabled && this.wifiStatus.status === "disconnected") {
          const that = this;
          setTimeout(() => {
            that.getWifiStatus();
          }, 5000);
        }
      }
    },
    wifiEnabled: function (newVal, oldVal) {
      if (
        typeof oldVal === "boolean" &&
        typeof newVal === "boolean" &&
        newVal !== this.isWifiEnabled &&
        !this.switchingWifiStatus
      ) {
        if (newVal === false) {
          this.disableWiFiWarning = true;
          this.acceptedWiFiWarning = false;
        } else {
          this.setWiFiEnabled(newVal);
        }
      }
    },
    online: function (newVal, oldVal) {
      if (newVal === true) {
        this.wifiInterval = setInterval(this.loadVisibleWifi, 10000);
        this.getCameraInfo();
      } else if (newVal === false && this.wifiInterval) {
        clearInterval(this.wifiInterval);
      }
    },
    "cameraCapabilities.wifi": function (newVal, oldVal) {
      if (newVal === true) {
        const that = this;
        this.getWifiStatus().then(function () {
          that.getWifiCapabilities(that.cameraId).then(function () {
            that.loadVisibleWifi();
          });
        });
      }
    },
    selectingWifiNetwork: function (newVal, oldVal) {
      if (this.selectingWifiNetwork && !this.selectedWifiNetwork) {
        this.loadVisibleWifi();
      }
    },
    "connectionDetails.globalHost": function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.initValues.globalHost = this.connectionDetails ? this.connectionDetails.globalHost : null;
      }
      if (oldVal !== null) {
        this.checkIfDirty();
      }
    },
    "connectionDetails.globalPort": function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.initValues.globalPort = this.connectionDetails ? parseInt(this.connectionDetails.globalPort) : null;
      }
      if (oldVal !== null) {
        this.checkIfDirty();
      }
    },
    "connectionDetails.globalSSL": function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.initValues.globalSSL = this.connectionDetails ? this.connectionDetails.globalSSL : null;
      }
      if (oldVal !== null) {
        this.checkIfDirty();
      }
    },
    "connectionDetails.localHost": function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.initValues.localHost = this.connectionDetails ? this.connectionDetails.localHost : null;
      }
      if (oldVal !== null) {
        this.checkIfDirty();
      }
    },
    "connectionDetails.localPort": function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.initValues.localPort = this.connectionDetails ? parseInt(this.connectionDetails.localPort) : null;
      }
      if (oldVal !== null) {
        this.checkIfDirty();
      }
    },
    "stompClient.connected": function (newVal, oldVal) {
      console.log("Stomp client connected?", oldVal, "=>", newVal);
      if (oldVal === true && newVal === false && this.camera) {
        this.setupStompClient();
      }
    },
    "$route.query"() {
      if (this.camera) {
        this.getCameraInfo();
      }
    },
  },
  mounted() {
    this.jobText = this.$t("Request");
    if (this.camera) {
      const that = this;
      this.getCameraInfo()
        .then(function () {
          that.getActiveJob();
          that.setupStompClient();
        })
        .then(function () {
          if (!that.wifiInterval) {
            that.wifiInterval = setInterval(that.loadVisibleWifi, 10000);
          }
        });
    }
  },
  beforeDestroy() {
    if (this.stompClient && this.stompClient.connected) {
      this.stompClient.disconnect();
    }
    if (this.wifiInterval) {
      clearInterval(this.wifiInterval);
      this.wifiInterval = null;
    }
  },
  methods: {
    getCameraInfo() {
      this.connectionDetails = null;
      this.wifiCapabilities = null;
      this.wifiStatus = null;
      this.mobileNetwork = null;
      this.mobileModem = null;
      this.cameraCredentials = null;
      this.cameraCredentialsHash = null;
      const that = this;
      return that.restapi
        .getCameraConnectionDetails(that.cameraId)
        .then(function (details) {
          that.connectionDetails = details;
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          if (that.hasWifiCapability) {
            return that.getWifiCapabilities(that.cameraId).then(function () {
              return true;
            });
          } else {
            return true;
          }
        })
        .then(function () {
          if (that.online && that.hasWifiCapability) {
            that.loading = true;
            return that.getWifiStatus().then(function () {
              that.loading = false;
            }).catch(function (error) {
              that.iswifiOn =  false
              console.error(error);
            });
          }
        })
        .then(function () {
          if (that.online && that.iswifiOn ) {
            return that.restapi
              .getCameraMobileNetwork(that.cameraId)
              .then(function (mobileNetwork) {
                that.mobileNetwork = mobileNetwork;
                return that.restapi
                  .getCameraMobileModem(that.cameraId)
                  .then(function (mobileModem) {
                    that.mobileModem = mobileModem;
                  })
                  .catch(function (error) {
                    console.error(error);
                  })
                  .then(function () {
                    return true;
                  });
              })
              .catch(function (error) {
                console.error(error);
              });
          } else {
            return true;
          }
        })
        .then(function () {
          return that.restapi
            .getCameraConnectionCredentials(that.cameraId)
            .then(function (credentials) {
              that.cameraCredentials = credentials;
              that.cameraCredentialsHash = hash(credentials);
            })
            .catch(function (error) {
              console.error(error);
            })
            .then(function () {
              return true;
            });
        });
    },
    getWifiCapabilities() {
      const that = this;
      return this.restapi
        .getCameraWifiCapabilities(that.cameraId)
        .then(function (capabilities) {
          that.wifiCapabilities = capabilities;
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          return true;
        });
    },
    getWifiStatus() {
      const that = this;
      return this.restapi
        .getCameraWifiStatus(that.cameraId)
        .then(function (wifiStatus) {
          that.wifiStatus = wifiStatus;
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          if (that.wifiStatus.status === "connected" && that.canSaveMultipleWifi) {
            that.loadingWifi += 1;
            return that.restapi
              .getCameraWifiSaved(that.cameraId)
              .then(function (savedWifi) {
                for (let i = 0; i < savedWifi.length; i++) {
                  const ssid = savedWifi[i].ssid;
                  const found = that.wifiNetworks.find((w) => {
                    return w.ssid === ssid;
                  });
                  if (found) {
                    Object.assign(found, savedWifi[i]);
                    found.visible = true;
                  } else {
                    savedWifi[i].saved = true;
                    savedWifi[i].visible = that.isSavedWifi(savedWifi[i]);
                    that.wifiNetworks.push(savedWifi[i]);
                  }
                }
              })
              .catch(function (error) {
                console.error(error);
              })
              .then(function () {
                that.loadingWifi -= 1;
                return true;
              });
          } else {
            return true;
          }
        });
    },
    loadVisibleWifi() {
      if (
        this.isPageVisible() &&
        !this.loadingWifi &&
        this.online &&
        this.hasWifiCapability &&
        this.canScanForAvailableWifi
      ) {
        const that = this;
        that.noWiFiNetworksMessage = "Searching for Wi-Fi networks...";
        that.loadingWifi += 1;
        return that.restapi
          .getCameraWifiVisible(that.cameraId)
          .then(function (wifiList) {
            for (let i = 0; i < wifiList.length; i++) {
              const ssid = wifiList[i].ssid;
              const found = that.wifiNetworks.find((w) => {
                return w.ssid === ssid;
              });
              if (found) {
                Object.assign(found, wifiList[i]);
                found.visible = true;
              } else {
                wifiList[i].saved = false;
                wifiList[i].visible = true;
                that.wifiNetworks.push(wifiList[i]);
              }
            }
            //removing networks that are out of range
            that.wifiNetworks.forEach((oldItem, i) => {
              const found = wifiList.find(newItem => newItem.ssid === oldItem.ssid)
              if (!found && !that.wifiNetworks[i].saved) that.wifiNetworks.splice(i, 1)
            })
          })
          .catch(function (error) {
            console.error(error);
          })
          .then(function () {
            that.loadingWifi -= 1;
            that.noWiFiNetworksMessage = "No Wi-Fi networks found";
            return true;
          });
      }
    },
    setupStompClient() {
      /* eslint-disable no-undef */
      const url = config.apiServer.replace("http", "ws") + "/stomp/v2.0?access_token=" + auth.getToken();
      this.stompClient = new StompClient(url, this.camera.accountId, "jobs", null, this.notify);
      this.stompClient.connect();
    },
    notify(tick) {
      const message = JSON.parse(tick.body);
      console.log("Received message:", message);
      this.messages.push(message);
      this.jobStatus = message.status;
      if (this.jobStatus === "Ended") {
        this.jobFailed = message.statusDetails.wasSuccessful === false;
        this.job = null;
        this.closeWifiDialog = true;
        this.jobStatus = null;
        this.jobWifiStatus = null;
        this.messages = [];
        this.getWifiStatus();
      } else if (!this.job) {
        this.getActiveJob();
      }
    },
    isCurrentWifi(ssid) {
      return this.wifiStatus ? this.wifiStatus.ssid === ssid : null;
    },
    isSavedWifi(ssid) {
      return this.wifiNetworks
        ? this.wifiNetworks.filter((w) => {
            return w.hasOwnProperty("preferred") && w.ssid === ssid;
          }).length > 0
        : null;
    },
    toggleSelectWifiNetwork() {
      this.selectingWifiNetwork = !this.selectingWifiNetwork;
      if (this.selectedSsid) this.selectingWifiNetwork = true;
      this.selectedSsid = null;
    },
    selectWifi(ssid) {
      this.wifiPassword = null;
      this.showWifiPassword = false;
      this.selectingWifiNetwork = false;
      this.selectedSsid = ssid;
    },
    forgetWifi(ssid) {
      console.log("Forget", ssid);
      this.selectedSsid = ssid;
      this.confirmForgetWifi = true;
    },
    forgetWifiConfirmed() {
      const networkId = this.selectedWifiNetwork.id;
      this.confirmForgetWifi = false;
      const indexOf = this.wifiNetworks.findIndex((n) => {
        return n.id === networkId;
      });
      if (indexOf !== -1) {
        console.log("Removing WiFi network with index", indexOf);
        this.wifiNetworks.splice(indexOf, 1);
      }
      this.selectedWifiNetwork.saved = false;
      this.deleteCameraWifi(networkId);
    },
    deleteCameraWifi(networkId) {
      const that = this;
      that.selectWifi(that.selectedSsid);
      that.job = "job";
      that.jobText = that.$t("Forgetting");
      that.restapi
        .deleteCameraWifiSaved(this.cameraId, networkId)
        .then(function (job) {
          console.log(`Removed WiFi ${networkId}`);
          const i = that.wifiNetworks.findIndex((w) => {
            return w.id === networkId;
          });
          if (i !== -1) that.wifiNetworks.splice(i, 1);
          that.loadVisibleWifi();
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          that.job = null;
        });
    },
    connectWifi(ssid) {
      console.log("Connect to WiFi", ssid);
      this.selectedSsid = ssid;
      if (this.selectedWifiNetwork) {
        if (this.selectedWifiNetwork.saved) {
          if (this.selectedWifiNetwork.visible) {
            this.setCameraWifi(this.selectedWifiNetwork.id);
          } else {
            this.outOfRangeWarning = true;
          }
        } else {
          console.log("New network");
          this.wifiPassword = null;
          if (this.selectedWifiNetwork.encrypted) {
            console.log("Needs password");
            this.setWifiPassword = true;
          } else {
            console.log("No password required");
            this.createCameraWifi(ssid, this.wifiPassword);
          }
        }
      }
    },
    acceptWifiPassword() {
      this.setWifiPassword = false;
      this.createCameraWifi(this.selectedWifiNetwork.ssid, this.wifiPassword);
    },
    setCameraWifi(networkId) {
      const that = this;
      that.selectWifi(that.selectedSsid);
      that.restapi
        .setCameraWifiSaved(this.cameraId, networkId)
        .then(function (job) {
          console.log(`Set WiFi to ${networkId}`);
          that.job = job;
          that.jobText = that.$t("Switching Wi-Fi network");
          that.jobStatus = job.status;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    createCameraWifi(ssid, password) {
      const that = this;
      that.selectWifi(that.selectedSsid);
      that.restapi
        .createCameraWifiSaved(this.cameraId, ssid, password)
        .then(function (job) {
          console.log(`Created WiFi ${ssid}`);
          that.job = job;
          that.jobText = that.$t("Switching Wi-Fi network");
          that.jobStatus = job.status;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    wifiIconForStrength(strength) {
      return `signal_wifi_${strengths[strength.toLowerCase()]}_bar`;
    },
    wifiImageForStrength(strength) {
      return this.connectionImages[`wifi-${strengths[strength.toLowerCase()]}`];
    },
    mobileNetworkImage() {
      if (this.mobileNetwork && this.mobileNetwork.strength) {
        if (this.mobileNetwork.networkType === "Fourth_Generation") {
          return this.connectionImages[`4g-${strengths[this.mobileNetwork.strength.toLowerCase()]}`];
        } else if (this.mobileNetwork.networkType === "Third_Generation") {
          return this.connectionImages[`3g-${strengths[this.mobileNetwork.strength.toLowerCase()]}`];
        } return null // show nothing if it's not 3g or 4g. We don't have images for them.
      } return null;
    },
    cancelWifiWarning() {
      this.disableWiFiWarning = false;
      this.acceptedWiFiWarning = false;
      this.wifiEnabled = this.wifiStatus.enabled;
    },
    acceptWiFiWarning() {
      this.disableWiFiWarning = false;
      this.acceptedWiFiWarning = true;
      this.setWiFiEnabled(false);
    },
    setWiFiEnabled(newValue) {
      console.log(`Setting wifi enabled to: ${newValue}`);
      this.switchingWifiStatus = true;
      const that = this;
      this.restapi
        .setCameraWifiStatus(this.cameraId, { enabled: newValue })
        .then(function (job) {
          that.job = job;
          that.jobText = newValue ? that.$t("Enabling Wi-Fi") : that.$t("Disabling Wi-Fi");
          that.jobStatus = job.status;
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          that.switchingWifiStatus = false;
        });
    },
    getActiveJob() {
      if (this.hasWifiCapability) {
        const that = this;
        this.restapi
          .getCameraJobs(this.cameraId)
          .then(function (jobs) {
            console.log("Found jobs", jobs);
            const job = jobs.find(function (j) {
              return (
                (j.type === "camera.wifi.connectNetwork" || j.type === "camera.wifi.addNetwork") &&
                (j.status === "Created" || j.status === "Running")
              );
            });
            if (job) {
              that.job = job;
              that.jobText = that.$t("Switching Wi-Fi network");
              that.jobStatus = "Running";
              that.upgradeStatus = job.statusDetails.upgradeStatus;
            }
          })
          .catch(function (error) {
            console.error(error);
          });
      }
    },
    checkIfDirty() {
      if (!this.initValues.hasOwnProperty("globalHost")) return null;
      this.isDirty =
        this.connectionDetails &&
        ((this.connectionDetails.globalHost !== null &&
          this.initValues.globalHost !== this.connectionDetails.globalHost) ||
          (this.connectionDetails.globalPort !== null &&
            this.initValues.globalPort !== parseInt(this.connectionDetails.globalPort)) ||
          (this.connectionDetails.globalSSL !== null &&
            this.initValues.globalSSL !== this.connectionDetails.globalSSL) ||
          (this.connectionDetails.localHost !== null &&
            this.initValues.localHost !== this.connectionDetails.localHost) ||
          (this.connectionDetails.localPort !== null &&
            this.initValues.localPort !== parseInt(this.connectionDetails.localPort)));
    },
    cancelChanges() {
      this.connectionDetails.globalHost = this.initValues.globalHost;
      this.connectionDetails.globalPort = parseInt(this.initValues.globalPort);
      this.connectionDetails.globalSSL = this.initValues.globalSSL;
      this.connectionDetails.localHost = this.initValues.localHost;
      this.connectionDetails.localPort = parseInt(this.initValues.localPort);
      this.cameraModus = this.initValues.cameraModus;
    },
    saveChanges() {
      if (this.rights && this.rights.demoUser) {
        return this.$store.dispatch("demoMessage", this.$t("As a demo user you cannot make any changes."))
      }
      let hasErrors = false;
      const that = this;
      const promise = new Promise(function (resolve, reject) {
        resolve("Success!");
      });
      promise
        .then(function () {
          const settings = {};
          if (that.connectionDetails.globalHost !== null) settings.globalHost = that.connectionDetails.globalHost;
          if (that.connectionDetails.globalPort !== null) settings.globalPort = that.connectionDetails.globalPort;
          if (that.connectionDetails.globalSSL !== null) settings.globalSSL = that.connectionDetails.globalSSL;
          if (that.connectionDetails.localHost !== null) settings.localHost = that.connectionDetails.localHost;
          if (that.connectionDetails.localPort !== null) settings.localPort = that.connectionDetails.localPort;
          return that.restapi
            .setCameraConnectionDetails(that.cameraId, settings)
            .then(function () {})
            .catch(function (error) {
              console.error(error);
              hasErrors = true;
            })
            .then(function () {
              return that.restapi
                .getCameraConnectionDetails(that.cameraId)
                .then(function (settings) {
                  that.connectionDetails = settings;
                  if (that.connectionDetails.hasOwnProperty("globalHost"))
                    that.initValues.globalHost = that.connectionDetails.globalHost;
                  if (that.connectionDetails.hasOwnProperty("globalPort"))
                    that.initValues.globalPort = parseInt(that.connectionDetails.globalPort);
                  if (that.connectionDetails.hasOwnProperty("globalSSL"))
                    that.initValues.globalSSL = that.connectionDetails.globalSSL;
                  if (that.connectionDetails.hasOwnProperty("localHost"))
                    that.initValues.localHost = that.connectionDetails.localHost;
                  if (that.connectionDetails.hasOwnProperty("localPort"))
                    that.initValues.localPort = parseInt(that.connectionDetails.localPort);
                })
                .catch(function (error) {
                  console.error(error);
                })
                .then(function () {
                  return hasErrors;
                });
            });
        })
        .then(function () {
          if (hasErrors === false) {
            store.dispatch("toastMessage", {
              showing: true,
              text: that.$t("Your changes were successfully saved."),
              timeout: 4000,
              color: "primary",
            });
          } else {
            store.dispatch("toastMessage", {
              showing: true,
              text: that.$t("An error occurred while saving your changes."),
              timeout: -1,
              color: "error",
              support: true
            });
          }
        });
    },
    saveCredentials() {
      console.log("Now saving the new username and password");
      const that = this;
      this.restapi
        .setCameraConnectionCredentials(this.cameraId, { username: this.username, password: this.password }, true)
        .then(function () {
          that.restapi
            .getCameraConnectionCredentials(that.cameraId)
            .then(function (credentials) {
              that.cameraCredentialsInvalid =
                that.cameraCredentials.username !== credentials.username ||
                that.cameraCredentials.password !== credentials.password;
              if (!that.cameraCredentialsInvalid) {
                that.cameraCredentials = credentials;
                that.cameraCredentialsHash = hash(credentials);
              }
            })
            .catch(function (error) {
              console.error(error);
            });
        })
        .catch(function (error) {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/settings";

.wifi-row-buttons {
  height: 100%;
}
</style>
