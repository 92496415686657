<template>
  <v-container fluid class="camera-info pa-0">
    <v-app-bar v-if="showToolbar" dense flat :color="toolbarColor || 'white'" class="title--text">
      <v-container v-if="isDirty" class="my-2 align">
        <v-row class="xs8 offset-xs2">
          <v-spacer></v-spacer>
            <v-btn @click="cancelChanges">
              {{ $t("Cancel") }}
            </v-btn>
            <v-btn class="ml-2" color="primary" @click="saveChanges">
              {{ $t("Save") }}
            </v-btn>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-container v-if="device" class="pa-0 align">
      <v-col cols="12" sm="10" offset-sm="1" class="pt-0">
        <p class="text-uppercase pl-0 mb-2">
          {{ $t("General") }}
        </p>
        <v-card>
          <v-col class="py-0">
            <v-list dense rounded two-line>
              <v-list-item class="py-0">
                <v-list-item-content class="py-0">
                  <v-container fluid>
                    <v-row>
                      <v-col class="py-0" cols="12" sm="6">
                        <v-text-field
                          v-model="deviceName"
                          :label="$t('Device name')"
                          :placeholder="$t('Name of your device')"
                          :error-messages="deviceNameErrors"
                          @input="$v.deviceName.$touch()"
                          @blur="$v.deviceName.$touch()"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-card>
      </v-col>

      <v-col cols="12" sm="10" offset-sm="1" class="pt-0">
        <p class="text-uppercase pl-0 mb-2">
          {{ $t("Device Info") }}
        </p>
        <v-card>
          <v-col cols="12" class="pa-0">
            <v-list v-if="brand" dense rounded>
              <v-list-item>
                <v-list-item-content>
                  <v-container class="pa-0" fluid>
                    <v-row>
                      <v-col class="py-0">
                        {{ $t("Brand") }}
                      </v-col>
                      <v-col class="py-0">
                        <p class="text-right my-0">
                          {{ brand }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider class="my-1" v-if="brand"></v-divider>
            <v-list dense rounded v-if="model">
              <v-list-item>
                <v-list-item-content>
                  <v-container class="pa-0" fluid>
                    <v-row>
                      <v-col class="py-0">
                        {{ $t("Type") }}
                      </v-col>
                      <v-col class="py-0">
                        <p class="text-right my-0">
                          {{ model }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider class="my-1" v-if="model"></v-divider>
            <v-list dense rounded>
              <v-list-item>
                <v-list-item-content>
                  <v-container class="pa-0" fluid>
                    <v-row>
                      <v-col class="py-0">
                        {{ $t("MAC address") }}
                      </v-col>
                      <v-col class="py-0">
                        <p class="text-right my-0">
                          {{ macAddress }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <template v-if="uptodate">
              <v-divider class="my-1"></v-divider>
              <v-list dense rounded>
                <v-list-item>
                  <v-list-item-content>
                    <v-container class="pa-0" fluid>
                      <v-row>
                        <v-col class="py-0">
                          {{ $t("Firmware version") }}
                        </v-col>
                        <v-col class="py-0">
                          <p class="text-right my-0">{{ currentFirmwareVersion }} &nbsp; ({{ $t("up to date") }})</p>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </template>
          </v-col>
        </v-card>
      </v-col>

      <v-col v-if="(upgradable || upgrading)" cols="12" sm="10" offset-sm="1" class="pt-0">
        <p class="text-uppercase pl-0 mb-2">
          {{ $t("Firmware update") }}
          <v-icon rounded color="primary" class="ml-2" small>
            fa-exclamation-circle
          </v-icon>
        </p>
        <firmware-update
          :device="device"
          :status="status"
          :firmware="firmware"
          @updated="getFirmware"
        ></firmware-update>
      </v-col>
    </v-container>


    <camera-delete-dialog
      :device="device"
      :showDialog="deletingCamera"
      @closeDialog="deletingCamera = false"
      @deleted="cameraDeleted"
    ></camera-delete-dialog>
  </v-container>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import CameraDeleteDialog from "@/components/Base/CameraDeleteDialog";
import FirmwareUpdate from "@/components/Devices/FirmwareUpdate";

import { store } from "@/store";

export default {
  name: "DeviceInfo",
  components: { CameraDeleteDialog, FirmwareUpdate },
  props: ["device", "showToolbar", "toolbarColor", "menus", "desktop", "status"],
  data() {
    return {
      isDirty: false,
      initValues: {},
      deletingCamera: false,
      firmware: null,
    };
  },
  computed: {
    brand() {
      return this.device && this.device.hasOwnProperty("deviceInfo") ? this.device.deviceInfo.brand : null;
    },
    model() {
      return this.device && this.device.hasOwnProperty("deviceInfo") ? this.device.deviceInfo.model : null;
    },

    uptodate() {
      return this.firmware ? this.firmware.status === "up-to-date" : null;
    },
    upgradable() {
      return this.firmware ? this.firmware.status === "upgradable" : null;
    },
    upgrading() {
      return this.firmware ? this.firmware.status === "upgrading" : null;
    },
    upgradeStatusText() {
      switch (this.upgradeStatus) {
        case "Requesting":
          return this.$t("Requesting...");
        case "Initializing":
          return this.$t("Initializing...");
        case "Uploading":
          return this.$t("Downloading firmware...");
        case "Upgrading":
          return this.$t("Installing firmware...");
        case "Restarting":
          return this.$t("Restarting camera...");
        default:
          return this.upgradeStatus;
      }
    },
    currentFirmwareVersion() {
      return this.firmware ? this.firmware.currentVersion : null;
    },
    progress() {
      if (this.jobProgress) {
        return parseInt((this.jobProgress.current / this.jobProgress.total) * 100);
      }
      return null;
    },

    deviceId() {
      return this.device ? this.device.deviceId : null;
    },

    deviceName: {
      get: function () {
        return this.device ? this.device.name : null;
      },
      set: function (newValue) {
        this.device.name = newValue;
        this.checkIfDirty();
      },
    },

    macAddress() {
      if (!this.device) return null;
      return this.device && this.device.hardwareId ? this.device.hardwareId.mac : "n.a.";
    },

    deviceNameErrors() {
      const errors = [];
      if (!this.$v.deviceName.$dirty) return errors;
      if (!this.$v.deviceName.required) errors.push("Camera name is required");
      if (!this.$v.deviceName.minLength || !this.$v.deviceName.maxLength) {
        errors.push(
          this.$t("Camera name should be X-Y characters", {
            min: this.$v.deviceName.$params.minLength.min,
            max: this.$v.deviceName.$params.maxLength.max,
          })
        );
      }
      return errors;
    },
  },
  watch: {
    device: function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.isDirty = false;
        this.initValues = {
          name: this.device.name,
        };
      } else if (oldVal !== null && newVal === null) {
        this.isDirty = false;
        this.initValues = {
          name: null,
        };
      }
      if (newVal) {
        this.initValues = {
          name: this.device.name,
        };
      }
    },
  },
  mounted() {
    this.initValues = {
      name: this.device?.name,
    };
    this.getFirmware();
  },
  methods: {
    cameraDeleted(cameraId) {
      this.$emit("deleted", cameraId);
      this.deletingCamera = false;
      this.$router.push({ name: "Settings" });
    },



    async getFirmware() {
      try {
        const firmware = await this.restapi.getDeviceFirmware(this.$route.params.deviceId);
        this.firmware = firmware;
      } catch (error) {
        console.error(error);
      }
    },
    checkIfDirty() {
      if (!this.initValues.hasOwnProperty("name")) return null;
      this.isDirty = this.initValues.name !== this.device.name;
    },
    cancelChanges() {
      this.device.name = this.initValues.name;
      this.checkIfDirty();
    },
    agreeRemoval() {
      this.deletingCamera = false;
      this.model = 0;
    },
    saveChanges() {
      let hasErrors = false;
      const that = this;
      const promise = new Promise(function (resolve, reject) {
        resolve("Success!");
      });
      promise
        .then(function () {
          if (that.device.name !== that.initValues.name) {
            return that.restapi
              .setDeviceName(that.device.deviceId, that.device.name)
              .then(function () {
                console.log("Done!");
                that.initValues.name = that.device.name;
                that.checkIfDirty();
              })
              .catch(function (error) {
                console.error(error);
                console.log("hasErrors?", hasErrors);
                hasErrors = true;
              })
              .then(function () {
                return hasErrors;
              });
          } else {
            return hasErrors;
          }
        })
        .then(function () {
          if (hasErrors === false) {
            store.dispatch("toastMessage", {
              showing: true,
              text: that.$t("Your changes were successfully saved."),
              timeout: 4000,
              color: "primary",
            });
          } else {
            store.dispatch("toastMessage", {
              showing: true,
              text: that.$t("An error occurred while saving your changes."),
              timeout: -1,
              color: "error",
              support: true,
            });
          }
        });
    },
  },
  destroyed() {
    this.cancelChanges();
  },
  validations: {
    deviceName: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(32),
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/settings";
.agree-button {
  position: absolute;
  width: 100%;
  bottom: 10px;
}
</style>
