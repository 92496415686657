<template>
  <v-container class="ml-0 mr-2 my-0 pa-0" fluid>
    <v-app-bar v-if="showToolbar" dense flat :app="isAppToolbar" :color="toolbarColor || 'white'" class="title--text">
      <v-container v-if="isDirty || isDirtySchedule" class="my-2 align">
        <v-row class="xs8 offset-xs2">
          <v-spacer></v-spacer>
            <v-btn @click="cancelChanges">
              {{ $t("Cancel") }}
            </v-btn>
            <v-btn class="ml-2" color="primary" @click="saveChanges">
              {{ $t("Save") }}
            </v-btn>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-row class="fill-height" align="center">
      <v-col sm="10" offset-sm="1" class="py-0">
        <v-row>
          <v-col cols="10" class="hidden-sm-and-up">
            <v-btn text class="pa-0" @click="$emit('allCameras')">
              <v-icon size="18" left>
                fa-arrow-left
              </v-icon>
              <p class="mb-0">
                {{ $t("Schedule & Notifications") }}
              </p>
            </v-btn>
          </v-col>
          <v-col cols="2" class="hidden-sm-and-up">
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" dark icon>
                  <v-icon size="18" color="black">
                    fa-ellipsis-v
                  </v-icon>
                </v-btn>
              </template>
              <v-list rounded class="px-4">
                <v-list-item
                  v-for="(menu, i) in menus"
                  :key="i"
                  @click="$router.push({ path: `/settings/cameras/${camera.cameraId}/${menu.endpoint}` })"
                >
                  <v-list-item-title>{{ menu.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="10" offset-sm="1" class="pt-0">
        <p class="text-uppercase pl-0 mb-2">
          {{ $t("Camera mode") }}
        </p>
        <v-card>
          <v-col cols="12" class="pa-0">
            <v-radio-group
              dense
              v-model="cameraModus"
              hide-details
              class="camera-mode my-0"
              :disabled="cameraMode && cameraMode.mode === 'private'"
            >
              <v-list rounded dense v-if="cameraMode">
                <v-list-item>
                  <v-list-item-action class="pa-0">
                    <v-radio key="armed" value="armed" class="camera-mode-option"></v-radio>
                  </v-list-item-action>
                  <v-list-item-content class="pa-0">
                    <v-list-item-title>{{ $t("Armed") }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t("Recording on, notifications on") }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="my-1"></v-divider>
                <v-list-item>
                  <v-list-item-action class="pa-0">
                    <v-radio key="silent" value="silent" class="camera-mode-option"></v-radio>
                  </v-list-item-action>
                  <v-list-item-content class="pa-0">
                    <v-list-item-title>{{ $t("Silent") }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t("Recording on, notifications off") }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="my-1"></v-divider>
                <v-list-item>
                  <v-list-item-action>
                    <v-radio key="disarmed" value="disarmed" class="camera-mode-option"></v-radio>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Disarmed") }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t("Recording off, notifications off") }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="my-1"></v-divider>
                <v-list-item>
                  <v-list-item-action>
                    <v-radio key="custom" value="custom" class="camera-mode-option"></v-radio>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Scheduled") }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t("Custom recording & notifications schedule") }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="my-1"></v-divider>
                <v-list-item class="body-2">
                  {{
                    $t("Your camera is set to RECORDINGMODE recording. Go to the MENU menu to change this.", {
                      recordingMode: recordingMode,
                      menu: $t("Recording"),
                    })
                  }}
                </v-list-item>
              </v-list>
            </v-radio-group>
          </v-col>
        </v-card>
      </v-col>
      <scheduled-tables ref="scheduleTable" v-if="cameraModus == 'custom'" :camera="camera" v-on:dirty="isDirtySchedule = $event"> </scheduled-tables>
    </v-row>
  </v-container>
</template>

<script>
import ScheduledTables from "@/components/Cameras/ScheduledTables";
import { store } from "@/store";
import { getMessageFromError } from "@eencloud/core-components/src/service/errors";
import warningForCameraSettingsChangesMixin from '@eencloud/core-components/src/service/warningForCameraSettingsChangesMixin';

export default {
  name: "ScheduleNotifications",
  components: { ScheduledTables },
  props: ["showToolbar", "appToolbar", "toolbarColor", "menus", "camera", "rights"],
  mixins: [warningForCameraSettingsChangesMixin],
  data() {
    return {
      cameraMode: null,
      isDirty: false,
      isDirtySchedule: false,
      initValues: {},
    };
  },
  computed: {
    theme() {
      return this.$store.getters.theme;
    },
    desktopImg() {
      if (this.theme.application === "Pronet Kameram")
        return this.getImgUrl("MacDesktop - Schedule & notifications - Pronet.png");
      if (this.theme.application === "Prosegur Cloud Video")
        return this.getImgUrl("MacDesktop - Schedule & notifications - Prosegur.png");
      if (this.theme.application === "Alibi Cloud VS")
        return this.getImgUrl("MacDesktop - Schedule & notifications - Alibi.png");
      return this.getImgUrl("MacDesktop - Schedule & notifications.png");
    },
    isAppToolbar() {
      if (this.appToolbar === undefined || this.appToolbar === null) return true;
      return !!this.appToolbar;
    },
    isMobile() {
      return ["iOS", "Android"].includes(this.getOS());
    },

    recordingMode() {
      return this.cameraMode
        ? this.cameraMode.recordingMode === "continuous"
          ? this.$t("Continuous")
          : this.$t("Event based")
        : null;
    },
    cameraId() {
      return this.camera ? this.camera.cameraId : null;
    },
    cameraModus: {
      get: function () {
        if (this.cameraMode) {
          return this.cameraMode.mode === "private" ? this.cameraMode.previousMode : this.cameraMode.mode;
        }
        return null;
      },
      set: function (newValue) {
        this.cameraMode.mode = newValue;
        this.checkIfDirty();
        this.isDirtySchedule = false
      },
    },
  },
  watch: {
    camera: function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.isDirty = false;
        this.initValues = {
          cameraMode: {},
        };
      } else if (oldVal !== null && newVal === null) {
        this.isDirty = false;
        this.initValues = {
          cameraMode: {},
        };
      }
      if (newVal) {
        this.statusInfo();
      }
    },
    "cameraMode.mode": function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.initValues.cameraMode = Object.assign({}, this.cameraMode);
      }
    },
  },
  methods: {
    downloadClient() {
      if (this.clients.hasOwnProperty(this.getOS())) {
        if (this.$ga !== undefined) {
          this.$ga.event("Schedule Notifications", "Download client app", this.getOS());
        }
        const link = this.$refs.downloadLink;
        link.href = this.clients[this.getOS()];
        link.click();
      }
    },
    async statusInfo() {
      try {
        this.cameraMode = await this.restapi.getCameraMode(this.cameraId);
      } catch (error) {
        console.log(error);
      }
    },
    checkIfDirty() {
      if (!this.initValues.hasOwnProperty("cameraMode")) return null;
      this.isDirty = this.initValues.cameraMode.mode !== this.cameraMode.mode;
    },
    cancelChanges() {
      this.cameraMode = Object.assign({}, this.initValues.cameraMode);
      if (this.$refs.scheduleTable) this.$refs.scheduleTable.cancelChanges();
      this.checkIfDirty();
    },
    async saveChanges() {
      if (this.rights && this.rights.demoUser) {
        return this.$store.dispatch("demoMessage", this.$t("As a demo user you cannot make any changes."))
      }
      else {
        try {
          if (this.isDirty) {
            await this.restapi.setCameraMode(this.cameraId, { mode: this.cameraMode.mode })
            this.initValues.cameraMode = Object.assign({}, this.cameraMode);
            this.checkIfDirty();
          }
          if (this.isDirtySchedule && this.cameraMode.mode == 'custom') { //unsaved changes made to the schedule are discarded when changing back to unscheduled mode
            await this.$refs.scheduleTable.saveSchedules();
          } 
          this.isDirtySchedule = false;
          store.dispatch("toastMessage", {
            text: this.$t("Your changes were successfully saved."),
            color: "primary",
            showing: true,
            timeout: 4000,
          });
        } catch (error) {
          console.log(error)
          store.dispatch("toastMessage", {
            text: getMessageFromError(error, "SET_CAMERA_MODE"),
            color: "error",
            showing: true,
            timeout: -1,
            support: true
          });
          }
        }
    },
  },
  onDestroy() {
    this.cameraMode = Object.assign({}, this.initValues.cameraMode);
    this.$refs.scheduleTable.cancelChanges();
  },
  mounted() {
    this.statusInfo();
    this.initValues = {
      cameraMode: {},
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/settings";
</style>
