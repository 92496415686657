<template>
  <v-container fluid class="recording pa-0">
    <v-app-bar v-if="showToolbar" dense flat :app="isAppToolbar" :color="toolbarColor || 'white'" class="title--text">
      <v-container v-if="isDirty" class="my-2 align">
        <v-row class="xs8 offset-xs2">
          <v-spacer></v-spacer>
            <v-btn  @click="cancelChanges">
              {{ $t("Cancel") }}
            </v-btn>
            <v-btn class="ml-2" color="primary" @click="saveChanges">
              {{ $t("Save") }}
            </v-btn>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-container v-if="camera" class="pa-0 align">
      <v-col sm="10" offset-sm="1" class="py-0">
        <v-row>
          <v-col cols="10" class="hidden-sm-and-up">
            <v-btn text class="pa-0" @click="$emit('allCameras')">
              <v-icon size="18" left>
                fa-arrow-left
              </v-icon>
              <p class="mb-0">
                {{ $t("Recording") }}
              </p>
            </v-btn>
          </v-col>
          <v-col cols="2" class="hidden-sm-and-up">
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" dark icon>
                  <v-icon size="18" color="black">
                    fa-ellipsis-v
                  </v-icon>
                </v-btn>
              </template>
              <v-list rounded dense class="px-4">
                <v-list-item
                  v-for="(menu, i) in menus"
                  :key="i"
                  @click="$router.push({ path: `/settings/cameras/${camera.cameraId}/${menu.endpoint}` })"
                >
                  <v-list-item-title>{{ menu.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="10" offset-sm="1">
        <p class="text-uppercase pl-0 mb-2">
          {{ $t("Cloud storage") }}
        </p>
        <v-card>
          <v-col cols="12" class="pa-0">
            <v-list rounded dense>
              <v-list-item>
                <v-list-item-content>
                  <v-container class="pa-0" fluid>
                    <v-row>
                      <v-col class="my-2 py-0 d-flex align-center">
                        {{ $t("Recording type") }}
                      </v-col>
                      <v-col class="py-0">
                        <v-select
                          v-model="recordingType"
                          :items="recordingTypes"
                          solo
                          dense
                          flat
                          hide-details
                          background-color="shadow"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <template v-if="cameraSubscriptionRights">
              <v-divider class="my-1"></v-divider>
              <v-list rounded dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-container class="pa-0" fluid>
                      <v-row>
                        <v-col class="my-2 py-0">
                          {{ $t("Retention") }}
                        </v-col>
                        <v-col class="py-0">
                          <div class="body-1 my-2 text-right">
                            {{ cameraSubscriptionRights.daysOfRecordings }}
                            {{ $t("days") }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </template>
          </v-col>
        </v-card>
      </v-col>
      <v-col
        v-if="camera.cameraCapabilities && !camera.cameraCapabilities.edgeStorage"
        cols="12"
        sm="10"
        offset-sm="1"
        class="pb-0"
      >
        <div>
          <p class="text-uppercase pl-0 mb-2 d-inline-block">
            {{ $t("Edge storage") }}
          </p>
          <v-btn @click="edgeStorageExplaination = true" icon color="primary">
            <v-icon size="20">fas fa-info-circle</v-icon>
          </v-btn>
        </div>
        <v-card>
          <v-col>
            <v-list rounded dense>
              <v-container class="pa-0" fluid>
                <v-row v-if="sortedJobs.length === 0" class="d-flex justify-center">
                  {{ $t("This camera does not support edge storage") }}
                </v-row>
              </v-container>
            </v-list>
          </v-col>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="10"
        offset-sm="1"
        class="py-0"
        v-if="
          camera.status && camera.status.online && camera.cameraCapabilities && camera.cameraCapabilities.edgeStorage
        "
      >
        <div>
          <p class="text-uppercase pl-0 mb-2 d-inline-block">
            {{ $t("Edge storage status") }}
          </p>
          <v-btn @click="edgeStorageExplaination = true" icon color="primary">
            <v-icon size="20">fas fa-info-circle</v-icon>
          </v-btn>
        </div>
        <v-card>
          <v-col>
            <v-list rounded dense>
              <v-container class="pa-0" fluid>
                <v-row class="">
                  <v-col class="py-0 colorGrey">{{ $t("Name") }}</v-col>
                  <v-col class="py-0 colorGrey">{{ $t("Capacity") }}</v-col>
                  <v-col class="py-0 colorGrey">{{ $t("In usage") }}</v-col>
                  <v-col class="py-0 colorGrey">{{ $t("Status") }}</v-col>
                  <v-col class="py-0"> </v-col>
                </v-row>
                <v-row class="align-center" v-for="(edge, index) in edgeStorage" :key="edge.storageId">
                  <v-col class="py-0" dense>{{ edge.name }}</v-col>
                  <v-col class="py-0" dense
                    ><span v-if="(edge.state == 'ready')">{{ formatBytes(edge.sizeTotalMiB) }}</span>
                  </v-col>
                  <v-col class="py-0" dense
                    ><span v-if="(edge.state == 'ready')">{{ formatBytes(edge.sizeInUseMiB) }}</span>
                  </v-col>
                  <v-col class="py-0 text-capitalize" dense>{{ edge.state }} </v-col>
                  <v-col class="py-0" dense>
                    <v-btn
                      small
                      block
                      v-if="(edge.state == 'error' || edge.state == 'ready')"
                      color="error"
                      @click="showConfirmationDialog(edge, index)"
                    >
                      {{ $t("Format") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-list>
          </v-col>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="10"
        offset-sm="1"
        class="pb-0"
        v-if="camera.cameraCapabilities && camera.cameraCapabilities.edgeStorage"
      >
        <p class="text-uppercase pl-0 mb-2">
          {{ $t("Edge storage Cloud Sync") }}
        </p>
        <v-card v-if="camera.status && camera.status.online">
          <v-col class="py-0">
            <v-list rounded dense>
              <v-container class="pa-0" fluid>
                <v-row>
                  <v-col class="my-5 py-0">
                    {{ $t("Cloud Sync") }}
                  </v-col>
                  <v-col class="py-0">
                    <v-switch
                      hide-details
                      dense
                      class="float-right"
                      v-model="edgesync"
                      @change="cloudWarningData = true"
                    >
                    </v-switch>
                  </v-col>
                </v-row>
              </v-container>
            </v-list>
          </v-col>
        </v-card>
        <v-card v-if="camera.status && !camera.status.online">
          <v-col>
            <v-list rounded dense>
              <v-container class="pa-0" fluid>
                <v-row class="d-flex justify-center">
                  {{ $t("Camera is offline") }}
                </v-row>
              </v-container>
            </v-list>
          </v-col>
        </v-card>
      </v-col>

      <v-col cols="12" sm="10" offset-sm="1" v-if="camera.cameraCapabilities && camera.cameraCapabilities.edgeStorage">
        <p class="text-uppercase pl-0 mb-2">
          {{ $t("Edge storage synchronization jobs") }}
        </p>
        <v-card>
          <v-col>
            <v-list rounded dense>
              <v-container class="pa-0" fluid>
                <v-row class="font-weight-bold" v-if="sortedJobs.length">
                  <v-col class="my-2 py-0 colorGrey">{{ $t("Sync Job ID") }}</v-col>
                  <v-col class="my-2 py-0 colorGrey">{{ $t("Status") }}</v-col>
                  <v-col class="my-2 py-0 colorGrey">{{ $t("Recording start") }}</v-col>
                  <v-col class="my-2 py-0 colorGrey">{{ $t("Recording end") }}</v-col>
                  <v-col class="my-2 py-0 colorGrey">{{ $t("Recording Duration") }}</v-col>
                  <v-col class="my-2 py-0 colorGrey">{{ $t("Job Duration") }}</v-col>
                  <v-col class="my-2 py-0 colorGrey">{{ $t("Sync Time") }}</v-col>
                </v-row>
                <v-row v-for="job in sortedJobs" :key="job.index">
                  <v-col class="my-2 py-0">{{ job.jobId.split("-")[2] }}</v-col>
                  <v-col class="my-2 py-0">{{ $t(job.status) }}</v-col>
                  <v-col class="my-2 py-0"
                    >{{ new Date(job.jobDetails["camera.edgeRecording.sync.start"]).accountTimeStamp().slice(0, 20) }}
                  </v-col>
                  <v-col class="my-2 py-0"
                    >{{
                      job.status === "Ended" || job.status === "Running"
                        ? new Date(job.jobDetails["camera.edgeRecording.sync.end"]).accountTimeStamp().slice(0, 20)
                        : ""
                    }}
                  </v-col>
                  <v-col class="my-2 py-0">
                    {{
                      jobDuration(
                        job.jobDetails["camera.edgeRecording.sync.start"],
                        job.jobDetails["camera.edgeRecording.sync.end"],
                        job.status
                      )
                    }}</v-col
                  >
                  <v-col class="my-2 py-0"> {{ jobDuration(job.startTime, job.endTime, job.status) }}</v-col>
                  <v-col class="my-2 py-0"> {{ new Date(job.creationTime).accountTimeStamp().slice(0, 20) }}</v-col>
                </v-row>
                <v-row v-if="sortedJobs.length === 0" class="d-flex justify-center">
                  {{ $t("No edge storage synchronization is going on") }}
                </v-row>
              </v-container>
            </v-list>
          </v-col>
        </v-card>
      </v-col>
    </v-container>
    <v-dialog v-model="confiramtionDialog" :persistent="persistance" hide-overlay width="500">
      <v-carousel
        touchless
        hide-delimiters
        :show-arrows="false"
        v-model="carouselModel"
        :continuous="false"
        light
        height="160"
      >
        <v-carousel-item>
          <v-card height="100%">
            <v-card-title primary-title>
              <div class="headline">{{ $t("Format") }} {{ storageToFormat }}</div>
            </v-card-title>
            <v-card-text class="d-flex justify-center align-center"
              ><span>{{ $t("WARNING : Formatting will erase ALL data on this storage.") }}</span>
            </v-card-text>
            <v-card-actions class="buttonsDelete">
              <v-spacer></v-spacer>
              <v-btn depressed color="primary" @click="formatStorage()">
                {{ $t("Confirm") }}
              </v-btn>
              <v-btn depressed @click.stop="confiramtionDialog = false">
                {{ $t("Cancel") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-carousel-item>
        <v-carousel-item class="formattedSuccess">
          <span>
            <div class="success-loader">
              <een-spinner :isloading="!formatting" :size="56" :width="7"></een-spinner>
            </div>
            <svg v-if="formatting" class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
              <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
            <h1 v-if="!formatting" class="text-center formatting">{{ $t("Formatting") }}</h1>
            <h1 v-if="formatting" class="text-center">{{ $t("Formatted") }}</h1>
          </span>
        </v-carousel-item>
      </v-carousel>
    </v-dialog>
    <een-spinner :isloading="loading" :size="60" :width="7"></een-spinner>
    <v-dialog v-model="cloudWarningData" max-width="600" persistent>
      <v-card>
        <v-card-title v-if="edgesync" primary-title
          >{{ $t("When enabling Cloud Sync, make sure the following settings are configured:") }}
        </v-card-title>
        <v-card-title v-if="!edgesync" primary-title
          >{{ $t("Clicking on confirm will disable the Coud Sync.") }}
        </v-card-title>
        <v-card-text v-if="edgesync">
          <ul>
            <li>{{ $t("Make sure to have a functioning SD card inserted into the camera.") }}</li>
            <li>{{ $t("Set your recording mode to continuous recording.") }}</li>
            <li>{{ $t("Ensure your camera is always armed or that your schedule is on continuous recording.") }}</li>
          </ul>
          <div class="pt-2">
            {{
              $t(
                "If you for example use 'event based recording', the cloud will sync the missing recordings in the background turning your camera into 'continuous based recording'."
              )
            }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" depressed @click="edgeSyncChange">{{ $t("Confirm") }}</v-btn>
          <v-btn depressed @click.stop="clsoecloudWarningDatadialog">{{ $t("Close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="edgeStorageExplaination" max-width="600" persistent>
      <v-card>
        <v-card-text class="pt-4"
          >{{
            $t(
              "Edge Storage Sync makes the camera SD card as the secondary storage for video and the cloud syncs video back from the SD card in the event of a network failure."
            )
          }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click.stop="edgeStorageExplaination = false">{{ $t("Close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import StompClient from "@/service/StompClient";
const auth = require("@/service/auth");
import { store } from "@/store";
import { getMessageFromError } from "@eencloud/core-components/src/service/errors";
import warningForCameraSettingsChangesMixin from '@eencloud/core-components/src/service/warningForCameraSettingsChangesMixin';

export default {
  name: "Recording",
  props: ["camera", "rights", "showToolbar", "appToolbar", "toolbarColor", "menus"],
  mixins: [warningForCameraSettingsChangesMixin],
  data() {
    return {
      isDirty: false,
      edgeStorageExplaination: false,
      confiramtionDialog: false,
      loading: false,
      storageId: null,
      showStatus: false,
      storageToFormat: null,
      stompClient: null,
      jobStompClient: null,
      stompJobsMessage: null,
      persistance: false,
      stompMessage: null,
      squenceToFormat: null,
      showFormat: false,
      allJobs: [],
      formatting: false,
      cloudWarningData: false,
      runningFormatJobs: false,
      message: [],
      edgesync: null,
      carouselModel: 0,
      initValues: {},
      edgeStorage: [],
      cameraMode: null,
      cameraSubscriptionRights: null,
    };
  },
  computed: {
    isAppToolbar() {
      if (this.appToolbar === undefined || this.appToolbar === null) return true;
      return !!this.appToolbar;
    },
    cameraId() {
      return this.camera ? this.camera.cameraId : null;
    },
    recordingTypes() {
      return [
        { value: "continuous", text: this.$t("Continuous") },
        { value: "eventBased", text: this.$t("Event based") },
      ];
    },
    recordingType: {
      get() {
        return this.cameraMode
          ? {
              value: this.cameraMode.recordingMode,
              text: this.$t(this.cameraMode.recordingMode),
            }
          : null;
      },
      set: function (recordingType) {
        console.log("Recording type", recordingType);
        this.cameraMode.recordingMode = recordingType;
        this.checkIfDirty();
      },
    },
    sortedJobs() {
      return this.allJobs.sort(
        (a, b) =>
          new Date(b.jobDetails["camera.edgeRecording.sync.start"]).getTime() -
          new Date(a.jobDetails["camera.edgeRecording.sync.start"]).getTime()
      );
    },
  },
  watch: {
    cameraId: function (newVal, oldVal) {
      if (
        (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) ||
        (oldVal !== null && newVal === null)
      ) {
        this.isDirty = false;
      }
      if (newVal) {
        this.getCameraInfo();
      }
    },
    cameraMode: function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.initValues.recordingMode = this.cameraMode.recordingMode;
      }
    },
    "$route.query"() {
      if (this.cameraId) {
        this.getCameraInfo();
      }
    },
    "stompClient.connected": function (newVal, oldVal) {
      console.log("Stomp client connected?", oldVal, "=>", newVal);
      if (oldVal === true && newVal === false) {
        this.setupStompClient();
      }
    },
    "jobStompClient.connected": function (newVal, oldVal) {
      console.log("Stomp Jobs connected?", oldVal, "=>", newVal);
      if (oldVal === true && newVal === false) {
        this.setupStompClient();
      }
    },
    "camera.status.online"(value) {
      if (value) {
        this.getCameraInfo();
        if (this.camera.cameraCapabilities && this.camera.cameraCapabilities.edgeStorage) {
          this.getEdgeStorage();
          this.getActiveJob();
        }
      }
    },
  },
  mounted() {
    if (this.cameraId) {
      this.getCameraInfo();
      if (this.camera.cameraCapabilities && this.camera.cameraCapabilities.edgeStorage && this.camera.status.online) {
        this.getEdgeStorage();
        this.getActiveJob();
      }

      this.setupStompClient();
    }
  },
  beforeDestroy() {
    if (this.stompClient && this.stompClient.connected) {
      this.stompClient.disconnect();
    }
    if (this.jobStompClient && this.jobStompClient.connected) {
      this.jobStompClient.disconnect();
    }
  },
  methods: {
    getCameraInfo() {
      this.cameraMode = null;
      this.cameraSubscriptionRights = null;
      const that = this;
      return that.restapi
        .getCameraMode(that.cameraId)
        .then(function (mode) {
          that.cameraMode = mode;
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          return that.restapi
            .getCameraSubscriptionRights(that.cameraId)
            .then(function (rights) {
              that.cameraSubscriptionRights = rights;
            })
            .catch(function (error) {
              console.error(error);
            })
            .then(function () {
              return true;
            });
        })
        .then(function () {
          return true;
        });
    },

    clsoecloudWarningDatadialog() {
      this.edgesync = !this.edgesync;
      this.cloudWarningData = false;
    },

    formatBytes(bytes, decimals = 2) {
      if (bytes == 0) return "0 Bytes";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },

    jobDuration(start, end, type) {
      if (type === "Ended") {
        let starTime = new Date(start).getTime(),
          endTime = new Date(end).getTime(),
          differnce = endTime - starTime,
          milliseconds = parseInt((differnce % 1000) / 100),
          seconds = Math.floor((differnce / 1000) % 60),
          minutes = Math.floor((differnce / (1000 * 60)) % 60),
          hours = Math.floor((differnce / (1000 * 60 * 60)) % 24);

        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
      }
      return "";
    },
    async getEdgeStorage() {
      try {
        let result = await this.restapi.getStorageID(this.camera.cameraId);
        this.edgeStorage = result.data;
        let data = await this.restapi.getRecordingConfiguration(this.camera.cameraId, this.edgeStorage[0].storageId);
        this.edgesync = data.autoSyncEnabled;
      } catch (error) {
        store.dispatch("toastMessage", {
          showing: true,
          text: getMessageFromError(error, "GET_EDGE_STORAGE"),
          timeout: -1,
          color: "error",
          support: true,
        });
      }
    },
    async edgeSyncChange() {
      if (this.rights?.demoUser) {
        this.cloudWarningData = false;
        this.edgesync = !this.edgesync;
        return this.$store.dispatch("demoMessage");
      }
      this.cloudWarningData = false;
      this.loading = true;
      try {
        await this.restapi.patchRecordingConfiguration(
          this.camera.cameraId,
          this.edgeStorage[0].storageId,
          this.edgesync
        );
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.edgesync = !this.edgesync;
        store.dispatch("toastMessage", {
          showing: true,
          text: getMessageFromError(error, "PATCH_STORAGE"),
          timeout: -1,
          color: "error",
          support: true,
        });
      }
    },
    showConfirmationDialog(edge, index) {
      this.squenceToFormat = index;
      this.carouselModel = 0;
      this.confiramtionDialog = true;
      this.storageToFormat = edge.name;
      this.storageId = edge.storageId;
    },

    async formatStorage() {
      let that = this;
      try {
        await this.restapi.formatEdgeStorage(this.camera.cameraId, this.storageId);
        that.carouselModel++;
        that.persistance = true;
        that.formatting = false;
        var statusChecker = setInterval(async () => {
          that.loading = false;
          let result = await this.restapi.getStorageID(this.camera.cameraId);
          that.edgeStorage = result.data;
          if (result.data[that.squenceToFormat].state == "ready") {
            that.persistance = false;
            that.formatting = true;

            setTimeout(() => {
              this.confiramtionDialog = false;
              clearInterval(statusChecker);
            }, 4000);
          }
        }, 2000);
      } catch (error) {
        store.dispatch("toastMessage", {
          showing: true,
          text: getMessageFromError(error, "FORMAT_EDGE"),
          timeout: -1,
          color: "error",
          support: true,
        });
      }
    },
    getActiveJob() {
      const that = this;
      this.restapi
        .getCameraJobs(this.camera.cameraId)
        .then(function (jobs) {
          jobs.forEach((element) => {
            if (
              element.type == "camera.edgeRecording.sync" &&
              element.jobDetails["camera.edgeRecording.sync.start"] !==
                element.jobDetails["camera.edgeRecording.sync.end"]
            ) {
              that.allJobs.map((job, index) => {
                if (job.jobId === element.jobId) {
                  that.allJobs.splice(index, 1);
                }
              });
              that.allJobs.unshift(element);
            }
          });
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    setupStompClient() {
      /* eslint-disable no-undef */
      const url = config.apiServer.replace("http", "ws") + "/stomp/v2.0?access_token=" + auth.getToken();
      this.stompClient = new StompClient(url, this.camera.accountId, "cameras", this.camera.cameraId, this.notify);
      this.jobStompClient = new StompClient(url, this.camera.accountId, "jobs", "", this.notifyJob);
      this.stompClient.connect();
      this.jobStompClient.connect();
    },

    notifyJob(tick) {
      this.stompJobsMessage = JSON.parse(tick.body);
      if (this.stompJobsMessage.cameraId == this.camera.cameraId) {
        if (
          this.stompJobsMessage.type == "camera.edgeRecording.sync" &&
          this.stompJobsMessage.jobDetails["camera.edgeRecording.sync.start"] !==
            this.stompJobsMessage.jobDetails["camera.edgeRecording.sync.end"]
        ) {
          this.allJobs.map((job, index) => {
            if (job.jobId === this.stompJobsMessage.jobId) {
              this.allJobs.splice(index, 1);
            }
          });
          this.allJobs.unshift(this.stompJobsMessage);
        }
      }
    },

    notify(tick) {
      this.stompMessage = JSON.parse(tick.body);
      if (this.stompMessage.hasOwnProperty("storageId")) {
        setTimeout(() => {
          this.getEdgeStorage();
        }, 1000);
        this.getEdgeStorage();
      }
    },
    checkIfDirty() {
      if (!this.initValues.hasOwnProperty("recordingMode")) return null;
      this.isDirty = this.initValues.recordingMode !== this.cameraMode.recordingMode;
    },
    cancelChanges() {
      this.recordingType = this.initValues.recordingMode;
    },
    saveChanges() {
      if (this.rights && this.rights.demoUser) {
        return this.$store.dispatch("demoMessage", this.$t("As a demo user you cannot make any changes."));
      }
      let hasErrors = false;
      const that = this;
      const promise = new Promise(function (resolve, reject) {
        resolve("Success!");
      });
      promise
        .then(function () {
          if (that.cameraMode.recordingMode !== that.initValues.recordingMode) {
            return that.restapi
              .setCameraMode(that.cameraId, {
                recordingMode: that.cameraMode.recordingMode,
              })
              .then(function () {
                that.initValues.recordingMode = that.cameraMode.recordingMode;
                that.checkIfDirty();
              })
              .catch(function (error) {
                console.error(error);
                hasErrors = true;
              })
              .then(function () {
                return hasErrors;
              });
          } else {
            return hasErrors;
          }
        })
        .then(function () {
          if (hasErrors === false) {
            store.dispatch("toastMessage", {
              showing: true,
              text: that.$t("Your changes were successfully saved."),
              timeout: 4000,
              color: "primary",
            });
          } else {
            store.dispatch("toastMessage", {
              showing: true,
              text: that.$t("An error occurred while saving your changes."),
              timeout: -1,
              color: "error",
              support: true,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/settings";
.formatEdge {
  height: 100px;
  width: 100%;
  background: white;
}
.edgeCheck {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.edgeCheckBoxSection {
  padding-top: 10px;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.formattedSuccess {
  background: white;
}

.success-loader {
  display: block;
  margin: 4% auto;
}

.colorGrey {
  color: #a5a5a5;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 4% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.checkmark_small {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;

  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

.formatting:after {
  content: "...";
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis-dot 1s infinite 0.3s;
  animation-fill-mode: fowards;
  width: 1.25em;
}

@keyframes ellipsis-dot {
  25% {
    content: "";
  }
  50% {
    content: ".";
  }
  75% {
    content: "..";
  }
  100% {
    content: "...";
  }
}

.buttonsDelete {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
</style>
