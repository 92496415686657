<template>
  <v-list-item class="primary--text">
    <camera-image
      :camera="camera"
      width="60"
      max-width="60"
      height="40"
      max-height="40"
      aspect-ratio="16/9"
    ></camera-image>
    <p class="subheading mb-0">{{ camera.name }}</p>
    <v-spacer></v-spacer>
    <v-switch v-model="camera.present" @change="camChanged" hide-details dense></v-switch>
  </v-list-item>
</template>

<script>
import CameraImage from "@/components/Base/CameraImage";

export default {
  name: "CameraList",
  props: ["camera"],
  components: { CameraImage },
  methods: {
    camChanged(val) {
      this.$emit("cameraUpdated", this.camera, val);
    },
  },
};
</script>

<style lang="scss" scoped></style>
