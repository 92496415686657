<template>
  <v-container fluid class="dashboard my-0 py-0">
    <v-row>
      <v-container fluid class="dashboard-content ma-0 pa-0" :class="{ 'has-navigation': showNavigation }">
        <v-row class="pa-0 height100">
          <v-col
            v-if="($vuetify.breakpoint.smAndUp && showZoneList) || ($vuetify.breakpoint.xs && !camera)"
            cols="auto"
            class="zone-list pa-0 pl-2 height100"
          >
            <zone-list
              id="zone-list"
              where="Footage"
              :zones="zones"
              :cameras="cameras"
              :camera="camera"
              :selectedCameras="selectedCameras"
              :selected-zones="selectedZones"
              @selectZone="selectZone"
              @toggleShow="zoneListToggled"
            ></zone-list>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.mdAndUp || ($vuetify.breakpoint.smAndDown && camera)" class="pa-0 pr-3">
            <v-toolbar v-if="camera" flat height="36" color="#eee"> </v-toolbar>
            <v-row
              v-if="!camera"
              id="no-camera-selected"
              align="center"
              justify="center"
              class="hidden-sm-and-down fill-height pt-12"
            >
              <v-icon small>fa-arrow-left</v-icon>
              <p class="subheading mb-0 ml-2">{{ $t("Select a camera to playback footage.") }}</p>
            </v-row>
            <een-footage
              ref="footage"
              class="footage"
              v-else-if="camera.deviceInfo && !isNaN(camera.timeOffset) && cameraInfoLoaded"
              :camera="camera"
              :key="camera.cameraId"
              :audioAvailable="audioAvailable"
              :accountTimeZoneOffset="$store.getters.accountTimeZoneOffset"
              :rights="rights"
              :hideFootageExportDialog="rights && !rights.export && !rights.demoUser"
              :hideDeleteRecordings="rights && !rights.deleteRecording && !rights.demoUser"
            ></een-footage>
            <een-spinner class="footage-spinner" :size="100" :isloading="true" v-else></een-spinner>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import ZoneList from "@/components/ZoneList";
import restapi from "@eencloud/core-components/src/service/CMApi";

export default {
  name: "FootageView",
  components: {
    ZoneList,
  },
  props: ["zones", "cameras", "camera", "rights", "showNavigation", "audioAvailable"],
  data() {
    return {
      showZoneList: true,
      selectedZones: [],
      cameraInfoLoaded: false,
    };
  },
  computed: {
    selectedCameras() {
      return this.camera ? [this.camera.cameraId] : [];
    },
  },
  mounted() {
    if (this.camera) this.getCameraInfo();
    if (!this.$route.params.cameraId && this.$cookies.get("FootageCamera") && this.cameras.some(cam => cam.cameraId == this.$cookies.get("FootageCamera"))) {
      this.$router.push({ name: "Footage", params: { cameraId: this.$cookies.get("FootageCamera") } });
    }
  },
  watch: {
    camera() {
      this.getCameraInfo();
      this.$cookies.set("FootageCamera", this.camera.cameraId, "8m");
    },
  },
  methods: {
    zoneListToggled() {
      setTimeout(() => {
        this.$refs.footage.onResize();
        window.dispatchEvent(new Event("resize"));
      }, 100);
    },
    selectZone(id) {
      const i = this.selectedZones.indexOf(id);
      if (i !== -1) {
        this.selectedZones.splice(i, 1);
      } else {
        this.selectedZones.push(id);
      }
    },
    showZoneListMethod() {
      this.showZoneList = !this.showZoneList;
    },
    async getCameraInfo() {
      this.cameraInfoLoaded = false;
      await this.getCapabilities();
      await this.getStatus();
      await this.getFootageSettings();
      await this.getDetectionCapabilities();
      this.cameraInfoLoaded = true;
    },
    async getCapabilities() {
      if (!this.footageShouldDestroy && !this.camera.cameraCapabilities) {
        const cameraCapabilities = await restapi.getCameraCapabilities(this.camera.cameraId);
        this.$set(this.camera, "cameraCapabilities", cameraCapabilities);
      }
    },
    async getStatus() {
      if (!this.footageShouldDestroy && !this.camera.status) {
        const status = await restapi.getCameraStatus(this.camera.cameraId);
        this.$set(this.camera, "status", status);
      }
    },
    async getFootageSettings() {
      if (!this.footageShouldDestroy && !this.camera.footageSettings) {
        const settings = await restapi.getCameraFootageSettings(this.camera.cameraId);
        this.$set(this.camera, "footageSettings", settings);
      }
    },
    async getDetectionCapabilities() {
      if (!this.footageShouldDestroy && !this.camera.detectionCapabilities) {
        const detectionCapabilities = await restapi.getCameraDetectionCapabilities(this.camera.cameraId);
        this.$set(this.camera, "detectionCapabilities", detectionCapabilities);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main";

.dashboard {
  .dashboard-content {
    background-color: #eee;
    min-height: 100vh;
    &.has-navigation {
      min-height: $main-content-height;
      max-height: $main-content-height;
    }
  }
}
.footage {
  height: calc(100% - 36px) !important;
}
.zone-list {
  background-color: white;
}
.footage-spinner {
  left: 57%;
}
</style>
