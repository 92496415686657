<template>
  <v-container fluid class="video-sound pa-0">
    <v-app-bar dense flat  :color="toolbarColor || 'white'" class="title--text">
      <v-container v-if="isDirty" class="my-2 align ma-0">
        <v-row class="xs8 offset-xs2">
          <v-spacer></v-spacer>
            <v-btn  @click="clearCanvas">
              {{ $t('Discard changes') }}
            </v-btn>
            <v-btn  color="primary" @click="saveAreas">
              {{ $t('Save') }}
            </v-btn>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-container v-if="camera" class="pa-0 align">
      <v-container class="pa-0" fluid>
        <v-row>
          <v-col cols="10" class="hidden-sm-and-up">
            <v-btn text class="pa-0" @click="$emit('allCameras')">
              <v-icon left size="18">fa-arrow-left</v-icon>
              <p class="mb-0">{{ $t('Detection Areas') }}</p>
            </v-btn>
          </v-col>
          <v-col cols="2" class="hidden-sm-and-up">
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" dark icon>
                  <v-icon size="18" color="black">fa-ellipsis-v</v-icon>
                </v-btn>
              </template>
              <v-list class="px-4">
                <v-list-item v-for="(menu, i) in menus" :key="i" @click="$router.push({ path: `/settings/cameras/${camera.cameraId}/${menu.endpoint}` })">
                  <v-list-item-title>{{ menu.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>
      <v-row>
        <v-col cols="12" sm="10" offset-sm="1">
          <v-card id="card">
            <v-card-text class="pl-0 pt-0">
              <v-row class="px-3">
                <v-col class="pa-0 mb-2">
                  <div id="canvas-container">
                    <div class='img'>
                      <LiveVideo :camera="camera" :detectionArea="true" @isPlaying="liveLoaded = true" type="hls" :isMultiview="false"></LiveVideo>
                    </div>
                    <img v-if="!liveLoaded" :src="snapshot" :width="dimensions.x" :height="dimensions.y" class='img'/>
                    <canvas id="c" :width="dimensions.x" :height="dimensions.y"></canvas>
                  </div>
                  <div class="buttons mx-5">
                    <v-btn depressed @click="startPolygon" class="pl-1 mr-3" v-ripple color="primary" text-color="white">
                          <v-icon right small color="white">fas fa-vector-square</v-icon>
                      <span class="ml-2">{{ t("New Area") }}</span>
                    </v-btn>
                    <v-btn depressed @click="newBasicTripwire" class="pl-1 mr-3" v-ripple color="primary" text-color="white">
                          <v-icon right small color="white">fas fa-plus</v-icon>
                      <span class="ml-2">{{ t("New Tripwire") }}</span>
                    </v-btn>
                    <v-btn @click="guidelinesModal = true" class="pl-1 float-right" depressed color="primary" text-color="white">
                          <v-icon right small color="white">fas fa-info</v-icon>
                      <span class="ml-2">{{ t("Guidelines") }}</span>
                    </v-btn>
                  </div>
                  <!-- <span @click="guidelinesModal = true" class="primary--text cursor-pointer float-right">{{ t("Guidelines") }}</span> -->
                </v-col>
                <v-col class="pa-0 ml-3 mt-4">
                  <div class="area-list">
                    <v-hover
                      v-for="(area) in areas"
                      v-slot:default="{ hover }"
                      :key="area.areaId"
                    >
                      <v-row @click="selectArea(area)" class="area-list-item cursor-pointer mb-2 px-3"  :class="{ 'isSelected': area.selected }">
                        <v-col class="pa-0" cols="auto">
                          <v-icon class="area-icon" :color="area.color" small>{{ area.areaType === "ObjectInArea" ? "fas fa-vector-square" : "fas fa-share-alt"}}</v-icon>
                        </v-col>
                        <v-col class="pa-0">
                          <DetectionAreaItem :detectionArea="area" :hover="hover" @isDirty="isDirty = true"></DetectionAreaItem>
                        </v-col>
                        <v-col class="pa-0" cols="auto">
                          <v-btn x-small icon @click.stop="removeArea(area.areaId)">
                            <v-icon small>fas fa-times</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-hover>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog width="800px" v-model="guidelinesModal" @close="guidelinesModal = false">
      <v-card>
        <v-form v-on:submit.prevent="showChooseSubscriptionPopup">
          <v-card-title class="card-title mb-3">
            {{ $t('Detection Area') + " " + $t('Guidelines') }}
          </v-card-title>
          <v-card-text>
            <ul class="mb-5">
              <li class="mb-0">{{ $t('An area will apply for video motion and object/person detection. It does not apply for infrared/PIR events.') }}</li>
              <li class="mb-0">{{ $t('Overlapping areas or tripwires are possible but do not influence the object/person detection.') }}</li>
              <li class="mb-0">{{ $t('If you draw an area over the tripwire, it will disable the tripwire.') }}</li>
              <li class="mb-0">{{ $t('Only the highlighted video will trigger video motion and object/person detection.') }}</li>
              <li class="mb-0">{{ $t('Tripwires should not be close to the border, the video analytics need to be detect the object/person before it goes over the tripwire.') }}</li>
            </ul>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed type="button"  @click.stop="guidelinesModal = false">{{ $t('Close') }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { onMounted, reactive, ref, toRefs, onBeforeUnmount, watch } from "vue"
import { fabric } from "fabric"
import { store } from '@/store/index'
import { t } from "@eencloud/core-components/src/service/locale"
import restapi from "@eencloud/core-components/src/service/CMApi"
import DetectionAreaItem from "@/components/Cameras/DetectionAreaItem"
import LiveVideo from '@/components/Base/LiveVideo'

let isDirtyValue = false;
export default {
  name: "DetectionAreas",
  props: [
    "camera",
    "rights",
    "showToolbar",
    "appToolbar",
    "toolbarColor",
    "menus",
    "desktop",
  ],
  components: {
    DetectionAreaItem,
    LiveVideo,
  },
  setup(props, context) {
    const state = reactive({
      dimensions: { x: 678, y: 382 },
      canvasPadding: { x: 19, y: 11 },
      canvas: null,
      selectedArea: null,
      areas: [],
      removedAreas: [],
      isDirty: false,
      online: props.camera.status.online,
      cameraId: props.camera.cameraId,
      colors: [
        {red: 12,  green: 148, blue: 200},
        {red: 128, green: 171, blue: 84},
        {red: 146, green: 107, blue: 174},
        {red: 189, green: 73,  blue: 70},
        {red: 253, green: 107, blue: 57},
        {red: 232, green: 151, blue: 25},
      ],
      snapshot: null,
      guidelinesModal: false,
      liveLoaded: false,
      pointer: { x: 0, y: 0 },
      firstCircle: null,
      tempCircle: null,
      tempPolygon: null,
      tempMarker: null
    });

    const { loadInitialAreas, saveAreas, newBasicArea, removeArea } = useAreas(state, props)
    const { setupCanvas, clearCanvas } = useCanvas(state)

    const { selectArea } = selectingPoints(state)

    const { newBasicTripwire } = useTripwire(state)

    const { startPolygon } = usePolygon(state)

    onMounted(() => {
      setupCanvas()
      setupEventHandling(state)
      loadInitialAreas()
      addWindowUnloadListener()
      updateDirtyValue(state);
    });

    watch(() => state.isDirty, () => {
      updateDirtyValue(state)
    });

    function addWindowUnloadListener() {
      window.addEventListener('beforeunload', beforeWindowUnload)
    }
    onBeforeUnmount(() => {
      window.removeEventListener('beforeunload', beforeWindowUnload)
    });

    function beforeWindowUnload(e) {
      if (state.isDirty) {
        e.preventDefault()
        e.returnValue = ''
      } 
    }
    return { ...toRefs(state), startPolygon, newBasicTripwire, saveAreas, newBasicArea, removeArea, clearCanvas, selectArea, t, beforeWindowUnload };
  },

  beforeRouteLeave(to, from, next) {
    if (isDirtyValue === true) {
      const answer = window.confirm(
        this.$t("Changes you made may not be saved")
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }

};

function updateDirtyValue(state) {
  isDirtyValue = state.isDirty;
}

function useCanvas(state) {
  const { loadInitialAreas } = useAreas(state)
  const { addPoint, cancelPolygon, completePolygon } = usePolygon(state)

  function setupCanvas() {
    let canvas = new fabric.Canvas("c", {
      width: state.dimensions.x,
      height: state.dimensions.y,
      selection: false,
      fireRightClick: true,
      stopContextMenu: true
    })

    canvas.on('mouse:down', e => {
      if (state.tempPolygon) {
        if (e.button === 3) {
          if (state.tempPolygon.points.length > 2) {
            completePolygon()
          }
        } else {
          if (state.tempPolygon.points.length > 2 && state.firstCircle.hovered) {
            completePolygon()
          } else {
            addPoint(state.pointer, e)
          }
        }
      }
    })

    canvas.on('mouse:move', e => {
      if (state.tempCircle) {
        let pointer = state.canvas.getPointer(e)

        if (pointer.y < state.canvasPadding.y || pointer.x < state.canvasPadding.x) {
          pointer.y = Math.max(pointer.y, state.canvasPadding.y)
          pointer.x = Math.max(pointer.x, state.canvasPadding.x)
        }

        if (pointer.y > state.canvas.height - state.canvasPadding.y || pointer.x > state.canvas.width - state.canvasPadding.x) {
          pointer.y = Math.min(pointer.y, state.canvas.height - state.canvasPadding.y)
          pointer.x = Math.min(pointer.x, state.canvas.width - state.canvasPadding.x)
        }
        state.pointer = {x: pointer.x, y: pointer.y}

        if (state.tempMarker) {
          state.tempMarker.points[state.tempMarker.points.length - 1] = state.pointer
        }
        state.tempCircle.left = pointer.x
        state.tempCircle.top = pointer.y
        state.tempCircle.setCoords()
        canvas.renderAll()
      }
    })

    state.canvas = canvas
  }

  function clearCanvas() {
    restapi.cancelAll()
    state.canvas.clear()
    state.areas = []
    state.removedAreas = []
    state.firstCircle = null
    state.tempCircle = null
    state.tempPolygon = null
    state.tempMarker = null
    state.colors = [
        {red: 12,  green: 148, blue: 200},
        {red: 128, green: 171, blue: 84},
        {red: 146, green: 107, blue: 174},
        {red: 189, green: 73,  blue: 70},
        {red: 253, green: 107, blue: 57},
        {red: 232, green: 151, blue: 25},
      ]
    loadInitialAreas()
  }

  return { setupCanvas, clearCanvas }
}

function useAreas(state, props) {
  const { selectArea, addSelectablePoints, translateFromCoords, translateToCoords } = selectingPoints(state)
  const { createTripwire } = useTripwire(state)
  const { cancelPolygon } = usePolygon(state)

  function loadInitialAreas() {
    restapi.getCameraAnalyticsSetting(state.cameraId)
      .then(data => {
        state.canvas.clear()
        let areas = []
        for (const area of data.areas) {
          if (area.areaType === "ObjectInArea") {
            let newArea = createArea(
              area.name ? area.name : t('Area'),
              area.color,
              area.coordinates.map(x => translateFromCoords(x)),
              area.areaId,
              false
            )
            areas = [ ...areas, newArea ]
          } else if (area.areaType === "TripWire") {
            let tripwire = createTripwire(
              area.name ? area.name : t('Tripwire'),
              area.color,
              area.coordinates.map(x => translateFromCoords(x)),
              area.areaId,
              false
            )
            areas = [ ...areas, tripwire ]
          }
        }
        for (const area of areas) {
          state.canvas.add(area.object)
          state.colors.push(state.colors.shift())
          // addSelectablePoints(area)
        }
        state.areas = areas
        state.isDirty = false
        loadCameraSnapshot(state)
        if (state.areas[0]) {
          selectArea(state.areas[0])
        }
      })
      .catch(error => { console.error(error) })
  }

  async function saveAreas() {
    if (props.rights && props.rights.demoUser) {
        return this.$store.dispatch("demoMessage", this.$t("As a demo user you cannot make any changes."))
      }
    if (state.areas.length === 0) {
      store.dispatch('toastMessage', {
        showing: true,
        text: t(`You need at least one area.`),
        timeout: -1,
        color: 'warning'
      })
      return null
    }

    for (const area of state.areas) {
      if (!area.validName) {
        store.dispatch('toastMessage', {
          showing: true,
          text: t(`Please change you area's name to a valid one.`),
          timeout: -1,
          color: 'warning'
        })
        return null
      }
    }

    let promises = []

    let analytics = await restapi.getCameraAnalyticsSetting(state.cameraId)
    let areaCount = analytics.areas.length
    // areaCount - state.removedAreas 0

    let newAreas = state.areas.filter(x => x.isNew)
    let n = areaCount === state.removedAreas.length ? Math.floor(state.removedAreas.length / 2) : state.removedAreas.length

    let areasToDelete = state.removedAreas.splice(0, n)
    await deleteAreas(areasToDelete)

    for (const area of state.areas) {
      const data = {
        name: area.name,
        color: area.originalColor,
        exclude: area.areaType === "ObjectInArea" ? false : null,
        coordinates: area.points.map(x => translateToCoords(x)),
        areaType: area.areaType
      }
      if (area.isNew) {
        let promise = restapi.addCameraAnalyticsArea(state.cameraId, data)
          .then(data => {
            area.areaId = data.areaId
            area.isNew = false
          })
        promises = [ ...promises, promise]
      } else {
        let promise = restapi.updateCameraAnalyticsArea(state.cameraId, area.areaId, data)
        promises = [ ...promises, promise]
      }
    }

    Promise.all(promises)
      .then(values => {
        deleteAreas(state.removedAreas).then(() => {
          state.isDirty = false
          store.dispatch('toastMessage', {
            showing: true,
            text: t(`Detection areas saved`),
            timeout: 2000,
            color: 'primary'
          })
        })
      })
      .catch(error => {
        console.error(error)
        store.dispatch('toastMessage', {
          showing: true,
          text: t(`Error on saving areas`),
          timeout: -1,
          color: 'warning'
        })
      })
  }

  function addAreas(areas) {
    let promises = []
    for (const area of areas) {
      const data = {
        name: area.name,
        color: area.originalColor,
        exclude: area.areaType === "ObjectInArea" ? false : null,
        coordinates: area.points.map(x => translateToCoords(x)),
        areaType: area.areaType
      }
      let promise = restapi.addCameraAnalyticsArea(state.cameraId, data)
        .then(response => {
          area.areaId = response.areaId
          area.isNew = false
        })
      promises = [ ...promises, promise]
    }
    return Promise.all(promises)
  }

  function updateAreas(areas) {
    let promises = []
    for (const area of areas) {
      const data = {
        name: area.name,
        color: area.originalColor,
        exclude: area.areaType === "ObjectInArea" ? false : null,
        coordinates: area.points.map(x => translateToCoords(x)),
        areaType: area.areaType
      }
      let promise = restapi.updateCameraAnalyticsArea(state.cameraId, area.areaId, data)
      promises = [ ...promises, promise]
    }
    return Promise.all(promises)
  }

  function deleteAreas(areas) {
    let promises = []
    for (const areaId of areas) {
      let promise = restapi.deleteCameraAnalyticsArea(state.cameraId, areaId)
      promises = [ ...promises, promise]
    }
    return Promise.all(promises)
  }

  function createArea(name, color, points, areaId, isNew) {
    if (state.areas.length >= 10) {
      return null
    }
    if (!color) {
      color = state.colors[0]
      state.colors.push(state.colors.shift())
    }
    let newArea = { name, areaId, areaType: "ObjectInArea", isNew, originalColor: color, points, selected: false, fill: `rgba(${color.red},${color.green},${color.blue},0.2)`, color: `rgb(${color.red},${color.green},${color.blue})`, circles: [], validName: true }
    let options = { fill: newArea.fill, stroke: newArea.color, strokeWidth: 3, selectable: false, objectCaching: false, evented: false }
    newArea.object = new fabric.Polygon(newArea.points, options)
    return newArea;
  }

  function newBasicArea() {
    cancelPolygon()
    const coordinates = [{x: 0.3, y: 0.3},{x: 0.7, y: 0.3},{x: 0.7, y: 0.7},{x: 0.3, y: 0.7}]
    let newArea = createArea(t('Area'), state.colors[0], coordinates.map(x => translateFromCoords(x)), new Date().getTime(), true)
    if (newArea) {
      state.colors.push(state.colors.shift())
      state.canvas.add(newArea.object)
      state.areas = [ ...state.areas, newArea ]
      selectArea(newArea)
      state.isDirty = true
    } else {
      store.dispatch('toastMessage', {
        showing: true,
        text: t(`You've reached the limit of 10 areas!`),
        timeout: -1,
        color: 'error'
      })
    }
  }

  function removeArea(areaId) {
    let index = state.areas.findIndex(x => x.areaId === areaId)
    let area = state.areas.splice(index, 1)[0]
    state.canvas.remove(area.object)
    state.isDirty = true
    for (const circle of area.circles) {
      state.canvas.remove(circle)
    }
    if (!area.isNew) {
      state.removedAreas = [ ...state.removedAreas, area.areaId ]
    }
    if (state.areas[0]) {
      selectArea(state.areas[0])
    }
  }

  return { loadInitialAreas, saveAreas, newBasicArea, removeArea }
}

function usePolygon(state) {
  const { selectArea, translateFromCoords } = selectingPoints(state)

  function startPolygon() {
    if (!state.tempPolygon && !state.tempCircle) {
      selectArea(null)
      let color = state.colors[0]
      let polygon = { name: t('Area'), areaId: new Date().getTime(), areaType: "ObjectInArea", isNew: true, originalColor: color, points: [], selected: false, fill: `rgba(${color.red},${color.green},${color.blue},0.2)`, color: `rgb(${color.red},${color.green},${color.blue})`, circles: [], validName: true }
      let circle = new fabric.Circle({
        radius: 9,
        stroke: polygon.color,
        strokeWidth: 3,
        fill: 'white',
        left: 0,
        top: 0,
        originX: 'center',
        originY: 'center',
        hasBorders: false,
        hasControls: false,
        name: 'temp',
        parent: null,
        selectable: false,
        objectCaching: false,
        evented: false
      })
      state.tempPolygon = polygon
      state.tempCircle = circle
      state.canvas.add(circle)
    } else {
      cancelPolygon()
    }
  }

  function cancelPolygon() {
    if (state.firstCircle) {
      state.canvas.remove(state.firstCircle)
      state.firstCircle = null
    }
    if (state.tempCircle) {
      state.canvas.remove(state.tempCircle)
      state.tempCircle = null
    }
    if (state.tempPolygon) {
      state.canvas.remove(state.tempPolygon.object)
      state.tempPolygon = null
    }
    if (state.tempMarker) {
      state.canvas.remove(state.tempMarker.object)
      state.tempMarker = null
    }
  }

  function completePolygon() {
    state.canvas.remove(state.tempPolygon.object)
    state.canvas.remove(state.tempMarker.object)
    state.canvas.remove(state.tempCircle)
    state.canvas.remove(state.firstCircle)

    let newArea = { ...state.tempPolygon }
    let options = { fill: state.tempPolygon.fill, stroke: state.tempPolygon.color, strokeWidth: 3, selectable: false, objectCaching: false, evented: false }
    newArea.object = new fabric.Polygon(newArea.points, options)

    state.areas = [ ...state.areas, newArea ]
    state.isDirty = true

    state.colors.push(state.colors.shift())
    state.canvas.add(newArea.object)
    selectArea(newArea)

    state.firstCircle = null
    state.tempPolygon = null
    state.tempCircle = null
    state.tempMarker = null
  }

  function addPoint(coordinates, event) {
    if (state.tempPolygon.object) {
      state.canvas.remove(state.tempPolygon.object)
      state.canvas.remove(state.firstCircle)
    }
    let options = { fill: state.tempPolygon.fill, stroke: state.tempPolygon.color, strokeWidth: 3, selectable: false, objectCaching: false, evented: false }
    state.tempPolygon.points = [ ...state.tempPolygon.points, coordinates ]
    state.tempPolygon.object = new fabric.Polygon(state.tempPolygon.points, options)
    state.canvas.add(state.tempPolygon.object)


    if (state.tempMarker) {
      state.canvas.remove(state.tempMarker.object)
    }
    let marker = { points: [], color: state.tempPolygon.color }
    let markerOptions = { fill: state.tempPolygon.fill, stroke: state.tempPolygon.color, strokeDashArray: [5, 5], strokeWidth: 3, selectable: false, objectCaching: false, evented: false }
    marker.points = [ state.tempPolygon.points[0], coordinates, coordinates ]
    marker.object = new fabric.Polygon(marker.points, markerOptions)
    state.tempMarker = marker
    state.canvas.add(state.tempMarker.object)


    let circle = new fabric.Circle({
      radius: 9,
      stroke: state.tempPolygon.color,
      strokeWidth: 3,
      fill: 'white',
      left: state.tempPolygon.points[0].x,
      top: state.tempPolygon.points[0].y,
      originX: 'center',
      originY: 'center',
      hasBorders: false,
      hasControls: false,
      name: 'temp',
      parent: null,
      hovered: false
    })
    circle.on('mouseover', () => {
      circle.set("fill", state.tempPolygon.color)
      circle.hovered = true
      state.canvas.renderAll()
    })
    circle.on('mouseout', () => {
      circle.set("fill", 'white')
      circle.hovered = false
      state.canvas.renderAll()
    })
    state.firstCircle = circle
    state.canvas.add(circle)
  }

  return { startPolygon, addPoint, cancelPolygon, completePolygon }
}

function useTripwire(state) {
  const { selectArea, translateFromCoords } = selectingPoints(state)
  const { cancelPolygon } = usePolygon(state)

  function createTripwire(name, color, points, areaId, isNew) {
    if (state.areas.length >= 10) {
      return null
    }
    if (!color) {
      color = state.colors[0]
      state.colors.push(state.colors.shift())
    }
    let tripwire = { name, areaId, areaType: "TripWire", isNew, originalColor: color, points, selected: false, color: `rgb(${color.red},${color.green},${color.blue})`, circles: [], validName: true }
    let options = { fill: 'rgba(0,0,0,0)', stroke: tripwire.color, strokeWidth: 3, selectable: false, objectCaching: false, evented: false }
    tripwire.object = new fabric.Polyline(tripwire.points, options)
    return tripwire;
  }

  function newBasicTripwire() {
    cancelPolygon()
    const coordinates = [{x: 0.3, y: 0.3},{x: 0.7, y: 0.7}]
    let newTripwire = createTripwire(t('Tripwire'), state.colors[0], coordinates.map(x => translateFromCoords(x)), new Date().getTime(), true)
    if (newTripwire) {
      state.colors.push(state.colors.shift())
      state.canvas.add(newTripwire.object)
      state.areas = [ ...state.areas, newTripwire ]
      selectArea(newTripwire)
      state.isDirty = true
    } else {
      store.dispatch('toastMessage', {
        showing: true,
        text: t(`You've reached the limit of 10 areas!`),
        timeout: -1,
        color: 'warning'
      })
    }
  }

  return { createTripwire, newBasicTripwire }
}

function selectingPoints(state) {
  function addSelectablePoints(area) {
    area.object.points.forEach(function(point, index) {
      var circle = new fabric.Circle({
        radius: 9,
        stroke: area.color,
        strokeWidth: 3,
        fill: 'white',
        left: point.x,
        top: point.y,
        originX: 'center',
        originY: 'center',
        hasBorders: false,
        hasControls: false,
        name: index,
        parent: area.object
      });
      area.circles = [ ...area.circles, circle ]
      state.canvas.add(circle);
    });
  }

  function translateFromCoords(point) {
    return { x: point.x * (state.dimensions.x - 2 * state.canvasPadding.x) + state.canvasPadding.x, y: point.y * (state.dimensions.y - 2 * state.canvasPadding.y) + state.canvasPadding.y  }
  }

  function translateToCoords(point) {
    return { x: (point.x - state.canvasPadding.x) / (state.dimensions.x - 2 * state.canvasPadding.x), y: (point.y - state.canvasPadding.y) / (state.dimensions.y - 2 * state.canvasPadding.y) }
  }

  function selectArea(newArea) {
    state.selectedArea = newArea
    for (const area of state.areas) {
      if (area === newArea) {
        if (!area.selected) {
          addSelectablePoints(area)
          area.selected = true
        }
      } else {
        for (const circle of area.circles) {
          state.canvas.remove(circle)
          area.selected = false
        }
      }
    }
  }

  return { selectArea, addSelectablePoints, translateFromCoords, translateToCoords }
}

function setupEventHandling(state) {
  state.canvas.on('object:moving', function (options) {
    var objType = options.target.get('type')
    var obj = options.target

    if (obj.currentHeight > obj.canvas.height || obj.currentWidth > obj.canvas.width) {
      return
    }
    obj.setCoords()

    if (obj.top < state.canvasPadding.y || obj.left < state.canvasPadding.x) {
      obj.top = Math.max(obj.top, state.canvasPadding.y)
      obj.left = Math.max(obj.left, state.canvasPadding.x)
    }

    if (obj.top + obj.height  > obj.canvas.height - state.canvasPadding.y || obj.left + obj.width  > obj.canvas.width - state.canvasPadding.x) {
      obj.top = Math.min(obj.top, obj.canvas.height - state.canvasPadding.y)
      obj.left = Math.min(obj.left, obj.canvas.width - state.canvasPadding.x)
    }

    state.isDirty = true
    obj.parent.points[obj.name] = {x: obj.getCenterPoint().x, y: obj.getCenterPoint().y}
  });
}

function loadCameraSnapshot(state) {
  if (state.online) {
    return restapi.getSnapshot(state.cameraId, "666x500")
      .then(function (snapshot) {
        state.snapshot = snapshot
      })
      .catch(function (error) {
        console.error(error)
        loadCameraSnapshot(state)
      })
  }
}
</script>

<style>
.row {
  margin: 0px -12px 0px -12px
}

#card {
  max-width: 1000px;
  min-width: 678px;
}

.img {
  padding: 11px 19px;
  width: 678px;
  height: 382px;
  position: absolute;
  z-index: 1;
}

.buttons {
  width: 637px;
}

#canvas-container {
  display: inline-block;
  margin: 0 auto;
  position: relative;
  margin-top: 6px;
}

canvas {
  position: relative;
  z-index: 20;
}

.area-list {
  /* height: 360px; */
  overflow-y: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.area-list-item {
  margin: 2px 5px;
  border-radius: 4px;
  padding: 5px 20px;
  max-width: 640px;
  min-width: 160px;
  box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}

.isSelected {
  background-color: white;
  box-shadow:0 1px 4px rgba(0, 0, 0, 0.3);
}

.area-icon {
  width: 16px;
  padding-bottom: 3px;
}

.card-title {
  background-color: #F6F6F6;
}
</style>
