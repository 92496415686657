import { render, staticRenderFns } from "./DetectionAreaItem.vue?vue&type=template&id=58f09c35"
import script from "./DetectionAreaItem.vue?vue&type=script&lang=js"
export * from "./DetectionAreaItem.vue?vue&type=script&lang=js"
import style0 from "./DetectionAreaItem.vue?vue&type=style&index=0&id=58f09c35&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports