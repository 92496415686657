<template>
  <v-container class="py-0">
    <v-row>
      <input ref="ref" v-model="area.name" v-autowidth="{maxWidth: '100%', minWidth: '20px', comfortZone: 0}" class="area-name ml-3 mr-2" :class="{ 'isInvalid': !area.validName }"/>
      <v-btn v-if="hover" x-small icon @click="editAreaName">
        <v-icon x-small>fas fa-pencil-alt</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { onMounted, reactive, ref, toRefs, watch } from "vue"
export default {
  name: "DetectionAreaItem",
  props: [
    "detectionArea",
    "hover"
  ],
  setup(props, { emit }) {
    const state = reactive({
      ref: null,
      area: props.detectionArea,
    });

    const { editAreaName } = areaName(state, emit)

    return { ...toRefs(state), editAreaName }
  }
}

function areaName(state, emit) {
  watch(() => state.area.name, (name, prevName) => { 
    if (name !== prevName && prevName !== undefined) {
      emit('isDirty')
    }
    let regex = /^[A-Za-zŽžÀ-ÿ0-9]+$/g
    state.area.validName = regex.test(name)
  })

  function editAreaName() {
    state.ref.focus()
  }
  
  return { editAreaName }
}
</script>

<style>

.isInvalid {
  color: red;
}

.area-name {
  width: calc(100% - 50px) !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
}

</style>