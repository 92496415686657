<template>
  <v-container fluid class="pa-0">
    <v-card width="90%">
      <v-form>
        <v-container class="pa-8" fluid>
          <v-layout row wrap>
            <v-flex sm6 class="hidden-xs-only">
              <p class="subheading my-2">{{ $t("Email") }}</p>
            </v-flex>
            <v-flex sm6 xs12>
              <v-text-field
                :label="$vuetify.breakpoint.smAndUp ? '' : $t('Email')"
                :solo="$vuetify.breakpoint.smAndUp"
                flat
                v-model="Email"
                dense
                @input="$v.Email.$touch()"
                @blur="$v.Email.$touch()"
                :error-messages="emailErrors"
                :background-color="$vuetify.breakpoint.smAndUp ? 'grey lighten-3' : ''"
              ></v-text-field>
            </v-flex>
            <v-flex sm12 class="hidden-xs-only" pb-5><v-divider></v-divider></v-flex>
            <een-spinner 
              :isloading="addingUser"
              :size="50"
              :width="7"
            ></een-spinner>
            <v-flex sm6 class="hidden-xs-only">
              <p class="subheading my-2">{{ $t("Your Password") }}</p>
            </v-flex>
            <v-flex sm6 xs12>
              <v-text-field
                :label="$vuetify.breakpoint.smAndUp ? '' : $t('Your Password')"
                :solo="$vuetify.breakpoint.smAndUp"
                flat
                @input="$v.Password.$touch()"
                @blur="$v.Password.$touch()"
                :error-messages="passwordErrors"
                type="password"
                v-model="Password"
                dense
                :background-color="$vuetify.breakpoint.smAndUp ? 'grey lighten-3' : ''"
              ></v-text-field>
            </v-flex>
            <v-flex sm12 class="hidden-xs-only" pb-5><v-divider></v-divider></v-flex>
            <v-flex xs12>
              <v-btn
                color="primary"
                :disabled="$v.$invalid || (!$v.Email.$dirty && !$v.Password.$dirty)"
                @click="saveEmail"
                >{{ $t("Save Email") }}</v-btn
              >
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { required, minLength, maxLength, email } from "vuelidate/lib/validators";
import { store } from "@/store";
import { getMessageFromError } from "@eencloud/core-components/src/service/errors";

export default {
  name: "ChangeEmail",
  props: ["rights"],
  data() {
    return {
      user: {},
      FirstName: "",
      LastName: "",
      Email: "",
      Password: "",
      addingUser: false,
    };
  },
  async mounted() {
    try {
      const response = await this.restapi.getSpecificUser(this.$route.params.userId);
      this.FirstName = response.firstName;
      this.LastName = response.lastName;
      this.Email = response.email;
    } catch (error) {
      store.dispatch("toastMessage", {
        text: getMessageFromError(error, "GET_USER"),
        color: "error",
        showing: true,
        timeout: -1,
        support: true
      });
    }
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.Email.$dirty) return errors;
      if (!this.$v.Email.required) errors.push(this.$t("Email is required"));
      if (this.$v.Email.$invalid) errors.push(this.$t("Enter a valid email address"));
      if (!this.$v.Email.minLength || !this.$v.Email.maxLength) {
        errors.push(this.$t("Email should be 5-32 characters"));
      }
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.Password.$dirty) return errors;
      if (!this.$v.Password.required) errors.push(this.$t("Password is required"));
      if (!this.$v.Password.minLength || !this.$v.Password.maxLength) {
        errors.push(this.$t("Password should be 2-32 characters"));
      }
      return errors;
    },
  },
  methods: {
    saveEmail() {
      if (this.rights && this.rights.demoUser) {
        return this.$store.dispatch("demoMessage")
      }
      const that = this;
      const newUser = { email: this.Email, firstName: this.FirstName, lastName: this.LastName };
      this.addingUser = true;
      this.restapi
        .isPasswordValid(this.Password)
        .then(function () {
          that.restapi
            .setUserDetails(that.$route.params.userId, newUser)
            .then(function () {
              that.addingUser = false;
              that.$v.$reset();
              store.dispatch("toastMessage", {
                text: that.$t("User email been updated successfully"),
                color: "primary",
                showing: true,
                timeout: 4000,
              });
            })
            .catch(function (error) {
              that.addingUser = false;
              that.Password = "";
              store.dispatch("toastMessage", {
                text: getMessageFromError(error, "UPDATE_USER"),
                color: "error",
                showing: true,
                timeout: -1,
                support: true
              });
            });
        })
        .catch(function (error) {
          that.addingUser = false;
          store.dispatch("toastMessage", {
            text: getMessageFromError(error, "PASSWORD_CORRECT"),
            color: "error",
            showing: true,
            timeout: -1,
            support: true
          });
        });
    },
  },
  validations: {
    Password: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(32),
    },
    Email: {
      required,
      email,
      minLength: minLength(5),
      maxLength: maxLength(255),
    },
  },
};
</script>

<style lang="scss" scoped>
form.v-form.larger {
  font-size: 14px;
  input {
    font-size: inherit;
  }
}
</style>
