<template>
  <span>
    <v-row>
      <v-col>
        <personal-details :rights="rights" @userUpdated="userUpdated"></personal-details>
      </v-col>
      <v-col>
        <change-email :rights="rights"></change-email>
      </v-col>
    </v-row>
    <v-row>
      <v-col offset="6">
    <change-password :rights="rights" :user="user"></change-password>
    </v-col>
    </v-row>
  </span>
</template>

<script>
import PersonalDetails from "@/components/Users/UserDetails/PersonalDetails";
import ChangeEmail from "@/components/Users/UserDetails/ChangeEmail";
import ChangePassword from "@/components/Users/UserDetails/ChangePassword";

import { store } from "@/store";

export default {
  name: "UserDetails",
  props: ["user", "rights"],
  components: {
    PersonalDetails,
    ChangeEmail,
    ChangePassword,
  },
  methods: {
    userUpdated(){
      this.$emit('nameUpdated')
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/main";

.settings {
  .settings-content {
    background-color: $color-panel-grey;
    min-height: $main-content-height;
  }
}

#settings-content {
  margin-left: 400px;
  margin-top: 50px;
}
</style>
