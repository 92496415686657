import FileSaver from 'file-saver'
import { getTimeStamp } from "@eencloud/core-components/src/service/time"
import { store } from '@/store/index'

Date.prototype.accountTimeStamp = function() {
  return getTimeStamp(store.getters.accountTimeZoneOffset, this)
}

export function getImgUrl (img) {
  var pixelRatio = getPixelRatio()
  var images = getImagesContext(pixelRatio)
  return images('./' + img)
}

export function getConfiguration () {
  /* eslint-disable no-undef */
  return config
}

export function title () {
  var config = getConfiguration()
  return config.title
}

export function version () {
  var config = getConfiguration()
  return config.version
}

export function getPixelRatio () {
  return window.devicePixelRatio
}

export function getImagesContext (pixelRatio) {
  // fallback is to scale 1.0
  var images = require.context('@eencloud/core-components/src/assets/images', false, /\.jpg$|\.png$|.gif$/)

  if (pixelRatio > 1 && pixelRatio < 2) {
    images = require.context('@eencloud/core-components/src/assets/images/hdpi150', false, /\.jpg$|\.png$|.gif$/)
  } else if (pixelRatio >= 2 && pixelRatio < 3) {
    images = require.context('@eencloud/core-components/src/assets/images/hdpi200', false, /\.jpg$|\.png$|.gif$/)
  } else if (pixelRatio >= 3) {
    images = require.context('@eencloud/core-components/src/assets/images/hdpi300', false, /\.jpg$|\.png$|.gif$/)
  }

  return images
}

export function phoneNumber (value) {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true
  }
  return (/^[+]?[0-9]{1,}/).test(value)
}

export function changeFavicon (link) {
  const favicon = document.getElementById('favicon')
  if (favicon !== null) {
    favicon.href = '/' + link
  }
}

export function saveFile (fileData, fileName) { 
  FileSaver.saveAs(fileData, fileName)
}

export function pad (num, size = 2) {
  var s = '000000000' + num
  return s.substr(s.length - size)
}

export function getUrl (path, extraQuery) {
  const href = window.location.href
  const hashPos = href.indexOf('#')
  let base = hashPos > -1 ? href.slice(0, hashPos) : href

  const searchPos = base.indexOf('?')
  const query = searchPos > -1 ? base.slice(searchPos) : ''
  base = query ? base.slice(0, searchPos) : base
  let extra = ''
  Object.keys(extraQuery).forEach(key => {
      extra += `&${key}=${extraQuery[key]}`
  })

  return `${base}#${path}${query}${extra}`
}

export function interval(duration, fn){
  var _this = this
  this.baseline = undefined
  
  this.run = function(){
    if(_this.baseline === undefined){
      _this.baseline = new Date().getTime()
    }
    fn()
    var end = new Date().getTime()
    _this.baseline += duration
 
    var nextTick = duration - (end - _this.baseline)
    if(nextTick<0){
      nextTick = 0
    }
    
    _this.timer = setTimeout(function(){
      _this.run(end)
    }, nextTick)
  }

  this.stop = function(){
    clearTimeout(_this.timer)
  }
}