<template>
  <v-container fluid class="dashboard my-0 py-0">
    <v-row justify="space-around" class="fill-height">
      <v-col class="dashboard-content">
        <v-row class="px-3 pb-4 pt-6" justify="center">
          <v-col cols="3" class="mt-4">
            <h2 class="headline">{{ $t("Devices") }}</h2>
          </v-col>
          <v-col cols="5">
            <v-text-field
              v-model="search"
              append-icon="fa-search"
              :label="$t('Search')"
              single-line
              hide-details
              width="300"
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="mt-3">
            <v-btn
              v-if="rights && (rights.addCameraManually || rights.demoUser)"
              color="primary"
              :ripple="false"
              @click="$router.push({ name: 'AddCamera' })"
              class="float-right"
              >{{ $t("Add Device") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="allCameras"
            item-key="cameraId"
            :key="key"
            hide-default-header
            dense
            :items-per-page="25"
            :search="search"
            :footer-props="{ 
              'items-per-page-options': [10, 25, 50, -1],
              'items-per-page-all-text': $t('All'),
              'items-per-page-text': $t('Rows per page')
             }"
            :loading-text="$t('Loading results')"
            :no-results-text="$t('No matching records found')"
            :no-data-text="$t('No data')"
          >
            <template v-slot:header="{ props, on }">
              <thead>
                <tr>
                  <th class="table-head" v-for="(header, index) in props.headers" :key="index">
                    <a @click="on.sort(header.value)">
                      {{ $t(header.text) }}
                      <v-icon
                        class="pb-1"
                        v-if="header.value === props.options.sortBy[0]"
                        color="grey darken-2"
                        size="12px"
                        >{{
                          props.options.sortDesc[0] ? "fas fa-long-arrow-alt-down" : "fas fa-long-arrow-alt-up"
                        }}</v-icon
                      >
                    </a>
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr @click="selectedPath(item)" :key="item.deviceId" :class="{ 'table-row': true }">
                <td class="table-cell">
                  <v-icon class="pl-3" size="14">{{
                    item.deviceGroupType == "camera" ? "fa-video" : "fas fa-hdd"
                  }}</v-icon>
                </td>
                <td class="table-cell">{{ item.name }}</td>
                <td class="table-cell">{{ item.deviceInfo ? item.deviceInfo.brand : "" }}</td>
                <td class="table-cell">{{ item.deviceInfo ? item.deviceInfo.model : "" }}</td>
                <td class="table-cell">{{ item.zoneName }}</td>
                <td class="table-cell action-buttons">
                  <div class="d-flex flex-row justify-end">
                    <v-btn v-if="rights && (rights.editCamera || rights.demoUser)" icon class="mr-3">
                      <v-icon color="grey darken-2" size="18px">fas fa-cog</v-icon>
                    </v-btn>
                    <v-btn v-if="rights && (rights.deleteCamera || rights.demoUser)" @click.stop="selectToDelete(item)" icon class="mr-3">
                      <v-icon color="grey darken-2" size="18px">fas fa-trash-alt</v-icon>
                    </v-btn>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <een-spinner :size="50" :isloading="isloading"></een-spinner>
    <camera-delete-dialog
      :camera="selectedCamera"
      :device="selectedDevice"
      :showDialog="deletingCamera"
      :rights="rights"
      @closeDialog="closeDeleteDialog"
      @deleted="cameraDeleted"
    ></camera-delete-dialog>
  </v-container>
</template>

<script>
import { reactive, toRefs, watch, onMounted } from "vue";
import restapi from "@eencloud/core-components/src/service/CMApi";
import CameraDeleteDialog from "@/components/Base/CameraDeleteDialog";
import { getMessageFromError } from "@eencloud/core-components/src/service/errors";
import { store } from "@/store";

export default {
  name: "Cameras",
  components: {
    CameraDeleteDialog,
  },
  props: ["cameras", "account", "zones", "rights"],
  setup(props, context) {
    let data = reactive({
      deletingCamera: false,
      selectedCamera: null,
      isloading: false,
      selectedDevice: null,
      headers: [
        { text: "Type", value: "deviceGroupType" },
        { text: "Name", value: "name" },
        { text: "Brand", value: "deviceInfo.brand" },
        { text: "Model", value: "deviceInfo.model" },
        { text: "Zone", value: "zoneName" },
        { text: "", value: "expand" },
      ],
      search: "",
      allCameras: [],
      key: 0,
    });

    onMounted(() => {
      setupTableData();
    });

    watch(
      () => props.zones ,
      () => {
          setupTableData();
      }
    );

    const { setupTableData } = useCameraInfo(data, props);
    const { selectToDelete, closeDeleteDialog, cameraDeleted } = useDeleteCamera(data, context, props, setupTableData);
    const { selectedPath } = selectedDevicePath();

    return { ...toRefs(data), selectToDelete, closeDeleteDialog, cameraDeleted, selectedPath, setupTableData };
  },
};

function useCameraInfo(data, props) {
  function setupTableData() {
    getAllDevices();
  }

  async function getAllDevices() {
    data.isloading = true;
    try {
      data.allCameras = await restapi.getDevices();
      data.allCameras.forEach((camera, i) => getCameraInfo(camera, i));
      data.isloading = false;
      data.key++;
    } catch (error) {
      data.isloading = false;
      console.log(error);
      store.dispatch("toastMessage", {
        showing: true,
        text: getMessageFromError(error, "GET_ALL_DEVICES"),
        timeout: -1,
        color: "error",
        support: true
      });
    }
  }

  async function getCameraInfo(device, i) {
    try {
      let deviceInfo = await restapi.getDeviceInfoBrand(device.deviceId);
      Object.assign(data.allCameras[i], { deviceInfo: deviceInfo });
      if (device.deviceGroupType === "camera") {
        let zone = props.zones.find((zone) => zone.zoneId === device.zoneId);
        Object.assign(data.allCameras[i], { zoneName: zone.name });
      }

      data.key++; // trick to update the table
    } catch (error) {
      console.log(error);
    }
  }
  return { setupTableData };
}

function selectedDevicePath() {
  function selectedPath(item) {
    if (item.deviceGroupType == "camera") {
      return this.$router.push({ path: `/settings/cameras/${item.deviceId}` });
    } else {
      return this.$router.push({ path: `/settings/devices/${item.deviceId}` });
    }
  }

  return { selectedPath };
}

function useDeleteCamera(data, context, props, setupTableData) {

  function selectToDelete(item) {
    if (props.rights?.demoUser) {
      return store.dispatch("demoMessage")
    }
    if (item.deviceGroupType === "camera") {
      data.selectedCamera = item;
      Object.assign(data.selectedCamera, { cameraId: item.deviceId });
    }
    if (item.deviceGroupType === "multiChannelDevice") data.selectedDevice = item;
    data.deletingCamera = true;
  }

  function cameraDeleted() {
    closeDeleteDialog();
    setupTableData();
    context.emit("loadCameras");
  }

  function closeDeleteDialog() {
    data.selectedCamera = null;
    data.selectedDevice = null;
    data.deletingCamera = false;
  }

  return { selectToDelete, closeDeleteDialog, cameraDeleted };
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main";

.dashboard {
  background-color: #eee;
  .dashboard-content {
    min-height: $main-content-height;
    max-width: 1000px;
    margin: auto;
  }
}

.headline {
  color: #555555;
}

.table-head {
  border-bottom-color: var(--v-primary-lighten1) !important;
  border-bottom-width: 2px !important;
}

.table-row {
  &:nth-child(even) {
    background-color: #f8f8f8;
  }
  &.expanded {
    background-color: #f5f5f5;
  }
  .table-cell {
    padding: 10px;
    vertical-align: middle;
    font-size: 0.9em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none !important;
    &:hover {
      cursor: pointer;
    }
    &.camera-status {
      text-align: center;
      color: white;
      .online {
        background-color: #207cad;
        padding: 5px;
        border-radius: $border-radius;
      }
      .offline {
        background-color: #d43536;
        padding: 5px;
        border-radius: $border-radius;
      }
    }
    &.action-buttons {
      padding: 5px;
      &::v-deep .v-btn {
        height: 30px;
        width: 30px;
      }
    }
  }
}

.expanded-area {
  cursor: default;
  .row {
    .col {
      padding: 0;
      span {
        font-size: 12px;
        color: rgb(204, 204, 204);
        img {
          height: 14px;
        }
        &.connectionIcon {
          color: rgb(74, 74, 74);
        }
      }
      p {
        margin-bottom: 5px;
      }
    }
  }
}

.iccid-icon,
.sim-status-icon {
  width: 20px;
  display: inline-block;
  margin-right: 5px;
}

.sim-status-icon {
  &.offline {
    path {
      fill: $color-gray;
    }
  }
  &.sim-installed {
    path {
      fill: $primary;
    }
  }
  &.no-sim-installed {
    path {
      fill: $color-gray;
    }
  }
}

.iccid-label,
.sim-status-label {
  display: inline-block;
}

.iccid-block,
.sim-status-block {
  padding-top: 5px;
}

.is-divider {
  height: 2px;
  background-color: #dfdfdf73;
  display: block;
  margin-top: 0.5rem;
  margin-bottom: 1.75rem;
}

div.spinner-container {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  padding-top: 40px;
  opacity: 0.5;
}
</style>
