<template>
  <div class="hover-width">
    <div class="zone-list-container" :class="{ hover: mini }">
      <div class="control-buttons">
        <v-btn
          height="25px"
          width="25px"
          class="mx-2"
          fab
          elevation="2"
          :ripple="false"
          x-small
          :retain-focus-on-click="false"
          @click="toggleMini"
          @mouseenter="toggleEnter"
          @mouseleave="toggleLeave"
        >
          <v-icon size="15px" v-if="mini" color="#555555">fas fa-angle-right</v-icon>
          <v-icon size="15px" v-else color="#555555">fas fa-angle-left</v-icon>
        </v-btn>
      </div>
      <div @mouseenter="zoneEnter" @mouseleave="zoneLeave" class="zone-list" :class="{ mini: mini && !expanded }">
        <div class="zone-list-inner">
          <v-text-field
            v-model="filter"
            hide-details
            class="px-4 mb-5 py-0 pr-9"
          > 
            <v-icon slot="prepend-inner" class="pt-1" size="16">fa-search</v-icon>
          </v-text-field>
          <v-list v-if="!mini || expanded" class="list pa-0" :class="{ 'single-view': view && view === 'single' }">
            <template v-for="(zone, index) in filteredZones">
              <zone-cameras
                :id="`zone-${index}`"
                :zone="zone"
                :key="`zone-${index}`"
                :selected="isSelectedZone(zone)"
                :cameras="filteredCameras"
                :camera="camera"
                :view="view"
                :rights="rights"
                :selectedCameras="selectedCameras"
                @selectZone="selectZone"
                @selectCamera="selectCamera"
                @showMultiView="showMultiView"
              ></zone-cameras>
              <div v-if="index < zones.length - 1" class="gap" :key="`zone-${index}-gap`"></div>
            </template>
          </v-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZoneCameras from "@/components/ZoneCameras";

export default {
  name: "ZoneList",
  components: {
    ZoneCameras,
  },
  props: ["zones", "cameras", "camera", "selectedZones", "view", "rights", "where", "selectedCameras"],
  data() {
    return {
      filter: "",
      mini: false,
      expanded: false,
      miniTimeout: false,
      expandTimeout: null,
    };
  },
  computed: {
    filteredZones() {
      const matcher = new RegExp(this.filter, "i");
      const zones =
        this.filter !== null && this.filter.length > 0
          ? this.zones
              .filter((z) => {
                return matcher.test(z.name) || this.camerasForFilteredZone(z.zoneId).length > 0;
              })
              .sort((a, b) => {
                return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
              })
          : this.zones.sort((a, b) => {
              return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            });
      const nonEmptyZones = zones.filter((z) => {
        return z.cameraIds.length > 0;
      });
      const emptyZones = zones.filter((z) => {
        return z.cameraIds.length === 0;
      });
      return [...nonEmptyZones, ...emptyZones];
    },
    filteredCameras() {
      const matcher = new RegExp(this.filter, "i");
      return this.filter !== null && this.filter.length > 0
        ? this.cameras.filter((c) => {
            return matcher.test(c.name);
          })
        : this.cameras;
    },
  },
  methods: {
    toggleEnter() {
      if (!this.miniTimeout) this.toggleHover = true;
    },
    toggleLeave() {
      this.toggleHover = false;
      this.expanded = false;
    },
    zoneLeave() {
      clearTimeout(this.expandTimeout);
      this.expandTimeout = null;
      setTimeout(() => {
        if (!this.toggleHover) this.expanded = false;
      }, 100);
    },
    zoneEnter() {
      this.expandTimeout = setTimeout(() => {
        this.expanded = true;
      }, 200);
    },
    toggleMini() {
      this.$emit("toggleShow");
      this.mini = !this.mini;
      this.miniTimeout = true;
      this.expanded = false;
      setTimeout(() => {
        this.miniTimeout = false;
      }, 500);
      setTimeout(() => {
        this.$emit("toggleShow");
      }, 300);
    },
    selectZone(id) {
      this.$emit("selectZone", id);
    },
    isSelectedZone(zone) {
      return this.selectedZones && Array.isArray(this.selectedZones) ? this.selectedZones.includes(zone.zoneId) : null;
    },
    selectCamera(id) {
      // this.$emit('select', id)
      let path = `/live/${id}`;
      if (this.$router.currentRoute.path !== path)
        this.$router.push({ name: this.where ? this.where : "Live", params: { cameraId: id } });
    },
    camerasForFilteredZone(zoneId) {
      return this.filteredCameras.filter((c) => {
        return c.zoneId === zoneId;
      });
    },
    showMultiView(event) {
      this.$emit("showMultiView", event);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/main";

.hover-width {
  min-width: 20px;
  height: 100%;
}

.zone-list-container {
  background-color: white;
  position: relative;
  height: 100%;
  border-right: 1px solid lightgrey;

  &.hover {
    position: absolute;
    z-index: 10;
  }

  .control-buttons {
    padding: 0;
    width: 11px;
    display: inline-block;
    position: absolute;
    right: -22px;
    top: 10px;
    z-index: 10;
    button {
      float: right;
      margin-bottom: 10px;
      font-size: 18px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      vertical-align: middle;
      border-right: 1px solid lightgrey;
      &:focus {
        color: transparent;
      }
      &.cancel-button {
        color: $primary;
        background-color: #ffffff;
        border: 2px solid $primary;
      }
      &.save-button {
        color: #ffffff;
        background-color: $primary;
      }
    }
  }
}

.zone-list {
  box-sizing: border-box;
  padding-top: 10px;
  height: 100%;
  max-width: 320px;
  width: 20vw;
  transition: width 0.3s ease, opacity 0.3s ease-out;
  overflow: hidden;
  &.mini {
    width: 20px;
    transition: width 0.3s ease, opacity 0.3s ease-in;
    opacity: 0;
  }
  .zone-list-inner {
    max-width: 320px;
    width: 20vw;
    box-sizing: border-box;
    height: 100%;
    padding-left: 0px;
  }
}

.list {
  height: calc(100% - 56px);
  overflow-x: hidden;
  background-color: white;
  overflow-y: auto;
  &:not(.single-view) {
    border-radius: 0;
    // border-right: 1px solid lightgrey;
    // background-color: $color-panel-grey;
  }
  >>> .v-list,
  >>> .v-card {
    background-color: transparent;
  }
  .gap {
    height: 10px;
  }
}
</style>
