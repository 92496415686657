<template>
  <v-container fluid class="settings my-0 py-0">
    <v-row>
      <v-container fluid class="settings-content ma-0 pa-0">
        <v-row class="height100 pl-2">
          <template>
            <v-col class="hidden-xs-only pa-0" sm="1" md="3" lg="3">
              <v-card class="side-menu-card" elevation="0" height="100%">
                <een-side-menu
                  :sideMenuHeader="mcdName"
                  :menus="menus"
                  :currentEndpoint="menus[menuIndex].endpoint"
                  @itemSelected="navigate"
                  :path="'/settings/devices/'"
                >
                </een-side-menu>
              </v-card>
            </v-col>
            <v-col id="settings-content" class="pa-0" cols="12" sm="11" md="9" lg="9">
              <router-view
                :device="mcd"
                :menus="menus"
                :zones="zones"
                :status="status"
                :cameras="cameras"
                @deleted="cameraDeleted"
                @loadCameras="loadCameras"
                :show-toolbar="showToolbar(menus[menuIndex].endpoint)"
                toolbar-color="transparent"
              ></router-view>
              <een-spinner :size="50" :isloading="isloading"></een-spinner>
            </v-col>
          </template>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
const auth = require("@/service/auth");
import StompClient from "@/service/StompClient";
import { getMessageFromError } from "@eencloud/core-components/src/service/errors";
import { store } from "@/store";

export default {
  name: "DeviceSettings",
  props: ["zones", "cameras"],
  data() {
    return {
      menuIndex: 0,
      mcd: null,
      isloading: false,
      stompClient: null,
      stompMessage: null,
      deviceInfo: null,
      status: null,
      mcdName: "",
      menus: [
        {
          name: "Device info",
          endpoint: "info",
          icon: "fas fa-hdd",
        },
        {
          name: "Channels",
          endpoint: "channels",
          icon: "fas fa-video",
        },
        {
          name: "Networks",
          endpoint: "networks",
          icon: "fas fa-wifi",
        },
      ],
    };
  },
  watch: {
    $route() {
      this.getComponentFromRoute();
    },
    "stompClient.connected": function (newVal, oldVal) {
      console.log("Stomp client connected?", oldVal, "=>", newVal);
      if (oldVal === true && newVal === false) {
        this.setupStompClient();
      }
    },
  },

  async mounted() {
    await this.getDeviceInformation();
    await this.getDeviceStatus();
    await this.setupStompClient();
  },
  updated() {
    this.getComponentFromRoute();
  },
  methods: {
    navigate(menu) {
      if (this.mcd && this.menus[this.menuIndex]?.endpoint !== menu.endpoint)
        this.$router.replace({ path: `/settings/devices/${this.mcd.deviceId}/${menu.endpoint}` });
    },
    async getDeviceInformation() {
      try {
        this.isloading = true;
        this.deviceInfo = await this.restapi.getDeviceInfoBrand(this.$route.params.deviceId);
      } catch (error) {
        store.dispatch("toastMessage", {
          showing: true,
          text: getMessageFromError(error, "GET_DEVICE_INFO"),
          timeout: -1,
          color: "error",
          support: true,
        });
      }
      try {
        this.mcd = await this.restapi.getDeviceInfo(this.$route.params.deviceId);
        this.mcdName = this.mcd.name;
        Object.assign(this.mcd, { deviceInfo: this.deviceInfo });
        this.isloading = false;
      } catch (error) {
        store.dispatch("toastMessage", {
          showing: true,
          text: getMessageFromError(error, "GET_DEVICE"),
          timeout: -1,
          color: "error",
          support: true,
        });
      }
    },

    setupStompClient() {
      /* eslint-disable no-undef */
      const url = config.apiServer.replace("http", "ws") + "/stomp/v2.0?access_token=" + auth.getToken();
      this.stompClient = new StompClient(
        url,
        this.mcd.accountId,
        "multiChannelDevices",
        this.mcd.deviceId,
        this.notify
      );
      this.stompClient.connect();
    },

    notify(tick) {
      this.stompMessage = JSON.parse(tick.body);
      if (this.stompMessage.online) this.getDeviceInformation();
    },

    showToolbar(menu) {
      return true;
    },
    loadCameras() {
      this.$emit("loadCameras");
    },

    cameraDeleted(id) {
      this.$emit("select", null);
      this.$emit("deleted", id);
    },
    getComponentFromRoute() {
      let result = 0;
      this.menus.map((item, i) => {
        if (this.$route.path.search(item.endpoint) != -1) {
          result = i;
        }
      });
      this.menuIndex = result;
    },

    async getDeviceStatus() {
      try {
        this.status = await this.restapi.getDeviceStatus(this.$route.params.deviceId);
      } catch (error) {
        console.error(error);
      }
    },
  },

  beforeDestroy() {
    if (this.stompClient && this.stompClient.connected) {
      this.stompClient.disconnect();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/main";

.settings {
  .settings-content {
    background-color: $color-panel-grey;
    min-height: $main-content-height;
  }
}
.menu-text {
  letter-spacing: 1px !important;
  font-size: 14px !important;
}
.item-icon {
  width: 50px;
  margin: auto !important;
  i {
    margin: auto;
  }
}
.side-menu-selected-item {
  background-color: #eeeeee;
  font-weight: 600;
  border-radius: 0;
}
.side-navigation-drawer {
  top: 70px !important;
}
.item-icon {
  width: 50px;
  margin: auto !important;
  i {
    margin: auto;
  }
}
.menu-text {
  letter-spacing: 1px !important;
  font-size: 14px !important;
}
.side-menu:hover {
  width: 102%;
  border-radius: 0 !important;
}
.side-menu:focus {
  background-color: transparent;
  width: 102%;
  border-radius: 0 !important;
}
.side-menu-card {
  background: #eeeeee;
}
</style>
