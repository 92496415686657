<template>
  <transition name="fade">
    <v-col cols="12" sm="10" offset-sm="1" class="pt-0">
      <p class="text-uppercase pl-0 mb-2">
        {{ $t("Scheduling Days") }}
      </p>
      <v-card class="mb-2">
        <v-col cols="12" class="pa-0">
          <v-list rounded dense>
            <v-list-item>
              <v-list-item-content>
                <v-container class="pa-0" fluid>
                  <v-row>
                    <v-col class="my-2 py-0 d-flex align-center">
                      {{ $t("Days of the week") }}
                    </v-col>
                    <v-col class="py-0">
                      <v-select
                        solo
                        :items="days"
                        v-model="selectedDay"
                        dense
                        flat
                        hide-details
                        background-color="shadow"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-card>
      <p class="text-uppercase pl-0 mb-2">
        {{ $t("Recording Schedule") }}
        {{ timeZoneInfo() }}
      </p>
      <v-card class="mb-2">
        <v-col cols="12" class="pa-0">
          <v-list rounded dense>
            <v-list-item>
              <v-list-item-content>
                <v-container class="pa-0 py-2" fluid>
                  <v-row class="pr-4">
                    <v-col cols="2" class="my-2 py-0 d-flex align-center">
                      {{ $t("Cloud recording") }}
                    </v-col>
                    <v-col class="py-0 mr-4">
                      <TimeRangeSelector :intervalList="findSchedule(currentRecordingSchedule, 'basedOnRecordingMode')" @rangeSelected="updateSchedule('recording', 'basedOnRecordingMode', $event)"></TimeRangeSelector>
                    </v-col>
                    <v-col class="pa-0" cols="auto">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="primary" class="float-right" @click="updateSchedule('recording', 'basedOnRecordingMode', [])"  v-bind="attrs" v-on="on">
                            <v-icon>fas fa-eraser</v-icon>
                          </v-btn>
                        </template>
                        <span>{{$t("Erase schedule")}}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-container>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-card>
      <p v-if="mobileSupportedCategories.length" class="text-uppercase pl-0 mb-2">
        {{ $t("Mobile Push Notification Schedule") }}
        {{ timeZoneInfo("account") }}
      </p>
      <v-card v-if="mobileSupportedCategories.length" class="mb-2">
        <v-col cols="12" class="pa-0">
          <v-list rounded dense  v-for="(category, index) in mobileSupportedCategories"
              :key="category.name">
            <v-list-item>
              <v-list-item-content>
                <v-container class="pa-0 py-2" fluid>
                  <v-row class="pr-4">
                    <v-col cols="2" class="my-2 py-0 d-flex align-center">
                      {{ $t(category.name) }}
                    </v-col>
                    <v-col class="py-0 mr-4 pt-2 pb-3">
                      <TimeRangeSelector
                        :intervalList="findSchedule(currentPushSchedules, category.value)"
                        @rangeSelected="updateSchedule('push', category.value, $event)"
                      ></TimeRangeSelector>
                    </v-col>
                    <v-col class="pa-0" cols="auto">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="primary" class="float-right" @click="updateSchedule('push', category.value, [])" v-bind="attrs" v-on="on">
                            <v-icon>fas fa-eraser</v-icon>
                          </v-btn>
                        </template>
                        <span>{{$t("Erase schedule")}}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-container>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="my-1"></v-divider>
          </v-list>
        </v-col>
      </v-card>
      <p v-if="emailSupportedCategories.length" class="text-uppercase pl-0 mb-2">
        {{ $t("Email Notification Schedule") }}
        {{ timeZoneInfo("account") }}
      </p>
      <v-card v-if="emailSupportedCategories.length" class="mb-2">
        <v-col cols="12" class="pa-0">
          <v-list rounded dense   v-for="(category, index) in emailSupportedCategories"
              :key="category.name">
            <v-list-item>
              <v-list-item-content>
                <v-container class="pa-0 py-2" fluid>
                  <v-row class="pr-4">
                    <v-col cols="2" class="my-2 py-0 d-flex align-center">
                      {{ $t(category.name) }}
                    </v-col>
                    <v-col class="py-0 mr-4 pt-2 pb-3">
                      <TimeRangeSelector
                        :intervalList="findSchedule(currentMailSchedule, category.value)"
                        @rangeSelected="updateSchedule('email', category.value, $event)"
                      ></TimeRangeSelector>
                    </v-col>
                    <v-col class="pa-0" cols="auto">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="primary" class="float-right" @click="updateSchedule('email', category.value, [])" v-bind="attrs" v-on="on">
                            <v-icon>fas fa-eraser</v-icon>
                          </v-btn>
                        </template>
                        <span>{{$t("Erase schedule")}}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-container>
              </v-list-item-content>
            </v-list-item>
             <v-divider class="my-1"></v-divider>
          </v-list>
        </v-col>
      </v-card>
      <v-dialog v-model="confirmAllDaysDialog" persistent width="500">
        <v-card>
          <v-card-title>
            {{ $t('All days of the week') }}
          </v-card-title>
          <v-card-text class="mb-4">
            {{ $t('The current visible schedule will be applied for all days of the week. All previous schedules will be cleared.')}}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="primary" @click="confirmDaySelect">{{ $t('Confirm') }}</v-btn>
            <v-btn depressed @click.stop="revertSelectedDay">{{ $t('Cancel') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </transition>
</template>

<script>
import TimeRangeSelector from '@/components/Base/TimeRangeSelector'
import Sentry from '@eencloud/core-components/src/plugins/sentry';
import _ from "lodash";
import { getMessageFromError } from "@eencloud/core-components/src/service/errors";
import { store } from "@/store";
import { getOffsetStamp } from '@eencloud/core-components/src/service/time'

export default {
  name: "ScheduledTable",
  props: {
    camera: {
      type: Object,
    },
  },
  components: {
    TimeRangeSelector,
  },
  data() {
    return {
      notificationCategories: [
        { name: "Smart Video Analytics", value: "analyticsObjectDetection"},
        { name: "Video Motion", value: "motion"},
        { name: "Infrared", value: "pir"},
        // { name: "Sound", value: "audio"},
      ],
      days: [
        { value: "all", text: this.$t("Same schedules for all days") },
        { value: "monday", text: this.$t("Monday") },
        { value: "tuesday", text: this.$t("Tuesday") },
        { value: "wednesday", text: this.$t("Wednesday") },
        { value: "thursday", text: this.$t("Thursday") },
        { value: "friday", text: this.$t("Friday") },
        { value: "saturday", text: this.$t("Saturday") },
        { value: "sunday", text: this.$t("Sunday") },
      ],
      selectedDay: "monday",
      referenceDay: null,
      recordingSchedule: null,
      pushSchedule: null,
      mailSchedule: null,
      confirmAllDaysDialog: false,
      initRecordingSchedule: null,
      initPushSchedule: null,
      initMailSchedule: null,
      preventAllSameWatcher: false
    };
  },
  async mounted () {
    Sentry.configureScope(scope => scope.setTransactionName(this.$options.name));
    try {
      this.recordingSchedule = await this.restapi.getRecordingSchedule(this.camera.cameraId)
      delete this.recordingSchedule.cameraId
      delete this.recordingSchedule.timeZone
      this.initRecordingSchedule = _.cloneDeep(this.recordingSchedule)
    } catch (error) {
      Sentry.captureException(error)
      store.dispatch("toastMessage", {
        showing: true,
        text: getMessageFromError(error, "GET_RECORDING_SCHEDULE"),
        timeout: -1,
        color: "error",
        support: true
      });
    }
    try {
      this.pushSchedule = await this.restapi.getPushNotificationSchedule(this.camera.cameraId)
      delete this.pushSchedule.cameraId
      delete this.pushSchedule.timeZone
      this.initPushSchedule = _.cloneDeep(this.pushSchedule)
    } catch (error) {
      Sentry.captureException(error)
      store.dispatch("toastMessage", {
        showing: true,
        text: getMessageFromError(error, "GET_PUSH_NOTIFICATION_SCHEDULE"),
        timeout: -1,
        color: "error",
        support: true
      });
    }
    try {
      this.mailSchedule = await this.restapi.getMailNotificationSchedule(this.camera.cameraId)
      delete this.mailSchedule.cameraId
      delete this.mailSchedule.timeZone
      this.initMailSchedule = _.cloneDeep(this.mailSchedule)

    } catch (error) {
      Sentry.captureException(error)
      store.dispatch("toastMessage", {
        showing: true,
        text: getMessageFromError(error, "GET_MAIL_NOTIFICATION_SCHEDULE"),
        timeout: -1,
        color: "error",
        support: true
      });
    }

    if (this.isAllDaysTheSame()) {
      this.preventAllSameWatcher = true
      this.selectedDay = "all"
      this.referenceDay = "monday"
    }
  },
  computed: {
    supportedCategories() {
      return this.notificationCategories.filter(category => {
        if (category.value === "motion") return this.camera.detectionCapabilities.videoMotion
        if (category.value === "analyticsObjectDetection") return this.camera.detectionCapabilities.analyticsObjectArea || this.camera.detectionCapabilities.analyticsPersonTripWire || this.camera.detectionCapabilities.analyticsPersonArea || this.camera.detectionCapabilities.analyticsObjectTripWire
        if (category.value === "pir") return this.camera.detectionCapabilities.pir
      })
    },
    mobileSupportedCategories() {
      return this.supportedCategories;
    },
    emailSupportedCategories() {
      return this.supportedCategories.filter(category => {
        if (category.value !== "motion") return category;
      })
    },
    allDaysOfTheWeek() {
      return this.selectedDay === "all"
    },
    currentRecordingSchedule() {
      if (!this.recordingSchedule) return null
      return this.selectedDay === "all" ? this.recordingSchedule[this.referenceDay] : this.recordingSchedule[this.selectedDay]
    },
    currentPushSchedules() {
      if (!this.pushSchedule) return null
      return this.selectedDay === "all" ? this.pushSchedule[this.referenceDay] : this.pushSchedule[this.selectedDay]
    },
    currentMailSchedule() {
      if (!this.mailSchedule) return null
      return this.selectedDay === "all" ? this.mailSchedule[this.referenceDay] : this.mailSchedule[this.selectedDay]
    }
  },
  watch: {
    selectedDay(newValue, oldValue) {
      if (!this.preventAllSameWatcher) {
        if (newValue === "all") {
          this.confirmAllDaysDialog = true
          this.referenceDay = oldValue ? oldValue : "monday"
          this.$emit('dirty', true)
        } else {
          this.preventAllSameWatcher = false
          this.referenceDay = null
        }
      }
    }
  },
  methods: {
    updateSchedule(type, triggerType, interval) {
      this.$emit('dirty', true)
      const currentDay = this.allDaysOfTheWeek ? this.referenceDay : this.selectedDay
      switch (type) {
        case 'recording':
          if (this.recordingSchedule[currentDay].length) {
            this.recordingSchedule[currentDay][0].intervalList = interval
          } else {
            this.recordingSchedule[currentDay].push({
              intervalList: interval,
              triggerType: triggerType
            })
          }
          break;
        case 'push':
          const pushMatch = this.pushSchedule[currentDay].find(item => item.triggerType === triggerType)
          if (pushMatch) {
            pushMatch.intervalList = interval
          } else {
            this.pushSchedule[currentDay].push({
              intervalList: interval,
              triggerType: triggerType
            })
          }
          break;
        case 'email':
          const mailMatch = this.mailSchedule[currentDay].find(item => item.triggerType === triggerType)
          if (mailMatch) {
            mailMatch.intervalList = interval
          } else {
            this.mailSchedule[currentDay].push({
              intervalList: interval,
              triggerType: triggerType
            })
          }
          break;
      }
    },
    async saveSchedules() {
      if (this.allDaysOfTheWeek) {
        this.days.forEach((day) => {
          if (day.value !== "all") {
            this.recordingSchedule[day.value] = _.cloneDeep(this.recordingSchedule[this.referenceDay])
            this.mailSchedule[day.value] = _.cloneDeep(this.mailSchedule[this.referenceDay])
            this.pushSchedule[day.value] = _.cloneDeep(this.pushSchedule[this.referenceDay])
          }
        })
      }
      try {
        await this.restapi.setRecordingSchedule(this.camera.cameraId, this.recordingSchedule)
      } catch (error) {
        Sentry.captureException(error)
        store.dispatch("toastMessage", {
          showing: true,
          text: getMessageFromError(error, "SET_RECORDING_SCHEDULE"),
          timeout: -1,
          color: "error",
          support: true
        });
      }
      try {
      await this.restapi.setMailNotificationSchedule(this.camera.cameraId, this.mailSchedule)
      } catch (error) {
        Sentry.captureException(error)
        store.dispatch("toastMessage", {
          showing: true,
          text: getMessageFromError(error, "SET_MAIL_NOTIFICATION_SCHEDULE"),
          timeout: -1,
          color: "error",
          support: true
        });
      }

      try {
      await this.restapi.setPushNotificationSchedule(this.camera.cameraId, this.pushSchedule)
      } catch (error) {
        Sentry.captureException(error)
        store.dispatch("toastMessage", {
          showing: true,
          text: getMessageFromError(error, "SET_PUSH_NOTIFICATION_SCHEDULE"),
          timeout: -1,
          color: "error",
          support: true
        });
      }
    },
    confirmDaySelect() {
      this.confirmAllDaysDialog = false
    },
    revertSelectedDay() {
      this.selectedDay = this.referenceDay
      this.referenceDay = null
      this.confirmAllDaysDialog = false
    },
    findSchedule(array, type) {
      if (!array) return []
      const matchingObject = array.find(item => item.triggerType === type)
      return matchingObject ? matchingObject.intervalList : []
    },
    cancelChanges() {
      this.recordingSchedule = _.cloneDeep(this.initRecordingSchedule)
      this.pushSchedule = _.cloneDeep(this.initPushSchedule)
      this.mailSchedule = _.cloneDeep(this.initMailSchedule)
      this.$emit('dirty', false)
    },
    offsetStamp(offset) {
      if (offset == 0) return "UTC"
      const hours = offset / 60
      const minutes = (offset % 60)
      if (hours > 0) return "UTC -" + hours + (minutes ? `:${Math.abs(minutes)}` : "")
      else return "UTC +" + Math.floor(Math.abs(hours)) + (minutes ? `:${Math.abs(minutes)}` : "")
    },
    isAllDaysTheSame() {
      const that = this
      function compare(schedule) {
        return _.isEqual(schedule.monday, schedule.tuesday) &&
        _.isEqual(schedule.tuesday, schedule.wednesday) &&
        _.isEqual(schedule.wednesday, schedule.thursday) &&
        _.isEqual(schedule.thursday, schedule.friday) &&
        _.isEqual(schedule.friday, schedule.saturday) &&
        _.isEqual(schedule.saturday, schedule.sunday)
      }
      return compare(that.initRecordingSchedule) && compare(that.initMailSchedule) && compare(that.initPushSchedule)
    },
    timeZoneInfo(entity) {
      if (entity === "account") {
        return store.getters.accountTimeZoneOffset !== this.camera.timeOffset ?
        " - " + this.$t("for") + " " + store.getters.account.email + " / " + getOffsetStamp(store.getters.accountTimeZoneOffset)
        : ""
      } else {
        return store.getters.accountTimeZoneOffset !== this.camera.timeOffset ?
         " - " + this.$t("for local camera time") + " / " + getOffsetStamp(this.camera.timeOffset)
         : ""
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/settings";

.schedule-list-item {
  height: 80px;
}
</style>
