<template>
  <div>
    <div class="pb-2 hidden-sm-and-down">
      <v-btn
        v-for="(item, index) in menuItems"
        text
        tile
        class="mt-3"
        :class="$route.name === item.menu ? 'selectedMenu' : null"
        :key="index"
        :ripple="false"
        color="white"
        v-show="item.show"
        @click="onClickMenuAction(item)"
      >
        <v-icon left size="18">{{ item.icon }}</v-icon>
        <span class="font-weight-bold">{{ $t(item.title) }}</span>
      </v-btn>
      <v-menu 
        bottom 
        offset-y
        v-if="rights && (rights.viewUserSettings || rights.editCamera || rights.deleteCamera || rights.demoUser || rights.editZone || rights.addZone || rights.deleteZone)"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            tile
            v-on="on"
            :ripple="false"
            v-bind="attrs"
            color="white"
            class="mt-3 font-weight-bold"
            :class="$route.path.search('settings') > 0 || $route.name === 'AddCamera' ? 'selectedMenu' : null"
          >
            <v-icon size="1.2em" color="white">fa-cog</v-icon>
            <span class="px-1 px-1">{{ $t("Settings") }}</span>
            <v-icon size="1.2em" color="white">fa fa-caret-down</v-icon>
          </v-btn>
        </template>
        <v-list class="mt-1">
          <v-list-item
            v-for="(item, index) in settingsMenuItems"
            v-show="item.show"
            :key="index"
            @click="$router.push({ name: item.menu })"
            :ripple="false"
          >
            <v-row>
              <v-col cols="9">
                <span class="primary--text">{{ $t(item.title) }}</span>
              </v-col>
              <v-col cols="2">
                <v-icon class="float-right" size="1em" color="primary" aria-hidden="true">{{ item.icon }} </v-icon>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-tooltip bottom v-if="$store.getters.theme && $store.getters.theme.application == 'Cameramanager'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="mt-3" @click="emit('openChat')" icon>
            <v-icon color="white">
              fas fa-headset
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Chat with support") }}</span>
      </v-tooltip>
    </div>
    <div class="mt-1 hidden-md-and-up">
      <v-menu z-index="10000" bottom offset-y>
        <template v-slot:activator="{ on: onMenu }">
          <v-btn icon v-on="onMenu">
            <v-tooltip bottom>
              <template #activator="{ on: onTooltip }">
                <span v-on="{ ...onMenu, ...onTooltip }">
                  <v-icon color="white">fas fa-bars</v-icon>
                </span>
              </template>
              <span>{{ $t("Menu") }}</span>
            </v-tooltip>
          </v-btn>
        </template>
        <v-list flat>
          <v-list-item 
            v-for="(item, index) in menuItems" 
            :key="index" 
            v-show="item.show">
            <v-list-item-title
              class="cursorPointer"
              @click="
                $router.push({
                  name: item.menu,
                  params: camera && item.menu !== 'Dashboard' ? { cameraId: camera.cameraId } : {},
                })
              "
            >
              <span class="primary--text">{{ $t(item.title) }}</span>
              <v-icon class="float-right ml-10" size="1em" color="primary" aria-hidden="true">{{ item.icon }}</v-icon>
            </v-list-item-title>
          </v-list-item>
          <v-divider v-if="rights && (rights.viewUserSettings || rights.editCamera || rights.deleteCamera || rights.demoUser)"></v-divider>
          <v-subheader 
            v-if="rights && (rights.viewUserSettings || rights.editCamera || rights.deleteCamera || rights.demoUser)">
            <span>{{ $t("Settings") }}</span>
          </v-subheader>
          <v-list-item
            v-for="(item, index) in settingsMenuItems" 
            :key="index" 
            v-show="item.show">
            <v-list-item-title class="cursorPointer" @click="$router.push({ name: item.menu })">
              <span class="primary--text">{{ $t(item.title) }}</span>
              <v-icon class="float-right ml-10" size="1em" color="primary" aria-hidden="true">{{ item.icon }}</v-icon>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { reactive, computed, toRefs } from 'vue';
import { store } from "@/store";
import router from '@/service/router'

export default {
  name: "Navigation",
  props: ['rights', 'selectedCamera'],
  setup(props, { emit }) {

    const isAiAnalyticsMenuVisible = computed(() => store.getters.isAiAnalyticsMenuVisible);

    let data = reactive({
      menuItems: computed(() => {
        return [
          {
            menu: "Dashboard",
            title: "Dashboard",
            icon: "fa-home",
            show: true
          },
          {
            menu: "Live",
            title: "Live",
            icon: "fa-play-circle",
            show: true
          },
          {
            menu: "Footage",
            title: "Footage",
            icon: "fa-history",
            show: props.rights && (props.rights.playback || props.rights.demoUser)
          },
          {
            menu: "AiAnalytics",
            title: "AI Analytics",
            icon: "fa-chart-line",
            show: isAiAnalyticsMenuVisible.value
          },
          {
            menu: "EventSearch",
            title: "Events",
            icon: "fa-film",
            show: props.rights && (props.rights.playback || props.rights.demoUser)
          },
        ]
      }),
      settingsMenuItems: computed(() => { 
        return [
          {
            menu: "Cameras",
            title: "Devices",
            icon: "fas fa-hdd",
            show: props.rights && (props.rights.editCamera || props.rights.deleteCamera || props.rights.demoUser)
          },
          {
            menu: "Zones",
            title: "Zones",
            icon: "fa-map-marked-alt",
            show: props.rights && (props.rights.editZone || props.rights.deleteZone || props.rights.demoUser || props.rights.addZone)
          },
          {
            menu: "Users",
            title: "Users",
            icon: "fa-users",
            show: props.rights && (props.rights.viewUserSettings || props.rights.demoUser)
          },
          {
            menu: "Logs",
            title: "Audit Logs",
            icon: "fa-clipboard-check",
            show: props.rights && (props.rights.viewUserSettings || props.rights.demoUser)// there is no separate permission fot this yet
          },
        ]
      }),
      alarms: false,
      menuTitleMapper: {
        Dashboard: "Dashboard",
        Footage: "Footage",
        AiAnalytics: "AI Analytics",
        Settings: "Settings",
        MyAccount: "My Account",
        AddCamera: "Add Camera",
        EventSearch: "Event Search",
      },
      betaImg: require("@eencloud/core-components/src/assets/images/beta.svg")
    })

    function onClickMenuAction(item) {
      if(item.menu === 'AiAnalytics') {        
        const aiAnalyticsUrl = config.aiAnalyticsUrl;
        const aiAnalyticsToken = store.getters.aiAnalyticsToken;
        if(aiAnalyticsUrl && aiAnalyticsToken.accessToken && aiAnalyticsToken.refreshToken) {
          window.open(`${aiAnalyticsUrl}/login/cm?access_token=${aiAnalyticsToken.accessToken}&refresh_token=${aiAnalyticsToken.refreshToken}`, '_blank');
        } else {
          console.log('AI Analytics URL or token is not available');
        }      
      } else {
        router.push({
            name: item.menu,
            params: item.menu !== 'Dashboard' ? { cameraId: this.selectedCamera } : {}
        });
      }
    }
    
    return { ...toRefs(data), emit, onClickMenuAction }
  },
}

</script>

<style lang="scss" scoped>
@import "../assets/styles/main";
@import "../assets/styles/custom";

.gap {
  width: 24px;
  min-width: 24px;
}
.navigation-logo {
  max-height: 100%;
  padding-left: 0;
  max-width: 150px;
  margin: 10px;
}
.navigation-toolbar {
  background: linear-gradient(to right, var(--v-primary-base) 0%, var(--v-lightprimary-base) 100%);
}
.beta-image {
  position: relative;
  top: 10px;
}
.navigationHeader {
  z-index: 1000;
}

.selectedMenu {
  margin-top: 14px !important;
  border-bottom: 2px solid white;
}
a {
  text-decoration: none;
}
.cursorPointer {
  cursor: pointer;
}

.app-bar {
  height: 70px !important;
}
.support-access-warning {
  background-color: rgb(255, 255, 50);
  max-width: 350px;
  height: 60px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 13px;
  line-height: 1.2;
  box-shadow: 0px 3px 15px #000000b2;
  margin-top: 5px;
  padding: 0 5px 0 10px;
  overflow-wrap: break-word;
}
</style>