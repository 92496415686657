<template>
  <v-card>
    <v-col cols="12" class="pa-0">
      <v-list>
        <v-list-item>
          <v-list-item-content class="pa-0">
            <v-row>
              <v-col cols="6">
                <p class="subheading my-0">
                  {{ $t("Current firmware version") }}
                </p>
              </v-col>
              <v-col cols="6">
                <p class="my-0 text-right">
                  {{ firmware.currentVersion }}
                </p>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <v-list-item>
          <v-list-item-content class="pa-0">
            <v-row>
              <v-col cols="6">
                <p class="subheading my-0">
                  {{ $t("New firmware version available") }}
                </p>
              </v-col>
              <v-col cols="6">
                <p class="my-0  text-right">
                  {{ firmware.targetVersion }}
                </p>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <v-list-item>
          <v-list-item-content class="pa-0">
            <v-row >
              <v-col>
                <p class="my-0" v-if="online || upgrading || job">
                  {{
                    $t(
                      "Keep your device connected to power and a reliable network during the entire updating process to prevent damage to your device."
                    )
                  }}
                </p>
                <p class="my-0" v-else>
                  {{ $t("This device is offline and can therefore not be upgraded at this moment.") }}
                </p>
              </v-col>
              <v-col cols="auto" class="pa-0">
                <div class="text-right">
                  <v-btn small text color="primary" :loading="!!jobStatus" :disabled="!online" @click="upgrade">
                    {{ $t("Update firmware") }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
    <v-dialog v-model="upgradeFailed" hide-overlay width="500">
      <v-card>
        <v-card-title primary-title class="headline error white--text">
          {{ $t("Error while upgrading") }}
        </v-card-title>
        <v-card-text>{{
          $t("The firmware upgrade of your device failed. Please try again or contact support.")
        }}{{ $t("Device Name") }} : {{deviceName}}</v-card-text>
        <v-card-actions>
          <v-btn depressed color="error" @click="upgradeFailed = false">
            {{ $t("Got it") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import auth from "@/service/auth";
import StompClient from "@/service/StompClient";

export default {
  name: "FirmwareUpdate",
  props: ["device", "status", "firmware"],
  data() {
    return {
      job: null,
      jobStatus: null, // Running -> Ended
      jobProgress: null,
      upgradeStatus: null, // Initializing -> Uploading (with progress) -> Upgrading -> Restarting -> Success -> Ended
      stompClient: null,
      messages: [],
      upgradeFailed: false,
    };
  },
  computed: {
    deviceId() {
      return this.device ? this.device.deviceId : null;
    },
    deviceName() {
      return this.device ? this.device.name : null;
    },
    currentFirmwareVersion() {
      return this.firmware ? this.firmware.currentVersion : null;
    },
    uptodate() {
      return this.firmware ? this.firmware.status === "up-to-date" : null;
    },
    upgrading() {
      return this.firmware ? this.firmware.status === "upgrading" : null;
    },
    upgradable() {
      return this.firmware ? this.firmware.status === "upgradable" : null;
    },
    online() {
      return this.status ? this.status.online : null;
    },
    upgradeStatusText() {
      switch (this.upgradeStatus) {
        case "Requesting":
          return this.$t("Requesting...");
        case "Initializing":
          return this.$t("Initializing...");
        case "Uploading":
          return this.$t("Downloading firmware...");
        case "Upgrading":
          return this.$t("Installing firmware...");
        case "Restarting":
          return this.$t("Restarting device...");
        default:
          return this.upgradeStatus;
      }
    },
    progress() {
      if (this.jobProgress) {
        return parseInt((this.jobProgress.current / this.jobProgress.total) * 100);
      }
      return null;
    },
  },
  watch: {
    device: function (newVal, oldVal) {
      console.log("device", oldVal, "=>", newVal);
      if (newVal) {
        this.getActiveJob();
      } else if (newVal === undefined || newVal === null) {
        if (this.stompClient && this.stompClient.connected) {
          this.stompClient.disconnect();
        }
      }
      if (oldVal === null || newVal.deviceId !== oldVal.deviceId) {
        this.setupStompClient();
      }
    },
    upgrading: function (newVal, oldVal) {
      if (newVal && !this.job) {
        this.getActiveJob();
      }
    },
    "stompClient.connected": function (newVal, oldVal) {
      console.log("Stomp client connected?", oldVal, "=>", newVal);
      if (oldVal === true && newVal === false) {
        this.setupStompClient();
      }
    },
  },
  mounted() {
    if (this.device) {
      this.getActiveJob();
      this.setupStompClient();
    }
  },
  beforeDestroy() {
    if (this.stompClient && this.stompClient.connected) {
      this.stompClient.disconnect();
    }
  },
  methods: {
    upgrade() {
      if (!this.jobStatus) {
        this.jobStatus = "Requesting";
        const that = this;
        this.restapi
          .setDeviceFirmware(this.deviceId, this.firmware.targetVersion)
          .then(function (job) {
            that.job = job;
            that.jobStatus = job.status;
            that.upgradeStatus = job.statusDetails.upgradeStatus;
          })
          .catch(function (error) {
            console.error(error);
          });
      }
    },
    setupStompClient() {
      console.log("Setting up stomp client");
      /* eslint-disable no-undef */
      const url = config.apiServer.replace("http", "ws") + "/stomp/v2.0?access_token=" + auth.getToken();
      this.stompClient = new StompClient(url, this.device.accountId, "jobs", null, this.notify);
      this.stompClient.connect();
    },
    notify(tick) {
      const message = JSON.parse(tick.body);
      console.log("Received message", message);
      this.messages.push(message);
      this.jobStatus = message.status;
      this.jobProgress = message.statusDetails.hasOwnProperty("progress") ? message.statusDetails.progress : null;
      this.upgradeStatus = message.statusDetails.upgradeStatus;
      if (this.jobStatus === "Ended" && message.type === "device.firmware.upgrade") {
        this.upgradeFailed = message.statusDetails.wasSuccessful === false;
        this.job = null;
        this.jobStatus = null;
        this.upgradeStatus = null;
        this.messages = [];
        this.$emit("updated");
      } else if (!this.job) {
        this.getActiveJob();
      }
    },
    getActiveJob() {
      const that = this;
      this.restapi
        .getDevicesJobs(this.deviceId)
        .then(function (jobs) {
          const job = jobs.find(function (j) {
            return j.type === "device.firmware.upgrade" && (j.status === "Created" || j.status === "Running");
          });
          if (job) {
            that.job = job;
            that.jobText = that.$t("Switching Wi-Fi network");
            that.jobStatus = "Running";
            that.upgradeStatus = job.statusDetails.upgradeStatus;
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/settings";
</style>
