<template>
  <div>
    <v-img
      v-if="camera.status && camera.status.online"
      ref="cameraImage"
      :src="imageSource"
      :lazy-src="greyImg"
      :width="width"
      :max-width="maxWidth || width"
      :height="height"
      :max-height="maxHeight || height"
      :aspect-ratio="aspectRatio || 1"
      class="rounded-image mr-2"
    ></v-img>
    <div v-else class="offline-camera-wrapper rounded-image mr-2" :style="offlineCameraWrapperStyle">
      <div class="offline-camera" :style="offlineCameraStyle"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CameraImage",
  props: ["camera", "width", "maxWidth", "height", "maxHeight", "aspectRatio"],
  data() {
    return {
      visible: false,
      observer: null,
    };
  },
  computed: {
    imageSource() {
      const s =
        this.camera && this.camera.hasOwnProperty("snapshots")
          ? this.camera.snapshots.find((s) => {
              return s.resolution === this.resolution;
            })
          : null;
      return s && s.image ? s.image : this.lazySrc;
    },
    resolution() {
      return `1000x100`;
    },
    offlineCameraWrapperStyle() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    },
    offlineCameraStyle() {
      return {
        left: `${this.width / 2 + 4}px`,
        top: `${this.height / 2 - 10}px`,
      };
    },
  },
  watch: {
    "camera.status": function (newVal, oldVal) {
      if (this.camera.status && this.camera.status.online && this.visible) {
        const snapshot = this.camera.snapshots.find((s) => {
          return s.resolution === this.resolution;
        });
        if (!snapshot) {
          const that = this;
          this.restapi.getSnapshot(this.camera.cameraId, this.resolution).then(function (snapshot) {
            const i = that.camera.snapshots.findIndex((s) => {
              return s.resolution === that.resolution;
            });
            that.camera.snapshots.push(i, 1, { resolution: `${that.resolution}`, image: snapshot });
          }).catch(error => console.log(error))
        }
      }
    },
  },
  created() {
    if (!this.camera.hasOwnProperty("snapshots")) this.$set(this.camera, "snapshots", []);
  },
  mounted() {
    this.observer = new IntersectionObserver(([el]) => {
      if (!this.visible) this.visible = el.intersectionRatio > 0;
      const snapshot = this.camera.snapshots.find((s) => {
        return s.resolution === this.resolution;
      });
      if (!snapshot && this.camera.status && this.camera.status.online && el.intersectionRatio > 0) {
        this.restapi.getSnapshot(this.camera.cameraId, this.resolution).then(snapshot => {
          const i = this.camera.snapshots.findIndex((s) => {
            return s.resolution === this.resolution;
          });
          this.camera.snapshots.push(i, 1, { resolution: `${this.resolution}`, image: snapshot });
        }).catch(error => console.log(error))
      }
    }, {});

    this.observer.observe(this.$el);
  },
  destroyed() {
    this.observer.disconnect();
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/main";

.rounded-image {
  border-radius: $double-border-radius;
}
.offline-camera-wrapper {
  background-color: #959595;
  .offline-camera {
    width: 100%;
    height: 100%;
    background-color: white;
    mask: url("~@eencloud/core-components/src/assets/images/videocam_off.svg") no-repeat;
    position: absolute;
  }
}
</style>
