<template>
  <v-col
    v-model="showDebugTool"
    :id="`debug-tools-${camera.cameraId}`"
    :class="['debug-tools', {
      displayNone: !showDebugTool,
      threeZone: threeZone,
      singleView: singleView,
    }]"
  >
    <div color="#0000004a">
      <div>
        <div class="text-debug" :class="{ threeZoneText: threeZone }">
          <v-icon
            @click="showDebug()"
            class="closeDebug"
            color="white"
            size="12"
          >
            fas fa-times</v-icon
          >
          <v-row dense v-for="currentData in allData" v-show="currentData.show" :key="currentData.name" class="debug-tools__row">
            <v-col dense no-gutters class="pl-5">
              {{ currentData.name }}
            </v-col>
            <v-col>
              <span>
                {{ currentData.value }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-menu dark v-if="debugData.type === 'WebCodecs' && debugData.audioBufferSize === null" :attach="`#debug-tools-${camera.cameraId}`">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text small class="text-debug">
                  {{ $t('Change max. buffer length') }}
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(item, index) in bufferSizes"
                  :key="index"
                  @click="$emit('setBufferLength', item.value)"
                  class="vlistitem"
                  :class="{'selected-buffer': item.value === selectedBuffer }"
                >
                  <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>
        </div>
        <div></div>
      </div>
    </div>
  </v-col>
</template>
<script>
export default {
  props: ["debugTools", "debugData", "camera", "threeZone", "singleView", "bufferSizes", "selectedBuffer"],
  data() {
    return {
      showDebugTool: false,
      maxBitRate: 0,
    };
  },
  watch: {
    debugTools: function (newVal, oldVal) {
      this.showDebugTool = newVal;
    },
    "debugData.bitrate": function (newVal) {
      this.maxBitRate = Math.max(this.maxBitRate, newVal);
    },
    "camera.cameraId": function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.maxBitRate = 0;
        this.debugData.bitrate = 0;
      }
    },
  },
  computed: {
    allData() {
      return [
        {
          name: this.$t("Stream type"),
          value: this.debugData.type,
          show: true
        },
         {
          name: this.$t("Audio codec"),
          value: this.debugData.audioType,
          show: true
        },
        {
          name: this.$t("Camera ID"),
          value: this.camera.cameraId,
          show: true
        },
        {
          name: this.$t("Bandwidth"),
          value: this.debugData.bandwidth ? `${this.debugData.bandwidth} KBps` : "-",
          show: true
        },
        {
          name: this.$t("Current Bit Rate"),
          value: `${this.debugData.bitrate ? this.debugData.bitrate : 0} KBps`,
          show: this.debugData.type !== 'WebCodecs'
        },
        {
          name: this.$t("Max Bit Rate"),
          value: `${this.maxBitRate} KBps`,
          show: this.debugData.type !== 'WebCodecs'
        },
        {
          name: this.$t("Fragment Duration"),
          value: `${this.debugData.duration ? this.debugData.duration.toFixed(2) : 0 } sec`,
          show: true
        },
        {
          name: this.$t("Dropped Frames"),
          value: `${this.debugData.droppedFrames ? this.debugData.droppedFrames : 0 } frames`,
          show: this.debugData.type === 'WebCodecs'
        },
        {
          name: "Decoder queue",
          value: this.debugData.queueSizes?.decoder,
          show: this.debugData.type === 'WebCodecs'
        },
        {
          name: this.$t("Max. buffer length"),
          value: `${this.selectedBuffer ? this.selectedBuffer / 1000 : "0" } sec`,
          show: this.debugData.audioBufferSize === null && this.debugData.type === 'WebCodecs'
        },
        {
          name: this.$t("QR code time stamp"),
          value: `${this.debugData.qrTimestamp} `,
          show: this.debugData.qrTimestamp
        },
        {
          name: this.$t("Current buffer length"),
          value: `${this.debugData.currentBufferLength} sec`,
          show: this.debugData.currentBufferLength !== null
        }
      ];
    },
  },
  methods: {
    showDebug() {
      this.showDebugTool = false;
      this.$emit("showTool");
    },
  },
};
</script>
<style scoped>
.center-align {
  display: flex;
  align-content: center;
  justify-content: center;
}
.text-debug {
  color: white !important;
  padding-left: 30px;
  font-size: 0.75rem;
}
.threeZoneText {
  padding-left: 0;
}
.displayNone {
  display: none;
}
.debug-tools {
  position: absolute;
  top: 20px;
  z-index: 10;
  width: 400px;
  background: #00000040;
}
.closeDebug {
  position: absolute !important;
  top: 10px;
  right: 7px;
}
.threeZone {
  width: 70%;
}
.singleView {
  top: 36px;
}
.selected-buffer {
  background-color: #8080805d;
  height: 10px;
}
.v-list-item {
  min-height: 20px !important;
}
.debug-tools__row {
  height: 20px;
  margin: 0px 0px 0px -30px;
}

</style>