<template>
  <v-list-item
    ref="cameraImage"
    v-if="camera"
    @click="$emit('select')"
    @dragstart="drag"
    :draggable="$route.name === 'Live'"
    class="camera-list-tile"
  >
    <v-icon
      v-if="camera.status && camera.status.recordingOnCloud"
      size="10"
      class="recording-icon"
    >fa-circle</v-icon>
    <camera-image
      :camera="camera"
      width="40"
      max-width="40"
      height="40"
      max-height="40"
      aspect-ratio="1"
    ></camera-image>
    <p ref="cameraName" class="camera-name caption mb-0">{{ camera.name }}</p>
  </v-list-item>
</template>

<script>
import CameraImage from "@/components/Base/CameraImage";

export default {
  name: "CameraListTile",
  components: {
    CameraImage,
  },
  props: ["camera"],
  data() {
    return {
      observer: null,
    };
  },
  mounted() {
    this.observer = new IntersectionObserver(([el]) => {
      if (!this.camera.hasOwnProperty("status") && el.intersectionRatio > 0) {
        const that = this;
        this.restapi
          .getCameraStatus(this.camera.cameraId)
          .then(function (status) {
            that.$set(that.camera, "status", status);
          });
      }
    }, {});

    this.observer.observe(this.$el);
  },
  destroyed() {
    this.observer.disconnect();
  },
  methods: {
    drag(ev) {
      ev.dataTransfer.setData("Text", JSON.stringify(this.camera));
      let node = document.createElement("Span");
      let cameraName = document.createTextNode(this.$refs.cameraName.innerHTML);
      node.appendChild(cameraName);
      node.classList.add("dragName");
      document.body.appendChild(node);
      ev.dataTransfer.setDragImage(node, 0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/main";

.diplayNone {
  display: none;
}

label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.camera-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 48px);
}
.truncate {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
>>> .v-input--selection-controls {
  margin-top: 0;
}
.rounded-image {
  border-radius: $border-radius;
}
.recording-icon {
  position: absolute;
  top: 8px;
  left: 18px;
  z-index: 1;
  color: red;
}
.offline-camera-wrapper {
  width: 40px;
  height: 40px;
  background-color: #959595;
  .offline-camera {
    width: 100%;
    height: 100%;
    background-color: white;
    mask: url("~@eencloud/core-components/src/assets/images/videocam_off.svg") no-repeat;
    position: absolute;
    left: 24px;
    top: 12px;
  }
}
>>> .v-toolbar {
  background-color: unset;
  .offline-camera-wrapper {
    width: 60px;
    .offline-camera {
      left: 40px;
    }
  }
}
>>> .v-divider--vertical.v-divider--inset {
  min-height: calc(100% - 16px);
}
>>> .v-divider--inset:not(.v-divider--vertical) {
  margin-left: 102px;
}
>>> .v-toolbar__title {
  font-size: 16px;
}
.camera-list-tile:hover {
  background: #eeeeee;
}
</style>
<style >
.dragName {
  background-color: white;
  padding: 4px 4px 4px 20px;
  position: absolute;
  left: -500px;
  font-family: "Roboto", sans-serif !important;
  top: -20px;
}
</style>
