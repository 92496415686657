<template>
  <v-container fluid class="detections pa-0">
    <v-app-bar v-if="showToolbar" dense flat :app="isAppToolbar" :color="toolbarColor || 'white'" class="title--text">
      <v-container v-if="isDirty" class="ma-0 align">
        <v-row class="xs8 offset-xs2">
          <v-spacer></v-spacer>
            <v-btn @click="cancelChanges">
              {{ $t("Cancel") }}
            </v-btn>
            <v-btn class="ml-2" color="primary" @click="saveChanges">
              {{ $t("Save") }}
            </v-btn>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-container v-if="capabilities" class="pa-0 align">
      <v-col sm="10" offset-sm="1" class="py-0">
        <v-row>
          <v-col cols="10" class="hidden-sm-and-up">
            <v-btn text class="pa-0" @click="$emit('allCameras')">
              <v-icon size="18" left>
                fa-arrow-left
              </v-icon>
              <p class="mb-0">
                {{ $t("Detections") }}
              </p>
            </v-btn>
          </v-col>
          <v-col cols="2" class="hidden-sm-and-up">
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" dark icon>
                  <v-icon size="18" color="black">
                    fa-ellipsis-v
                  </v-icon>
                </v-btn>
              </template>
              <v-list dense class="px-4">
                <v-list-item v-for="(menu, i) in menus" :key="i" @click="$router.push({ path: `/settings/cameras/${camera.cameraId}/${menu.endpoint}` })">
                  <v-list-item-title>{{ menu.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="10" offset-sm="1" class="pt-0">
        <p class="text-uppercase pl-0 mb-2 font-size-12" style="color:#555555">
          {{ $t("Camera detections") }}
        </p>
        <v-card >
          <v-col cols="12" class="pa-0">
            <v-list dense rounded v-if="hasVideoMotionDetection" class="pa-0">
              <v-list-item>
                <v-list-item-content class="pa-0">
                  <v-container class="pa-0" fluid>
                    <v-row>
                      <v-col class="py-0 my-2" cols="11">
                        {{ $t("Video motion") }}
                      </v-col>
                      <v-col class="py-0" cols="1" :pl-4="$vuetify.breakpoint.mdAndUp">
                        <v-switch
                          hide-details
                          dense
                          v-model="videoMotionDetection"
                          color="accent"
                          class="my-0 detections__switchbutton--right"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider v-if="hasPirDetection && hasVideoMotionDetection"></v-divider>
            <v-list rounded dense v-if="hasPirDetection" class="pa-0">
              <v-list-item>
                <v-list-item-content class="pa-0">
                  <v-container class="pa-0" fluid>
                    <v-row>
                      <v-col class="my-2 py-0" cols="11">
                        {{ $t("Infrared") }}
                      </v-col>
                      <v-col class="py-0" cols="1" :pl-4="$vuetify.breakpoint.mdAndUp">
                        <v-switch
                          hide-details
                          dense
                          v-model="pirDetection"
                          color="accent"
                          class="my-0 detections__switchbutton--right"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider v-if="hasAudioDetection && (hasVideoMotionDetection || hasPirDetection)"></v-divider>
            <v-list rounded dense v-if="hasAudioDetection" class="pa-0">
              <v-list-item >
                <v-list-item-content class="pa-0">
                  <v-container class="pa-0" fluid>
                    <v-row>
                      <v-col class="my-2 py-0" cols="11">
                        {{ $t("Sound") }}
                      </v-col>
                      <v-col class="py-0" cols="1" :pl-4="$vuetify.breakpoint.mdAndUp">
                        <v-switch
                          hide-details
                          dense
                          v-model="audioDetection"
                          color="accent"
                          class="my-0 detections__switchbutton--right"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider
              v-if="hasDoorbellDetection && (hasAudioDetection || hasVideoMotionDetection || hasPirDetection)"
            ></v-divider>
            <v-list rounded dense v-if="hasDoorbellDetection" class="pa-0">
              <v-list-item class="pa-0">
                <v-list-item-content>
                  <v-container class="pa-0" fluid>
                    <v-row>
                      <v-col class="my-2 py-0" cols="11">
                        {{ $t("Doorbell") }}
                      </v-col>
                      <v-col class="py-0" cols="1" :pl-4="$vuetify.breakpoint.mdAndUp">
                        <v-switch
                          hide-details
                          dense
                          v-model="doorbellDetection"
                          color="accent"
                          class="my-0"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-card>
        <p v-if="hasOnlyOneDetectionAvailable" class="body-2 my-2">
          {{
            $t(
              "By turning off all detections your camera will not detected anything. Therefore at least one detection should be enabled. If you do not want any detections, set your camera to Disarmed."
            )
          }}
        </p>
        <p class="body-2 my-2">
          {{
            $t(
              "Camera detections are triggered by the camera itself and are used to start a recording, notify you or show an event on your timeline."
            )
          }}
        </p>
      </v-col>
      <v-col v-if="hasSmartVideoAnalytics" class="pt-0" cols="12" sm="10" offset-sm="1">
        <p class="text-uppercase font-size-12 pl-0 mb-2">
          {{ $t("Smart detections") }}
        </p>
        <v-card>
          <v-col cols="12" class="pa-0" :pb-2="!smartAnalyticsEnabled" :pb-0="smartAnalyticsEnabled">
            <v-list rounded dense class="pa-0">
              <v-list-item>
                <v-list-item-content class="pa-0">
                  <v-container class="pa-1" fluid>
                    <v-row>
                      <v-col cols="11" class="pa-2">
                        {{ $t("Smart video analytics") }}
                      </v-col>
                      <v-col class="py-0" cols="1" :pl-4="$vuetify.breakpoint.mdAndUp">
                        <v-switch
                          hide-details
                          dense
                          v-model="smartAnalyticsEnabled"
                          color="accent"
                          class="my-0 detections__switchbutton--right"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider v-if="smartAnalyticsEnabled"></v-divider>
            <v-radio-group dense hide-details v-if="smartAnalyticsEnabled" v-model="analyticsType" class="my-0 pa-0">
              <v-list rounded dense class="pa-0">
                <v-list-item>
                  <v-list-item-content class="py-0 pr-0 pl-2">
                    <v-radio
                      dense
                      key="object"
                      value="object"
                      :label="$t('Object detections')"
                      class="analytics-type-option my-2"
                    ></v-radio>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-list rounded dense class="pa-0">
                <v-list-item>
                  <v-list-item-content class="py-0 pr-0 pl-2">
                    <v-radio
                      dense
                      key="person"
                      value="person"
                      :label="$t('Person detections')"
                      class="analytics-type-option my-2"
                    ></v-radio>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-radio-group>
          </v-col>
        </v-card>
        <p class="body-2 my-2">
          {{
            $t(
              "When a camera detection is triggered by the camera, smart video analytics identify if this detection was triggered by a Person or Object and if entered a specific area or crossed a tripwire."
            )
          }}
        </p>
        <p class="body-2 my-2">
          {{
            $t("Choose Person detection only, if you only want Person detections, all other objects will be excluded.")
          }}
        </p>
      </v-col>
    </v-container>

    <v-dialog v-model="disableAllDetectionsWarning" hide-overlay width="500">
      <v-card>
        <v-card-title accent-title class="headline">
          {{ $t("Unable to turn off all detections") }}
        </v-card-title>
        <v-card-text>{{
          $t(
            "By turning off all detections, your camera will not detect anything. Therefore at least one detection should be enabled. If you do not want any detections, set your camera to Disarmed."
          )
        }}</v-card-text>
        <v-card-actions>
          <v-btn text color="accent" @click="disableAllDetectionsWarning = false">
            {{ $t("Got it") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { store } from "@/store";
import warningForCameraSettingsChangesMixin from '@eencloud/core-components/src/service/warningForCameraSettingsChangesMixin';

export default {
  name: "Detections",
  props: ["camera", "rights", "showToolbar", "appToolbar", "toolbarColor", "menus", "desktop"],
  mixins: [warningForCameraSettingsChangesMixin],
  data() {
    return {
      isDirty: false,
      initValues: {},
      videoMotionDetection: null,
      pirDetection: null,
      audioDetection: null,
      doorbellDetection: null,
      analyticsSettings: null,
      disableAllDetectionsWarning: false,
      shiftKeyDown: false,
    };
  },
  computed: {
    isAppToolbar() {
      if (this.appToolbar === undefined || this.appToolbar === null) return true;
      return !!this.appToolbar;
    },
    cameraId() {
      return this.camera ? this.camera.cameraId : null;
    },
    online() {
      return this.status ? this.status.online : null;
    },
    status() {
      return this.desktop
        ? this.desktop.status
        : this.camera && this.camera.hasOwnProperty("status")
        ? this.camera.status
        : null;
    },
    capabilities() {
      return this.desktop
        ? this.desktop.capabilities
        : this.camera && this.camera.hasOwnProperty("detectionCapabilities")
        ? this.camera.detectionCapabilities
        : null;
    },
    hasVideoMotionDetection() {
      return this.capabilities ? this.capabilities.videoMotion : null;
    },
    hasPirDetection() {
      return this.capabilities ? this.capabilities.pir : null;
    },
    hasAudioDetection() {
      return this.capabilities ? this.capabilities.audio : null;
    },
    hasDoorbellDetection() {
      return this.capabilities ? this.capabilities.doorbell : null;
    },
    hasOnlyOneDetectionAvailable() {
      const video = this.hasVideoMotionDetection;
      const pir = this.hasPirDetection;
      const audio = this.hasAudioDetection;
      const doorbell = this.hasDoorbellDetection;
      // need to check if only one settings is turned on
      const result =
        (video & !pir & !audio & !doorbell) |
        (!video & pir & !audio & !doorbell) |
        (!video & !pir & audio & !doorbell) |
        (!video & !pir & !audio & doorbell);
      return !!+result;
    },
    hasSmartVideoAnalytics() {
      return this.capabilities
        ? this.capabilities.analyticsObjectsArea ||
            this.capabilities.analyticsObjectTripWire ||
            this.capabilities.analyticsPersonArea ||
            this.capabilities.analyticsPersonTripWire
        : null;
    },
    smartAnalyticsEnabled: {
      get: function () {
        return this.analyticsSettings ? this.analyticsSettings.enabled : null;
      },
      set: function (booleanValue) {
        this.analyticsSettings.enabled = booleanValue;
        this.checkIfDirty();
      },
    },
    analyticsType: {
      get: function () {
        return this.analyticsSettings ? (this.analyticsSettings.personDetectionOnly ? "person" : "object") : null;
      },
      set: function (type) {
        this.analyticsSettings.personDetectionOnly = type === "person";
        this.checkIfDirty();
      },
    },
  },
  watch: {
    cameraId: function (newVal, oldVal) {
      if (
        (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) ||
        (oldVal !== null && newVal === null)
      ) {
        this.isDirty = false;
      }
      if (newVal) {
        this.getCameraInfo();
      }
    },
    analyticsSettings: function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.initValues.analyticsSettings = {
          enabled: this.analyticsSettings.enabled,
          personDetectionOnly: this.analyticsSettings.personDetectionOnly,
        };
      }
    },
    hasAudioDetection: function (newVal, oldVal) {
      if (newVal) this.getAudioDetectionSetting();
    },
    hasVideoMotionDetection: function (newVal, oldVal) {
      if (newVal) this.getVideoMotionDetectionSetting();
    },
    hasPirDetection: function (newVal, oldVal) {
      if (newVal) this.getPirDetectionSetting();
    },
    hasDoorbellDetection: function (newVal, oldVal) {
      if (newVal) this.getDoorbellDetectionSetting();
    },
    videoMotionDetection: function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.initValues.videoMotionDetection = this.videoMotionDetection;
      }
      if (oldVal !== null) {
        if (newVal === true || this.couldDisableDetectionSetting("videoMotionDetection")) {
          this.checkIfDirty();
        } else if (newVal === false) {
          this.$nextTick(function () {
            this.videoMotionDetection = true;
          });
          this.disableAllDetectionsWarning = true;
        }
      }
    },
    pirDetection: function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.initValues.pirDetection = this.pirDetection;
      }
      if (oldVal !== null) {
        if (newVal || this.couldDisableDetectionSetting("pirDetection")) {
          this.checkIfDirty();
        } else if (newVal === false) {
          this.$nextTick(function () {
            this.pirDetection = true;
          });
          this.disableAllDetectionsWarning = true;
        }
      }
    },
    audioDetection: function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.initValues.audioDetection = this.audioDetection;
      }
      if (oldVal !== null) {
        if (newVal === true || this.couldDisableDetectionSetting("audioDetection")) {
          this.checkIfDirty();
        } else if (newVal === false) {
          this.$nextTick(function () {
            this.audioDetection = true;
          });
          this.disableAllDetectionsWarning = true;
        }
      }
    },
    doorbellDetection: function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.initValues.doorbellDetection = this.doorbellDetection;
      }
      if (oldVal !== null) {
        if (newVal === true || this.couldDisableDetectionSetting("doorbellDetection")) {
          this.checkIfDirty();
        } else if (newVal === false) {
          this.$nextTick(function () {
            this.doorbellDetection = true;
          });
          this.disableAllDetectionsWarning = true;
        }
      }
    },
  },
  mounted() {
    if (this.cameraId) {
      this.getCameraInfo();
    }
    window.addEventListener('keydown', this.keydown);
    window.addEventListener('keyup', this.keyup);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keydown);
    window.removeEventListener('keyup', this.keyup);
  },
  methods: {
    keydown(e) {
      if (e.key === "Shift") {
        this.shiftKeyDown = true;
      }
    },
    keyup(e) {
      if (e.key === "Shift") {
        this.shiftKeyDown = false;
      }
    },
    getCameraInfo() {
      this.analyticsSettings = null;
      this.videoMotionDetection = null;
      this.pirDetection = null;
      this.audioDetection = null;
      this.doorbellDetection = null;
      const that = this;
      const promise = new Promise(function (resolve, reject) {
        resolve("Success!");
      });
      promise
        .then(function () {
          if (that.hasSmartVideoAnalytics) {
            return that.restapi
              .getCameraAnalyticsSetting(that.cameraId)
              .then(function (settings) {
                that.analyticsSettings = settings;
              })
              .catch(function (error) {
                console.error(error);
              });
          } else {
            return true;
          }
        })
        .then(() => {
          if (that.capabilities && that.capabilities.videoMotion) return that.getVideoMotionDetectionSetting();
          else return true;
        })
        .then(() => {
          if (that.capabilities && that.capabilities.pir) return that.getPirDetectionSetting();
          else return true;
        })
        .then(() => {
          if (that.capabilities && that.capabilities.audio) return that.getAudioDetectionSetting();
          else return true;
        })
        .then(() => {
          if (that.capabilities && that.capabilities.doorbell) return that.getDoorbellDetectionSetting();
          else return true;
        })
        .then(() => {
          return true;
        });
    },
    getVideoMotionDetectionSetting() {
      const that = this;
      this.restapi
        .getCameraDetectionSetting(this.cameraId, "video")
        .then(function (enabled) {
          that.videoMotionDetection = enabled;
        })
        .catch(function (error) {
          if (error.response && error.response.status !== 400) {
            console.error(error);
          }
        });
    },
    getPirDetectionSetting() {
      const that = this;
      this.restapi
        .getCameraDetectionSetting(this.cameraId, "pir")
        .then(function (enabled) {
          that.pirDetection = enabled;
        })
        .catch(function (error) {
          if (error.response && error.response.status !== 400) {
            console.error(error);
          }
        });
    },
    getAudioDetectionSetting() {
      const that = this;
      this.restapi
        .getCameraDetectionSetting(this.cameraId, "audio")
        .then(function (enabled) {
          that.audioDetection = enabled;
        })
        .catch(function (error) {
          if (error.response && error.response.status !== 400) {
            console.error(error);
          }
        });
    },
    getDoorbellDetectionSetting() {
      const that = this;
      this.restapi
        .getCameraDetectionSetting(this.cameraId, "doorbell")
        .then(function (enabled) {
          that.doorbellDetection = enabled;
        })
        .catch(function (error) {
          if (error.response && error.response.status !== 400) {
            console.error(error);
          }
        });
    },
    couldDisableDetectionSetting(type) {
      let detectionSettingsCount = 0;
      detectionSettingsCount += this.hasVideoMotionDetection && this.videoMotionDetection ? 1 : 0;
      detectionSettingsCount += this.hasPirDetection && this.pirDetection ? 1 : 0;
      detectionSettingsCount += this.hasAudioDetection && this.audioDetection ? 1 : 0;
      detectionSettingsCount += this.hasDoorbellDetection && this.doorbellDetection ? 1 : 0;
      return detectionSettingsCount > 0;
    },
    checkIfDirty() {
      let areAnalyticsSettingsDirty = false;
      
      if (this.initValues.hasOwnProperty("analyticsSettings")) {
        areAnalyticsSettingsDirty = this.initValues.analyticsSettings.enabled !== this.analyticsSettings.enabled ||
          this.initValues.analyticsSettings.personDetectionOnly !== this.analyticsSettings.personDetectionOnly
      }

      this.isDirty = areAnalyticsSettingsDirty ||
        (this.videoMotionDetection !== null && this.initValues.videoMotionDetection !== this.videoMotionDetection) ||
        (this.pirDetection !== null && this.initValues.pirDetection !== this.pirDetection) ||
        (this.audioDetection !== null && this.initValues.audioDetection !== this.audioDetection) ||
        (this.doorbellDetection !== null && this.initValues.doorbellDetection !== this.doorbellDetection);
    },
    cancelChanges() {
      if (this.initValues.hasOwnProperty("analyticsSettings")) {
        this.analyticsSettings.enabled = this.initValues.analyticsSettings.enabled;
        this.analyticsSettings.personDetectionOnly = this.initValues.analyticsSettings.personDetectionOnly;
      }
      this.videoMotionDetection = this.initValues.videoMotionDetection;
      this.pirDetection = this.initValues.pirDetection;
      this.audioDetection = this.initValues.audioDetection;
      this.doorbellDetection = this.initValues.doorbellDetection;
      this.checkIfDirty();
    },
    async saveChanges() {
      if (this.rights && this.rights.demoUser) {
        return this.$store.dispatch("demoMessage")
      }

      let hasErrors = false;

      if (this.initValues.hasOwnProperty("analyticsSettings") &&
        (this.analyticsSettings.enabled !== this.initValues.analyticsSettings.enabled ||
          this.analyticsSettings.personDetectionOnly !== this.initValues.analyticsSettings.personDetectionOnly)
      ) {
        try {
          await this.restapi.setCameraAnalyticsSetting(this.cameraId, this.analyticsSettings.enabled, this.analyticsSettings.personDetectionOnly);
          this.initValues.analyticsSettings.enabled = this.analyticsSettings.enabled;
          this.initValues.analyticsSettings.personDetectionOnly = this.analyticsSettings.personDetectionOnly;
          this.checkIfDirty();
        } catch (error) {
          console.error(error);
          hasErrors = true;
        }
      }

      if (this.videoMotionDetection !== null && this.videoMotionDetection !== this.initValues.videoMotionDetection) {
        try {
          await this.restapi.setCameraDetectionSetting(this.cameraId, "video", this.videoMotionDetection);
          this.initValues.videoMotionDetection = this.videoMotionDetection;
          this.checkIfDirty();
        } catch (error) {
          console.error(error);
          this.disableAllDetectionsWarning = true;
          hasErrors = true;
        }
      }

      if (this.pirDetection !== null && this.pirDetection !== this.initValues.pirDetection) {
        try {
          await this.restapi.setCameraDetectionSetting(this.cameraId, "pir", this.pirDetection);
          this.initValues.pirDetection = this.pirDetection;
          this.checkIfDirty();
        } catch (error) {
          console.error(error);
          this.disableAllDetectionsWarning = true;
          hasErrors = true;
        }
      }

      if (this.audioDetection !== null && this.audioDetection !== this.initValues.audioDetection) {
        try {
          await this.restapi.setCameraDetectionSetting(this.cameraId, "audio", this.audioDetection);
          this.initValues.audioDetection = this.audioDetection;
          this.checkIfDirty();
        } catch (error) {
          console.error(error);
          this.disableAllDetectionsWarning = true;
          hasErrors = true;
        }
      }

      if (this.doorbellDetection !== null && this.doorbellDetection !== this.initValues.doorbellDetection) {
        try {
          await this.restapi.setCameraDetectionSetting(this.cameraId, "doorbell", this.doorbellDetection);
          this.initValues.doorbellDetection = this.doorbellDetection;
          this.checkIfDirty();
        } catch (error) {
          console.error(error);
          this.disableAllDetectionsWarning = true;
          hasErrors = true;
        }
      }

      if (hasErrors === false) {
        store.dispatch("toastMessage", {
          showing: true,
          text: this.$t("Your changes were successfully saved."),
          timeout: 4000,
          color: "primary",
        });
      } else {
        store.dispatch("toastMessage", {
          showing: true,
          text: this.$t("An error occurred while saving your changes."),
          timeout: -1,
          color: "error",
          support: true
        });
      }
    },
  },
  "$route.query"() {
    if (this.cameraId) {
      this.getCameraInfo();
    }
  },
};
</script>

<style lang="scss" scoped>
.detections {
  >>> .theme--light .v-label,
  >>> .v-input--selection-controls.v-input .v-label {
    font-size: unset;
  }
}

.font-size-12 {
  font-size: 12px;
}

.row {
  margin: 0px -12px 0px -12px
}

.detections__switchbutton--right {
  right: 12px !important;
  position: absolute;
}

</style>
