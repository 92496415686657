<template>
  <v-card class="mt-4" width="90%">
    <v-container class="px-8 py-5">
      <v-layout>
        <v-flex>
          <p class="subheading my-2">{{ $t("Click to reset sub user password") }}</p>
        </v-flex>
        <v-flex>
          <v-btn class="float-right" color="primary" @click="changePassword">{{ $t("Reset password") }}</v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
import { store } from "@/store";
export default {
  name: "ChangePassword",
  props: ["user", "rights"],
  methods: {
    async changePassword() {
      try {
        if (this.rights?.demoUser) {
          return this.$store.dispatch("demoMessage")
        }
        this.restapi.requestEndUserPasswordResetLink(this.user.email);
        store.dispatch("toastMessage", {
          text: this.$t("Please check email at EMAIL to reset password", { email: this.user.email }),
          color: "primary",
          showing: true,
          timeout: 4000,
        });
      } catch (error) {
        store.dispatch("toastMessage", {
          text: getMessageFromError(error, "REQUEST_PASSWORD_RESET"),
          color: "error",
          showing: true,
          timeout: -1,
          support: true
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
form.v-form.larger {
  font-size: 14px;
  input {
    font-size: inherit;
  }
}
</style>
