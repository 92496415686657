import Vue from 'vue'
import VueCookie from 'vue-cookies'
import ClientOAuth2 from 'client-oauth2'
import axios from 'axios'
import { getConfiguration } from '@/service/main'
import { store } from "@/store"

Vue.use(VueCookie)

const config = getConfiguration()

const APP_ID = config.appId
const APP_SWITCHER_ID = 'webapp.v2'
const UID_KEY = 'uid'
const CLIENT_ID = clientId()

let client = initClient()

function initClient() {
  return new ClientOAuth2({
    clientId: CLIENT_ID,
    authorizationUri: config.authServer + '/oauth/authorize',
    redirectUri: getRedirectUri(),
    scopes: ['read', 'write'],
    query: {
      appId: APP_SWITCHER_ID
    }
  })
}

export function resetClient() {
  client = initClient()
}

let skipAppCookies = false

export function oauthUri () {
  if (client.token) {
    return client.token.getUri()
  }
  return null
}

function clientId () {
  let uid = window.$cookies.get(UID_KEY) || false
  if (!uid) {
    uid = Math.floor(Math.random() * 0x10000).toString(16)
    window.$cookies.set(UID_KEY, uid, '1y')
  }
  return APP_ID + '-' + uid
}

export function checkAuth () {
  return this.getToken()
}

export function isAuthorized () {
  const token = this.getToken()
  const appId = window.$cookies.get(this.cookieApp())

  if (skipAppCookies) {
    return !!token
  } else {
    return !!token && appId === APP_ID
  }
}

export function getToken () {
  return skipAppCookies ? skipAppCookies : window.$cookies.get(cookieAuth(), '/', cookieDomain())
}

export function removeTokenCookie () {
  window.$cookies.remove(this.cookieAuth(), '/', this.cookieDomain())
}

export function deleteToken () {
  const deleteOauthToken = true
  const token = window.$cookies.get(this.cookieAuth(), '/', this.cookieDomain())
  if (token) {
    if (deleteOauthToken) {
      axios.delete(config.authServer + '/rest/v2.0/users/self/tokens/current', {
        headers: { Authorization: 'Bearer ' + token },
      })
    }
    window.$cookies.remove(this.cookieAuth(), '/', this.cookieDomain())
  }
  resetClient()
}

export function doLogin () {
  window.location = oauthUri()
}

export function login (token, expiresIn, skipCookie) {
  const validExpiresIn = !!expiresIn
  const that = this
  skipCookie = (skipCookie === 'true')

  if (skipCookie) {
    skipAppCookies = token
    store.dispatch("supportAccess", true);
  }
  
  if (validExpiresIn) {
    if (!skipAppCookies) {
      window.$cookies.set(this.cookieApp(), APP_ID, parseInt(expiresIn), '/', this.cookieDomain())
      window.$cookies.set(this.cookieAuth(), token, parseInt(expiresIn), '/', this.cookieDomain())
    }
    setTimeout(() => {
      that.logout()
    }, expiresIn * 1000)
  } else {
    window.$cookies.remove(this.cookieApp(), '/', this.cookieDomain())
    window.$cookies.remove(this.cookieAuth(), '/', this.cookieDomain())
  }
}

export function logout (returnToUrl = true) {
  const token = getToken()
  if (token) {
    this.cleanOnLogout()
    window.location = config.authServer + '/auth/logout?token=' + token + '&redirect_uri=' +
      (returnToUrl ? window.location.href.split('?')[0] : window.location.origin)
  } else {
    window.location = oauthUri() + '&redirect_uri=' + (returnToUrl ? window.location.href.split('?')[0] : window.location.origin)
  }
}

export function getRedirectUri () {
  let splitUri = window.location.href.split('?')
  let uriLocation = splitUri[0]
  let queries = splitUri[1] ? splitUri[1] : ''

  if (!uriLocation) {
    uriLocation = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')
  }
  // uriLocation = (uriLocation.replace('/logout', '/') + '#/?a=0').replace('#/#', '#')
  uriLocation = uriLocation.replace('/logout', '/')
  if (uriLocation.indexOf('#') === -1) {
    uriLocation += '#/'
  }
  uriLocation += `?${queries}`
  if (uriLocation.indexOf('a=0') === -1) {
    uriLocation += 'a=0'
  }
  return uriLocation
}

export function cleanOnLogout () {
  if (!skipAppCookies) {
    window.$cookies.remove(this.cookieApp(), '/', this.cookieDomain())
  }
  window.$cookies.remove(this.cookieAuth(), '/', this.cookieDomain())
}

export function cookieDomain () {
  const ipv4Url = RegExp([
    '^https?://([a-z0-9\\.\\-_%]+:([a-z0-9\\.\\-_%])+?@)?',
    '((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\\.){3}(25[0-5]|2[0-4',
    '][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])?',
    '(:[0-9]+)?(/[^\\s]*)?$',
  ].join(''), 'i')
  const fqd = window.location.hostname.split('.')
  if (ipv4Url.test(window.location.href)) {
    return window.location.hostname
  } else if (fqd.length === 1) {
    return fqd[0]
  } else {
    const fqdr = fqd.reverse()
    return `${fqdr[1]}.${fqdr[0]}`
  }
}

export function cookieApp () {
  return 'app-' + getHostName(config.authServer)
}

export function cookieAuth () {
  return 'auth-' + getHostName(config.authServer)
}

export function isLoggedIntoOtherApp () {
  if (skipAppCookies) {
    return false
  } else {
    const cookieApp = window.$cookies.get(this.cookieApp())
    return cookieApp && cookieApp !== APP_ID
  }
}

function getHostName (url) {
  const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i)
  if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
    return match[2]
  }
  return null
}

export default {
  oauthUri,
  doLogin,
  login,
  logout,
  checkAuth,
  isAuthorized,
  getToken,
  clientId,
  cleanOnLogout,
  cookieDomain,
  cookieApp,
  isLoggedIntoOtherApp,
}
