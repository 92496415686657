<template>
  <v-container fluid class="pa-0">
    <v-card width="90%">
      <v-form>
        <v-container class="pa-8" fluid>
          <v-layout row wrap>
            <v-flex sm6 class="hidden-xs-only">
              <p class="subheading my-2">{{ $t("First name") }}</p>
            </v-flex>
            <v-flex sm6 xs12>
              <v-text-field
                :label="$vuetify.breakpoint.smAndUp ? '' : $t('First name')"
                :solo="$vuetify.breakpoint.smAndUp"
                flat
                :error-messages="firstNameErrors"
                @input="$v.FirstName.$touch()"
                @blur="$v.FirstName.$touch()"
                v-model="FirstName"
                dense
                :background-color="$vuetify.breakpoint.smAndUp ? 'grey lighten-3' : ''"
              ></v-text-field>
            </v-flex>
            <v-flex sm12 class="hidden-xs-only" pb-5><v-divider></v-divider></v-flex>
            <een-spinner 
              :isloading="updatingUser"
              :size="50"
              :width="7"
            ></een-spinner>
            <v-flex sm6 class="hidden-xs-only">
              <p class="subheading my-2">{{ $t("Last name") }}</p>
            </v-flex>
            <v-flex sm6 xs12>
              <v-text-field
                :label="$vuetify.breakpoint.smAndUp ? '' : $t('Last name')"
                :solo="$vuetify.breakpoint.smAndUp"
                flat
                :error-messages="lastNameErrors"
                v-model="LastName"
                @input="$v.LastName.$touch()"
                @blur="$v.LastName.$touch()"
                dense
                :background-color="$vuetify.breakpoint.smAndUp ? 'grey lighten-3' : ''"
              ></v-text-field>
            </v-flex>
            <v-flex sm12 class="hidden-xs-only" pb-5><v-divider></v-divider></v-flex>
            <v-flex xs12>
              <v-btn
                color="primary"
                :disabled="$v.$invalid || (!$v.FirstName.$dirty && !$v.LastName.$dirty)"
                @click="savePersonalDetails"
                >{{ $t("Save changes") }}</v-btn
              >
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { store } from "@/store";
import { getMessageFromError } from "@eencloud/core-components/src/service/errors";

export default {
  name: "PersonalDetails",
  props: ["rights"],
  data() {
    return {
      user: {},
      FirstName: "",
      LastName: "",
      Email: "",
      updatingUser: false,
    };
  },
  async mounted() {
    try {
      const response = await this.restapi.getSpecificUser(this.$route.params.userId);
      this.FirstName = response.firstName;
      this.LastName = response.lastName;
      this.Email = response.email;
    } catch (error) {
      store.dispatch("toastMessage", {
        text: getMessageFromError(error, "ADD_USER"),
        color: "error",
        showing: true,
        timeout: -1,
        support: true
      });
    }
  },
  computed: {
    firstNameErrors() {
      const errors = [];
      if (!this.$v.FirstName.$dirty) return errors;
      if (!this.$v.FirstName.required) errors.push(this.$t("First name is required"));
      if (!this.$v.FirstName.minLength || !this.$v.FirstName.maxLength) {
        errors.push(this.$t("First name should be 2-32 characters"));
      }
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.LastName.$dirty) return errors;
      if (!this.$v.LastName.required) errors.push(this.$t("Last name is required"));
      if (!this.$v.LastName.minLength || !this.$v.LastName.maxLength) {
        errors.push(this.$t("Last name should be 2-32 characters"));
      }
      return errors;
    },
  },
  methods: {
    savePersonalDetails() {
      if (this.rights?.demoUser) {
        return this.$store.dispatch("demoMessage")
      }
      const that = this;
      const newUser = { email: this.Email, firstName: this.FirstName, lastName: this.LastName };
      this.updatingUser = true;
      this.restapi
        .setUserDetails(this.$route.params.userId, newUser)
        .then(function () {
          that.updatingUser = false;
          that.$v.$reset();
          store.dispatch("toastMessage", {
            text: that.$t("User has been updated successfully"),
            color: "primary",
            showing: true,
            timeout: 4000,
          });
          that.$emit("userUpdated");
        })
        .catch(function (error) {
          that.updatingUser = false;
          store.dispatch("toastMessage", {
            text: getMessageFromError(error, "UPDATE_USER"),
            color: "error",
            showing: true,
            timeout: -1,
            support: true
          });
        });
    },
  },
  validations: {
    FirstName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(32),
    },
    LastName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(32),
    },
  },
};
</script>

<style lang="scss" scoped>
form.v-form.larger {
  font-size: 14px;
  input {
    font-size: inherit;
  }
}

</style>
