import webstomp from 'webstomp-client'

function StompClient (url, accountId, type, id = null, callback, debug = false) {
  this.headers = { host: 'cmhost' }
  this.url = url
  this.accountId = accountId
  this.type = type
  this.id = id
  this.callback = callback
  this.debug = debug
  this.connected = false
  this.webstomp = null
  this.subscription = null
}

StompClient.prototype.send = function () {
  console.log('Send message:' + this.sendMessage)
  if (this.webstomp && this.webstomp.connected) {
    const msg = { name: this.sendMessage }
    this.webstomp.send('/queue/some_queue', JSON.stringify(msg), {})
  }
}

StompClient.prototype.connect = function () {
  this.webstomp = webstomp.over(new WebSocket(this.url), { debug: this.debug })
  this.webstomp.connect(this.headers, frame => {
    this.connected = true
    this.subscription = this.webstomp.subscribe(`/exchange/cameramanager/${this.accountId}.` +
      (this.type ? `${this.type}.` : '#.') + (this.id ? `${this.id}.#` : '#'), this.callback || this.log)
  },
  error => {
    console.error(error)
    this.connected = this.webstomp.connected
  })
}

StompClient.prototype.disconnect = function () {
  if (this.webstomp) {
    if (this.subscription) {
      this.subscription.unsubscribe(this.headers)
    }
    this.webstomp.disconnect()
  }
  this.connected = false
}

StompClient.prototype.toggle = function () {
  this.connected ? this.disconnect() : this.connect()
}

StompClient.prototype.log = function (tick) {
  console.log('Received message:', tick.body)
}

export default StompClient
