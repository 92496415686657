<template>
  <v-container fluid class="dashboard my-0 py-0">
    <v-row justify="space-around" class="fill-height">
      <v-col class="dashboard-content">
        <v-row class="px-3 pb-4 pt-6" justify="center">
          <v-col cols="3" class="mt-4">
            <h2 class="headline d-inline-block">{{ $t("Zones") }}</h2>
            <v-btn @click="zoneExplaination = true" class="mt-n2" icon color="primary">
              <v-icon size="20">fas fa-info-circle</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="5">
            <v-text-field
              v-model="search"
              append-icon="fa-search"
              :label="$t('Search')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="mt-3">
            <v-btn
              color="primary"
              :ripple="false"
              @click="showAddZoneModal"
              class="float-right"
              v-if="rights && (rights.addZone || rights.demoUser)"
              >{{ $t("Add Zone") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="cameraZones"
            item-key="zoneId"
            hide-default-header
            dense
            :items-per-page="25"
            :search="search"
            :loading-text="$t('Loading results')"
            :no-results-text="$t('No matching records found')"
            :no-data-text="$t('No data')"
            :footer-props="{
              'items-per-page-options': [10, 25, 50, -1],
              'items-per-page-all-text': $t('All'),
              'items-per-page-text': $t('Rows per page'),
            }"
          >
            <template v-slot:header="{ props, on }">
              <thead>
                <tr>
                  <th class="table-head" v-for="header in props.headers" :key="header.value">
                    <a @click="on.sort(header.value)">
                      {{ $t(header.text) }}
                      <v-icon
                        class="pb-1"
                        v-if="header.value === props.options.sortBy[0]"
                        color="grey darken-2"
                        size="12px"
                        >{{
                          props.options.sortDesc[0] ? "fas fa-long-arrow-alt-down" : "fas fa-long-arrow-alt-up"
                        }}</v-icon
                      >
                    </a>
                  </th>
                  <th class="table-head"></th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr :key="item.zoneId" :class="{ 'table-row': true }" @click="goToZoneSettings(item.zoneId)">
                <td class="table-cell">{{ item.name }}</td>
                <td class="table-cell action-buttons">
                  <div class="d-flex flex-row justify-end">
                    <v-btn v-if="rights && (rights.editZone || rights.demoUser)" icon class="mr-3">
                      <v-icon color="grey darken-2" size="17px">fas fa-pen</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="rights && (rights.deleteZone || rights.demoUser)"
                      @click.stop="onClickRemove(item)"
                      icon
                      class="mr-3"
                      ><v-icon color="grey darken-2" size="18">fas fa-trash-alt</v-icon>
                    </v-btn>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="addingNewZone" max-width="400">
      <v-card>
        <v-card-title primary-title>
          <p class="title unset-title-color">{{ $t("Add new zone") }}</p>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="newZoneName"
            :label="$t('Zone name')"
            :error-messages="newZoneNameErrors"
            @input="$v.newZoneName.$touch()"
            @blur="$v.newZoneName.$touch()"
            @keyup.enter="addZone()"
            ref="addZoneInput"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <een-spinner :isloading="addingZone"></een-spinner>
          <v-btn depressed color="primary" :disabled="$v.newZoneName.$invalid || addingZone" @click="addZone">{{
            $t("Add new zone")
          }}</v-btn>
          <v-btn depressed @click="cancelAddZone">{{ $t("Cancel") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmRemoveZone" max-width="400">
      <v-card>
        <v-card-title primary-title>
          <p class="title unset-title-color">{{ $t("Are you sure you want to remove this zone?") }}</p>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" @click="removeZone">{{ $t("Confirm") }}</v-btn>
          <v-btn depressed @click.stop="confirmRemoveZone = false">{{ $t("Cancel") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="zoneExplaination" max-width="600" persistent>
      <v-card>
        <v-card-text class="pt-4"
          >{{
            $t(
              "Zones can be used to group cameras, all cameras of a zone will be grouped together."
            )
          }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click.stop="zoneExplaination = false">{{ $t("Close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { store } from "@/store";

export default {
  name: "Zones",
  props: ["zones", "rights"],
  data() {
    return {
      headers: [{ text: "Name", value: "name" }],
      addingNewZone: false,
      addingZone: false,
      newZoneName: null,
      confirmRemoveZone: false,
      selectedZone: null,
      currentZones: null,
      search: "",
      zoneExplaination: false
    };
  },
  computed: {
    cameraZones: {
      get: function () {
        if (this.currentZones) return this.currentZones;
        return this.zones;
      },
      set: function (newVal) {
        this.currentZones = newVal;
      },
    },
    newZoneNameErrors() {
      const errors = [];
      if (!this.$v.newZoneName.$dirty) return errors;
      if (!this.$v.newZoneName.required) errors.push(this.$t("Zone name is required"));
      if (!this.$v.newZoneName.minLength || !this.$v.newZoneName.maxLength) {
        errors.push(
          this.$t("Zone name should be X-Y characters", {
            min: this.$v.newZoneName.$params.minLength.min,
            max: this.$v.newZoneName.$params.maxLength.max,
          })
        );
      }
      return errors;
    },
  },
  methods: {
    selectToDelete(item) {
      this.selectedZone = item;
      this.confirmRemoveZone = true;
    },
    showAddZoneModal() {
      this.addingNewZone = true;
      // auto focus
      setTimeout(() => {
        this.$refs.addZoneInput.focus();
      }, 200);
      this.$v.$reset();
    },
    cancelAddZone() {
      this.addingNewZone = false;
      this.newZoneName = null;
      this.$v.$reset();
    },
    addZone() {
      if (this.rights && this.rights.demoUser) {
        return this.$store.dispatch("demoMessage");
      }
      if (!this.$v.newZoneName.$invalid) {
        const that = this;
        let zoned;
        this.addingZone = true;
        this.restapi
          .addZone(this.newZoneName)
          .then(function (zone) {
            zoned = zone;
            that.addingNewZone = false;
            that.newZoneName = null;
            that.addingZone = false;
            that.$v.$reset();
            that.$emit("addedzone", zone);
          })
          .catch(function (error) {
            console.error(error);
          });
      }
    },
    onClickRemove(zone) {
      zone.cameraIds.length
        ? store.dispatch("toastMessage", {
            text: this.$t("Only empty zones can be removed. Move your cameras to another zone or remove your cameras."),
            color: "error",
            showing: true,
            timeout: -1,
            support: true,
          })
        : this.selectToDelete(zone);
    },
    removeZone() {
      if (this.rights && this.rights.demoUser) {
        this.confirmRemoveZone = false;
        return this.$store.dispatch("demoMessage");
      }
      const that = this;
      this.restapi
        .deleteZone(this.selectedZone.zoneId)
        .then(this.$emit("deletedzone", this.selectedZone.zoneId))
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          that.confirmRemoveZone = false;
        });
    },
    goToZoneSettings(zoneId) {
      if (this.rights && (this.rights.editZone || this.rights.demoUser))
        this.$router.push({ name: "EditZone", params: { zoneId } });
    },
  },
  validations: {
    newZoneName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(32),
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main";

.dashboard {
  background-color: #eee;
  .dashboard-content {
    min-height: $main-content-height;
    max-width: 1000px;
    margin: auto;
  }
}

.headline {
  color: #555555;
}

.table-head {
  border-bottom-color: var(--v-primary-lighten1) !important;
  border-bottom-width: 2px !important;
}

.table-row {
  &:nth-child(even) {
    background-color: #f8f8f8;
  }
  &.expanded {
    background-color: #f5f5f5;
  }
  .table-cell {
    padding: 10px;
    vertical-align: middle;
    font-size: 0.9em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none !important;
    &:hover {
      cursor: pointer;
    }
    &.camera-status {
      text-align: center;
      color: white;
      .online {
        background-color: #207cad;
        padding: 5px;
        border-radius: $border-radius;
      }
      .offline {
        background-color: #d43536;
        padding: 5px;
        border-radius: $border-radius;
      }
    }
    &.action-buttons {
      padding: 5px;
      &::v-deep .v-btn {
        height: 30px;
        width: 30px;
      }
    }
  }
}

.expanded-area {
  cursor: default;
  .row {
    .col {
      padding: 0;
      span {
        font-size: 12px;
        color: rgb(204, 204, 204);
        img {
          height: 14px;
        }
        &.connectionIcon {
          color: rgb(74, 74, 74);
        }
      }
      p {
        margin-bottom: 5px;
      }
    }
  }
}

.iccid-icon,
.sim-status-icon {
  width: 20px;
  display: inline-block;
  margin-right: 5px;
}

.sim-status-icon {
  &.offline {
    path {
      fill: $color-gray;
    }
  }
  &.sim-installed {
    path {
      fill: $primary;
    }
  }
  &.no-sim-installed {
    path {
      fill: $color-gray;
    }
  }
}

.iccid-label,
.sim-status-label {
  display: inline-block;
}

.iccid-block,
.sim-status-block {
  padding-top: 5px;
}

.is-divider {
  height: 2px;
  background-color: #dfdfdf73;
  display: block;
  margin-top: 0.5rem;
  margin-bottom: 1.75rem;
}
</style>
