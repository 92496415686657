<template>
  <v-row class="add-multiple-camera">
    <v-col cols="10" offset="1" lg="8" offset-lg="2">
      <div class="mb-4 d-flex justify-space-between align-center">
        <span class="headline">{{ $t(isResultMode ? "Result" : "Add multiple devices") }}</span>
      </div>
      <p v-if="!isResultMode"
        class="body-2"
        v-html="$t('You can add multiple devices right away.')"
      ></p>
      <p v-if="isResultMode && isDirty"
        class="body-2 color-red"
        v-html="$t('Please do not reload or move away from the page')"
      ></p>
      <v-card v-if="!isResultMode"> 
        <v-form ref="form">
          <v-data-table
            class="add-multiple-camera__table"
            :items="newCameras"
            item-key="id"
            hide-default-header
            hide-default-footer
            dense
            disable-pagination
          >
            <template v-slot:header="{ props, on }">
              <thead>
                <tr>
                  <th class="table-head" v-for="(header, index) in tableHeaders" :key="index">
                    {{ header }}
                  </th>
                  <th class="table-head"></th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr :key="item.id" class="table-row">
                <td class="table-cell">
                  <v-text-field
                    v-model="item.name"
                    flat
                    dense
                    solo
                    :rules="cameraNameRules"
                    :placeholder="$t('Enter name')"
                    background-color="grey lighten-3"
                  />
                </td>
                <td class="table-cell">
                  <v-autocomplete
                    required
                    flat
                    filled
                    dense
                    :items="zones"
                    item-text="name"
                    item-value="zoneId"
                    v-model="item.zoneId"
                    :rules="zoneNameRules"
                  />
                </td>
                <td class="table-cell">
                  <v-text-field
                    v-model="item.macAddress"
                    v-mask="hexMacs"
                    flat
                    dense
                    solo
                    :placeholder="$t('Enter MAC address')"
                    background-color="grey lighten-3"
                    :rules="macAddressRules"
                  />
                </td>
                <td class="table-cell action-buttons">
                  <div :class="['d-flex flex-row justify-end',{'action-buttons__hidden': item.id < 1}]">
                    <v-btn icon @click="removeItem(item.id)" :disabled="statusLoading">
                      <v-icon color="grey darken-2" size="17px">fas fa-times</v-icon>
                    </v-btn>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-form>
      </v-card>
      <v-card v-else> 
        <v-data-table
            class="add-multiple-camera__table"
            :items="newCameras"
            item-key="id"
            hide-default-header
            hide-default-footer
            dense
            disable-pagination
            :key="key"
          >
            <template v-slot:header="{ props, on }">
              <thead>
                <tr>
                  <th class="table-head" v-for="(header, index) in resultTableHeaders" :key="index">
                    <a @click="on.sort(header.value)">
                    {{ header.text }}
                    <v-icon
                        class="pb-1"
                        v-if="header.value === props.options.sortBy[0]"
                        color="grey darken-2"
                        size="12px"
                        >{{
                          props.options.sortDesc[0] ? "fas fa-long-arrow-alt-down" : "fas fa-long-arrow-alt-up"
                        }}</v-icon>
                    </a>
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr :key="item.id" class="table-row table-row__result">
                <td class="table-cell table-cell__result">
                  {{ item.name }}
                </td>
                <td class="table-cell table-cell__result">
                  {{ getZoneName(item.zoneId) }}
                </td>
                <td class="table-cell table-cell__result">
                  {{ item.macAddress }}
                </td>
                <td :class="['table-cell table-cell__result', {'color-red': !!item.error || !!item.exception,
                'color-success': item.status === $t('Added')
                }]">
                  <div class="d-flex align-baseline">
                    {{ item.status }}
                    <v-btn
                      v-if="item.status && (item.error || item.exception)"
                      @click.exact="showError(item)"
                      small
                      fab
                      elevation="0"
                      class="error-btn ml-1"
                    >
                      <v-icon size="17" color="red">
                        fa-exclamation-circle
                      </v-icon>
                    </v-btn>
                    <een-spinner :size="18" :width="2" :isloading="!item.status" :absolute="false"/>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
      </v-card>
      <div class="d-flex justify-space-between align-baseline" v-if="!isResultMode">
        <v-btn @click="addMoreItem" color="primary" class="mt-8 text-transform-none" :disabled="this.newCameras.length >= 20 || statusLoading">
          {{ $t("Add 5 more") }}
          <v-icon right>fas fa-plus</v-icon>
        </v-btn>
        <div>
          <v-btn @click="$emit('cancel')" class="mr-3 text-transform-none" :disabled="statusLoading">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn color="primary" @click="addAllDevices" class="text-transform-none" :loading="statusLoading">
            {{ $t("Add all devices") }}
          </v-btn>
        </div>
      </div>
      <div class="float-right mt-8" v-else>
        <v-btn @click="$emit('cancel')" color="primary" class="text-transform-none" :disabled="isDirty">
          {{ $t("Close") }}
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mask } from "vue-the-mask";
import { store } from "@/store";
import { getMessageFromError } from "@eencloud/core-components/src/service/errors";
import Sentry from "@eencloud/core-components/src/plugins/sentry";
import { getCameraErrorMsg } from "@/service/cameraErrors";
import warningForCameraSettingsChangesMixin from '@eencloud/core-components/src/service/warningForCameraSettingsChangesMixin';

export default {
  name: "AddMultipleCamera",
  directives: { mask },
  mixins: [warningForCameraSettingsChangesMixin],
  data() {
    return {
      isDirty: false,
      isResultMode: false,
      key: 0,
      statusLoading: false,
      tableHeaders: [this.$t("Name"), this.$t("Zone"), this.$t("MAC address"),],
      resultTableHeaders: [
        { text: this.$t("Name"), value: "name" },
        { text: this.$t("Zone"), value: "zone" },
        { text: this.$t("MAC address"), value: "macAddress" },
        { text: this.$t("Status"), value: "status" },
      ],
      blankTableRow: {name: '', zoneId: '', macAddress: ''},
      hexMacs: {
        mask: "FF-FF-FF-FF-FF-FF",
        tokens: {
          F: {
            pattern: /[0-9a-fA-F]/,
            transform: (v) => v.toLocaleUpperCase(),
          },
        },
      },
      zones: [],
      newCameras: [],
      cameraNameRules: [
        value => !!value || this.$t("Device name is a required field"),
        value => value.length <= 32 || this.$t("Device name should be max 32 characters"),
      ],
      zoneNameRules: [value => value > 0 || this.$t("Device Zone is a required field")],
      macAddressRules: [
        (value) => !!value || this.$t("MAC Address is a required field"),
        (value) => value.length === 17 || this.$t("Please enter a valid MAC address"),
      ],
    };
  },

  mounted() {
    Sentry.configureScope((scope) => scope.setTransactionName(this.$options.name));
    const that = this;
    this.restapi.getZones().then(function (zones) {
      that.zones = zones;
    });
    this.newCameras = Array(5).fill().map((_,i) => { return ({...this.blankTableRow, id: i});});
  },
  methods: {
    getZoneName(id) {
      return this.zones.find(zone=>zone.zoneId === id).name;
    },
    removeItem(id) {
      this.newCameras = this.newCameras.filter(camera => camera.id !== id)
    },
    addMoreItem() {
      const size = 20 - this.newCameras.length > 5 ? 5 : 20 - this.newCameras.length;
      const arr = Array(size).fill().map((_,i) => { return ({...this.blankTableRow, id: this.newCameras.length + i});});
      this.newCameras = [...this.newCameras, ...arr];
    },
    async addAllDevices() {
      if(!this.$refs.form.validate()) {
        this.statusLoading = false;
        return;
      }
      if (this.rights && this.rights.demoUser) {
        this.statusLoading = false;
        return this.$store.dispatch("demoMessage")
      }
      this.statusLoading = true;
      this.isDirty = true;
      const deviceIds = [];
      this.isResultMode = true;
      this.newCameras.forEach(async (camera, index) => {
        try {
          const addCameraByMAC = await this.restapi.addDeviceByMAC(camera.name, camera.zoneId, camera.macAddress);
          camera.deviceId= addCameraByMAC.deviceId;
          deviceIds.push(addCameraByMAC.deviceId);
        } catch(error) {
          Sentry.captureException(error);
          console.log("add multiple devices using mac error", error);
          camera.exception = error;
          camera.status = this.$t("Failed"); 
          ++this.key;
        }

        if(index === this.newCameras.length-1) {
          this.statusLoading = false;
          deviceIds.length ? this.getAdditionStatus(deviceIds) : (this.isDirty = false);
        }
      });
      
    },

    async getAdditionStatus(deviceIds) {
      let addedCameraStatusPoll = undefined;
      try {
        addedCameraStatusPoll = await this.restapi.getAllAdditionStatus(deviceIds.toString());
      } catch(error) {
        this.newCameras.forEach(device => {
          if(deviceIds.includes(device.deviceId)) {
            device.exception = error;
            device.status = this.$t("Failed"); 
            ++this.key;
          }
        });
      }
      if(!addedCameraStatusPoll) {
        this.isDirty = false;
        return;
      }
      addedCameraStatusPoll.forEach((deviceStatus) => {
        const device = this.newCameras.find((device) => device.deviceId === deviceStatus.deviceId);
        if(device) {
          device.status = undefined;
          switch(deviceStatus.status) {
            case "added": device.status = this.$t("Added"); break; 
            case "failure": 
              device.error = deviceStatus.subStatus;
              device.status = this.$t("Failed"); 
              break; 
          }
          ++this.key;
        }
      });

      const isAllProcessed = addedCameraStatusPoll.every(deviceStatus => ["added", "failure"].includes(deviceStatus.status));
      if(!isAllProcessed) {
        setTimeout(()=> this.getAdditionStatus(deviceIds), 5000);
      } else {
        this.$emit("loadCameras");
        this.isDirty = false
      }
    },

    showError(device) {
      const text = device.error? getCameraErrorMsg(device.error) : getMessageFromError(device.exception, "ADD_DEVICE");
      store.dispatch("toastMessage", {
        showing: true,
        text,
        timeout: -1,
        support: true,
        color: "error",
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/main";
.add-multiple-camera {
  &__table {
    tr:hover {
      background: transparent !important;
    }
    .table-head {
      border-bottom-color: var(--v-primary-lighten1) !important;
      border-bottom-width: 2px !important;
      color: $primary !important;
    }

    .table-row {

      &__result {
        height: 50px;
        &:nth-child(even) {
          background-color: #f8f8f8;
        }
        &:hover:nth-child(even) {
          background-color: #f8f8f8 !important;
        }
      }
      .table-cell {
        padding: 10px 10px 0 16px;
        vertical-align: middle;
        font-size: 0.9em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border: none !important;

        &:first-child {
          padding-left: 16px;
        }

        &__result {
          padding-top: 0;
        }
      } 
      .action-buttons {
        padding: 15px 0 0 0;
        display: flex;
        height: 100%;
        &::v-deep .v-btn {
          height: 30px;
          width: 30px;
        }

        &__hidden {
          visibility: hidden;
        }
      }
    }
  }

  .text-transform-none {
    text-transform: none;
  }
  .overlay-text {
    margin-top: 175px;
  }
  .color-white {
    color: #ffffff;
  }
  .color-red {
    color: red;
  }
  .color-success {
    color: green;
  }
  .error-btn {
    background-color: unset !important;
  }
}
</style>
