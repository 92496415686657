// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'babel-polyfill'
import 'es6-promise/auto'
import 'es6-object-assign/auto'
import 'es6-string-polyfills'
import Vue from 'vue'
import VueCookie from 'vue-cookies'
import vuetify from "./plugins/vuetify";
import App from '@/App'
import router from '@/service/router'
import locale from '@eencloud/core-components/src/service/locale'
import { store } from '@/store/index'
import Vuelidate from 'vuelidate'
import Theme from "@eencloud/core-components/src/service/themes";
import CMApi from "@eencloud/core-components/src/service/CMApi";
import axios from 'axios'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueMoment from 'vue-moment'
import PageVisibility from 'vue-visibility-change'
import VueGtm from 'vue-gtm'
import VueInputAutowidth from 'vue-input-autowidth'
import { getImgUrl, pad, getUrl } from '@/service/main'
import '@eencloud/core-components/src/plugins/sentry';
import CoreComponents from "@eencloud/core-components";

/* eslint-disable no-undef */
if (config) {
  console.log('version', config.version)
  store.commit('configuration', config)
}

const auth = require('@/service/auth')

axios.interceptors.response.use(null, (error) => {
  console.log('interceptors.response error', error)
  if (error.response && error.response.status === 401) {
    auth.removeTokenCookie()
    store.dispatch("setAuthorized", auth.isAuthorized())
    if (router.currentRoute.path.indexOf('/desktopsettings') === 0) {
      console.log('Dispatching invalidtoken event')
      document.getElementById('app').dispatchEvent(new Event('invalidtoken'))
    } else {
      auth.resetClient()
      window.location = auth.oauthUri()
    }
  } else if (error.message === 'Network Error') {
    console.error('Network Error')
  }

  return Promise.reject(error)
})

CMApi.baseURL = config.apiServer
Vue.prototype.restapi = CMApi
Vue.restapi = Vue.prototype.restapi

Vue.prototype.getImgUrl = getImgUrl
Vue.prototype.pad = pad

Vue.prototype.getOS = function () {
  const userAgent = window.navigator.userAgent
  const platform = window.navigator.platform
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  const iosPlatforms = ['iPhone', 'iPad', 'iPod']

  if (macosPlatforms.indexOf(platform) !== -1) return 'Mac OS'
  if (iosPlatforms.indexOf(platform) !== -1) return 'iOS'
  if (windowsPlatforms.indexOf(platform) !== -1) return 'Windows'
  if (/Android/.test(userAgent)) return 'Android'
  if (/Linux/.test(platform)) return 'Linux'
  return null
}

Vue.prototype.clients = {
  Windows: 'http://www.cameramanager.com/installer/cm/CameramanagerWin.exe',
  'Mac OS': 'http://www.cameramanager.com/installer/cm/CameramanagerMac.dmg',
  Linux: 'http://www.cameramanager.com/installer/cm/CameramanagerLinux.sh.tar.gz',
  iOS: 'https://itunes.apple.com/ng/app/cameramanager/id1333496857?mt=8',
  Android: 'https://play.google.com/store/apps/details?id=com.cameramanager.cm',
}

Vue.prototype.isIE = !!document.documentMode
Vue.prototype.greyImg = 'data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=='
Vue.prototype.supportedLanguages = config.supportedLanguages
Vue.prototype.clients = config.clients

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV === 'development'
Vue.config.performance = Vue.config.devtools
Vue.config.silent = !Vue.config.devtools

if (Vue.config.silent) {
  const console = {}
  console.log = function () {}
  console.debug = function () {}
  console.info = function () {}
  console.warn = function () {}
  console.error = function () {}
  window.console = console
}

Vue.use(VueCookie)

Vue.prototype.getMessageFromError = function (error, component) {
  return getMessageFromError(error, component)
}
Vue.use(CoreComponents)
Vue.use(Vuelidate)
Vue.use(VueLodash, { lodash: lodash })
Vue.use(VueMoment)
Vue.use(PageVisibility)
Vue.use(VueInputAutowidth)

Vue.prototype.isPageVisible = function () {
  return !PageVisibility.hidden()
}

// if (config['google-tag-manager']['enabled']) {
//   Vue.use(VueGtm, {
//     id: config['google-tag-manager']['tracking-id'],
//     queryParams: {
//       gtm_auth: config['google-tag-manager']['gtm_auth'],
//       gtm_preview: config['google-tag-manager']['gtm_preview'],
//       gtm_cookies_win:'x'
//     },
//     defer: false,
//     compatibility: false,
//     enabled: config['google-tag-manager']['enabled'],
//     debug: false,
//     loadScript: true,
//     vueRouter: router,
//     trackOnNextTick: false,
//   });
// }

router.beforeEach((to, from, next) => {
  if (location.search) {
    location.replace(getUrl(to.path, to.query))
  }
  // Only reroute if URI contains access token data
  if (to.query.hasOwnProperty('access_token') && to.query.hasOwnProperty('expires_in')) {
    auth.login(to.query.access_token, to.query.expires_in, to.query.skip_app_cookie)
    auth.getToken()
  }
  if (to.query.hasOwnProperty("device_brand")) {
    store.dispatch("setDeviceBrand", to.query.device_brand)
  }
  if (to.query.hasOwnProperty("device_model")) {
    store.dispatch("setDeviceModel", to.query.device_model)
  }
  if (to.query.hasOwnProperty("device_serial")) {
    store.dispatch("setDeviceSerial", to.query.device_serial)
  }
  if (to.query.hasOwnProperty("device_MAC_address")) {
    store.dispatch("setDeviceMAC", decodeURIComponent(to.query.device_MAC_address))
  }
  if (to.query.hasOwnProperty("skip_app_cookie")) {
    store.dispatch("setPasswordAccess", to.query.skip_app_cookie)
  }
  if (to.query.hasOwnProperty("cameraId")) {
    store.dispatch("setCameraId", to.query.cameraId)
  }
  if (to.query.hasOwnProperty("time")) {
    store.dispatch("setFootageTime", to.query.time)
  }
  if (to.query.hasOwnProperty("events")) {
    store.dispatch("setFootageEvents", to.query.events.split(','))
  }
  if (to.query.hasOwnProperty("zoom")) {
    store.dispatch("setFootageZoom", to.query.zoom)
  }
  if (Object.entries(to.query).length > 0  && !location.search) {
    if (store.getters.device_MAC_address) {
      router.replace({ name: 'AddCamera', query: {} })
    } else {
      router.replace({ path: to.path, query: {} })
    }
  }
  store.dispatch("setAuthorized", auth.isAuthorized())

  if (!store.getters.isAuthorized && (!to.meta.hasOwnProperty('auth') || to.meta.auth)) {
    if (to.path.indexOf('/desktopsettings') === 0) {
      console.log('Dispatching invalidtoken event')
      document.getElementById('app').dispatchEvent(new Event('invalidtoken'))
      next()
    } else if (auth.isLoggedIntoOtherApp()) {
      next({ path: '/firstlogout' })
    } else {
      auth.resetClient()
      window.location = auth.oauthUri()
    }
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  document.title = config.title
  if (!store.getters.isAuthorized && to.path.indexOf('/desktopsettings') === 0) {
    console.log('Dispatching invalidtoken event')
    document.getElementById('app').dispatchEvent(new Event('invalidtoken'))
  }
})

const i18n = locale.i18n

export const vm = new Vue({
  el: '#app',
  router,
  store,
  i18n,
  vuetify,
  components: { App },
  template: '<App/>',
})
