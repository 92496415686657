<template>
  <v-container v-if="!isAuthorized" class="ma-0 pa-0" fluid>
    <navigation :logo="logo"></navigation>
    <v-container fluid class="logout ma-0 pa-0">
      <v-row class="hidden-xs-and-down">
        <v-container fluid class="logout-content has-navigation ma-0 pa-2">
          <v-row class="fill-height" align="center" justify="center">
            <v-col class="pa-0" cols="12">
              <p class="headline text-center">
                {{ $t('You are now logged out. To login again click') }} <router-link to="/">
                  {{ $t('here') }}
                </router-link>.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
  import Navigation from '@/components/Navigation'
  import router from '@/service/router'

  // Because of a Chrome issue we needed to implement this special logout component
  const auth = require('@/service/auth')

  export default {
    name: 'Logout',
    components: { Navigation },
    props: ['logo'],
    computed: {
      isAuthorized () {
        return this.$store.getters.isAuthorized
      },
    },
    beforeMount () {
      if (this.isAuthorized) {
        auth.logout(false)
      } else {
        auth.deleteToken()
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "../assets/styles/main";

  .logout {
    .logout-content {
      border-radius: 16px;
      background-color: $color-panel-grey;
      min-height: 100vh;
      max-height: 100vh;
      &.has-navigation {
        min-height: $main-content-height;
        max-height: $main-content-height;
      }
    }
  }
</style>
